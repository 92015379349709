<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Bancos</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Maestros <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card items animated fadeIn fast">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label class="control-label">Tipo</label>
                    <select class="custom-select" (change)="handlerType( $event.target.value )">
                            <option value="Media Superior">Media Superior</option>
                            <option value="Alta Jubilación">Alta Jubilación</option>
                        </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 text-right pt-3">
                    <a class="btn btn-outline-success" [routerLink]="['/banks/affiliations/apply']">
                        <i class="fas fa-user-check"></i>
                        <span>Aplicar</span>
                    </a>
                </div>
            </div>
        </div>

        <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

        <app-loading-data *ngIf="loadingData"></app-loading-data>

        <ul class="item-list striped" *ngIf="!loadingData && jsonArray.length > 0">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Número</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>RFC</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Mensaje</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray; let i = index">
                <div class="item-row" [ngClass]="{'required': item.active != true}">
                    <div class="item-col item-col-item">
                        <div class="item-heading">Número</div>
                        <div> {{ item.identifier }} </div>
                    </div>
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">RFC</div>
                        <div> {{ item.rfc }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Mensaje</div>
                        <div>
                            <p *ngIf="item.bank === null">No cuenta con los datos bancarios.</p>
                            <p *ngIf="item.documentNumber === null">No cuenta con el numero de identificación.</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="item-list">
            <div class="item">
                <div class="form-group text-center m-t-10">
                    <button [disabled]="working || jsonArray.length === 0" class="btn btn-outline-info" (click)="generateFile()">
                            <i [ngClass]="{ 'far fa-file': !working, 'fas fa-sync-alt fa-spin': working }"></i>
                            <span *ngIf="!working">Generar</span>
                            <span *ngIf="working">Generando...</span>
                        </button>
                </div>
            </div>
        </div>
    </div>

</article>