import { Component, OnInit } from '@angular/core';

import { IncomingService } from './services/incoming.service';
declare let jsPDF;

@Component({
  selector: 'app-incoming',
  templateUrl: './incoming.component.html',
  styles: []
})
export class IncomingComponent implements OnInit {
  dataReport: any[] = [];
  dataReportInterest: any[] = [];
  dataInterestAhorro: any[] = [];
  dataInterestAportacion: any[] = [];
  detailed = false;
  searchValue = '';
  errorMessage = '';
  loadingData = false;
  interestDiv = false;
  dataString = '';
  amount = 0;
  amountAhorro = 0;
  amountAportacion = 0;
  //amountInterests = 0;
  amountTotal = 42;


  constructor(private incomingServie: IncomingService) { }

  ngOnInit() {

  }

  setErrorMessage( error ) { 
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    //Preguntar si es mejor usar variables globales o locales.
    var yearAndFort = this.getYearAndFortnight(value);
    if( yearAndFort != null){
      this.incomingServie.get(yearAndFort[0],yearAndFort[1])
      .subscribe((data)=>{
        this.dataReport = data;
        this.getAmountTotal(data);
        this.loadingData = false;
      
      }, error => this.setErrorMessage(error));
    }
  }

  searchData() {
    this.detailed = true;
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      this.detailed = false;
      search = 'null';
    }
    this.loadData( search );
    this.interestDiv = true;
  }

  getAmountTotal(data:any[]){
    data.forEach(item=>{
      this.amountTotal += item.importe;
      console.log("Suma "+this.amountTotal);
    });
  }

  /**
   * 
   * @param interestData Arreglo con los datos de la base de datos
   */
  calculateAmount(interestData: any[]){
    let interestWithImportAhorro = 0;
    let interestWithImportAportacion = 0;
    let interestAhorro = 0;
    let interestAportacion = 0;
    let teacherRFC = '';
    

    interestData.forEach(item=>{
      if ( item.rfc !== teacherRFC ) {
        teacherRFC = item.rfc;
        interestWithImportAhorro = 0;
      }
      let interestPeritem = 0;
      const arrayItem = Object.keys(item).map(function(key) {
        return [String(key), item[key]];
      });

      for (let index = 0; index < arrayItem.length; index++) {
        if ( arrayItem[index][0].slice(0, 3).toUpperCase() === 'QNA' ) {
          if ( arrayItem[index][1] !== 0 && item.tipo == "Ahorro" ) {
            const interestFortnight = (interestWithImportAhorro + arrayItem[index][1]) * 0.00208;
            interestPeritem += interestFortnight;
            interestWithImportAhorro += arrayItem[index][1] + interestFortnight;
          }else if(arrayItem[index][1] !== 0 && item.tipo == "Aportación" ){
            //TODO: Interes pror aportacion
            const interestFortnight = (interestWithImportAportacion + arrayItem[index][1]) * 0.00208;
            interestPeritem += interestFortnight;
            interestWithImportAportacion += arrayItem[index][1] + interestFortnight;
          }
        }
      }
      
      if(item.tipo == 'Ahorro'){
        this.amountAhorro += parseFloat(interestPeritem.toFixed(2));
        //interestAportacion += interestWithImportAhorro;
      }else if(item.tipo == 'Aportación'){
        this.amountAportacion += parseFloat(interestPeritem.toFixed(2));
      }
    });
    //this.amountAportacion = 0;
    //this.amountAhorro = interestAhorro;
  }
 

  /**
   * 
   * @param date Fecha en tipo cadena
   * Retorna un arreglo de string.
   * En el subindice 0 se encuentra el año
   * En el subindice 1 se encuentra el numero de quincena 
   */
  getYearAndFortnight(date: string){
    var dateModified = date.replace("-","/");
    dateModified = dateModified.replace("-","/");
    var fortnightNum = 0;
    var dateObj = new  Date (dateModified);
    var year = dateObj.getFullYear();
    var month = dateObj.getMonth();
    var day = dateObj.getDate();
    var x = 0;

    if(!isNaN(year) || !isNaN(month) || !isNaN(day)){
      if(day >= 1 && day <= 15){
        x=1;
      }else if(day > 15 && day <=31){
        x=2;
      }
      
      fortnightNum = month * 2 + x;
  
      console.log("# qcna" + fortnightNum);
  
      return [year +"",fortnightNum +""];
    }else{
      return null;
    }
  }
}