<article class="content">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/administration/data-update', searchValue]" routerLinkActive="router-link-active">Actualización de datos</a></li>
          <li class="breadcrumb-item active" aria-current="page">Detalle</li>
      </ol>
  </nav>

  <div class="title-search-block">
      <div class="title-block">
          <div class="row">
              <div class="col-md-6">
                  <h3 class="title">
                      <h3 class="title">Detalle</h3>
                  </h3>
              </div>
          </div>
      </div>
  </div>

  <div class="card card-block animated fadeIn fast">
    
    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <app-loading-data *ngIf="loadingData" ></app-loading-data>

    <div class="row">
      <label class="col-md-3"># Empleado</label>
      <label class="col-md-3">{{ dataupdate.employeeNumber }}</label>
      <label class="col-md-3">RFC</label>
      <label class="col-md-3">{{ dataupdate.rfc }}</label>
      <label class="col-md-3">CURP</label>
      <label class="col-md-3">{{ dataupdate.curp }}</label>
      <label class="col-md-3">Paterno</label>
      <label class="col-md-3">{{ dataupdate.lastName }}</label>
      <label class="col-md-3">Materno</label>
      <label class="col-md-3">{{ dataupdate.motherLastName }}</label>
      <label class="col-md-3">Nombre</label>
      <label class="col-md-3">{{ dataupdate.name }}</label>
      <label class="col-md-3">Fecha de Nacimiento</label>
      <label class="col-md-3">{{ dataupdate.birthdate | date: 'dd/MM/yyyy' }}</label>
      <label class="col-md-3">Genero</label>
      <label class="col-md-3">{{ dataupdate.gender }}</label>
      <label class="col-md-3">Correo electrónico</label>
      <label class="col-md-3">{{ dataupdate.email }}</label>
      <label class="col-md-3">Teléfono</label>
      <label class="col-md-3">{{ dataupdate.phone }}</label>
      <label class="col-md-3">Calle</label>
      <label class="col-md-3">{{ dataupdate.street }}</label>
      <label class="col-md-3">Número</label>
      <label class="col-md-3">{{ dataupdate.number }}</label>
      <label class="col-md-3">Colonia</label>
      <label class="col-md-3">{{ dataupdate.colony }}</label>
      <label class="col-md-3">Código Postal</label>
      <label class="col-md-3">{{ dataupdate.zipCode }}</label>
  </div>

      <table class="table table-striped table-hover" >
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Parentesco</th>
                <th>Porcentaje</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of dataupdate.beneficiaries; let i =index;" >
                <td >{{ item.name }}</td>
                <td >{{ item.relationship }}</td>
                <td >{{ item.percentage }}</td>
            </tr>
        </tbody>
    </table>
  </div>
</article>