import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { LowStaffInterfaces } from '../../administration/low-staffing/interfaces/low-staff.interfaces';
import { LowStaffService } from '../../administration/low-staffing/services/low-staff.service';
import { SupportInterface } from '../../administration/supports/interfaces/support.interface';

@Component({
  selector: 'app-low-staffing-report-details',
  templateUrl: './low-staffing-report-details.component.html',
  styles: [
  ]
})
export class LowStaffingReportDetailsComponent implements OnInit {
  searchValue = '';
  loadingData = false;
  waytopay = 3;
  errorMessage = '';
  start = '';
  end = '';
  amount = 0;
  amountInterests = 0;
  dataReport: any[] = [];
  percentageInterest = 0.00208;
  supports: Array<SupportInterface>;
  lowstaffing: any;

  constructor( private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    private lowStaffingService: LowStaffService ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.loadingData = true;
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }
      if (params['waytopay']) {
        this.waytopay = params['waytopay'];
      }
      if (params['start']) {
        this.start = params['start'];
      }
      if (params['end']) {
        this.end = params['end'];
      }

      if (params['id'] && params['type']) {
        this.lowStaffingService.getById( params['id'], params['type'] ).subscribe(
          lowstaffing => {
            this.lowstaffing = lowstaffing;
            // this.supports = this.lowstaffing.teacher.supports.filter( s => s.type === 'Defunción Adherente' );
            // this.loadData(this.teacher.id);
            this.loadingData = false;
          },
          error => this.setErrorMessage(error));
      }
    });
  }

  setErrorMessage( error ) {
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

}
