import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../account/login/services/account.service';
import { LoanInterface } from './interfaces/loan.interface';
import { DrawService } from '../draws/services/draw.service';
import { TeacherService } from '../teachers/services/teacher.service';
import { LoanService } from './services/loan.service';
import { LoanParameterService } from '../../catalogs/loan-parameter/services/loan-parameter.service';
import { LoanParameterInterface } from '../../catalogs/loan-parameter/interfaces/loan-parameter.interface';
import { RegionService } from '../../catalogs/regions/services/region.service';
import { RegionInterface } from '../../catalogs/regions/interfaces/region.interface';
import { FinancialLiquidityService } from '../financial-liquidity/services/financial-liquidity.service';
import { FinancialLiquidityInterface } from '../financial-liquidity/interfaces/financial-liquidity.interface';
import { BankInterface } from '../banks/interfaces/bank.interface';
import { BankService } from '../banks/services/bank.service';
import { SavingReportService } from '../../reports/savings/services/saving-report.service';
import { OpenDirective } from '../../modals/open.directive';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { ModuleListComponent } from '../../configuration/modules/module-list.component';

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styles: []
})
export class LoanComponent implements OnInit {
  title = 'Agregar';
  haveAdmission = false;
  editing = false;
  returnPath = '/administration/loans/pending';
  forma: UntypedFormGroup;
  message: string;
  LoadingData = false;
  sendingData = false;
  amount = 0;
  amountInterests = 0;
  amountSavings = 0;
  banks = Array<BankInterface>();
  movementTypes = ['Cheque', 'Transferencia'];
  searchValue = '';
  loanParameters: LoanParameterInterface[] = [];
  prefixesParameters: LoanParameterInterface[] = [];
  fortnightsStart: string[] = [];
  fortnightsEnd: string[] = [];
  numberFortnights: number;
  regions: RegionInterface[] = [];
  financialLiquidity: FinancialLiquidityInterface[] = [];
  prefixes: Array<string> = [];
  loan: LoanInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    bankId: '00000000-0000-0000-0000-000000000000',
    teacherStatusId: '00000000-0000-0000-0000-000000000000',
    date: new Date(),
    active: true,
    drawId: '',
    teacherId: '',
    loanParameterId: '',
   prefix: '',
    individualAccount: '',
    checkNumber: '0',
    amount: 0,
    discount: 0,
    interests: 0,
    comment: '',
    loanNumber: '',
    fortnightStart: '',
    fortnightEnd: '',
    status: '',
    openingBonus: 0,
    remainingFortnightly: 0,
    warranty: 0,
    applied: false,
    haveFinancialLiquidity: false,
    account: '',
    movementType: '',
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    draw: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: true,
      name: 'N/A',
      openDate: new Date(),
      closeDate: new Date(),
      status: '',
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      loans: []
    },
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      email: '',
      admission: '',
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      lastStatus: 'Activo',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      applied: true,
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      document: 0,
      documentNumber: '',
      NumberOfLoans: 0,
      supports: [],
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      lowStaffing: []
    },
    region: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date,
      active: true,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: 'N/A'
    },
    loanParameter: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      active: true,
      amount: 0,
      discount: 0,
      prefix: '',
      interests: 0,
      totalPerceptions: 0,
      saving: 0,
      guaranteedPercentage: 0,
      openingBonus: 0,
      fortnightId: '00000000-0000-0000-0000-000000000000',
      fortnight: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: true,
        numberFortnight: 0
      },
      fortnightCapital: 0,
      fortnightIVA: 0,
      fortnightInterest: 0,
      fortnightLifeInsurance: 0,
      iva: 0,
      lifeInsurance: 0,
      type: '',
      teachers: '',
      accountingConcept: ''
    },
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: 'Prueba',
      code: '00'
    },
    teacherStatus: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date,
      active: true,
      name: 'Activo',
      percentageSaving: 0,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000'
    },
    acceptanceDate: new Date(),
    protectionPremium: 0,
    block: 0,
    questions: [],
    payments: []
  };
  indexFortnightsStart = 0;
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor( public accountService: AccountService, private formBuilder: UntypedFormBuilder,
    private router: Router, private activatedRoute: ActivatedRoute,
    private drawService: DrawService, private loanParameterService: LoanParameterService,
    private teacherService: TeacherService, private loanService: LoanService,
    private regionService: RegionService, private financialLiquidityService: FinancialLiquidityService,
    private bankService: BankService,
    private reportService: SavingReportService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private notificationService: NotificationService,
    private helperService: HelperService) {
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      drawId: ['', [Validators.required]],
      loanParameterId: ['', [Validators.required]],
      prefix: [{ value: '', disabled: this.editing  }, [Validators.required]],
      individualAccount: [''],
      checkNumber: [''],
      amount: ['', [Validators.required]],
      discount: ['', [Validators.required]],
      interests: ['', [Validators.required]],
      comment: [''],
      loanNumber: [{ value: '', disabled: this.editing  }, [Validators.required]],
      fortnightStart: ['', [Validators.required]],
      fortnightEnd: ['', [Validators.required]],
      status: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      teacherAdmission: ['', [Validators.required]],
      haveFinancialLiquidity: [''],
      account: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      clabe: ['', [Validators.minLength(18), Validators.maxLength(18)]],
      movementType: ['', [Validators.required]],
      bankId: ['', [Validators.required]],
      teacherStatusId: ['', [Validators.required]],
      street: [''],
      number: [''],
      colony: [''],
      zipCode: ['', [Validators.minLength(5), Validators.maxLength(5)]],
      email: [''],
      phone: ['', Validators.maxLength(20)],
      protectionPremium: ['']
    });
  }

  ngOnInit() {
    this.LoadingData = true;
    this.buildForm();

    this.drawService.getOpen()
      .subscribe(
        draw => {
          if (draw) {
            this.loan.draw = draw;
            this.loan.drawId = draw.id;
            this.regionService.get('null')
              .subscribe(
                regions => {
                  this.regions = regions;
                  this.fortnightsStart = this.getFortnights(30);
                  this.fortnightsEnd = this.getFortnights(110);
                  this.bankService.get('null').subscribe( banks => {
                    this.banks = banks;
                    this.activatedRoute.params.subscribe(params => {
                      if ( params['returnPath'] ) {
                        this.returnPath = params['returnPath'];
                      }

                      if (params['id']) {
                        this.title = 'Editar';
                        this.editing = true;
                        this.loanService.getById( params['id'] )
                          .subscribe(data => {
                            this.loan = data;
                            this.loanParameterService
                              .GetLoanParameterByTeacher(this.loan.teacher.type)
                              .subscribe(
                                (parameters) => {
                                  this.loanParameters = parameters;
                                  this. prefixes = Array.from(new Set(this.loanParameters.map((item: any) => item.prefix)));
                                  this.loan.teacher = data.teacher;
                                  if (this.loan.teacher.admission) {
                                    this.haveAdmission = true;
                                  }
                                  this.setValues(this.loan);
                                  this.LoadingData = false;
                                },
                                (error) => this.errorMessage(error)
                              );
                          }, error => this.errorMessage(error));
                      } else if (params['teacher']) {
                        this.loan.fortnightStart = this.fortnightsStart[0];
                        this.loan.teacherId = params['teacher'];
                        this.teacherService.getById(params['teacher'])
                          .subscribe(teacher => {
                            this.loan.regionId = teacher.regionId;
                            this.loan.teacher = teacher;
                            this.getData(this.loan.teacher.rfc);
                            this.financialLiquidityService.getLastTwoYears(teacher.id)
                              .subscribe(financialLiquidity => {
                                this.financialLiquidity = financialLiquidity;
                                if (this.loan.teacher.admission) {
                                  this.haveAdmission = true;
                                }

                                this.loanParameterService.GetLoanParameterByTeacher(teacher.type)
                                  .subscribe(parameters => {
                                    this.loanParameters = parameters;
                                   this. prefixes = Array.from(new Set(this.loanParameters.map((item: any) => item.prefix)));
                                    this.setValues(this.loan);
                                    this.LoadingData = false;
                                  }, error => this.errorMessage(error));
                              }, error => this.errorMessage(error));
                            // this.forma.setValue(this.loan);
                          }, error => this.errorMessage(error));
                      }
                    });
                  }, error => this.errorMessage(error));
                }, error => this.errorMessage(error));
          } else {
            this.message = 'No se encuentra ningún sorteo abierto.';
            this.LoadingData = false;
          }
        },
        error => this.errorMessage(error)
      );
  }

  setValues( loan: LoanInterface ): void {
    this.forma.controls['drawId'].patchValue( loan.drawId );
    this.forma.controls['prefix'].patchValue( loan.loanParameter.prefix );
    if( this.editing ) {
      this.setPrefixParameter();
      this.setParameters( loan.loanParameterId );
    }
    this.forma.controls['loanParameterId'].patchValue( loan.loanParameterId );
    this.forma.controls['individualAccount'].patchValue( loan.individualAccount );
    this.forma.controls['checkNumber'].patchValue( loan.checkNumber );
    this.forma.controls['amount'].patchValue( loan.amount );
    this.forma.controls['discount'].patchValue( loan.discount );
    this.forma.controls['interests'].patchValue( loan.interests );
    this.forma.controls['comment'].patchValue( loan.comment );
    this.forma.controls['loanNumber'].patchValue( loan.loanNumber );
    this.forma.controls['fortnightStart'].patchValue( loan.fortnightStart );
    this.forma.controls['fortnightEnd'].patchValue( loan.fortnightEnd );
    this.forma.controls['status'].patchValue( loan.status );
    this.forma.controls['regionId'].patchValue( loan.teacher.regionId );
    this.forma.controls['teacherAdmission'].patchValue( loan.teacher.admission );
    this.forma.controls['haveFinancialLiquidity'].patchValue( loan.haveFinancialLiquidity );
    if ( loan.account !== '' ) {
      if (loan.account.length === 10 ) {
        this.forma.controls['account'].patchValue( loan.account );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      } else if ( loan.account.length > 10 ) {
        this.forma.controls['clabe'].patchValue( loan.account );
        this.forma.controls['account'].patchValue( '0000000000' );
      } else if ( loan.teacher.clabe.length === 10 ) {
        this.forma.controls['account'].patchValue( loan.teacher.clabe );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      } else if ( loan.teacher.clabe.length > 10 ) {
        this.forma.controls['clabe'].patchValue( loan.teacher.clabe );
        this.forma.controls['account'].patchValue( '0000000000' );
      } else {
        this.forma.controls['clabe'].patchValue( '' );
        this.forma.controls['account'].patchValue( '' );
      }
    } else if (this.editing) {
      this.forma.controls['clabe'].patchValue( '000000000000000000' );
      this.forma.controls['account'].patchValue( '0000000000' );
    } else {
      this.forma.controls['clabe'].patchValue( '' );
      this.forma.controls['account'].patchValue( '' );
    }

    this.forma.controls['movementType'].patchValue( loan.movementType );

    if(this.editing && loan.movementType === 'Transferencia') {
      if ( this.loan.bankId !== null ) {
        this.forma.controls['bankId'].patchValue( loan.bankId );
      } else if ( loan.teacher.bankId ) {
        this.forma.controls['bankId'].patchValue( loan.teacher.bankId );
      } else {
        this.forma.controls['bankId'].patchValue( '00000000-0000-0000-0000-000000000000' );
      }
    } else {
      if ( loan.teacher.bankId ) {
        this.forma.controls['bankId'].patchValue( loan.teacher.bankId );
      } else {
        this.forma.controls['bankId'].patchValue( '' );
      }
    }
    
    this.forma.controls['teacherStatusId'].patchValue( loan.teacher.teacherStatus.find(t => t.active === true).id );
    this.forma.controls['street'].patchValue( loan.teacher.street );
    this.forma.controls['number'].patchValue( loan.teacher.number );
    this.forma.controls['colony'].patchValue( loan.teacher.colony );
    this.forma.controls['zipCode'].patchValue( loan.teacher.zipCode );
    this.forma.controls['email'].patchValue( loan.teacher.email );
    this.forma.controls['phone'].patchValue( (loan.teacher.teacherPhones.length === 0 ) ? '' : loan.teacher.teacherPhones[0].phone );
    this.forma.controls['protectionPremium'].patchValue( loan.protectionPremium );
  }

  setFormatCheck(value: string): string {
    let checkWithFormat = value.toString();
    while (checkWithFormat.length < 7) {
      checkWithFormat = `0${ checkWithFormat }`;
    }
    return checkWithFormat;
  }

  sendForm(): void {
    this.sendingData = true;
    this.getValues(this.loan);
    this.loan.date = new Date();
    if ( this.title === 'Agregar') {
      this.loanService.create(this.loan)
        .subscribe(() => {
          this.router.navigate([this.returnPath, this.searchValue]);
        }, error => this.errorMessage(error));
    } else {
      this.loanService.update(this.loan.id, this.loan)
            .subscribe(() => {
              this.router.navigate([this.returnPath, this.searchValue]);
            }, error => this.errorMessage(error));
    }
  }

  getValues( loan: LoanInterface ) {
    loan.drawId = this.forma.controls['drawId'].value;
    loan.loanParameterId = this.forma.controls['loanParameterId'].value;
    loan.individualAccount = this.forma.controls['individualAccount'].value;
    loan.checkNumber = this.forma.controls['checkNumber'].value;
    loan.amount = this.forma.controls['amount'].value;
    loan.discount = this.forma.controls['discount'].value;
    loan.interests = this.forma.controls['interests'].value;
    loan.comment = this.forma.controls['comment'].value + ' ' + this.message;
   // loan.prefix = this.forma.controls['prefix'].value;
    loan.loanNumber = this.forma.controls['prefix'].value + '-' + this.forma.controls['loanNumber'].value;
    loan.fortnightStart = this.forma.controls['fortnightStart'].value;
    loan.fortnightEnd = this.forma.controls['fortnightEnd'].value;
    loan.status = this.forma.controls['status'].value;
    loan.regionId = this.forma.controls['regionId'].value;
    loan.haveFinancialLiquidity = this.forma.controls['haveFinancialLiquidity'].value;
    loan.teacherStatusId = this.forma.controls['teacherStatusId'].value;
    loan.movementType = this.forma.controls['movementType'].value;
    // Cheque
    if ( this.forma.controls['movementType'].value === this.movementTypes[0] ) {
      loan.account = '';
    } else {
      loan.bankId = this.forma.controls['bankId'].value;
      if ( this.forma.controls['bankId'].value !== 'be28ff92-e845-41bb-8bee-3060267a7023' ) {
        loan.account = this.forma.controls['clabe'].value;
      } else {
        loan.account = this.forma.controls['account'].value;
      }
    }
    this.loan.teacher.street = this.forma.controls['street'].value;
    this.loan.teacher.number = this.forma.controls['number'].value;
    this.loan.teacher.colony = this.forma.controls['colony'].value;
    this.loan.teacher.zipCode = this.forma.controls['zipCode'].value;
    this.loan.teacher.email = this.forma.controls['email'].value;
    if ( this.forma.controls['phone'].value !== '' ) {
      this.loan.teacher.teacherPhones.push({
        id: '00000000-0000-0000-0000-000000000000',
        userId: this.accountService.getUserId(),
        teacherId: this.loan.teacher.id,
        date: new Date(),
        active: true,
        lastUpdated: new Date,
        phoneTypeId: '176BF9E8-E83F-428D-AD19-0CBAECDD40AB',
        userIdLastUpdated: this.accountService.getUserId(),
        phone: this.forma.controls['phone'].value
      });
    }
  }

  getFortnights(numberFortnights: number): string[] {
    const arrFortnight = [];
    let counter = 1;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        arrFortnight.push( date.getFullYear().toString() + '-' + this.setFormatDay(numberFortnight) );
        counter++;
      }
      numberFortnight++;
      if ( numberFortnight === 25 ) {
        numberFortnight = 1;
      }
      this.getDateFortnight(date);
    } while ( counter <= numberFortnights );
    return arrFortnight;
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
      date.setDate( date.getDate() - 1 );
    } else {
      date.setDate( date.getDate() + 15 );
    }
  }

  setFormatDay(day: number): string {
    if ( day.toString().length === 1 ) {
      return '0' + day.toString();
    }
    return day.toString();
  }

  changeParameter( ): void {
    const fortnightId = this.forma.controls['loanParameterId'].value;
    this.loan.haveFinancialLiquidity = this.forma.controls['haveFinancialLiquidity'].value;
    this.setParameters(fortnightId);
  }

  setPrefixParameter( ) {
    this.prefixesParameters = this.loanParameters.filter(x => x.prefix == this.forma.controls['prefix'].value );
  }

  handlerCHangeMovement( ) {
    if ( this.forma.controls['movementType'].value === 'Cheque' ) {
      this.forma.controls['bankId'].patchValue( '00000000-0000-0000-0000-000000000000' );
      this.forma.controls['account'].patchValue( '0000000000' );
      this.forma.controls['clabe'].patchValue( '000000000000000000' );
    } else {
      this.forma.controls['bankId'].patchValue( this.loan.teacher.bankId );
      if ( this.loan.teacher.clabe.length === 10 ) {
        this.forma.controls['account'].patchValue( this.loan.teacher.clabe );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      } else if ( this.loan.teacher.clabe.length > 10 ) {
        this.forma.controls['clabe'].patchValue( this.loan.teacher.clabe );
        this.forma.controls['account'].patchValue( '0000000000' );
      } else {
        this.forma.controls['clabe'].patchValue( '' );
        this.forma.controls['account'].patchValue( '' );
      }
    }
  }

  handlerBanks() {
    if ( this.forma.controls['bankId'].value === 'be28ff92-e845-41bb-8bee-3060267a7023' ) {
      if ( this.loan.teacher.clabe.length === 10 ) {
        this.forma.controls['account'].patchValue( this.loan.teacher.clabe );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      } else {
        this.forma.controls['account'].patchValue( '' );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      }
    } else {
      if ( this.loan.teacher.clabe.length > 10 ) {
        this.forma.controls['clabe'].patchValue( this.loan.teacher.clabe );
        this.forma.controls['account'].patchValue( '0000000000' );
      } else {
        this.forma.controls['account'].patchValue( '0000000000' );
        this.forma.controls['clabe'].patchValue( '' );
      }
    }
  }

  setParameters(id: string): void {
    let reset = true;
    this.message = '';
    this.loanParameters.forEach(parameter => {
      if ( parameter.id === id ) {
        if ( !this.editing ) {
          this.loan.discount = parameter.discount;
          this.loan.interests = parameter.interests;
          this.loan.amount = parameter.amount - parameter.openingBonus - (parameter.amount * (parameter.guaranteedPercentage / 100));
          this.loan.openingBonus = parameter.openingBonus;
          this.loan.warranty = parameter.amount * (parameter.guaranteedPercentage / 100);
          this.loan.remainingFortnightly = parameter.fortnight.numberFortnight;
          this.loan.protectionPremium = parameter.lifeInsurance;
          this.loan.status = this.evaluteLoan(parameter);
          this.loan.teacher.credit = parameter.amount;
        }
        this.numberFortnights = parameter.fortnight.numberFortnight - 1;
        this.forma.controls['fortnightEnd'].patchValue( this.fortnightsEnd[this.numberFortnights + this.indexFortnightsStart] );
        this.forma.controls['interests'].patchValue( parameter.interests );
        this.forma.controls['discount'].patchValue( parameter.discount );
        this.forma.controls['amount'].patchValue( parameter.amount );
        this.forma.controls['comment'].patchValue( this.loan.comment );
        this.forma.controls['status'].patchValue( this.loan.status );
        this.forma.controls['protectionPremium'].patchValue( this.loan.status );
        reset = false;
      }
    });
    if ( reset ) {
      this.loan.discount = 0;
      this.loan.interests = 0;
      this.loan.openingBonus = 0;
      this.loan.warranty = 0;
      this.loan.remainingFortnightly = 0;
      this.forma.controls['fortnightEnd'].patchValue( '' );
      this.forma.controls['interests'].patchValue( 0 );
      this.forma.controls['amount'].patchValue( 0 );
      this.forma.controls['discount'].patchValue( 0 );
      this.forma.controls['status'].patchValue( '' );
      this.forma.controls['comment'].patchValue( '' );
    }
  }

  evaluteLoan( loanParameter: LoanParameterInterface ): string {
    let status = '';
    // this.loan.comment = '';
    this.message = '';
    this.getData(this.loan.teacher.rfc);
    if ( this.amountSavings >= loanParameter.saving ) {
      if ( !this.loan.teacher.loans.some( l => l.status === 'Activo' ) ) {
        if ( this.loan.teacher.birthdate && this.loan.teacher.admission ) {
          if ( this.loan.haveFinancialLiquidity.toString() === 'true' ) {
            status = 'Activo';
          } else {
            this.setErrorEvaluate('El maestro no cuenta con la suficiente liquidez financiera.');
            status = 'Cancelado';
          }
        } else {
          this.setErrorEvaluate('El maestro no cuenta con las fechas para el cálculo.');
          status = 'Cancelado';
        }
      } else {
        this.setErrorEvaluate('El maestro cuenta con un prestamo activo.');
        status = 'Cancelado';
      }
    } else {
      this.setErrorEvaluate('El maestro no cuenta con los ahorros necesarios para el prestamos.');
      status = 'Cancelado';
    }
    return status;
  }

  getData(rfc: string) {
    this.reportService.get( rfc )
      .subscribe(( data ) => {
        this.calculateAmount( data );
      }, error => this.setErrorEvaluate(error));
  }

  calculateAmount(data: any[]) {
    this.amountSavings = 0;
    let interestWithImport = 0;
    let teacherRFC = '';

    data.forEach(year => {
      if ( year.rfc !== teacherRFC ) {
        teacherRFC = year.rfc;
        interestWithImport = 0;
      }
      let interestPeritem = 0;
      const arrayYear = Object.keys(year).map(function(key) {
        return [String(key), year[key]];
      });
      for (let index = 0; index < arrayYear.length; index++) {
        if ( arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA' ) {
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * 0.00208;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
          }
        }
      }
      year.interests = parseFloat(interestPeritem.toFixed(2));
      this.amountInterests += year.interests;
      this.amountSavings += (year.interests + year.total);
    });
  }

  blurAdmission( value: string ): void {
    if ( value ) {
      this.loan.teacher.admission = value;
      this.forma.controls['teacherAdmission'].patchValue( this.loan.teacher.admission );
      this.changeParameter();
    }
  }

  setErrorEvaluate( message: string): void {
    // this.loan.comment = message;
    this.message = message;
  }

  getNumberFortnight(id: string): number {
    let numberFortnight = 0;
    this.loanParameters .forEach(parameter => {
      if ( parameter.id === id ) {
        numberFortnight = parameter.fortnight.numberFortnight;
      }
    });
    return numberFortnight;
  }

  errorMessage( error: any ) {
    console.error(error);
    if (error && error.error) {
      this.message = error.message;
      this.LoadingData = false;
      this.sendingData = false;
    }
  }

  changeFortnightStart(): void {
    // tslint:disable-next-line: max-line-length
    this.indexFortnightsStart = this.getGetIndex(this.fortnightsStart, this.forma.controls['fortnightStart'].value);    if ( this.loan.amount !== 0 ) {
      this.forma.controls['fortnightEnd'].patchValue( this.fortnightsEnd[this.numberFortnights + this.indexFortnightsStart] );
    }
  }
  getGetIndex(array: any[], value: string): number {
    const BreakException = { };
    let index = 0;
    try {
      array.forEach(element => {
        if ( element === value ) {
          throw BreakException;
        }
        index += 1;
      });
    } catch (e) {
      if (e !== BreakException) { throw e; }
    }
    return index;
  }

  async showChangeStatus(  ) {
    const credentials = await this.notificationService.showConfirmAuthentication('¿Seguro que desea cambiar el estatus del prestamos?');
    if(credentials !== null) {
      this.canChangeStatus(credentials);
    }
  }

  canChangeStatus(userInfo: any ) {
    this.accountService.postCheckChangeStatus(userInfo)
    .subscribe(( ) => {
      this.loan.status = 'Activo';
      this.forma.controls['status'].patchValue( this.loan.status );
    }, error => {
      if ( this.message === '' ) {
        this.message = error.error[''][0];
      } else {
        this.message = this.message + error.error[''][0];
      }
    });
  }

  validData(): boolean {
    if ( !this.forma.valid ) {
      return false;
    }

    if( this.loan.status === 'Cancelado' ) {
      return false;
    }

    if ( this.forma.controls['movementType'].value === 'Transferencia' ) {
      if ( this.forma.controls['bankId'].value === '00000000-0000-0000-0000-000000000000' ) {
        return false;
      } else {
        if ( this.forma.controls['bankId'].value === 'be28ff92-e845-41bb-8bee-3060267a7023' &&
        this.forma.controls['account'].value === '' ) {
          return false;
        } else if ( this.forma.controls['bankId'].value !== 'be28ff92-e845-41bb-8bee-3060267a7023' &&
        this.forma.controls['clabe'].value === '' ) {
          return false;
        }
      }
    }

    return true;
  }

}
