import { Component, OnInit } from '@angular/core';
import { LoanReportService } from './services/loan-report.service';
import { DrawService } from '../../administration/draws/services/draw.service';
import { DrawInterface } from '../../administration/draws/interfaces/draw.interface';
import { LoanService } from '../../administration/loans/services/loan.service';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-loan-report',
  templateUrl: './loan-report.component.html',
  styles: []
})
export class LoanReportComponent implements OnInit {
  itemsPerPage = 15;
  currentPage = 1;
  jsonArray: any[] = [];
  detailed = false;
  searchValue = '';
  errorMessage = '';
  loadingData = false;
  typeSelected = 'null';
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  // draws: DrawInterface[] = [];

  constructor( private reportService: LoanReportService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    // this.detailed = false;
    this.loadData( 'null' );
    /* this.drawService.get('null')
      .subscribe(data => {
        this.draws = data;
        this.loadData( this.drawSelected );
      }, error => this.setErrorMessage(error)); */
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  loadData( searchValue: string ) {
    this.reportService.get( searchValue, this.typeSelected, this.inicio, this.fin )
    .subscribe(data => {
      this.jsonArray = data;
      console.log(this.jsonArray);
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  searchData() {
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search);
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  exportData() {
    this.excelService.exportAsExcelFile(this.jsonArray,
      `Prestamos_${ this.inicio }_${ this.fin }`);
  }

}
