<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Póliza abonos</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" >
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" >
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Origen</label>
                        <select class="custom-select" formControlName="source">
                          <option value="SEECH" >SEECH</option>
                          <option value="DOMICILACION" >DOMICILACIÓN</option>
                      </select>
                    </div>
                </div> -->
            </div>
        </form>

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="isLoadingData" type="button" class="btn btn-outline-success" (click)="getExcel()">
                    <i [ngClass]="{ 'far fa-file-excel': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                    <span *ngIf="!isLoadingData"> Generar Poliza</span>
                    <span *ngIf="isLoadingData" >
                         Procesando...
                    </span>
                </button>

                <button [disabled]="isLoadingData" type="button" class="btn btn-outline-success" (click)="getExcelData()">
                    <i [ngClass]="{ 'far fa-file-excel': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                    <span *ngIf="!isLoadingData"> Exportar</span>
                    <span *ngIf="isLoadingData" >
                         Procesando...
                    </span>
                </button>

            </div>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
    </div>
</article>
