import { Component } from '@angular/core';
import { ApplicationService } from './services/application.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styles: []
})
export class ApplicationListComponent {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];

  constructor( private applicationService: ApplicationService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService ) {
    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
        this.loadData(this.searchValue);
      } else {
        this.loadData('null');
      }
    });
  }

  loadData(value: string) {
    this.loadingData = true;
    this.applicationService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  search() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregar() {
    const text = this.searchValue;
    this.router.navigate( ['configuration/applications/add', '', text] );
  }

  deleteItem(id: string) {
    this.applicationService.delete(id).subscribe(
      () => {
        this.loadData('null');
        this.notificationService.toastConfirm('Se elimino correctamente la aplicación');
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  showConfirm(id: string, name: string) {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar la aplicación ${name}?`, 'Eliminar aplicación')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(id);
      }
    });
  }

}
