import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { DeadlineInterface } from './interfaces/deadline.interface';
import { DeadlineService } from './services/deadline.service';

@Component({
  selector: 'app-deadline-detail',
  templateUrl: './deadline-detail.component.html',
  styles: []
})
export class DeadlineDetailComponent implements OnInit {
  errorMessage = '';
  loadingData = false;
  searchValue = '';
  deadline: DeadlineInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    fortnightId: '00000000-0000-0000-0000-000000000000',
    amount: 0,
    discount: 0,
    interests: 0,
    totalPerceptions: 0,
    saving: 0,
    openingBonus: 0,
    guaranteedPercentage: 0,
    iva: 0,
    lifeInsurance: 0,
    fortnightInterest: 0,
    fortnightIVA: 0,
    fortnightCapital: 0,
    fortnightLifeInsurance: 0,
    type: '',
    teachers: '',
    accountingConcept: '',
    fortnight: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      numberFortnight: 0
    },
    prefix: '',
    percentageInterest: 0
  };

  constructor(
    private deadlineService: DeadlineService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accountService: AccountService
  ) { }

  ngOnInit() {
    this.loadingData = true;
    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if ( params['id'] ) {
        this.deadlineService.getById(params['id'])
          .subscribe(data => {
            this.deadline = data;
            this.loadingData = false;
            console.log(this.deadline.amount);
          },
            error => this.setErrorMessage(error));
      } else {
        this.router.navigate(['/administration/deadlines', this.searchValue]);
      }
    });
  }

  setErrorMessage( error: any ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

}
