<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
            <li class="breadcrumb-item"><a [routerLink]="[pathReturn, year, fortnight]" routerLinkActive="router-link-active">Búsqueda Global</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Estatus</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" >
        <form [formGroup]="forma">
            <div class="row">
                <div class="col-8">
                    <h2 class="m-1">{{ teacher.name }}<small> ({{ teacher.rfc }})</small></h2>
                </div>

                <label class="col-md-1 m-2">
            Estatus
          </label>
                <div class="col-md-2 m-2">
                    <select class="custom-select" formControlName="status" (change)="handlerStatus( $event.target.value )">
                        <option value="00000000-0000-0000-0000-000000000000" >Todos</option>
                        <option [value]="status.id" *ngFor="let status of teacher.teacherStatus" >{{ status.name }}</option>
                    </select>
                </div>
            </div>
        </form>

        <hr />

        <fieldset>
            <div class="row pl-2 pr-2">
                <div class="col-md-2">
                    <legend>Ahorros</legend>
                </div>
                <div class="col-md-10 text-right">
                    <div class="row">
                        <label class="col-md-2">
                            Fecha
                        </label>

                        <div class="col-md-2">
                            <input type="date" class="form-control" name="fin" [value]="date" [(ngModel)]="date">
                        </div>

                        <div class="col-md-2">
                            <input type="button" (click)="searchPeriod()" class="btn btn-primary" value="Buscar" />
                        </div>

                        <label class="col-md-2">
                            Porcentaje
                      </label>

                        <div class="col-md-2">
                            <select class="custom-select" (change)="calculateSavings( $event.target.value )">
                              <option value="0.00208">5 %</option>
                              <option value="0.00167">4 %</option>
                            </select>
                        </div>

                        <div class="col-2 text-right" >
                            <button type="button" class="btn btn-outline-primary" (click)="getPDFSaving( )">
                                <i class="fas" [ngClass]="{ 'fa-file-pdf': !processingSaving, 'fa-sync-alt fa-spin': processingSaving }"></i>
                                <span *ngIf="!processingSaving"> Generar PDF</span>
                                <span *ngIf="processingSaving" >
                                    Procesando...
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <app-loading-data *ngIf="loadingData"></app-loading-data>

            <table class="table table-striped" *ngIf="!loadingData">
                <thead>
                    <tr>
                        <th scope="col">Año</th>
                        <th scope="col">Qna 1<br />Qna 13</th>
                        <th scope="col">Qna 2<br />Qna 14</th>
                        <th scope="col">Qna 3<br />Qna 15</th>
                        <th scope="col">Qna 4<br />Qna 16</th>
                        <th scope="col">Qna 5<br />Qna 17</th>
                        <th scope="col">Qna 6<br />Qna 18</th>
                        <th scope="col">Qna 7<br />Qna 19</th>
                        <th scope="col">Qna 8<br />Qna 20</th>
                        <th scope="col">Qna 9<br />Qna 21</th>
                        <th scope="col">Qna 10<br />Qna 22</th>
                        <th scope="col">Qna 11<br />Qna 23</th>
                        <th scope="col">Qna 12<br />Qna 24</th>
                        <th scope="col">Subtotal<br />Interes</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let item of savings; let i = index">
                        <td>{{ item.year }}</td>
                        <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
                        <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
                        <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
                        <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
                        <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
                        <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
                        <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
                        <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
                        <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
                        <td>{{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}</td>
                        <td>{{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}</td>
                        <td>{{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}</td>
                        <td>{{ item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 + item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 + item.fortnight13
                            + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 + item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24 | currency }}
                            <br />{{ item.folio | currency }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="row" *ngIf="!loadingData">
                <div class="col-12 text-right">
                    <h3 class="m-1 info">Subtotal {{ amount | currency }}</h3>
                    <h3 class="m-1 info">Interes {{ amountInterests | currency }}</h3>
                    <h3 class="m-1 info">Total {{ amount + amountInterests | currency }}</h3>
                </div>
            </div>

        </fieldset>

        <fieldset>
            <legend>Liquidez</legend>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Año</th>
                        <th scope="col">Qna 1<br />Qna 13</th>
                        <th scope="col">Qna 2<br />Qna 14</th>
                        <th scope="col">Qna 3<br />Qna 15</th>
                        <th scope="col">Qna 4<br />Qna 16</th>
                        <th scope="col">Qna 5<br />Qna 17</th>
                        <th scope="col">Qna 6<br />Qna 18</th>
                        <th scope="col">Qna 7<br />Qna 19</th>
                        <th scope="col">Qna 8<br />Qna 20</th>
                        <th scope="col">Qna 9<br />Qna 21</th>
                        <th scope="col">Qna 10<br />Qna 22</th>
                        <th scope="col">Qna 11<br />Qna 23</th>
                        <th scope="col">Qna 12<br />Qna 24</th>
                        <th scope="col">Subtotal</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let item of teacher.financialliquidity; let i = index">
                        <td>{{ item.year }}</td>
                        <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
                        <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
                        <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
                        <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
                        <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
                        <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
                        <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
                        <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
                        <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
                        <td>{{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}</td>
                        <td>{{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}</td>
                        <td>{{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}</td>
                        <td>{{ item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 + item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 + item.fortnight13
                            + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 + item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24 | currency }}</td>
                    </tr>
                </tbody>
            </table>

        </fieldset>

        <fieldset>
            <div class="row pl-2 pr-2">
                <div class="col-md-2">
                    <legend>Prestamos ( {{ loans?.length }} )</legend>
                </div>
                <div class="col-md-8" >
                    <form [formGroup]="formaLoans" >
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group ">
                                    <label class="control-label ">Año</label>
                                    <select class="custom-select" formControlName="year">
                                        <option value="" >Seleccionar...</option>
                                        <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group ">
                                    <label class="control-label ">Quincena</label>
                                    <select class="custom-select" formControlName="fortnight">
                                        <option value="" >Seleccionar...</option>
                                        <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <button type="button" [disabled]="loadingLoans" class="btn btn-outline-primary" (click)="searchLoans()" >
                                    <i class="fas fa-search"></i> Buscar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-2 text-right">
                    <label>
                      Mostrar todo <input type="checkbox" (change)="changeLoans( $event.target.checked )" >
                  </label>
                </div>
            </div>

            <table *ngIf="showLoansData" class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Sorteo</th>
                        <th scope="col">Monto</th>
                        <th scope="col"># Cheque</th>
                        <th scope="col">Qna Inicial</th>
                        <th scope="col">Qna Final</th>
                        <th scope="col">Estatus</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let item of loans; let i = index">
                        <td>{{ item.draw.name }}</td>
                        <td>{{ item.amount | currency }}</td>
                        <td>{{ item.checkNumber }}</td>
                        <td>{{ item.fortnightStart }}</td>
                        <td>{{ item.fortnightEnd }}</td>
                        <td>
                            <span *ngIf="item.status !== 'Activo' || item.questions.length > 0">
                                {{ item.status }}
                            </span>

                            <a class="primary" (click)="showConfirm( item.id )" href="" data-toggle="modal" *ngIf="item.status === 'Activo' && item.questions.length === 0">
                                {{ item.status }}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div *ngIf="!showLoansData" class="accordion" id="accordionExample">
                <div class="card" *ngFor="let item of loans; let i = index">
                    <div class="card-header" [id]="'heading' + i">
                        <h5 class="mb-0">
                            <button class="btn btn-link" type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                                Sorteo: {{ item.draw.name }} Monto: {{ item.amount | currency }} # Hoja: {{ item.loanNumber }} # Cheque / Transferencia: {{ item.checkNumber }}<br>Qna Inicial: {{ item.fortnightStart }} Qna Final: {{ item.fortnightEnd }} Estatus: {{ item.status }}
                                Fecha de aplicación {{ item.acceptanceDate | date:'dd/MM/yyyy' }}
                                <td *ngIf="item.status === 'Activo'" >Abonos realizados completos ( {{ fullPayments }} ) Abonos realizados incompletos ( {{ incompletePayments }} ) Total de abonos {{ item.loanParameter.fortnight.numberFortnight }}</td>
                            </button>
                        </h5>
                    </div>

                    <div [id]="'collapse' + i " class="collapse show" [attr.aria-labelledby]="'heading' + i" data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="row mb-2 " >
                                <div class="col-8 text-right" *ngIf="accountService.havePermission(applications, 'Búsqueda Global Agregar Abono')" >
                                    <a class="btn btn-outline-info" [routerLink]="['/reports/teachers-global/add-payment', item.id ]">
                                        <i class="fas fa-plus"></i> Agregar Abono
                                    </a>
                                </div>

                                <div class="col-2 text-right" *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                    <a class="btn btn-outline-warning" (click)="showChangeFortnight( item )" href="" data-toggle="modal">
                                        <i class="fas fa-exchange-alt"></i> Cambiar Quincena Final
                                    </a>
                                </div>

                                <div class="col-2 text-right" >
                                    <button type="button" class="btn btn-outline-primary" (click)="getPDF( item )">
                                        <i class="fas" [ngClass]="{ 'fa-file-pdf': !processingData, 'fa-sync-alt fa-spin': processingData }"></i>
                                        <span *ngIf="!processingData"> Generar PDF</span>
                                        <span *ngIf="processingData" >
                                            Procesando...
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Año</th>
                                        <th scope="col">Qna 1<br />Qna 13</th>
                                        <th scope="col">Qna 2<br />Qna 14</th>
                                        <th scope="col">Qna 3<br />Qna 15</th>
                                        <th scope="col">Qna 4<br />Qna 16</th>
                                        <th scope="col">Qna 5<br />Qna 17</th>
                                        <th scope="col">Qna 6<br />Qna 18</th>
                                        <th scope="col">Qna 7<br />Qna 19</th>
                                        <th scope="col">Qna 8<br />Qna 20</th>
                                        <th scope="col">Qna 9<br />Qna 21</th>
                                        <th scope="col">Qna 10<br />Qna 22</th>
                                        <th scope="col">Qna 11<br />Qna 23</th>
                                        <th scope="col">Qna 12<br />Qna 24</th>
                                        <th scope="col">Subtotal</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="text-right" *ngFor="let payment of getPayments( item.payments ); let i = index">
                                        <td>{{ payment.year }}</td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 1, payment.fortnight1 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight1 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight1 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 13, payment.fortnight13 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight13 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight13 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 2, payment.fortnight2 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight2 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight2 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 14, payment.fortnight14 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight14 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight14 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 3, payment.fortnight3 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight3 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight3 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 15, payment.fortnight15 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight15 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight15 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 4, payment.fortnight4 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight4 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight4 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 16, payment.fortnight16 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight16 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight16 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 5, payment.fortnight5 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight5 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight5 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 17, payment.fortnight17 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight17 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight17 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 6, payment.fortnight6 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight6 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight6 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 18, payment.fortnight18 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight18 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight18 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 7, payment.fortnight7 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight7 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight7 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 19, payment.fortnight19 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight19 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight19 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 8, payment.fortnight8 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight8 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight8 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 20, payment.fortnight20 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight20 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight20 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 9, payment.fortnight9 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight9 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight9 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 21, payment.fortnight21 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight21 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight21 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 10, payment.fortnight10 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight10 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight10 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 22, payment.fortnight22 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight22 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight22 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 11, payment.fortnight11 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight11 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight11 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 23, payment.fortnight23 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight23 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight23 | currency }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 12, payment.fortnight12 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight12 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight12 | currency }}
                                            </span>
                                            <br />
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showConfirmChangeFortnight( payment, 24, payment.fortnight24 )" href="" data-toggle="modal">
                                                    {{ payment.fortnight24 | currency }}
                                                </a>
                                            </span>
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                {{ payment.fortnight24 | currency }}
                                            </span>
                                        </td>
                                        <td>{{ payment.fortnight1 + payment.fortnight2 + payment.fortnight3 + payment.fortnight4 + payment.fortnight5 + payment.fortnight6 + payment.fortnight7 + payment.fortnight8 + payment.fortnight9 + payment.fortnight10
                                            + payment.fortnight11 + payment.fortnight12 + payment.fortnight13 + payment.fortnight14 + payment.fortnight15 + payment.fortnight16 + payment.fortnight17 + payment.fortnight18 + payment.fortnight19 + payment.fortnight20
                                            + payment.fortnight21 + payment.fortnight22 + payment.fortnight23 + payment.fortnight24 | currency }}<br />
                                            <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >{{ payment.type }}</span>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                                <a (click)="showChangeLoan( payment )" href="" data-toggle="modal">
                                                    {{ payment.type }}
                                                </a>
                                            </span>
                                            <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                                <a class="required" (click)="showConfirmPayment( payment )" href="" data-toggle="modal">
                                                    <i class="far fa-trash-alt"></i>
                                                </a>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot *ngIf="item.status !== 'Activo'">
                                    <tr>
                                        <td colspan="14" class="text-right">
                                            <h4 class="m-1 info">Total a pagar {{ getAmountLoan( item ) | currency }} </h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="14" class="text-right">
                                            <h4 class="m-1 info">Abonos {{ calculateGrandTotal( item ) | currency }} </h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="14" class="text-right">
                                            <h4 class="m-1 info">Saldo pendiente {{ getAmountLoan( item ) - calculateGrandTotal( item ) | currency }} </h4>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                            <div class="row" *ngIf="item.status === 'Activo'">
                                <div class="col-12 text-right">
                                    <h3 class="m-1 info">Total a pagar {{ totalToPay | currency }}</h3>
                                    <h3 class="m-1 info">Abonos {{ amountOfPayments | currency }}</h3>
                                    <h3 class="m-1 info">Saldo pendiente {{ totalToPay - amountOfPayments | currency }}</h3>
                                    <h3 class="m-1 info">Saldo condonado {{ condonedBalance | currency }}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <label for=""> Sin Ligar </label>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Año</th>
                                <th scope="col">Qna 1<br />Qna 13</th>
                                <th scope="col">Qna 2<br />Qna 14</th>
                                <th scope="col">Qna 3<br />Qna 15</th>
                                <th scope="col">Qna 4<br />Qna 16</th>
                                <th scope="col">Qna 5<br />Qna 17</th>
                                <th scope="col">Qna 6<br />Qna 18</th>
                                <th scope="col">Qna 7<br />Qna 19</th>
                                <th scope="col">Qna 8<br />Qna 20</th>
                                <th scope="col">Qna 9<br />Qna 21</th>
                                <th scope="col">Qna 10<br />Qna 22</th>
                                <th scope="col">Qna 11<br />Qna 23</th>
                                <th scope="col">Qna 12<br />Qna 24</th>
                                <th scope="col">Subtotal</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="text-right" *ngFor="let payment of getPaymentsWithoutFlirting( teacher.payments ); let i = index">
                                <td>{{ payment.year }}</td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 1, payment.fortnight1 )" href="" data-toggle="modal">
                                            {{ payment.fortnight1 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight1 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 13, payment.fortnight13 )" href="" data-toggle="modal">
                                            {{ payment.fortnight13 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight13 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 2, payment.fortnight2 )" href="" data-toggle="modal">
                                            {{ payment.fortnight2 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight2 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 14, payment.fortnight14 )" href="" data-toggle="modal">
                                            {{ payment.fortnight14 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight14 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 3, payment.fortnight3 )" href="" data-toggle="modal">
                                            {{ payment.fortnight3 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight3 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 15, payment.fortnight15 )" href="" data-toggle="modal">
                                            {{ payment.fortnight15 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight15 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 4, payment.fortnight4 )" href="" data-toggle="modal">
                                            {{ payment.fortnight4 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight4 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 16, payment.fortnight16 )" href="" data-toggle="modal">
                                            {{ payment.fortnight16 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight16 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 5, payment.fortnight5 )" href="" data-toggle="modal">
                                            {{ payment.fortnight5 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight5 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 17, payment.fortnight17 )" href="" data-toggle="modal">
                                            {{ payment.fortnight17 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight17 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 6, payment.fortnight6 )" href="" data-toggle="modal">
                                            {{ payment.fortnight6 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight6 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 18, payment.fortnight18 )" href="" data-toggle="modal">
                                            {{ payment.fortnight18 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight18 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 7, payment.fortnight7 )" href="" data-toggle="modal">
                                            {{ payment.fortnight7 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight7 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 19, payment.fortnight19 )" href="" data-toggle="modal">
                                            {{ payment.fortnight19 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight19 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 8, payment.fortnight8 )" href="" data-toggle="modal">
                                            {{ payment.fortnight8 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight8 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 20, payment.fortnight20 )" href="" data-toggle="modal">
                                            {{ payment.fortnight20 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight20 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 9, payment.fortnight9 )" href="" data-toggle="modal">
                                            {{ payment.fortnight9 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight9 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 21, payment.fortnight21 )" href="" data-toggle="modal">
                                            {{ payment.fortnight21 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight21 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 10, payment.fortnight10 )" href="" data-toggle="modal">
                                            {{ payment.fortnight10 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight10 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 22, payment.fortnight22 )" href="" data-toggle="modal">
                                            {{ payment.fortnight22 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight22 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 11, payment.fortnight11 )" href="" data-toggle="modal">
                                            {{ payment.fortnight11 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight11 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 23, payment.fortnight23 )" href="" data-toggle="modal">
                                            {{ payment.fortnight23 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight23 | currency }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 12, payment.fortnight12 )" href="" data-toggle="modal">
                                            {{ payment.fortnight12 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight12 | currency }}
                                    </span>
                                    <br />
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showConfirmChangeFortnight( payment, 24, payment.fortnight24 )" href="" data-toggle="modal">
                                            {{ payment.fortnight24 | currency }}
                                        </a>
                                    </span>
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        {{ payment.fortnight24 | currency }}
                                    </span>
                                </td>
                                <td>{{ payment.fortnight1 + payment.fortnight2 + payment.fortnight3 + payment.fortnight4 + payment.fortnight5 + payment.fortnight6 + payment.fortnight7 + payment.fortnight8 + payment.fortnight9 + payment.fortnight10
                                    + payment.fortnight11 + payment.fortnight12 + payment.fortnight13 + payment.fortnight14 + payment.fortnight15 + payment.fortnight16 + payment.fortnight17 + payment.fortnight18 + payment.fortnight19 + payment.fortnight20
                                    + payment.fortnight21 + payment.fortnight22 + payment.fortnight23 + payment.fortnight24 | currency }}<br />
                                    <span *ngIf="!accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >{{ payment.type }}</span>
                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')" >
                                        <a (click)="showChangeLoan( payment )" href="" data-toggle="modal">
                                            {{ payment.type }}
                                        </a>
                                    </span>

                                    <span *ngIf="accountService.havePermission(applications, 'Búsqueda Global Ajuste Prestamos')">
                                        <a class="required" (click)="showConfirmPayment( payment )" href="" data-toggle="modal">
                                            <i class="far fa-trash-alt"></i>
                                        </a>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </fieldset>

        <fieldset *ngIf="showLoansData">
            <div class="row pl-2 pr-2">
                <div class="col-md-6">
                    <legend>Abonos</legend>
                </div>
            </div>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Año</th>
                        <th scope="col">Qna 1<br />Qna 13</th>
                        <th scope="col">Qna 2<br />Qna 14</th>
                        <th scope="col">Qna 3<br />Qna 15</th>
                        <th scope="col">Qna 4<br />Qna 16</th>
                        <th scope="col">Qna 5<br />Qna 17</th>
                        <th scope="col">Qna 6<br />Qna 18</th>
                        <th scope="col">Qna 7<br />Qna 19</th>
                        <th scope="col">Qna 8<br />Qna 20</th>
                        <th scope="col">Qna 9<br />Qna 21</th>
                        <th scope="col">Qna 10<br />Qna 22</th>
                        <th scope="col">Qna 11<br />Qna 23</th>
                        <th scope="col">Qna 12<br />Qna 24</th>
                        <th scope="col">Subtotal</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let item of payments; let i = index">
                        <td>{{ item.year }}</td>
                        <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
                        <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
                        <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
                        <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
                        <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
                        <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
                        <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
                        <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
                        <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
                        <td>{{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}</td>
                        <td>{{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}</td>
                        <td>{{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}</td>
                        <td>{{ item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 + item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 + item.fortnight13
                            + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 + item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24 | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>

    <ng-template appOpen></ng-template>
</article>
