import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TeacherInterface } from '../interface/teacher.interface';
import { Observable } from 'rxjs/Observable';
import { ContributionInfointerface } from '../interface/contributionInfointerface';

@Injectable()
export class TeacherService {
  private apiURL = 'api/Administration/Teachers';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( teacher: TeacherInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', teacher);
    }

    get( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }

    getIdentifier() {
      return this.http.get<any>(this.apiURL + '/Identifier');
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    getByIdWithSavings( id: string ) {
      return this.http.get<any>( this.apiURL + '/Savings/' + id );
    }

    getCollectionData( year: number, fortnight: number, teacherType: string ) {
      return this.http.get<any>( `${ this.apiURL }/collection/${ year }/${ fortnight }/${ teacherType }` );
    }

    getBeneficiaries(  ) {
      return this.http.get<any>( `${ this.apiURL }/Beneficiaries` );
    }

    getEmploymentFortnight( fortnight: string ) {
      return this.http.get<any>( `${ this.apiURL }/EmploymentFortnight/${ fortnight }` );
    }

    getYears(  ) {
      return this.http.get<any>( `${ this.apiURL }/Years` );
    }

    update( id: string, teacher: TeacherInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, teacher  );
    }

    updateContribution( contribution: ContributionInfointerface ) {
      return this.http.put<any>( this.apiURL , contribution  );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }

    reentry(id: string, type: string) {
      return this.http.delete<any>( this.apiURL + '/' + id + '/' + type);
    }

    directDebitPayment(id: string) {
      return this.http.patch<any>( this.apiURL + '/DirectDebitPayment/' + id, null);
    }

    removeDirectDebitPayment(id: string) {
      return this.http.delete<any>( this.apiURL + '/DirectDebitPayment/' + id);
    }

    itemsFortnight(year: number, type: number) {
      return this.http.get<any>( `${ this.apiURL }/items_fortnight/${ year }/${ type }` );
    }

    getAmountActiveTeachers(year: number, fortnight: number) {
      return this.http.get<any>( `${ this.apiURL }/GetAmountActiveTeachers/${ year }/${ fortnight }` );
    }

    getDebtorMasters( year: number, fortnight: string, type: string ) {
      return this.http.get<any>( `${ this.apiURL }/GetDebtorMasters/${ year }/${ fortnight }/${ type }` );
    }
    getLoansPendings( teacherId: string ) {
      return this.http.get<any>( `${ this.apiURL }/GetPendingLoans/${ teacherId }` );
    }
}
