import { Injectable, Inject } from '@angular/core';
import { LowStaffInterfaces } from '../interfaces/low-staff.interfaces';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { TransferInterface } from '../../../banks/transfers/interfaces/transfer.interface';
import { ResponseInterface } from '../../../../interfaces/response.interface';
import { CancelTransferInterface } from '../../../banks/transfers/interfaces/cancelTransfer.interface';

@Injectable()
export class LowStaffService {
  private apiURL = 'api/Administration/LowStaffing';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

  get( searchValue: string, type: string, startDate: string, endDate: string ) {
    let url = `${ this.apiURL }/Search/${ searchValue }/${ type }`;
      if ( startDate !== '' && endDate !== '' ) {
        url = `${ url }/${ startDate }/${ endDate }`;
      }
      return this.http.get<any>( url );
  }

  getById( id: string, type: string ) {
    return this.http.get<any>(this.apiURL + '/' + id + '/' + type);
  }

  getPending(  ) {
    return this.http.get<any>(this.apiURL + '/Pending');
  }

  create(lowStaff: LowStaffInterfaces): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', lowStaff);
  }

  apply(fortnight: string) {
    return this.http.get<any>(this.apiURL + '/Apply/' + fortnight);
  }

  delete( id: string ) {
    return this.http.delete<any>( this.apiURL + '/' + id );
  }

  getSavings( id: string) {
    return this.http.get<any>(this.apiURL + '/Savings/' + id);
  }

  deactivate(id: string) {
    // return this.http.put<any>(this.apiURL + '/' + id);
  }
  
  getPolicy( startDate: string, endDate: string, movementType: string, filtro: string ) {
      return this.http.get<any>( `${ this.apiURL }/Policy/${ startDate }/${ endDate }/${ movementType }/${ filtro }` );
  }

  getReport( type: number, startDate: string, endDate: string, searchValue: string ) {
    return this.http.get<any>( `${ this.apiURL }/LowStaffing/${ type }/${ startDate }/${ endDate }/${ searchValue }` );
  }

  getTransfers() {
    return this.http.get<any>(`${ this.apiURL }/Transfers`);
  }

  applyTransfers(block: number, date: string) {
    return this.http.get<any>(`${ this.apiURL }/Apply/${ block }/${ date }`);
  }

  getDetails(block: number) {
    return this.http.get<any>(`${ this.apiURL }/Details/${ block }`);
  }

  getTreansferPendings( ) {
    return this.http.get<any>(`${ this.apiURL }/Transfers/Pendings`);
  }

  getTreansferSupports( ) {
    return this.http.get<any>(`${ this.apiURL }/Transfers/Supports`);
  }

  putProvider(providers: Array<TransferInterface>) {
    return this.http.put<ResponseInterface>(`${ this.apiURL }`, providers).toPromise();
  }

  postProvider(fortnight: string, providers: Array<TransferInterface>) {
    return this.http.post<ResponseInterface>(`${ this.apiURL }/${ fortnight }`, providers).toPromise();
  }

  update( transfer: CancelTransferInterface ) {
    return this.http.put<ResponseInterface>( `${ this.apiURL }/PutTransfer`, transfer ).toPromise();
  }

}
