import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AffilationItem } from '../interfaces/affilation-item';

@Injectable()
export class AffiliationService {
  private apiURL = 'api/Banks/Affiliations';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    get( type: string ) {
      return this.http.get<any>(this.apiURL + '/' + type);
    }

    apply( userId: string, affiliationItems: Array<AffilationItem> ) {
      return this.http.post<any>(this.apiURL + '/Apply/' + userId, affiliationItems);
    }
}
