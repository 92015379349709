import { Routes } from '@angular/router';
import { DeadlineDetailComponent } from './deadline-detail.component';
import { DeadlineListComponent } from './deadline-list.component';
import { DeadlineComponent } from './deadline.component';

export const DEADLINES_ROUTES: Routes = [
    { path: 'add', component: DeadlineComponent },
    { path: 'add/:searchValue', component: DeadlineComponent },
    { path: 'details/:id', component: DeadlineDetailComponent },
    { path: 'details/:id/:searchValue', component: DeadlineDetailComponent },
    { path: ':searchValue', component: DeadlineListComponent },
    { path: '', component: DeadlineListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
