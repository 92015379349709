<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Cobranza Detalle</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Detalle ( {{ dataReport?.length }} )</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Maestros</label>
                        <select class="custom-select" formControlName="teachers">
                            <option value="All" >Todos</option>
                            <option value="Media Superior" >Media Superior</option>
                            <option value="Alta Jubilación" >Alta Jubilación</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Tipo</label>
                        <select class="custom-select" formControlName="type">
                        <option value="All" >Todos</option>
                        <option value="AHORRO" >Ahorros</option>
                        <option value="ABONO" >Abonos</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Fecha Inicio</label>
                        <input type="date" class="custom-select" formControlName="startDate" />
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Fecha Fin</label>
                        <input type="date" class="custom-select" formControlName="endDate" />
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-center">
                    <button [disabled]="loadingData" type="button" class="btn btn-outline-primary" (click)="loadData()">
                        <i [ngClass]="{ 'fas fa-search': !loadingData, 'fas fa-sync-alt fa-spin': loadingData } "></i>
                        <!-- <i class="fas fa-search"></i> {{ loadingData }} -->
                        <span *ngIf="!loadingData ">Buscar</span>
                        <span *ngIf="loadingData">Buscando...</span>
                  </button>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-right">
                    <button [disabled]="dataReport?.length === 0 || !dataReport" style="margin: 15px;" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i class="far fa-file-excel"></i>
                        Generar
                    </button>
                </div>
            </div>
        </form>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

        <div class="row" *ngIf="dataReport?.length > 0">
            <div class="col-12">
                <h3 class="text-right" >Aplicado {{ amountApplied | currency }} <span class="required" >No aplicado {{ amountNotApplied | currency }}</span> Total {{ amountApplied + amountNotApplied | currency }}</h3>
            </div>
        </div>

        <table class="table table-striped table-hover" *ngIf="dataReport?.length > 0">
            <thead>
                <tr>
                    <th>RFC</th>
                    <th>Nombre</th>
                    <th>Tipo</th>
                    <th>Fecha</th>
                    <th>Quincena</th>
                    <th>Aplicado</th>
                    <th>No aplicado</th>
                    <th>Código</th>
                    <th>Mensaje</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataReport; let i = index">
                    <td>{{ item.rfc }}</td>
                    <td>{{ item.name }}</td>
                    <td>
                        {{ item.type }}
                    </td>
                    <td>{{ item.movementDate | date:'dd/MM/yyyy' }}</td>
                    <td>{{ item.fortnight }}</td>
                    <td class="text-right">
                        <span *ngIf="item.applied">{{ item.amount | currency }}</span>
                    </td>
                    <td class="text-right">
                        <span class="required" *ngIf="!item.applied">{{ item.amount | currency }}</span>
                    </td>
                    <td>{{ item.code }}</td>
                    <td>
                        {{ getDescriptionError(item.code) }} {{ item.message }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</article>