<article class="content items-list-page">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Administración</li>
      </ol>
  </nav>

  <div class="title-search-block">
      <div class="title-block">
          <div class="row form-group">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                  <h3 class="title">
                      Consecutivo Cheques y/o Transferencias de Prestamos <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                  </h3>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-right">
                <a [routerLink]="['/reports/loans-check-transfers/add' ]" *ngIf="accountService.havePermission(applications, 'Consecutivo Cheques y/o Transferencias de Prestamos Agregar')" class="btn btn-outline-primary"  >
                    <i class="fas fa-plus"></i> Agregar
                </a>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-right">
                <button [disabled]="jsonArray.length === 0" type="button" class="btn btn-outline-success" (click)="getExcel()">
                    <i [ngClass]="{ 'far fa-file-excel': !exporting, 'fas fa-sync-alt fa-spin': exporting }"></i>
                    <span *ngIf="!exporting">Exportar {{ jsonArray.length }}</span>
                    <span *ngIf="exporting" >
                        Procesando...
                    </span>
                </button>
               </div>
          </div>
      </div>
  </div>

  <div class="box-search">
      <div class="row">
          <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <form role="search">
                  <div class="input-box">
                      <i class="fas fa-search"></i>
                      <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar...">
                      <div class="underline"></div>
                  </div>
              </form>
          </div>

          <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-5 col-lg-5">
              <div class="input-box">
                  <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                          <label>Inicio</label>
                      </div>

                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                          <input type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                      </div>

                      <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                          <label>Al</label>
                      </div>

                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                          <input type="date" name="fin" [value]="fin" [(ngModel)]="fin">
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
            <label>Tipo</label>
          </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <select name="tipo" class="form-control" [value]="type" [(ngModel)]="type" >
                    <Option value="2" selected>Cheque</Option>
                    <Option value="1">Transferencia</Option>
                </select>
            </div>

          <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
              <button type="button" class="btn btn-outline-primary" (click)="search( )">
                  Buscar
                </button>
          </div>
      </div>
  </div>

  <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

  <app-loading-data *ngIf="loadingData"></app-loading-data>

  <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
          <thead>
              <th scope="col">
                  Nombre
              </th>
              <th scope="col">
                Fecha
              </th>
              <th scope="col">
                  # Cheque
              </th>
              <th scope="col">
                  Importe
              </th>
              <th scope="col">
                  Comentario
              </th>
              <th scope="col">
                Estatus
              </th>
              <th >

              </th>
          </thead>
          <tbody>
              <tr [ngClass]="{'required': item.active != true}" *ngFor="let item of jsonArray">
                  <td>{{ item.name }}</td>
                  <td>{{ item.movementDate | date:'dd/MM/yyyy' }}</td>
                  <td>{{ item.number }}</td>
                  <td>{{ item.amount | currency }}</td>
                  <td>{{ item.comment }}</td>
                  <td>
                    <span *ngIf="item.active" >Activo</span>
                    <span *ngIf="!item.active" >Cancelado</span>
                  </td>
                  <td>
                    <span *ngIf="accountService.havePermission(applications, 'Consecutivo Cheques y/o Transferencias de Prestamos Eliminar') && item.active === true && type === 2" >
                        <a class="required" (click)="showConfirm( item )" href="" data-toggle="modal">
                            <i class="far fa-trash-alt"></i>
                        </a>
                    </span>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
  </div>

</article>
