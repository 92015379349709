import { Component } from '@angular/core';
import { AccountService } from './components/account/login/services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor( public accountService: AccountService ) {}

  isLogged(): boolean {
    return this.accountService.isLogged();
  }
}
