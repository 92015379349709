import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BankInterface } from '../../administration/banks/interfaces/bank.interface';
import { BankService } from '../../administration/banks/services/bank.service';
import { TransferInterface } from '../transfers/interfaces/transfer.interface';
declare var $: any;

@Component({
  selector: 'app-edit-data',
  template: `
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><i class="fas fa-exclamation-triangle"></i> Acción</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="forma">
              <div class="row form-group">
                <label class="col-xs-12 col-sm-12 col-md-12 col-lg-12" >{{ provider.name }}<small> ( {{ provider.rfc }} )</small></label>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label class="control-label"><i class="fas fa-asterisk" ></i> Email</label>
                    <input type="text" class="form-control underlined"formControlName="email">
                    <span *ngIf="forma.controls['email'].touched && forma.controls['email'].errors?.required" class="has-error">
                      El campo es requerido.
                    </span>
                    <span *ngIf="forma.controls['email'].touched && forma.controls['email'].errors?.email" class="has-error">
                      Formato invalido.
                    </span>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Banco</label>
                    <select class="custom-select" formControlName="bankCode">
                      <option value="">Seleccionar...</option>
                      <option [value]="bank.id" *ngFor="let bank of banks ">{{ bank.name }}</option>
                    </select>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label class="control-label"><i class="fas fa-asterisk" ></i> Cuenta</label>
                    <input type="text" class="form-control underlined" maxlength="18" formControlName="clabe">
                    <span *ngIf="forma.controls['clabe'].touched && forma.controls['clabe'].errors?.required" class="has-error">
                      El campo es requerido.
                    </span>
                    <span *ngIf="forma.controls['clabe'].touched && forma.controls['clabe'].errors?.minlength" class="has-error">
                      El campo nombre debe tener {{ forma.controls['CLABE'].errors.minlength.requiredLength }}
                      caracteres.
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="ok()" >
              Si
            </button>
            <button type="button" class="btn btn-secondary" (click)="cancel()" >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class EditDataLowstaffComponent implements OnInit {
  forma: UntypedFormGroup;
  public provider: TransferInterface;

  @Output() public alertOutput = new EventEmitter<any>();
  banks: BankInterface[] = [];

  constructor( private bankService: BankService,
    private formBuilder: UntypedFormBuilder ) { }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      bankCode: ['', [Validators.required]],
      clabe: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(18)]]
    });

    this.bankService.get('null').subscribe(
      banks => {
        this.banks = banks;
        this.forma.controls['email'].patchValue(this.provider.email);
        this.forma.controls['bankCode'].patchValue(this.provider.bankId);
        this.forma.controls['clabe'].patchValue(this.provider.clabe);
      }, error => console.log(error));
  }

  open() {
    $('#myModal').modal();
  }

  ok() {
    $('#myModal').modal('hide');
    this.provider.email = this.forma.controls['email'].value;
    this.provider.bankId = this.forma.controls['bankCode'].value;
    this.provider.clabe = this.forma.controls['clabe'].value;
    this.provider.active = true;
    this.alertOutput.emit( this.provider );
  }

  cancel() {
    $('#myModal').modal('hide');
  }

}
