import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'protractor';
import { AccountService } from '../../account/login/services/account.service';
import { FortnightInterface } from '../../catalogs/fortnights/interfaces/fortnight.interface';
import { DeadlineInterface } from './interfaces/deadline.interface';
import { DeadlineService } from './services/deadline.service';

@Component({
  selector: 'app-deadline',
  templateUrl: './deadline.component.html',
  styles: []
})
export class DeadlineComponent implements OnInit {
  title = 'Agregar';
  forma: UntypedFormGroup;
  message: string;
  gettingData = false;
  sendingData = false;
  searchValue = '';
  prefixes: string[] = [];
  fortnights: Array<FortnightInterface>;
  deadline: DeadlineInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    fortnightId: '00000000-0000-0000-0000-000000000000',
    amount: 0,
    discount: 0,
    interests: 0,
    totalPerceptions: 0,
    saving: 0,
    openingBonus: 0,
    guaranteedPercentage: 0,
    iva: 0,
    lifeInsurance: 0,
    fortnightInterest: 0,
    fortnightIVA: 0,
    fortnightCapital: 0,
    fortnightLifeInsurance: 0,
    type: '',
    teachers: '',
    accountingConcept: '',
    fortnight: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      numberFortnight: 0
    },
    prefix: '',
    percentageInterest: 0
  };

  constructor(
    public accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private deadlineServeice: DeadlineService,
    private router: Router
  ) { }

  ngOnInit() {
    this.gettingData = true;
    this.buildForm();

    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      this.deadlineServeice.getFortnights().subscribe(fortnights => {
        this.fortnights = fortnights;
        this.deadlineServeice.getPrefixes().subscribe( prefixes => {
          this.prefixes = prefixes;
          this.forma.setValue(this.deadline);
        });
      }, err => this.errorMessage(err) );

      /* if (params['id']) {
        this.title = 'Editar';
        this.deadline.id = params['id'];
        this.deadlineServeice.getById(params['id'])
          .subscribe(data => {
            this.deadline = Object.assign({}, data);
            console.log(this.deadline);
            this.forma.setValue(this.deadline);
          },
            error => this.errorMessage(error));
      } else {
        this.forma.setValue(this.deadline);
      } */
      this.gettingData = false;
    });
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      id: [''],
      userId: [''],
      date: [''],
      active: [''],
      lastUpdated: [''],
      userIdLastUpdated: [''],
      fortnightId: [''],
      amount: ['', [Validators.required, Validators.min(1)]],
      discount: ['', [Validators.required, Validators.min(1)]],
      interests: ['', [Validators.required, Validators.min(1)]],
      totalPerceptions: ['', [Validators.min(0)]],
      saving: ['', [Validators.min(0)]],
      openingBonus: ['', [ Validators.min(0)]],
      guaranteedPercentage: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      iva: ['', [Validators.required, Validators.min(1)]],
      lifeInsurance: ['', [Validators.required, Validators.min(0)]],
      fortnightInterest: ['', [Validators.required, Validators.min(1)]],
      fortnightIVA: ['', [Validators.required, Validators.min(1)]],
      fortnightCapital: ['', [Validators.required, Validators.min(1)]],
      fortnightLifeInsurance: ['', [Validators.required, Validators.min(0)]],
      type: ['', [Validators.required]],
      teachers: ['', [Validators.required]],
      accountingConcept: [''],
      fortnight: ['', [Validators.required]],
      prefix: [''],
      percentageInterest: ['', [Validators.required, Validators.min(1), Validators.max(100)]]
    });
  }

  errorMessage( err: any ) {
    if (err && err.error) {
      console.log(err);
      this.message = err.error;
      this.sendingData = false;
    }
  }

  sendForm() {
    this.sendingData = true;
    this.deadline = Object.assign({}, this.forma.value);
    this.deadline.fortnight = this.fortnights.filter(f => f.id = this.deadline.fortnightId)[0];
    this.deadline.date = new Date();
    console.log(this.deadline);
    this.deadlineServeice.create(this.deadline).subscribe( success => {
      this.router.navigate(['administration/deadlines', this.searchValue]);
    }, err => this.errorMessage(err));
  }

}
