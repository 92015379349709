import { Component, OnInit } from '@angular/core';
import { CollectionService } from './services/collection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionInterface } from './interfaces/collection.interface';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';

@Component({
  selector: 'app-collection-details',
  templateUrl: './collection-details.component.html',
  styles: [],
})
export class CollectionDetailsComponent implements OnInit {
  loadingData = false;
  errorMessage = '';
  amountApplied = 0;
  amountNotApplied = 0;
  processing = false;
  applications: ApplicationInterface[] = [];
  searchValue = '';
  bankErrors: any = [
    {
      code: '00',
      description: 'Ok'
    },
    {
      code: '01',
      description: 'Cuenta inexistente'
    },
    {
      code: '02',
      description: 'Cuenta bloqueada'
    },
    {
      code: '03',
      description: 'Cuenta cancelada'
    },
    {
      code: '04',
      description: 'Cuenta con insuficiencia de fondos'
    },
    {
      code: '05',
      description: 'Cuenta en otra divisa'
    },
    {
      code: '11',
      description: 'Indomiciliado'
    },
    {
      code: '08',
      description: 'Baja por oficina'
    },
    {
      code: '10',
      description: 'Domiciliación dada de baja'
    },
    {
      code: '09',
      description: 'El importe actual supera al importe original o viceversa'
    },
    {
      code: '14',
      description: 'Banco no válido o no participante'
    },
    {
      code: '15',
      description: 'Fuera de horario'
    },
    {
      code: '33',
      description: 'Supera el límite (también hace referencia a la fecha de transferencia no autorizada)'
    },
    {
      code: '46',
      description: 'Cuenta del receptor inválida'
    },
    {
      code: '6',
      description: 'Cuenta no pertenece al banco receptor'
    },
    {
      code: '7',
      description: 'Transaccion duplicada'
    },
    {
      code: '13',
      description: 'Cliente desconoce el cargo'
    },
    {
      code: '99',
      description: 'Verificación de cuentas'
    }
  ];
  collection: CollectionInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    active: false,
    fileName: '',
    movementDate: new Date(),
    accountingDate: new Date(),
    fortnight: '',
    type: '',
    payroll: '',
    origin: '',
    movementNumber: 0,
    appliedAmount: 0,
    notAppliedAmount: 0,
    collectionDetails: [],
    userIdLastUpdated: this.accountService.getUserId()
  };

  constructor(
    private collectionService: CollectionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accountService: AccountService
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('banks', 'collections').subscribe(
      (applications) => {
        this.applications = applications;
        this.activatedRoute.params.subscribe((params) => {
          if ( params['searchValue'] ) {
            this.searchValue = params['searchValue'];
          }

          if (params['id']) {
            this.collectionService.getDetails( params['id'] ).subscribe(
              (data: CollectionInterface) => {
                this.collection = data;
                this.loadingData = false;
    
                this.amountApplied = parseFloat(this.collection
                  .collectionDetails
                  .filter(item => item.applied === true)
                  .map(item => item.amount)
                  .reduce(function(sum, current) {
                    return sum + current;
                  }));
    
                  this.amountNotApplied = parseFloat(this.collection
                    .collectionDetails
                    .filter(item => item.applied === false)
                    .map(item => item.amount)
                    .reduce(function(sum, current) {
                      return sum + current;
                    }));
              },
              (error) => this.setErrorMessage(error)
            );
          } else {
            this.router.navigate(['/banks/collections', this.searchValue]);
          }
        });
      },
      (error) => this.setErrorMessage(error)
    );
  }

  setErrorMessage(error) {
    this.errorMessage = error;
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  getDescriptionError(code: string): string {
    var bankError = this.bankErrors.find(e => e.code === code);
    if ( bankError ) {
      return bankError.description;
    }
    return '';
  }

  handlerClick(): void {
    this.processing = true
    console.log( this.collection );
    this.collection.userIdLastUpdated = this.accountService.getUserId();
    this.collectionService.postReverse( this.collection )
    .then( response => {
      if( response.isSuccess ) {
        this.router.navigate(['/banks/collections', this.searchValue]);
      } else {
        this.errorMessage = response.message;
      }

    }). catch( error => this.setErrorMessage(error) );
  }

  someValid(): boolean {
    return this.collection.collectionDetails.some(d => d.active === true);
  }

}
