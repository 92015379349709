<div class="content">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Administración</li>
          <li class="breadcrumb-item"><a [routerLink]="[pathReturn]" routerLinkActive="router-link-active">Prestamos</a></li>
      </ol>
  </nav>

  <div class="title-search-block">
      <div class="title-block">
          <div class="row">
              <div class="col-md-6">
                  <h3 class="title">
                      <h3 class="title">Agregar</h3>
                  </h3>
              </div>
          </div>
      </div>
  </div>

  <app-loading-data *ngIf="gettingData"></app-loading-data>

  <div class="card card-block animated fadeIn fast">
      <form [formGroup]="forma">
          <div class="row">
              <div class="col-md-12 text-center">
                  <h4 class="info"><b>{{ payment.teacher.name | uppercase }}</b></h4>
                  <hr>
              </div>
          </div>

          <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <label class="control-label"> Tipo</label>
                  <select class="custom-select" formControlName="type" (change)="handlerChangeType()">
                      <option value="Abono">Abono</option>
                      <option value="Liquidación">Liquidación</option>
                      <!-- <option value="Liquidación/Ahorro">Liquidación/Ahorro</option> -->
                  </select>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['movementDate'].valid && forma.controls['movementDate'].touched, 'has-success': forma.controls['movementDate'].valid && forma.controls['movementDate'].touched }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Fecha</label>
                      <input type="date" class="form-control underlined" formControlName="movementDate">
                      <span *ngIf="forma.controls['movementDate'].touched && forma.controls['movementDate'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['folio'].valid && forma.controls['folio'].touched, 'has-success': forma.controls['folio'].valid }">
                      <label class="control-label">Folio</label>
                      <input type="text" class="form-control underlined" formControlName="folio">
                      <span *ngIf="forma.controls['folio'].touched && forma.controls['folio'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['depositAmount'].valid && forma.controls['depositAmount'].touched, 'has-success': forma.controls['depositAmount'].valid }">
                        <label class="control-label">Importe Depósito</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="depositAmount">
                        <span *ngIf="forma.controls['depositAmount'].touched && forma.controls['depositAmount'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['depositAmount'].touched && forma.controls['depositAmount'].errors?.min" class="has-error ">
                            El valor mínino es 1.
                        </span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" *ngIf="forma.controls['type'].value === 'Liquidación'">
                    <div class="form-group">
                        <label class="control-label">Aportación</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="amountSaving">
                        <span *ngIf="forma.controls['amountSaving'].touched && forma.controls['amountSaving'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['amountSaving'].touched && forma.controls['amountSaving'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" *ngIf="forma.controls['type'].value === 'Liquidación' && this.forma.controls['payments']">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['interest'].valid && forma.controls['interest'].touched, 'has-success': forma.controls['interest'].valid }">
                        <label class="control-label">
                            Interes
                        </label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="interest">
                        <span *ngIf="forma.controls['interest'].touched && forma.controls['interest'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['interest'].touched && forma.controls['interest'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" *ngIf="forma.controls['type'].value === 'Liquidación' && this.forma.controls['payments']">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['condoneInterest'].valid && forma.controls['condoneInterest'].touched, 'has-success': forma.controls['condoneInterest'].valid }">
                        <label class="control-label">
                            Interes condonado
                        </label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="condoneInterest" [disabled]="this.forma.controls['payments'].length == 0" (focus)="focusInterest();">
                        <span *ngIf="forma.controls['condoneInterest'].touched && forma.controls['condoneInterest'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['condoneInterest'].touched && forma.controls['condoneInterest'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3" *ngIf="forma.controls['type'].value === 'Liquidación'">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['financialProducts'].valid && forma.controls['financialProducts'].touched, 'has-success': forma.controls['financialProducts'].valid }">
                        <label class="control-label">Producto Financiero</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="financialProducts">
                        <span *ngIf="forma.controls['financialProducts'].touched && forma.controls['financialProducts'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['financialProducts'].touched && forma.controls['financialProducts'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" *ngIf="forma.controls['type'].value === 'Liquidación' && this.forma.controls['payments']">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['lifeInsurance'].valid && forma.controls['lifeInsurance'].touched, 'has-success': forma.controls['lifeInsurance'].valid }">
                        <label class="control-label">Prima de garantia</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="lifeInsurance">
                        <span *ngIf="forma.controls['lifeInsurance'].touched && forma.controls['lifeInsurance'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['lifeInsurance'].touched && forma.controls['lifeInsurance'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3" *ngIf="forma.controls['type'].value === 'Liquidación' && this.forma.controls['payments']">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['condoneLifeInsurance'].valid && forma.controls['condoneLifeInsurance'].touched, 'has-success': forma.controls['condoneLifeInsurance'].valid }">
                        <label class="control-label">Prima de garantia Condonada</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="condoneLifeInsurance">
                        <span *ngIf="forma.controls['condoneLifeInsurance'].touched && forma.controls['condoneLifeInsurance'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['condoneLifeInsurance'].touched && forma.controls['condoneLifeInsurance'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

          </div>

          <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Año inicio</label>
                      <select class="custom-select" formControlName="year">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Qna inicio</label>
                      <select class="custom-select" formControlName="fortnight">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Año fin</label>
                      <select class="custom-select" formControlName="yearEnd">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Qna fin</label>
                      <select class="custom-select" formControlName="fortnightEnd">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                  </div>
              </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['amount'].valid && forma.controls['amount'].touched, 'has-success': forma.controls['amount'].valid }">
                        <label class="control-label">Importe</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="amount">
                        <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.min" class="has-error ">
                            El valor mínino es 1.
                        </span>
                    </div>
                </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group">
                      <label class="control-label">Acumulado</label>
                      <input type="text" maxlength="250" class="form-control underlined" readonly value="{{ amount | currency }}">
                  </div>
              </div>
          </div>

          <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                  <button type="button" class="btn btn-outline-secondary" [disabled]="this.forma.controls['amount'].value < 0 || this.forma.controls['fortnight'].value === '' || this.forma.controls['fortnightEnd'].value === ''" (click)="addPayment()">
                      <i class="far fa-plus-square"></i> Agregar
                  </button>
              </div>
          </div>

          <div class="row">
              <div formArrayName="payments" *ngFor="let payment of forma.get('payments')['controls']; let i = index">
                  <div [formGroupName]="i">
                      <div class="row" *ngIf="payment.get('year').value">
                          <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1">
                              <div class="form-group">
                                  <label class="control-label">#</label>
                                  <input type="text" maxlength="250" class="form-control underlined" readonly [value]="i + 1">
                              </div>
                          </div>

                          <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                              <div class="form-group">
                                  <label class="control-label">Año</label>
                                  <input type="text" maxlength="250" class="form-control underlined" readonly name="year" formControlName="year">
                              </div>
                          </div>

                          <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                              <div class="form-group">
                                  <label class="control-label">Quincena</label>
                                  <input type="text" maxlength="250" class="form-control underlined" readonly name="fortnight" formControlName="fortnight">
                              </div>
                          </div>

                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                <div class="form-group">
                                    <label class="control-label">Importe</label>
                                    <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" name="amount" formControlName="amount" (blur)="blurPayment();">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          <div class="row" *ngIf="forma.controls['type'].value === 'Liquidación'">
              <div class="col-12">
                  <h3>Ahorros <b>({{ amountSavings.amount + amountSavings.amountInterest | currency }})</b></h3>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Año inicio</label>
                      <select class="custom-select" formControlName="yearSaving">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Qna inicio</label>
                      <select class="custom-select" formControlName="fortnightSaving">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Año fin</label>
                      <select class="custom-select" formControlName="yearEndSaving">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Qna fin</label>
                      <select class="custom-select" formControlName="fortnightEndSaving">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                  </div>
              </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['amountWithdrawFortnight'].valid && forma.controls['amountWithdrawFortnight'].touched, 'has-success': forma.controls['amountWithdrawFortnight'].valid }">
                        <label class="control-label">Importe</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="amountWithdrawFortnight">
                        <span *ngIf="forma.controls['amountWithdrawFortnight'].touched && forma.controls['amountWithdrawFortnight'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['amountWithdrawFortnight'].touched && forma.controls['amountWithdrawFortnight'].errors?.min" class="has-error ">
                            El valor mínino es 1.
                        </span>
                    </div>
                </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group">
                      <label class="control-label">Acumulado</label>
                      <input type="text" maxlength="250" class="form-control underlined" readonly value="{{ amountWithdraw | currency }}">
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Año aplica</label>
                      <select class="custom-select" formControlName="yearApply">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <div class="form-group ">
                      <label class="control-label ">Qna aplica</label>
                      <select class="custom-select" formControlName="fortnightApply">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                  </div>
              </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['interestWithdraw'].valid && forma.controls['interestWithdraw'].touched, 'has-success': forma.controls['interestWithdraw'].valid }">
                        <label class="control-label">
                            Interes
                        </label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="interestWithdraw">
                        <span *ngIf="forma.controls['interestWithdraw'].touched && forma.controls['interestWithdraw'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['interestWithdraw'].touched && forma.controls['interestWithdraw'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2" >
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['condoneInterestWithdraw'].valid && forma.controls['condoneInterestWithdraw'].touched, 'has-success': forma.controls['condoneInterestWithdraw'].valid }">
                        <label class="control-label">
                            Interes condonado
                        </label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="condoneInterestWithdraw" [disabled]="this.forma.controls['paymentsSaving'].length == 0" (focus)="focusInterestWithdraw();">
                        <span *ngIf="forma.controls['condoneInterestWithdraw'].touched && forma.controls['condoneInterestWithdraw'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['condoneInterestWithdraw'].touched && forma.controls['condoneInterestWithdraw'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" >
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['lifeInsuranceWithdraw'].valid && forma.controls['lifeInsuranceWithdraw'].touched, 'has-success': forma.controls['lifeInsuranceWithdraw'].valid }">
                        <label class="control-label">Prima de garantia</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="lifeInsuranceWithdraw">
                        <span *ngIf="forma.controls['lifeInsuranceWithdraw'].touched && forma.controls['lifeInsuranceWithdraw'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['lifeInsuranceWithdraw'].touched && forma.controls['lifeInsuranceWithdraw'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" >
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['condoneLifeInsuranceWithdraw'].valid && forma.controls['condoneLifeInsuranceWithdraw'].touched, 'has-success': forma.controls['condoneLifeInsuranceWithdraw'].valid }">
                        <label class="control-label">Prima de garantia Condonada</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="condoneLifeInsuranceWithdraw">
                        <span *ngIf="forma.controls['condoneLifeInsuranceWithdraw'].touched && forma.controls['condoneLifeInsuranceWithdraw'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['condoneLifeInsuranceWithdraw'].touched && forma.controls['condoneLifeInsuranceWithdraw'].errors?.min" class="has-error ">
                            El valor mínino es 0.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <button type="button" class="btn btn-outline-secondary" [disabled]="this.forma.controls['amountWithdrawFortnight'].value <= 0 || this.forma.controls['fortnightSaving'].value === '' || this.forma.controls['fortnightEndSaving'].value === '' || forma.controls['fortnightApply'].value === ''"
                        (click)="addPaymentwithdraw()">
                        <i class="far fa-plus-square"></i> Agregar
                    </button>
                </div>
            </div>

          <div class="row">
              <div formArrayName="paymentsSaving" *ngFor="let payment of forma.get('paymentsSaving')['controls']; let i = index">
                  <div [formGroupName]="i">
                      <div class="row" *ngIf="payment.get('year').value">
                          <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                              <div class="form-group">
                                  <label class="control-label">#</label>
                                  <input type="text" maxlength="250" class="form-control underlined" readonly [value]="i + 1">
                              </div>
                          </div>

                          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                              <div class="form-group">
                                  <label class="control-label">Año</label>
                                  <input type="text" maxlength="250" class="form-control underlined" readonly name="year" formControlName="year">
                              </div>
                          </div>

                          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                              <div class="form-group">
                                  <label class="control-label">Quincena</label>
                                  <input type="text" maxlength="250" class="form-control underlined" readonly name="fortnight" formControlName="fortnight">
                              </div>
                          </div>

                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label">Importe</label>
                                    <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" name="amount" formControlName="amount" (blur)="blurPaymentWithdraw();">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          <div class="form-group text-center mt-2 ">
              <button type="button" [disabled]="!forma.valid || sendingData || ( forma.controls['type'].value === 'Liquidación' && forma.get('payments')['controls'].length === 0 && forma.get('paymentsSaving')['controls'].length === 0 )" class="btn btn-outline-success" (click)="sendForm()">
                <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                <span *ngIf="!sendingData "> Guardar</span>
                <span *ngIf="sendingData "> Guardando...</span>
              </button>
          </div>

        </form>

        <div>
            <a class="btn btn-outline-info " [routerLink]="[pathReturn]" ><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>

  </div>

    <ng-template appOpen></ng-template>
</div>
