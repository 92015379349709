<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
            <li class="breadcrumb-item"><a [routerLink]="[pathReturn]" routerLinkActive="router-link-active">Búsqueda Global</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Detalle</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="message" [message]="message"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData">
        <div class="row">
            <div class="col-md-12">
                <h2 class="m-1">{{ teacher.name }}<small> ({{ teacher.rfc }})</small></h2>
            </div>
        </div>

        <hr />

        <fieldset>
            <div class="row pl-2 pr-2">
                <div class="col-md-6">
                    <legend>Ahorros</legend>
                </div>
                <div class="col-md-6 text-right">
                    <div class="row">
                        <label class="col-md-4">
                            Porcentaje
                        </label>

                        <div class="col-md-4">
                            <select class="custom-select" (change)="calculateSavings( $event.target.value )">
                                <option value="0.00208">5 %</option>
                                <option value="0.00167">4 %</option>
                            </select>
                        </div>

                        <label class="col-md-4">
                            Mostrar todo <input (change)="changeSavings( $event.target.checked )" type="checkbox"  >
                        </label>
                    </div>
                </div>
            </div>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Año</th>
                        <th scope="col">Qna 1<br />Qna 13</th>
                        <th scope="col">Qna 2<br />Qna 14</th>
                        <th scope="col">Qna 3<br />Qna 15</th>
                        <th scope="col">Qna 4<br />Qna 16</th>
                        <th scope="col">Qna 5<br />Qna 17</th>
                        <th scope="col">Qna 6<br />Qna 18</th>
                        <th scope="col">Qna 7<br />Qna 19</th>
                        <th scope="col">Qna 8<br />Qna 20</th>
                        <th scope="col">Qna 9<br />Qna 21</th>
                        <th scope="col">Qna 10<br />Qna 22</th>
                        <th scope="col">Qna 11<br />Qna 23</th>
                        <th scope="col">Qna 12<br />Qna 24</th>
                        <th scope="col">Subtotal<br />Interes</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let item of savings; let i = index">
                        <td>{{ item.year }}</td>
                        <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
                        <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
                        <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
                        <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
                        <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
                        <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
                        <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
                        <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
                        <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
                        <td>{{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}</td>
                        <td>{{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}</td>
                        <td>{{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}</td>
                        <td>{{ item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 + item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 + item.fortnight13
                            + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 + item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24 | currency }}
                            <br />{{ item.folio | currency }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="row">
                <div class="col-12 text-right">
                    <h3 class="m-1 info">Subtotal {{ amount | currency }}</h3>
                    <h3 class="m-1 info">Interes {{ amountInterests | currency }}</h3>
                    <h3 class="m-1 info">Total {{ amount + amountInterests | currency }}</h3>
                </div>
            </div>

        </fieldset>

        <fieldset>
            <legend>Liquidez</legend>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Año</th>
                        <th scope="col">Qna 1<br />Qna 13</th>
                        <th scope="col">Qna 2<br />Qna 14</th>
                        <th scope="col">Qna 3<br />Qna 15</th>
                        <th scope="col">Qna 4<br />Qna 16</th>
                        <th scope="col">Qna 5<br />Qna 17</th>
                        <th scope="col">Qna 6<br />Qna 18</th>
                        <th scope="col">Qna 7<br />Qna 19</th>
                        <th scope="col">Qna 8<br />Qna 20</th>
                        <th scope="col">Qna 9<br />Qna 21</th>
                        <th scope="col">Qna 10<br />Qna 22</th>
                        <th scope="col">Qna 11<br />Qna 23</th>
                        <th scope="col">Qna 12<br />Qna 24</th>
                        <th scope="col">Subtotal</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let item of teacher.financialliquidity; let i = index">
                        <td>{{ item.year }}</td>
                        <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
                        <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
                        <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
                        <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
                        <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
                        <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
                        <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
                        <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
                        <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
                        <td>{{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}</td>
                        <td>{{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}</td>
                        <td>{{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}</td>
                        <td>{{ item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 + item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 + item.fortnight13
                            + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 + item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24 | currency }}</td>
                    </tr>
                </tbody>
            </table>

        </fieldset>

        <fieldset>
            <div class="row pl-2 pr-2">
                <div class="col-md-6">
                    <legend>Prestamos</legend>
                </div>
                <div class="col-md-6 text-right">
                    <label>
                        Mostrar todo <input checked (change)="changeLoans( $event.target.checked )" type="checkbox"  >
                    </label>
                </div>
            </div>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Sorteo</th>
                        <th scope="col">Monto</th>
                        <th scope="col"># Cheque</th>
                        <th scope="col">Qna Inicial</th>
                        <th scope="col">Qna Final</th>
                        <th scope="col">Estatus</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let item of loans; let i = index">
                        <td>{{ item.draw.name }}</td>
                        <td>{{ item.amount | currency }}</td>
                        <td>{{ item.checkNumber }}</td>
                        <td>{{ item.fortnightStart }}</td>
                        <td>{{ item.fortnightEnd }}</td>
                        <td>{{ item.status }}</td>
                    </tr>
                </tbody>
            </table>
        </fieldset>

        <fieldset>
            <div class="row pl-2 pr-2">
                <div class="col-md-6">
                    <legend>Abonos</legend>
                </div>
                <div class="col-md-6 text-right">
                    <label>
                        Mostrar todo <input checked (change)="changePayments( $event.target.checked )" type="checkbox"  >
                    </label>
                </div>
            </div>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Año</th>
                        <th scope="col">Qna 1<br />Qna 13</th>
                        <th scope="col">Qna 2<br />Qna 14</th>
                        <th scope="col">Qna 3<br />Qna 15</th>
                        <th scope="col">Qna 4<br />Qna 16</th>
                        <th scope="col">Qna 5<br />Qna 17</th>
                        <th scope="col">Qna 6<br />Qna 18</th>
                        <th scope="col">Qna 7<br />Qna 19</th>
                        <th scope="col">Qna 8<br />Qna 20</th>
                        <th scope="col">Qna 9<br />Qna 21</th>
                        <th scope="col">Qna 10<br />Qna 22</th>
                        <th scope="col">Qna 11<br />Qna 23</th>
                        <th scope="col">Qna 12<br />Qna 24</th>
                        <th scope="col">Subtotal</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let item of payments; let i = index">
                        <td>{{ item.year }}</td>
                        <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
                        <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
                        <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
                        <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
                        <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
                        <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
                        <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
                        <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
                        <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
                        <td>{{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}</td>
                        <td>{{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}</td>
                        <td>{{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}</td>
                        <td>{{ item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 + item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 + item.fortnight13
                            + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 + item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24 | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</article>