import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoanParameterInterface } from '../interfaces/loan-parameter.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LoanParameterService {
  private apiURL = 'api/Catalogs/LoanParameters';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  create(phoneType: LoanParameterInterface): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', phoneType);
  }

  get(searchValue: string) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  getById(id: string) {
    return this.http.get<any>(this.apiURL + '/' + id);
  }

  GetLoanParameterByTeacher(teacher: string) {
    return this.http.get<any>(this.apiURL + '/GetLoanParameterByTeacher/' + teacher);
  }

  update(id: string, user: LoanParameterInterface) {
    return this.http.put<any>(this.apiURL + '/' + id, user);
  }

  delete(id: string) {
    return this.http.delete<any>(this.apiURL + '/' + id);
  }
}
