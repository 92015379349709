import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExcelService } from '../../../services/excel.service';
import { PaymentPolicyInterface } from '../payments/interfaces/payment-policy.interface';
import { PaymentPolicyService } from '../payments/services/payment-policy.service';

@Component({
  selector: 'app-debits',
  templateUrl: './debits.component.html',
  styles: []
})
export class DebitsComponent implements OnInit {
  forma: UntypedFormGroup;
  errorMessage = '';
  sendingData = false;
  dataReport: any[] = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;

  constructor( private formBuilder: UntypedFormBuilder,
    private paymentService: PaymentPolicyService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      startDate: [this.inicio],
      endDate: [this.inicio]
    });
  }

  getExcel(): void {
    if ( this.isValidDate(this.forma.controls['startDate'].value) && this.inicio.length === 10 ) {
      if ( this.isValidDate(this.forma.controls['endDate'].value) && this.inicio.length === 10 ) {
        this.sendingData = true;
        const paymentPolicy: PaymentPolicyInterface = {
          year: 0,
          fortnight: 0,
          startDate: new Date( this.forma.controls['startDate'].value ),
          endDate: new Date( this.forma.controls['endDate'].value ),
          source: 'DOMICILACION'
        };

        this.paymentService.getDomiciliations( paymentPolicy )
        .subscribe(data => {
          console.log('data', data);
          this.sendingData = false;
          this.dataReport = this.clearData(data);
          if ( this.dataReport.length > 0 ) {
            this.excelService.exportAsExcelFileWithoutHeader(this.dataReport, `Poliza_Domiciliaciones_`);
            this.sendingData = false;
          }
        }, error => this.setErrorMessage(error));
      } else {
        this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
      }
    } else {
      this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
    }
  }

  clearData(data: any): any[] {
    const array = [];

    this.addEmptyItemToArray( array, 2 );

    array.push( this.createItem( 'lg', '0',
    `APORTACION AL FONDO Y CREDITOS PEN Y JUB`,
    `${ this.forma.controls['startDate'].value }`, '', '', '' ) );

    array.push( this.createItem('',
    '1120-005-000',
    '0',
    `APORTACION AL FONDO Y CREDITO PENSIONADOS Y JUBILADOS  C D`,
    '',
    data.amount,
    '' ) );

    data.details.forEach(element => {
      array.push(
        this.createItem( '',
          element.account,
          '0',
          element.concept,
          '',
          '',
          element.capital )
        );
    });

    array.push( this.createItem('',
    '4100-001-000',
    '0',
    `INTERESES SOBRE PRESTAMOS/INTERES GANADO`,
    '',
    '',
    data.interest + data.iva ) );

    array.push( this.createItem('',
    '2140-001-000',
    '0',
    `INTERESES POR DEVENGAR`,
    '',
    data.interest + data.iva,
    '' ) );

    array.push( this.createItem('',
    '1160-002-000',
    '0',
    `INTERESES POR COBRAR`,
    '',
    '',
    data.interest + data.iva ) );

    array.push( this.createItem('',
    '4100-002-003',
    '0',
    `PRIMA DE PROTECCION AL AHORRO COBRADA`,
    '',
    '',
    data.lifeInsurance ) );

    array.push( this.createItem('',
    '2140-002-000',
    '0',
    `PROVISION PRIMA DE PROTECCION AL AHORRO`,
    '',
    data.lifeInsurance,
    '' ) );

    array.push( this.createItem('',
    '1160-001-000',
    '0',
    `PROVISION PRIMA DE PROTECCION AL AHORRO`,
    '',
    '',
    data.lifeInsurance ) );

    return array;
  }

  addEmptyItemToArray(data: any[], numberItems: number): any {
    for (let index = 0; index < numberItems; index++) {
      data.push(this.createItem('', '', '', '', '', '', ''));
    }
  }

  createItem(col: string, cuenta: string, depto: string, concepto: string, col2: string, abono: string, cargo: string ): any {
    return {
        col: col,
        cuenta: cuenta,
        depto: depto,
        concepto: concepto,
        col2: col2,
        abono: abono,
        cargo: cargo,
      };
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.sendingData = false;
    }
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
