<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <h3 class="title">
                        Prestamos <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <a *ngIf="accountService.havePermission(applications, 'Prestamos Pendientes') && pending > 0" class="btn btn-outline-dark" [routerLink]="['/administration/loans/pending', searchValue]">
                        <i class="fas fa-print"></i> Pendientes ({{ pending }})
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar..." (keyup.enter)="search()">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>R.F.C.</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span># Identificación</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Prestamo</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Monto</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Sorteo</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Estatus</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header fixed item-col-actions-dropdown-3"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.teacher.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">R.F.C.</div>
                        <div>
                            <span>{{ item.teacher.rfc }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading"># Identificación</div>
                        <div> {{ item.checkNumber }} </div>
                    </div>

                    <div class="item-col item-col-item text-right">
                        <div class="item-heading">Prestamo</div>
                        <div> {{ item.loanNumber }} </div>
                    </div>

                    <div class="item-col item-col-sales">
                        <div class="item-heading">Monto</div>
                        <div> {{ item.amount | currency }} </div>
                    </div>

                    <div class="item-col item-col-sales">
                        <div class="item-heading">Sorteo</div>
                        <div> {{ item.draw.name }} </div>
                    </div>

                    <div class="item-col item-col-sales">
                        <div class="item-heading">Estatus</div>
                        <div> {{ item.status }} </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown-3">
                        <div class="item-list-actions text-center">
                            <!-- <span *ngIf="accountService.havePermission(applications, 'Abonos Agregar') && item.status === 'Activo'">
                                <a class="primary" [routerLink]="['/administration/payments/add', item.id ]" >
                                    <i class="fas fa-plus"></i>
                                </a> *ngIf="item.status === 'Activo'"
                            </span> -->

                            <span>
                                <a class="primary" [routerLink]="['/administration/payments/add', item.id ]" >
                                    <i class="fas fa-plus"></i>
                                </a>
                            </span>

                            <span *ngIf="accountService.havePermission(applications, 'Prestamos Cancelar') && item.status === 'Activo'">
                                 | <a class="required" (click)="showConfirm( item.id, item.loanNumber, item.teacher.name, item )" href="" data-toggle="modal" >
                                    <i class="far fa-trash-alt"></i>
                                </a>
                            </span>
                            <span *ngIf="accountService.havePermission(applications, 'Prestamos Cambiar Estatus')">
                                <a class="primary" (click)="showChangeStatus( item )" href="" data-toggle="modal">
                                  | <i class="fas fa-exchange-alt"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

</article>
