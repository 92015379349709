import { Routes } from '@angular/router';
import { ImproperPaymentListComponent } from './improper-payment-list.component';
import { ImproperPaymentComponent } from './improper-payment.component';

export const IMPROPERPAYMNETS_ROUTES: Routes = [
    { path: 'add/:id', component: ImproperPaymentComponent },
    { path: ':searchValue', component: ImproperPaymentListComponent },
    { path: '', component: ImproperPaymentListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
