import { Injectable, Inject } from '@angular/core';
import { ImproperPaymentInterface } from '../interfaces/improper-payment.interface';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { ImproperPaymentCreateInterface } from '../interfaces/improper-payment-create.interface';

@Injectable({
  providedIn: 'root'
})
export class ImproperPaymentService {
  private apiURL = 'api/Administration/ImproperPayments';

  constructor(private http: HttpClient,

  @Inject('BASE_URL') private baseUrl: string) { }

  create(improperPayment: ImproperPaymentCreateInterface): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', improperPayment);
  }

  get(searchValue: string) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  update(id: string, impropertPayment: ImproperPaymentInterface) {
    return this.http.put<any>(this.apiURL + '/' + id, impropertPayment);
  }

  apply(id: string, impropertPayment: ImproperPaymentInterface) {
    return this.http.put<any>(this.apiURL + '/Apply/' + id, impropertPayment);
  }

  delete(id: string) {
    return this.http.delete<any>(this.apiURL + '/' + id);
  }

}
