import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SupplierInterface } from '../interfaces/supplier.interface';

@Injectable()
export class SupplierService {
  private apiURL = this.baseUrl + 'api/Banks/Suppliers';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  get() {
    return this.http.get<any>(`${ this.apiURL }`);
  }

  post(suppliers: Array<SupplierInterface>) {
    return this.http.post<any>(`${ this.apiURL }`, suppliers);
  }

  put(supplier: SupplierInterface) {
    return this.http.put<any>(`${ this.apiURL }`, supplier);
  }

}
