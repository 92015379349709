<article class="content items-list-page">

  <app-loading-data *ngIf="loadingData"></app-loading-data>

  <app-error-message *ngIf="errorMessage" [message]="errorMessage"
  ></app-error-message>

  <div class="card items animated fadeIn fast" >
    <div class="row mb-2 mt-2 ">
      <div class="col-12 text-center">
        <h3>
          <p [ngClass]="{'required': !teacher.active }">{{ teacher.name }} ({{ teacher.type }})</p>
          <p [ngClass]="{'required': !teacher.active }">{{ teacher.rfc }}</p>
        </h3>
      </div>
    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-12 text-center">
        <h4><p>Estado de cuenta préstamo personal</p>
        </h4>
      </div>
    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-2">
        No. Cheque o Transferencia:
      </div>
      <div class="col-2">
        {{ loan?.checkNumber }}
      </div>

      <div class="col-2">
        Importe del Cheque o transferencia
      </div>
      <div class="col-2">
        {{ loan?.amount - ( loan?.loanParameter.openingBonus + (loan?.amount * (loan?.loanParameter.guaranteedPercentage / 100 )) ) | currency }}
      </div>

      <div class="col-2">
        Fecha:
      </div>
      <div class="col-2">
        {{ loan?.acceptanceDate | date: 'dd-MM-yyyy' }}
      </div>

    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-2">
        Plazo:
      </div>
      <div class="col-2">
        {{ loan?.loanParameter.fortnight.numberFortnight }}
      </div>

      <div class="col-2">
        Qna inicial:
      </div>
      <div class="col-2">
        {{ loan?.fortnightStart }}
      </div>

      <div class="col-2">
        Qna final:
      </div>
      <div class="col-2">
        {{ loan?.fortnightEnd }}
      </div>
    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-2">
        Capital:
      </div>
      <div class="col-2">
        {{ loan?.amount | currency }}
      </div>

      <div class="col-2">
        Interes:
      </div>
      <div class="col-2">
        {{ ( loan?.loanParameter.interests + loan?.loanParameter.iva ) | currency }}
      </div>

      <div class="col-2">
        Prima de Garantia:
      </div>
      <div class="col-2">
        {{ ( loan?.loanParameter.lifeInsurance ) | currency }}
      </div>

    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-2">
        Monto total del adeudo:
      </div>
      <div class="col-2">
        {{ (loan?.loanParameter.amount + loan?.loanParameter.interests + loan?.loanParameter.iva + loan?.loanParameter.lifeInsurance) | currency }}
      </div>
      <div class="col-2">
        Quincena último Pago:
      </div>
      <div class="col-2">
        {{ fortnightLastPayment }}
      </div>
    </div>

    <hr />

    <div class="row mb-2 mt-2 ">
      <div class="col-1">
        <strong>Abonos</strong>
      </div>
      <div class="col-3">
        Capital Pagado:
      </div>
      <div class="col-2">
        {{ amountCapitalPayments | currency }}
      </div>

      <div class="col-1">
        <strong>Saldo</strong>
      </div>
      <div class="col-3">
        Saldo de Capital:
      </div>
      <div class="col-2">
        {{ balanceCapitalPayments | currency }}
      </div>
    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-1">

      </div>
      <div class="col-3">
        Interes Pagado:
      </div>
      <div class="col-2">
        {{ amountInterestPayments | currency }}
      </div>

      <div class="col-1">
        
      </div>
      <div class="col-3">
        Saldo de Interes:
      </div>
      <div class="col-2">
        {{ balanceInterestPayments | currency }}
      </div>
    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-1">

      </div>
      <div class="col-3">
        Prima de Garantia:
      </div>
      <div class="col-2">
        {{ amountLifeInsurancePayments | currency }}
      </div>

      <div class="col-1">
        
      </div>
      <div class="col-3">
        Saldo Prima de Garantia
      </div>
      <div class="col-2">
        {{ balanceLifeInsurancePayments | currency }}
      </div>
    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-1">
        
      </div>
      <div class="col-3">
        Total Pagado:
      </div>
      <div class="col-2">
        {{ amountCapitalPayments + amountInterestPayments + amountLifeInsurancePayments | currency }}
      </div>

      <div class="col-1">
        
      </div>
      <div class="col-3">
        Saldo Total Pendiente:
      </div>
      <div class="col-2">
        {{ balanceCapitalPayments + balanceInterestPayments + balanceLifeInsurancePayments | currency }}
      </div>
    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-1">
        
      </div>
      <div class="col-3">
        Quincenas Pagadas:
      </div>
      <div class="col-2">
        {{ fullPayments + incompletePayments }}
      </div>

      <div class="col-1">
        
      </div>
      <div class="col-3">
        Quincenas Pendientes:
      </div>
      <div class="col-2">
        {{ loan?.loanParameter.fortnight.numberFortnight - (fullPayments + incompletePayments) }}
      </div>
    </div>

    <div class="row mb-2 mt-2 ">
      <div class="col-1">
        
      </div>
      <div class="col-3">
        Quincenas Incompletas:
      </div>
      <div class="col-2">
        {{ incompletePayments }}
      </div>

      <div class="col-1">
        
      </div>
      <div class="col-3">
        
      </div>
      <div class="col-2">
        
      </div>
    </div>
    
  </div>

  

  <div class="form-group">
    <a class="btn btn-outline-info" ng-reflect-router-link="/administration/deadlines," [href]="returnUrl">
      <i class="fas fa-arrow-left"></i> Regresar
    </a>
  </div>
</article>
