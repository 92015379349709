import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account/login/services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  loading = false;
  userName = '';
  userProfile = '';
  constructor(public accountService: AccountService,
    private router: Router ) {
      this.userName = this.accountService.getUserName();
      this.userProfile = this.accountService.getUserProfile();
    }

  logout() {
    this.loading = true;
    this.accountService.logout();
    this.router.navigate(['/login']);
  }

  ngOnInit() {
  }

}
