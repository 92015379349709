import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelService } from '../../../services/excel.service';
import { LoanReportService } from './services/loan-report.service';

@Component({
  selector: 'app-interest-recover-details',
  templateUrl: './interest-recover-details.component.html',
  styles: []
})
export class InterestRecoverDetailsComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  processingData = false;
  errorMessage = false;
  year = 0;
  fortnight = 0;
  dataReport: Array<any>[] = [];

  constructor( private loanReportService: LoanReportService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.activatedRoute.params.subscribe(params => {
      if ( params['year'] && params['fortnight'] ) {
        this.year = params['year'];
        this.fortnight = params['fortnight'];
        this.loanReportService.getInterestRecoverDetails(this.year, this.fortnight).subscribe(data => {
          this.dataReport = data;
          console.log(this.dataReport);
          this.loadingData = false;
        }, error => this.setErrorMessage(error));
      } else {
        this.router.navigate(['administration/ways-to-pay']);
      }
    }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error: any ) {
    console.error(error);
    if (error && error.error) {
      this.errorMessage = error.message;
      this.loadingData = false;
    }
  }

  getExcel(): void {
    this.processingData = true;
    // tslint:disable-next-line:max-line-length
    this.excelService.exportAsExcelFileWithoutHeader(this.clearData( this.dataReport ), `Prestamos_Anio_${ this.year }_Quincena_${ this.fortnight }`);
    this.processingData = false;
  }

  clearData(data: any[]): any[] {
    const array = [];

    array.push( this.createItem( 'RFC', 'Nombre', 'Prestamo', '# Qnas', 'Prima de Protección', 'Capital', 'Interes', 'IVA' ) );

    data.forEach(element => {
      array.push(
        this.createItem( element.rfc,
          element.name,
          element.amount,
          element.numberFortnight,
          element.fortnightLifeInsurance,
          element.fortnightCapital,
          element.fortnightInterest,
          element.fortnightIVA )
        );
    });
    return array;
  }

  // tslint:disable-next-line:max-line-length
  createItem(rfc: string, nombre: string, prestamo: string, qnas: string, primaproteccion: string, capital: string, interes: string, iva: string ): any {
    return {
        rfc: rfc,
        nombre: nombre,
        prestamo: prestamo,
        qnas: qnas,
        primaproteccion: primaproteccion,
        capital: capital,
        interes: interes,
        iva: iva
      };
  }

}
