<article class="content items-list-page">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Reportes</li>
      </ol>
  </nav>

  <div class="title-search-block">
      <div class="title-block">
          <div class="row">
              <div class="col-md-6">
                  <h3 class="title">
                      <h3 class="title">Ingresos</h3>
                  </h3>
              </div>

              <!--<div *ngIf="dataReport.length !== 0" class="col-md-6 text-right">-->
                <div class="col-md-6 text-right">
                  <button type="button" class="btn btn-outline-info"><i class="far fa-file-pdf"></i> PDF</button>
              </div>
          </div>
      </div>
  </div>

  <div class="box-search">
      <div class="row">
          <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <form role="search">
                  <div class="input-box">
                      <i class="fas fa-search"></i>
                      <input type="date" [(ngModel)]="searchValue" maxlength="50" name="searchValue" autocomplete="off" placeholder="Ingresar fecha ... " (keyup.enter)="searchData()">
                      <div class="underline"></div>
                  </div>
              </form>
          </div>
      </div>
  </div>

  <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
  
  <ul class="item-list striped">
    <li class="item item-list-header">
      <div class="row">
          <div class="item-col item-col-header item-col-item">
              <div class="no-overflow">
                  <span>Concepto</span>
              </div>
          </div>
          <div class="item-col item-col-header">
              <div>
                  <span>Importe</span>
              </div>
          </div>
      </div>
    </li>

    <li *ngFor="let item of dataReport; let i = index">
        <div class="item-row">
            <div class="item-col fixed pull-left item-col-title">
                <div class="item-heading">Acción</div>
                <div *ngIf="item.concepto == 'Ahorro'">
                    <span >Ahorro</span>
                </div>

                <div *ngIf="item.concepto == 'Retencion'">
                    <span >Abonos a préstamos</span>
                </div>

                <div *ngIf="item.concepto == 'Aportacion'">
                    <span >Aportaciones voluntarias</span>
                </div>
                <div *ngIf="item.concepto == 'Interes'">
                    <span >Interes</span>
                </div>
                <div *ngIf="item.concepto == 'IVA'">
                    <span >IVA</span>
                </div>
            </div>

            <div class="item-col item-col-item">
                <div class="item-heading">Total</div>
                <div *ngIf="item.concepto == 'Ahorro'">
                    <span>{{ item.importe | currency }}</span>
                </div>

                <div *ngIf="item.concepto == 'Aportacion'">
                    <span>{{ item.importe | currency }}</span>
                </div>

                <div *ngIf="item.concepto == 'Retencion'">
                    <span>{{ item.importe | currency }}</span>
                </div>
                <div *ngIf="item.concepto == 'Interes'">
                    <span>{{ item.importe | currency }}</span>
                </div>
                <div *ngIf="item.concepto == 'IVA'">
                    <span>{{ item.importe | currency }}</span>
                </div>
            </div>
        </div>
    </li>
  </ul>

  <div class="row">
      <div class="col-12 text-center">
          <h3 class="m-3">Total del estado financiero {{ amountTotal | currency }}</h3>
      </div>
  </div>

  </div>
 
  <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

  <app-loading-data *ngIf="loadingData"></app-loading-data>

  <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

</article>