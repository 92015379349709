<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3 class="title">
                        <h3 class="title">Prestamos Detalle ( {{ dataReport.length }} )</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast">
        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>
                
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Tipo</label>
                        <select class="custom-select" formControlName="type">
                            <option value="1">Sin filtro</option>
                            <option value="2">Mayor a </option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" >
                    <div class="form-group " *ngIf="forma.controls['type'].value === '2'" style="padding-top: 30px;">
                        <input type="number" class="form-control" value="0" formControlName="amount" >
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="padding-top: 30px;" >
                    <div class="row">
                        <div class="col-4 text-center">
                            <button type="button" [disabled]="loadingData" class="btn btn-outline-primary" (click)="handlerSearch()" >
                                <i class="fas fa-search"></i> Buscar
                            </button>
                        </div>
                        <div class="col-4 text-center">
                            <button type="button" [disabled]="dataReport.length === 0" class="btn btn-outline-warning" (click)="getPDF()">
                                <i [ngClass]="{ 'fas fa-file-pdf': !processingData, 'fas fa-sync-alt fa-spin': processingData }"></i>
                                <span *ngIf="!processingData"> Generar PDF</span>
                                <span *ngIf="processingData" >
                                    Procesando...
                                </span>
                            </button>  
                        </div>
                        <div class="col-4 text-center">
                            <button type="button" [disabled]="dataReport.length === 0" class="btn btn-outline-success" (click)="getExcel()">
                                <i [ngClass]="{ 'far fa-file-excel': !processingData, 'fas fa-sync-alt fa-spin': processingData }"></i>
                                <span *ngIf="!processingData"> Generar</span>
                                <span *ngIf="processingData" >
                                    Procesando...
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <table *ngIf="dataReport.length > 0" class="table table-striped table-hover">
            <thead class="text-center">
                <tr>
                    <th scope="col">RFC</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Capital ({{ amountCapital | currency }})</th>
                    <th scope="col">Interes ({{ amountInteres | currency }})</th>
                    <!-- <th scope="col">IVA ({{ amountIVA | currency }})</th> -->
                    <th scope="col">Prima de Protección ({{ amountLifeInsurance | currency }})</th>
                    <th scope="col">Total ({{ amountCapital + amountInteres + amountIVA + amountLifeInsurance | currency }})</th>
                </tr>
            </thead>

            <tbody>
                <tr class="text-right" *ngFor="let item of dataReport; let i = index">
                    <td>{{ item.rfc }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.prestamos - ( item.abonos + item.liquidacion + item.cobros ) | currency }}</td>
                    <td>{{ item.interesGenerado - item.interesPagado | currency }}</td>
                    <!-- <td>{{ item.iva - item.paymentIva | currency }}</td> -->
                    <td>{{ item.prima | currency }}</td>
                    <!-- <td>{{ item.recovery | currency }}</td> -->
                    <td>{{ (( item.prestamos - item.abonos ) + 
                            ( item.interesGenerado - item.interesPagado ) + 
                            ( item.prima ) ) | currency }}</td>
                </tr>
            </tbody>

            <tfoot>
                <tr class="text-right">
                    <td class="info" colspan="2">Total General</td>
                    <td>{{ amountCapital | currency }}</td>
                    <td>{{ amountInteres | currency }}</td>
                    <td>{{ amountIVA | currency }}</td>
                    <td>{{ amountLifeInsurance | currency }}</td>
                    <td>{{ amountCapital + amountInteres + amountIVA + amountLifeInsurance | currency }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</article>