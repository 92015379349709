<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
            <li class="breadcrumb-item"><a [routerLink]="['/reports/interestsrecover', year, fortnight]" routerLinkActive="router-link-active">Intereses por Recuperar</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3 class="title">
                        Detalle Año {{ year }} Quincena {{ fortnight }}
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
        <div class="form-group row p-3">
            <div class="col-12 text-right">
                <button type="button" class="btn btn-outline-success" (click)="getExcel()">
                    <i [ngClass]="{ 'far fa-file-excel': !processingData, 'fas fa-sync-alt fa-spin': processingData }"></i>
                    <span *ngIf="!processingData">Generar</span>
                    <span *ngIf="processingData" >
                        Procesando...
                    </span>
                </button>
            </div>
        </div>

        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>RFC</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Prestamo</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span># Qnas</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Prima de Protección</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Capital</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Interes</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>IVA</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="item" *ngFor="let item of dataReport | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col item-col-item">
                        <div class="item-heading">RFC</div>
                        <div> {{ item.rfc }} </div>
                    </div>
                    <div class="item-col item-col-item">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Prestamo</div>
                        <div> {{ item.amount | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading"># Qnas</div>
                        <div> {{ item.numberFortnight }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Prima de Protección</div>
                        <div> {{ item.fortnightLifeInsurance | currency }} </div>
                    </div>

                    <div class="item-col item-col-item pull-right">
                        <div class="item-heading">Capital</div>
                        <div> {{ item.fortnightCapital | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Interes</div>
                        <div> {{ item.fortnightInterest | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">IVA</div>
                        <div> {{ item.fortnightIVA | currency }} </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && dataReport.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>


</article>