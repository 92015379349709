import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//    ROUTING
import { APP_ROUTING } from './app.routes';
//    PIPES
import { CurrencyPipe } from '@angular/common';
//    COMPONENTS
import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/account/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { ApplicationsComponent } from './components/configuration/applications/applications.component';
import { ApplicationListComponent } from './components/configuration/applications/application-list.component';
import { ApplicationComponent } from './components/configuration/applications/application.component';
import { ModulesComponent } from './components/configuration/modules/modules.component';
import { ModuleComponent } from './components/configuration/modules/module.component';
import { ModuleListComponent } from './components/configuration/modules/module-list.component';
import { AngularPaginatorComponent } from './components/angular-paginator/angular-paginator.component';
import { PermissionsComponent } from './components/configuration/permissions/permissions.component';
import { ProfilesComponent } from './components/configuration/profiles/profiles.component';
import { ProfileComponent } from './components/configuration/profiles/profile.component';
import { ProfileListComponent } from './components/configuration/profiles/profile-list.component';
import { UsersComponent } from './components/configuration/users/users.component';
import { UserComponent } from './components/configuration/users/user.component';
import { UserListComponent } from './components/configuration/users/user-list.component';
import { TeachersComponent } from './components/administration/teachers/teachers.component';
import { TeacherListComponent } from './components/administration/teachers/teacher-list.component';
import { TeacherComponent } from './components/administration/teachers/teacher.component';
import { LoanComponent } from './components/administration/loans/loan.component';
import { LoanListComponent } from './components/administration/loans/loan-list.component';
import { LoansComponent } from './components/administration/loans/loans.component';
import { NotDataComponent } from './components/not-data/not-data.component';
import { SavingComponent } from './components/administration/savings/saving.component';
import { SavingsComponent } from './components/administration/savings/savings.component';
import { SavingListComponent } from './components/administration/savings/saving-list.component';
import { LoadingDataComponent } from './components/loading-data/loading-data.component';
import { DrawListComponent } from './components/administration/draws/draw-list.component';
import { DrawComponent } from './components/administration/draws/draw.component';
import { DrawsComponent } from './components/administration/draws/draws.component';
import { BulkLoadComponent } from './components/administration/bulk-load/bulk-load.component';
import { UploadComponent } from './components/administration/bulk-load/upload.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { SavingReportComponent } from './components/reports/savings/saving-report.component';
import { PaymentReportComponent } from './components/reports/payments/payment-report.component';
import { LoanReportComponent } from './components/reports/loans/loan-report.component';
import { BalanceComponent } from './components/reports/balance/balance.component';
import { ConfigurationsComponent } from './components/configuration/configurations/configurations.component';
import { UnsubscribeComponent } from './components/administration/teachers/unsubscribe.component';
import { LowStaffListComponent } from './components/administration/low-staffing/low-staff-list.component';
import { LowStaffComponent } from './components/administration/low-staffing/low-staff.component';
import { LowStaffingComponent } from './components/administration/low-staffing/low-staffing.component';
import { PaymentListComponent } from './components/administration/payments/payment-list.component';
import { PaymentsComponent } from './components/administration/payments/payments.component';
import { PaymentDetailComponent } from './components/administration/payments/payment-detail.component';
import { LoansPendingComponent } from './components/administration/loans/loans-pending.component';
import { IncomingComponent } from './components/reports/incoming/incoming.component';
import { ExpensesComponent } from './components/reports/expenses/expenses.component';
//    DIRECTIVES
import { OpenDirective } from './components/modals/open.directive';
import { AngularPaginatorDirective } from './components/angular-paginator/directives/angular-paginator.directive';
//    PIPES
import { AngularPaginatorPipe } from './components/angular-paginator/pipes/angular-paginator.pipe';
//    SERVICES
import { AccountService } from './components/account/login/services/account.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AngularPaginatorService } from './components/angular-paginator/services/angular-paginator.service';
import { ModulesService } from './components/configuration/modules/services/modules.service';
import { ApplicationService } from './components/configuration/applications/services/application.service';
import { ProfilesService } from './components/configuration/profiles/services/profiles.service';
import { UsersService } from './components/configuration/users/services/users.service';
import { TeacherService } from './components/administration/teachers/services/teacher.service';
import { PhoneTypeService } from './components/catalogs/phone-types/services/phone-type.service';
import { DrawService } from './components/administration/draws/services/draw.service';
import { FortnightService } from './components/catalogs/fortnights/services/fortnight.service';
import { LoanService } from './components/administration/loans/services/loan.service';
import { SavingService } from './components/administration/savings/services/saving.service';
import { RegionService } from './components/catalogs/regions/services/region.service';
import { RelationshipService } from './components/catalogs/relationships/services/relationship.service';
import { LoanParameterService } from './components/catalogs/loan-parameter/services/loan-parameter.service';
import { BulkLoadService } from './components/administration/bulk-load/services/bulk-load.service';
import { SavingReportService } from './components/reports/savings/services/saving-report.service';
import { PaymentReportService } from './components/reports/payments/services/payment-report.service';
import { LoanReportService } from './components/reports/loans/services/loan-report.service';
import { BalanceService } from './components/reports/balance/service/balance.service';
import { ConfigurationsService } from './components/configuration/configurations/services/configurations.service';
import { TeacherStatusService } from './components/catalogs/teacher-status/services/teacher-status.service';
import { LowStaffService } from './components/administration/low-staffing/services/low-staff.service';
import { ContributionService } from './components/catalogs/contributions/services/contribution.service';
import { PaymentService } from './components/administration/payments/services/payment.service';
import { FinancialLiquidityService } from './components/administration/financial-liquidity/services/financial-liquidity.service';
import { PaymentComponent } from './components/administration/payments/payment.component';
import { DeathsComponent } from './components/reports/death/deaths.component';
import { DeathsReportService } from './components/reports/death/services/deaths-report.service';
import { LoanIssuedReportComponent } from './components/reports/loans/loan-issued-report.component';
import { InterestsRecoverReportComponent } from './components/reports/loans/interests-recover-report.component';
import { ExcelService } from './services/excel.service';
import { IncomingService } from './components/reports/incoming/services/incoming.service';
import { ExpensesService } from './components/reports/expenses/services/expenses.service';
import { PaymentPolicyComponent } from './components/policies/payments/payment-policy.component';
import { PaymentPolicyService } from './components/policies/payments/services/payment-policy.service';
import { SavingPolicyService } from './components/policies/savings/services/saving-policy.service';
import { SavingPolicyComponent } from './components/policies/savings/saving-policy.component';
import { InterestsPolicyComponent } from './components/policies/savings/interests-policy.component';
import { BankService } from './components/administration/banks/services/bank.service';
import { AffiliationsComponent } from './components/banks/affiliations/affiliations.component';
import { AffiliationService } from './components/banks/affiliations/services/affiliation.service';
import { CollectionComponent } from './components/banks/collections/collection.component';
import { CollectionService } from './components/banks/collections/services/collection.service';
import { AffiliationsApplyComponent } from './components/banks/affiliations/affiliations-apply.component';
import { CollectionApplyComponent } from './components/banks/collections/collection-apply.component';
import { AccountingPaymentComponent } from './components/accounting/payments/payment.component';
import { AccountingPaymentService } from './components/accounting/payments/services/payment.service';
import { SuppliersComponent } from './components/banks/suppliers/suppliers.component';
import { TransfersComponent } from './components/banks/transfers/transfers.component';
import { MoneyOutComponent } from './components/policies/money-out/money-out.component';
import { MoneyOutPolicyService } from './components/policies/money-out/services/money-out-policy.service';
import { TransferService } from './components/banks/transfers/services/transfer.service';
import { SupplierService } from './components/banks/suppliers/services/supplier.service';
import { TransferListComponent } from './components/banks/transfers/transfer-list.component';
import { TransferDetailComponent } from './components/banks/transfers/transfer-detail.component';
import { TransferComponent } from './components/banks/transfers/transfer.component';
import { AccountingSavingsComponent } from './components/accounting/accounting-savings/accounting-savings.component';
import { BeneficiariesComponent } from './components/accounting/beneficiaries/beneficiaries.component';
import { AcceptanceDateComponent } from './components/banks/transfers/acceptance-date.component';
import { TeacherGlobalComponent } from './components/reports/teacher-global/teacher-global.component';
import { TeacherGlobalDetailsComponent } from './components/reports/teacher-global/teacher-global-details.component';
import { TeacherGlobalsComponent } from './components/reports/teacher-global/teacher-globals.component';
import { TeacherGlobalStatusComponent } from './components/reports/teacher-global/teacher-global-status.component';
import { InputsPolicyComponent } from './components/policies/inputs/inputs-policy.component';
import { UpsComponent } from './components/statistic/ups/ups.component';
import { SettlementPolicyComponent } from './components/policies/settlement/settlement-policy.component';
import { AddQuestionComponent } from './components/reports/teacher-global/add-question.component';
import { QuestionsComponent } from './components/reports/questions/questions/questions.component';
import { CollectionHistoryComponent } from './components/banks/collections/collection-history.component';
import { CollectionDetailsComponent } from './components/banks/collections/collection-details.component';
import { CollectionsComponent } from './components/banks/collections/collections.component';
import { WayToPayListComponent } from './components/administration/way-to-pay/way-to-pay-list.component';
import { WayToPayDetailsComponent } from './components/administration/way-to-pay/way-to-pay-details.component';
import { WayToPaysComponent } from './components/administration/way-to-pay/way-to-pays.component';
import { SavingDetailsComponent } from './components/reports/saving-details/saving-details.component';
import { BankDepositsComponent } from './components/policies/bank-deposits/bank-deposits.component';
import { BankDepositsService } from './components/policies/bank-deposits/services/bank-deposits.service';
import { WayToPayUpdateComponent } from './components/administration/way-to-pay/way-to-pay-update.component';
import { EmploymentFortnightComponent } from './components/reports/employment-fortnight/employment-fortnight.component';
import { InterestRecoverDetailsComponent } from './components/reports/loans/interest-recover-details.component';
import { SupportComponent } from './components/administration/supports/support.component';
import { SupportListComponent } from './components/administration/supports/support-list.component';
import { SupportsComponent } from './components/administration/supports/supports.component';
import { SupportService } from './components/administration/supports/services/support.service';
import { SupportsPendingComponent } from './components/administration/supports/supports-pending.component';
import { DebitsComponent } from './components/policies/debits/debits.component';
import { SupportDetailComponent } from './components/administration/supports/support-detail.component';
import { DeadlinesComponent } from './components/administration/deadlines/deadlines.component';
import { DeadlineListComponent } from './components/administration/deadlines/deadline-list.component';
import { DeadlineComponent } from './components/administration/deadlines/deadline.component';
import { DeadlineService } from './components/administration/deadlines/services/deadline.service';
import { DeadlineDetailComponent } from './components/administration/deadlines/deadline-detail.component';
import { ActiveTeachersComponent } from './components/reports/active-teachers/active-teachers.component';
import { LowStaffingDetailsComponent } from './components/administration/low-staffing/low-staffing-details.component';
import { CollectionsSeechComponent } from './components/administration/collections/collections-seech.component';
import { CollectionListSeechComponent } from './components/administration/collections/collection-list-seech.component';
import { CollectionDetailsSeechComponent } from './components/administration/collections/collection-details-seech.component';
import { LowstaffingPolicyComponent } from './components/policies/lowstaffing/lowstaffing-policy.component';
import { CollectionBankListComponent } from './components/reports/collection-banks/collection-bank-list.component';
import { DataUpdatesComponent } from './components/administration/data-update/data-updates.component';
import { DataUpdateListComponent } from './components/administration/data-update/data-update-list.component';
import { DataUpdateDetailsComponent } from './components/administration/data-update/data-update-details.component';
import { DataUpdateProcessComponent } from './components/administration/data-update/data-update-process.component';
import { DataUpdateService } from './components/administration/data-update/service/data-update.service';
import { TeacherLowStaffComponent } from './components/administration/teachers/teacher-low-staff.component';
import { LowStaffingGlobalComponent } from './components/reports/low-staffing-global/low-staffing-global.component';
import { ChecksService } from './components/reports/low-staffing-global/services/checks.service';
import { LowStaffingGlobalAddComponent } from './components/reports/low-staffing-global/low-staffing-global-add.component';
import { LoanChecksTransfersComponent } from './components/reports/loans/loan-checks-transfers.component';
import { LoanChecksTransfersAddComponent } from './components/reports/loans/loan-checks-transfers-add.component';
import { AccountStatementsComponent } from './components/reports/account-statements/account-statements.component';
import { AccountStatusComponent } from './components/reports/account-statements/account-status.component';
import { TransfersLowstaffComponent } from './components/banks/transfers-lowstaff/transfers-lowstaff.component';
import { TransfersLowstaffingComponent } from './components/banks/transfers-lowstaff/transfers-lowstaffing.component';
import { TransfersLowstaffListComponent } from './components/banks/transfers-lowstaff/transfers-lowstaff-list.component';
import { TransfersLowstaffDetailComponent } from './components/banks/transfers-lowstaff/transfers-lowstaff-detail.component';
import { SuppliersLowstaffComponent } from './components/banks/suppliers-lowstaff/suppliers-lowstaff.component';
import { EditDataLowstaffComponent } from './components/banks/suppliers-lowstaff/edit-data-lowstaff.component';
import { LowStaffingReportComponent } from './components/reports/low-staffing-report/low-staffing-report.component';
import { LowStaffingReportsComponent } from './components/reports/low-staffing-report/low-staffing-reports.component';
import { LowStaffingReportDetailsComponent } from './components/reports/low-staffing-report/low-staffing-report-details.component';
import { ImproperPaymentsComponent } from './components/administration/improper-payments/improper-payments.component';
import { ImproperPaymentListComponent } from './components/administration/improper-payments/improper-payment-list.component';
import { ImproperPaymentComponent } from './components/administration/improper-payments/improper-payment.component';
import { UpdateWayToPayComponent } from './components/administration/improper-payments/update-way-to-pay.component';
import { CollectionRecoverComponent } from './components/reports/collection-banks/collection-recover.component';
import { AddPaymentComponent } from './components/reports/teacher-global/add-payment.component';
import { TeacherDetailsComponent } from './components/administration/teachers/teacher-details.component';
import { HelperService } from './services/helper.service';
import { DebtorMasterComponent } from './components/reports/debtor-master/debtor-master.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        FooterComponent,
        LoginComponent,
        HeaderComponent,
        OpenDirective,
        AngularPaginatorComponent,
        AngularPaginatorDirective,
        AngularPaginatorPipe,
        ModulesComponent,
        ModuleComponent,
        ModuleListComponent,
        ApplicationsComponent,
        ApplicationListComponent,
        ApplicationComponent,
        PermissionsComponent,
        ProfilesComponent,
        ProfileComponent,
        ProfileListComponent,
        TeachersComponent,
        TeacherListComponent,
        TeacherComponent,
        UsersComponent,
        UserComponent,
        UserListComponent,
        LoanComponent,
        LoanListComponent,
        LoansComponent,
        LoadingDataComponent,
        NotDataComponent,
        SavingComponent,
        SavingsComponent,
        SavingListComponent,
        DrawListComponent,
        DrawComponent,
        DrawsComponent,
        BulkLoadComponent,
        UploadComponent,
        ErrorMessageComponent,
        SavingReportComponent,
        PaymentReportComponent,
        PaymentPolicyComponent,
        SavingPolicyComponent,
        LoanReportComponent,
        BalanceComponent,
        ConfigurationsComponent,
        UnsubscribeComponent,
        LowStaffListComponent,
        LowStaffComponent,
        LowStaffingComponent,
        PaymentsComponent,
        PaymentListComponent,
        PaymentDetailComponent,
        LoansPendingComponent,
        PaymentComponent,
        DeathsComponent,
        LoanIssuedReportComponent,
        InterestsRecoverReportComponent,
        IncomingComponent,
        ExpensesComponent,
        InterestsPolicyComponent,
        AffiliationsComponent,
        CollectionComponent,
        AffiliationsApplyComponent,
        CollectionApplyComponent,
        AccountingPaymentComponent,
        SuppliersComponent,
        TransfersComponent,
        MoneyOutComponent,
        TransferListComponent,
        TransferDetailComponent,
        TransferComponent,
        AccountingSavingsComponent,
        BeneficiariesComponent,
        EditDataLowstaffComponent,
        LowStaffingDetailsComponent,
        AcceptanceDateComponent,
        UpdateWayToPayComponent,
        TeacherGlobalComponent,
        TeacherGlobalDetailsComponent,
        TeacherGlobalsComponent,
        TeacherGlobalStatusComponent,
        InputsPolicyComponent,
        UpsComponent,
        SettlementPolicyComponent,
        AddQuestionComponent,
        QuestionsComponent,
        CollectionHistoryComponent,
        CollectionDetailsComponent,
        CollectionsComponent,
        WayToPayListComponent,
        WayToPayDetailsComponent,
        WayToPaysComponent,
        SavingDetailsComponent,
        BankDepositsComponent,
        WayToPayUpdateComponent,
        EmploymentFortnightComponent,
        InterestRecoverDetailsComponent,
        SupportComponent,
        SupportListComponent,
        SupportsComponent,
        SupportsPendingComponent,
        DebitsComponent,
        SupportDetailComponent,
        DeadlinesComponent,
        DeadlineListComponent,
        DeadlineComponent,
        DeadlineDetailComponent,
        ActiveTeachersComponent,
        CollectionsSeechComponent,
        CollectionListSeechComponent,
        CollectionDetailsSeechComponent,
        LowstaffingPolicyComponent,
        CollectionBankListComponent,
        DataUpdatesComponent,
        DataUpdateListComponent,
        DataUpdateDetailsComponent,
        DataUpdateProcessComponent,
        TeacherLowStaffComponent,
        LowStaffingGlobalComponent,
        LowStaffingGlobalAddComponent,
        LoanChecksTransfersComponent,
        LoanChecksTransfersAddComponent,
        AccountStatementsComponent,
        AccountStatusComponent,
        TransfersLowstaffComponent,
        TransfersLowstaffingComponent,
        TransfersLowstaffListComponent,
        TransfersLowstaffDetailComponent,
        SuppliersLowstaffComponent,
        LowStaffingReportComponent,
        LowStaffingReportsComponent,
        LowStaffingReportDetailsComponent,
        ImproperPaymentsComponent,
        ImproperPaymentComponent,
        ImproperPaymentListComponent,
        CollectionRecoverComponent,
        AddPaymentComponent,
        TeacherDetailsComponent,
        DebtorMasterComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        APP_ROUTING,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    providers: [
        AccountService,
        AngularPaginatorService,
        AuthGuardService,
        ModulesService,
        ApplicationService,
        ProfilesService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        UsersService,
        TeacherService,
        PhoneTypeService,
        DrawService,
        DeadlineService,
        FortnightService,
        LoanService,
        SavingService,
        RegionService,
        RelationshipService,
        LoanParameterService,
        BulkLoadService,
        SavingReportService,
        PaymentReportService,
        PaymentPolicyService,
        LoanReportService,
        BalanceService,
        CurrencyPipe,
        ConfigurationsService,
        TeacherStatusService,
        LowStaffService,
        ContributionService,
        PaymentService,
        FinancialLiquidityService,
        DeathsReportService,
        ExcelService,
        HelperService,
        IncomingService,
        ExpensesService,
        SavingPolicyService,
        BankService,
        AffiliationService,
        CollectionService,
        AccountingPaymentService,
        MoneyOutPolicyService,
        TransferService,
        SupplierService,
        BankDepositsService,
        SupportService,
        DataUpdateService,
        ChecksService,
        provideNgxMask()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
