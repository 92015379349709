import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PeriodInterface } from '../../../../interfaces/period.interface';

@Injectable()
export class LoanReportService {
  private apiURL = 'api/Reports/Loans';

  constructor(  private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string  ) { }

    get( searchValue: string, movementType: string, startDate: string, endDate: string ) {
      let url = `${ this.apiURL }/${ searchValue }/${ movementType }`;
      if ( startDate !== '' && endDate !== '' ) {
        url = `${ url }/${ startDate }/${ endDate }`;
      }
      return this.http.get<any>( url );
    }

    getLoansIssued( period: PeriodInterface ) {
      return this.http.post<any>(this.apiURL + '/LoansGranted', period);
    }

    getYears( ) {
      return this.http.get<any>(this.apiURL + '/GetYears');
    }

    getInterestsRecover( year: number, fortnight: number ) {
      return this.http.get<any>(this.apiURL + '/InterestsRecover/' + year + '/' + fortnight);
    }

    getInterestsRecoverByTeacher( teacherId: string, year: number, fortnight: number ) {
      return this.http.get<any>(this.apiURL + '/InterestsRecoverTeacher/' + teacherId + '/' + year + '/' + fortnight);
    }

    getInterestRecoverDetails( year: number, fortnight: number ) {
      return this.http.get<any>(this.apiURL + '/InterestRecoverDetails/' + year + '/' + fortnight);
    }
}
