<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
            <li class="breadcrumb-item"><a [routerLink]="['/reports/teachers-global/status', payment.teacherId]" routerLinkActive="router-link-active">Búsqueda Global</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Agregar</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="gettingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4 class="info"><b>{{ payment.teacher.name | uppercase }}</b></h4>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año inicio</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Qna inicio</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año fin</label>
                        <select class="custom-select" formControlName="yearEnd">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Qna fin</label>
                        <select class="custom-select" formControlName="fortnightEnd">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['amount'].valid && forma.controls['amount'].touched, 'has-success': forma.controls['amount'].valid }">
                        <label class="control-label">Importe</label>
                        <input type="number" class="form-control underlined" formControlName="amount">
                        <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.min" class="has-error ">
                            El valor mínino es 1.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                    <div class="form-group">
                        <label class="control-label">Acumulado</label>
                        <input type="text" maxlength="250" class="form-control underlined" readonly value="{{ amount | currency }}">
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                        <button type="button" class="btn btn-outline-secondary" [disabled]="this.forma.controls['amount'].value < 0 || this.forma.controls['fortnight'].value === '' || this.forma.controls['fortnightEnd'].value === ''" (click)="addPayment()">
                            <i class="far fa-plus-square"></i> Agregar
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div formArrayName="payments" *ngFor="let payment of forma.get('payments')['controls']; let i = index">
                    <div [formGroupName]="i">
                        <div class="row" *ngIf="payment.get('year').value">
                            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                                <div class="form-group">
                                    <label class="control-label">#</label>
                                    <input type="text" maxlength="250" class="form-control underlined" readonly [value]="i + 1">
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label">Año</label>
                                    <input type="text" maxlength="250" class="form-control underlined" readonly name="year" formControlName="year">
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label">Quincena</label>
                                    <input type="text" maxlength="250" class="form-control underlined" readonly name="fortnight" formControlName="fortnight">
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label">Importe</label>
                                    <input type="number" class="form-control underlined" name="amount" formControlName="amount" (blur)="blurPayment();">
                                </div>
                            </div>

                            <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                <button type="button" class="btn btn-outline-danger" (click)="deletePayment( i )">
                                    <i class="far fa-trash-alt"></i> Eliminar
                                </button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group text-center ">
                <button type="button" [disabled]="!forma.valid || sendingData || amount == 0" class="btn btn-outline-success" (click)="sendForm()">
                  <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                  <span *ngIf="!sendingData ">Guardar</span>
                  <span *ngIf="sendingData ">Guardando...</span>
                </button>
            </div>

            <app-error-message *ngIf="message" [message]="message"></app-error-message>
        </form>

        <div class="row">
            <a class="btn btn-outline-info " [routerLink]="['/reports/teachers-global/status', payment.teacherId]"><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>

    </div>

    <ng-template appOpen></ng-template>
</div>
