import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PeriodInterface } from '../../../../interfaces/period.interface';

@Injectable()
export class SavingPolicyService {
  private apiURL = 'api/policies/Savings';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  get(year: number, fortnight: number, source: string) {
    return this.http.get<any>(this.apiURL + '/' + year + '/' + fortnight + '/' + source);
  }

  getYears() {
    return this.http.get<any>(this.apiURL);
  }

  getDataInterests(year: number, type: string) {
    return this.http.get<any>(`${ this.apiURL }/${ year }/${ type }`);
  }

  getDataInputs( period: PeriodInterface ) {
    return this.http.post<any>(this.apiURL, period);
  }

  getDataSettlement( period: PeriodInterface ) {
    return this.http.post<any>(`${ this.apiURL }/settlement`, period);
  }
}
