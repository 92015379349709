import { Component, OnInit, ViewChild } from '@angular/core';
import { ExcelService } from '../../../services/excel.service';
import { AccountService } from '../../account/login/services/account.service';
import { LoanService } from '../../administration/loans/services/loan.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { ChecksInterface } from '../low-staffing-global/interfaces/checks.interface';
import { ChecksService } from '../low-staffing-global/services/checks.service';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-loan-checks-transfers',
  templateUrl: './loan-checks-transfers.component.html',
  styles: []
})
export class LoanChecksTransfersComponent implements OnInit {
  applications: ApplicationInterface[];
  exporting = false;
  loadingData = false;
  type = 2;
  searchValue = '';
  jsonArray: ChecksInterface[] = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  errorMessage = '';

  constructor( private checksService: ChecksService,
    private loanService: LoanService,
    public accountService: AccountService,
    private excelService: ExcelService,
    private notificationService: NotificationService,
    private helperService: HelperService ) { }

  ngOnInit() {
    this.accountService.getApplications('reports', 'loans-check-transfers')
      .subscribe(applications => {
        this.applications = applications;
        this.loadData();
      }, error => this.helperService.setErrorMessage(error));
  }

  loadData( ) {
    this.loanService.getChecksTransfers (this.type , this.inicio, this.fin, this.searchValue)
    .subscribe(response => {
      if( response.isSuccess ) {
        this.jsonArray = response.result;
      } else {
        this.errorMessage = response.message;
      }
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  showConfirm( check: ChecksInterface ): void {
    this.notificationService.showConfirm(`¿Seguro que desea cancelar el movimiento ${ check.number }?`, 'Cancelación de movimiento')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem( check );
      }
    });
  }

  deleteItem( check: ChecksInterface ): void {
    this.checksService.delete(check.id).subscribe(
      success => {
        check.active = false;
        this.notificationService.toastConfirm(`Se cancelo correctamente el movimiento`);
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  search(): void {
    this.loadingData = true;
    this.loadData();
  }

  getExcel(): void {
    this.exporting = true;
    const newData = this.jsonArray.map(d => ({
      nombre: d.name,
      fecha: `${ d.movementDate.toString().substring(0, 4) }-${ d.movementDate.toString().substring(5, 7) }-${ d.movementDate.toString().substring(8, 10) }`,
      cheque: d.number,
      importe: d.amount,
      comentario: d.comment,
      estatus: ( d.active ) ? 'Activo' : 'Cancelado'
    }));
    this.excelService.exportAsExcelFile(newData,
      `Cheques_Prestamos`);
    this.exporting = false;
  }

}
