import { DataUpdateService } from './service/data-update.service';
import { DataUpdateInterface } from './interfaces/data-update.interface';
import { Component, OnInit, enableProdMode } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { DataUpdateBeneficiaryInterface } from './interfaces/data-update-beneficiary.interface';
import { element } from 'protractor';
import { start } from 'repl';

@Component({
  selector: 'app-data-update-process',
  templateUrl: './data-update-process.component.html',
  styles: []
})
export class DataUpdateProcessComponent implements OnInit {
  errorMessage: string;
  isLoadingData = false;
  isProcessingData = false;
  teachersToProcess = false;
  searchValue = '';
  csvContent: string;
  selectedFileNames: string[] = [];
  teachers: DataUpdateInterface[] = [];

  constructor( private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    private dataUpdateService: DataUpdateService ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if ( params['searchValue'] ) {
        this.searchValue = params['searchValue'];
      }
    }, error => this.setErrorMessage(error));
  }

  handlerClick(): void {
    this.isProcessingData = true;
    this.dataUpdateService.process(this.teachers).subscribe( response => {
      this.reserForm();
    }, error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.errorMessage = error.message;
      this.isLoadingData = false;
      this.isProcessingData = false;
    }
  }

  onFileSelect(input: HTMLInputElement) {
    const files = input.files;
    if (files && files.length) {
      if (!this.validFile(files[0].name)) {
        this.errorMessage = `Solo puede procesar archivos con la extencion .csv`;
      } else {
        const fileToRead = files[0];
        this.selectedFileNames = [];
        this.selectedFileNames.push(files[0].name);
        const fileReader = new FileReader();
        fileReader.onloadend = () => this.onFileLoad(fileReader.result.toString().split('\n'), this.accountService.getUserId());

        fileReader.readAsText( fileToRead , 'UTF-8');
      }
    }
  }

  validFile( fileName: string ): boolean {
    if ( fileName.slice(-3).toUpperCase() === 'CSV') {
      return true;
    } else {
      return false;
    }
  }

  onFileLoad( lines: Array<any>, userId: string ) {
    const re = /\"/gi;
    this.teachers = [];
    this.teachersToProcess = true;
    this.isLoadingData = true;
    for ( let index = 1; index < lines.length; index++ ) {
      const columns = lines[index].replace( new RegExp('"', 'g'), '').split(',');
      if ( columns.length === 61 ) {
        let arrBirthdate = columns[7].split('/');
        let employeeNumber = 0;

        if ( columns[1].length <= 7 ) {
          if ( this.esNumero( columns[1] ) ) {
            employeeNumber = columns[1];
          }
        }

        const item: DataUpdateInterface =  {
          id: '00000000-0000-0000-0000-000000000000',
          userId: userId,
          date: new Date(),
          active: true,
          lastUpdated: new Date(),
          userIdLastUpdated: userId,
          employeeNumber: employeeNumber,
          rfc: columns[2],
          curp: columns[3],
          lastName: columns[4],
          motherLastName: columns[5],
          name: columns[6],
          birthdate: new Date( arrBirthdate[2], parseInt(arrBirthdate[1], null) - 1, arrBirthdate[0] ),
          gender: columns[8],
          email: columns[9],
          phone: columns[10],
          street: columns[11],
          number: ( columns[12].length > 7 ) ? columns[12].substring(0, 6) : columns[12],
          colony: columns[13],
          zipCode: (columns[14].length === 0 ) ? 0 : parseInt(columns[14], null),
          errorMessage: '',
          sendMail: false,
          // tslint:disable-next-line:max-line-length
          movementDate: new Date(`${ columns[0].replace(re, '').substring(0, 4) }-${ columns[0].replace(re, '').substring(5, 7) }-${ parseInt(columns[0].replace(re, '').substring(8, 10), null) + 1 }`),
          beneficiaries: this.getBeneficiaries(columns, userId)
        };

        if ( arrBirthdate.length !== 3 ) {
          if ( columns[7].indexOf('-') !== -1 ) {
            arrBirthdate = columns[7].split('-');
            item.birthdate = new Date( arrBirthdate[0], parseInt(arrBirthdate[1], null) - 1, arrBirthdate[2] );
          } else {
            item.errorMessage = 'El formato de la fecha no es correcto';
            item.birthdate = new Date( 1999, 0, 1);
          }
        }
        this.dataUpdateService.getValidateTeacher( employeeNumber, columns[2], columns[3]).subscribe( (data: boolean) => {
          item.active = data;
          this.teachers.push(item);
        }, error => {
          this.errorMessage = error.message;
          this.teachers.push(item);
        });
      }
    }
    this.isLoadingData = false;
  }

  reserForm(): void {
    this.teachers = [];
    this.teachersToProcess = false;
    this.selectedFileNames = [];
    this.errorMessage = '';
    this.isLoadingData = false;
    this.isProcessingData = false;
  }

  getBeneficiaries( items: any[], userId: string ): DataUpdateBeneficiaryInterface[] {
    const re = /\"/gi;
    const returnArr: DataUpdateBeneficiaryInterface[] = [];
    const startPosition = this.getStartPositionItem( parseInt(items[15].replace(re, ''), null) );
    // let interval = startPosition;
    const period: any = {
      interval: parseInt(items[15].replace(re, ''), null),
      start: startPosition,
      end: startPosition + (parseInt(items[15].replace(re, ''), null) * 3) - 1
    };

    // console.log( items );

    while (period.start < period.end) {
      const item: DataUpdateBeneficiaryInterface =  {
        id: '00000000-0000-0000-0000-000000000000',
        userId: userId,
        date: new Date(),
        active: true,
        lastUpdated: new Date(),
        userIdLastUpdated: userId,
        name: items[period.start],
        relationship: items[period.start + 1],
        percentage: items[period.start + 2]
      };

      returnArr.push( item );
      period.start = period.start + 3;
    }
    return returnArr;
  }

  getStartPositionItem( elements: number ): number {
    let startPosition = 16;
    let iteration = elements - 1;
    while (iteration > 0) {
      startPosition += iteration * 3;
      iteration--;
    }
    return startPosition;
  }

  setPermission(event: Event, item: DataUpdateInterface): void {
    // item.active = (<HTMLSelectElement>event.srcElement).checked;
  }

  esNumero( dato: string ): boolean {
    const valoresAceptados = /^[0-9]+$/;
    if (dato.match(valoresAceptados)) {
      return true;
    } else {
      return false;
    }
  }
}
