import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { PaymentService } from '../payments/services/payment.service';
import { PaymentInterface } from '../payments/interfaces/payment.interface';
import { PeriodInterface } from '../../../interfaces/period.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenDirective } from '../../modals/open.directive';
import { WayToPayUpdateComponent } from './way-to-pay-update.component';
import { SavingService } from '../savings/services/saving.service';
import { WayToPayUpdateInterface } from './interfaces/way-to-pay-update.interface';

@Component({
  selector: 'app-way-to-pay-list',
  templateUrl: './way-to-pay-list.component.html',
  styles: []
})
export class WayToPayListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  errorMessage = '';
  jsonArray = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  period: PeriodInterface = {
    start: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`,
    end: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`
  };
  applications: ApplicationInterface[];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor( public accountService: AccountService,
    private paymentService: PaymentService,
    private savingService: SavingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver, ) { }

  ngOnInit() {
    this.loadingData = true;
    this.activatedRoute.params.subscribe(params => {
      if ( params['start'] && params['end'] ) {
        this.inicio = params['start'];
        this.fin = params['end'];
      }
      this.accountService.getApplications('administration', 'ways-to-pay')
        .subscribe(applications => {
          this.applications = applications;
          this.loadData();
        }, error => this.setErrorMessage(error));
    }, error => this.setErrorMessage(error));
  }

  loadData() {
    this.errorMessage = '';
    if ( this.isValidDate(this.inicio) && this.inicio.length === 10 ) {
      if ( this.isValidDate(this.fin) && this.inicio.length === 10 ) {
        this.loadingData = true;
        this.period.start = this.inicio;
        this.period.end = this.fin;
        this.paymentService.getLiquidationsPayments( this.period )
          .subscribe( (data) => {
            this.jsonArray = data;
            this.loadingData = false;
          }, error => this.setErrorMessage(error));
      } else {
        this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
        this.loadingData = false;
      }
    } else {
      this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
      this.loadingData = false;
    }
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  calculateAamount( item: PaymentInterface ): number {
    return item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 +
      item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 +
      item.fortnight13 + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 +
      item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24;
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  handlerDetailsClick( element: any ): void {
    // this.router.navigate(['/administration/ways-to-pay/detail', id, type, this.inicio, this.fin]);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      WayToPayUpdateComponent
    );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<WayToPayUpdateComponent>componentRef.instance).open();
    (<WayToPayUpdateComponent>componentRef.instance).alertOutput.subscribe(
      (wayToPayResponse: string) => {
        console.log(wayToPayResponse);
        element.wayToPay = wayToPayResponse;
        const wayToPay: WayToPayUpdateInterface = {
          teacherId: element.teacherId,
          wayToPay: wayToPayResponse,
          folio: '',
          // movementDate: element.movementDate,
          userId: this.accountService.getUserId()
        };

        if ( element.type === 'Liquidación' ) {
          this.paymentService.putLiquidationsPayments( wayToPay )
            .subscribe(response => {
              // this.router.navigate(['administration/ways-to-pay', '']);
            }, error => this.setErrorMessage(error));
        } else {
          this.savingService.putInputsSaving( wayToPay )
            .subscribe(response => {
              // this.router.navigate(['administration/ways-to-pay', '']);
            }, error => this.setErrorMessage(error));
        }
      },
      error => this.setErrorMessage(error)
    );
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
