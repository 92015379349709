import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SavingPolicyService } from './services/saving-policy.service';
import { ExcelService } from '../../../services/excel.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

@Component({
  selector: 'app-interests-policy',
  templateUrl: './interests-policy.component.html',
  styles: []
})
export class InterestsPolicyComponent implements OnInit {
  forma: UntypedFormGroup;
  errorMessage = '';
  isLoadingData = false;
  dataInteres: any[] = [];
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

  constructor( private savingService: SavingPolicyService, private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      type: [''],
      percentage: ['0.00208']
    });
    this.savingService.getYears()
    .subscribe(years => {
      this.years = years;
      this.isLoadingData = false;
    }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.isLoadingData = false;
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  getExcel(): void {
    this.isLoadingData = true;
    this.savingService.getDataInterests( this.forma.controls['year'].value, this.forma.controls['type'].value )
    .subscribe(data => {
      this.calculateAmount(data, this.forma.controls['year'].value, this.forma.controls['fortnight'].value, this.forma.controls['percentage'].value);
      this.isLoadingData = false;
    }, error => this.setErrorMessage(error));
  }

  calculateAmount(data: any[], year: number, fortnight: string, percentage: number) {
    let interestWithImport = 0;
    let teacherRFC = '';
    let amountTotalInterest = 0;
    this.dataInteres = [];
    let fortnightTitle = fortnight;
    if ( fortnight.length === 1 ) {
      fortnightTitle = `0${ fortnight }`;
    }
    const descripcion = `Capitalización de intereses${ year }-${ fortnightTitle }`;

    this.dataInteres.push({
      x: '',
      cuenta: '',
      departamento: '',
      descripcion: '',
      y: '',
      cargo: '',
      abono: ''
    });

    this.dataInteres.push({
      x: '',
      cuenta: '',
      departamento: '',
      descripcion: '',
      y: '',
      cargo: '',
      abono: ''
    });

    this.dataInteres.push({
      x: 'Ig',
      cuenta: '0',
      departamento: descripcion,
      descripcion: '15',
      y: '',
      cargo: '',
      abono: ''
    });

    this.dataInteres.push({
      x: '',
      cuenta: '7200-001-000',
      departamento: 0,
      descripcion: descripcion,
      y: '',
      cargo: '00000000000',
      abono: ''
    });

    data.forEach(item => {
      if ( item.rfc !== teacherRFC ) {
        teacherRFC = item.rfc;
        interestWithImport = 0;
      }
      let interestPeritem = 0;
      // let interests = interestWithImport;
      const arrayItem = Object.keys(item).map(function(key) {
        return [String(key), item[key]];
      });

      for (let index = 0; index < arrayItem.length; index++) {
        if ( arrayItem[index][0].slice(0, 3).toUpperCase() === 'QNA' ) {
          if ( arrayItem[index][1] !== 0 ) {
             const interestFortnight = (interestWithImport + arrayItem[index][1]) * percentage;
             if ( parseInt(arrayItem[index][0].slice(4, 6), 0) === parseInt(fortnight, 0)
               && parseInt(arrayItem[2][1], 0) === parseInt(year.toString(), 0)) {
               this.dataInteres.push({
                x: '',
                cuenta: '2110-002-000',
                departamento: 0,
                descripcion: `${ item.rfc } - ${ item.name }`,
                y: '',
                cargo: '',
                abono: interestFortnight
               });
               amountTotalInterest += interestFortnight;
            }

             interestPeritem += interestFortnight;
             interestWithImport += arrayItem[index][1] + interestFortnight;
          }
        }
      }
      item.interests = parseFloat(interestPeritem.toFixed(2));
    });

    this.dataInteres[3].cargo = amountTotalInterest;

    this.excelService.exportAsExcelFileWithoutHeader(this.dataInteres, `${ descripcion }_${ this.forma.controls['type'].value }`);
  }

}
