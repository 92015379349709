<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Maestros Activos</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast">
        <form [formGroup]="forma">
            <div class="row form-group ml-2 mr-2">

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 text-right">
                    <div class="mt-3 mg-2" >
                        <button type="button" class="btn btn-outline-primary" (click)="search( )">
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <ul class="nav nav-tabs" id="myTab" role="tablist" *ngIf="!loadingData">
            <li class="nav-item">
                <a class="nav-link active" id="basica-tab" data-toggle="tab" href="#basica" (click)="loadData('Básico')" role="tab" aria-controls="basica" aria-selected="true">Básica ({{ getNumber('Básico') }})</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="reingresos-tab" data-toggle="tab" href="#reingresos" (click)="loadData('Reingreso')" role="tab" aria-controls="reingresos" aria-selected="false">Reingresos ({{ getNumber('Reingreso') }})</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="jubilados-tab" data-toggle="tab" href="#jubilados" (click)="loadData('Alta Jubilación')" role="tab" aria-controls="jubilados" aria-selected="false">Alta Jubilados ({{ getNumber('Alta Jubilación') }})</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="MediaSuperior-tab" data-toggle="tab" href="#MediaSuperior" (click)="loadData('Media Superior')" role="tab" aria-controls="MediaSuperior" aria-selected="false">Media Superior ({{ getNumber('Media Superior') }})</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="basica" role="tabpanel" aria-labelledby="basica-tab">
                <ul class="item-list striped">
                    <li class="item item-list-header">
                        <div class="row">
                            <div class="item-col item-col-header item-col-header">
                                <div class="no-overflow">
                                    <span>Nombre</span>
                                </div>
                            </div>
                            <div class="item-col item-col-header item-col-header">
                                <div>
                                    <span>R.F.C.</span>
                                </div>
                            </div>
                            <div class="item-col item-col-header">
                                <div>
                                    <span>CURP</span>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="item" *ngFor="let item of data ; let i = index">
                        <div class="item-row">
                            <div class="item-col fixed pull-left item-col-title">
                                <div class="item-heading">Nombre</div>
                                <div>
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>

                            <div class="item-col item-col-item">
                                <div class="item-heading">R.F.C.</div>
                                <div>
                                    <span>{{ item.rfc }}</span>
                                </div>
                            </div>

                            <div class="item-col item-col-item">
                                <div class="item-heading">CURP</div>
                                <div> {{ item.curp }} </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</article>