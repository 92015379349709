import { Component, OnInit } from '@angular/core';
import { TransferService } from './services/transfer.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-transfer-detail',
  templateUrl: './transfer-detail.component.html',
  styles: []
})
export class TransferDetailComponent implements OnInit {
  isLoadingData = false;
  errorMessage: string;
  jsonArray = [];
  currentPage = 1;
  itemsPerPage = 100;

  constructor( private transferService: TransferService,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.activatedRoute.params.subscribe(params => {

      this.transferService.getDetails(params['block'])
        .subscribe(data => {
          this.jsonArray = data;
          this.isLoadingData = false;
        }, error => {
          this.setErrorMessage(error);
          this.isLoadingData = false;
        });
    }, error => {
      this.setErrorMessage(error);
      this.isLoadingData = false;
    });
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.isLoadingData = false;
    }
  }

}
