<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Consultas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <h3 class="title">
                        Prestamos <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar..." (keyup.enter)="search()">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Comentario</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Fecha</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Ahorro</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Abono</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header fixed item-col-actions-dropdown-3"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Comentario</div>
                        <div>
                            <span>{{ item.comment }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Fecha</div>
                        <div>
                            <span>{{ item.date | date: 'dd/MM/yyyy' }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Ahorro</div>
                        <div> {{ item.saving }} </div>
                    </div>

                    <div class="item-col item-col-item text-right">
                        <div class="item-heading">Abono</div>
                        <div> {{ item.payment }} </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown-3">
                        <div class="item-list-actions text-center">
                            <span *ngIf="accountService.havePermission(applications, 'Abonos Agregar') && item.payment > 0">
                            <a class="primary" [routerLink]="['/administration/payments/add', item.loanId, '../../../../../reports/questions' ]" >
                                <i class="fas fa-plus"></i>
                            </a> |
                          </span>
                            <span *ngIf="accountService.havePermission(applicationsTeachers, 'Maestros Ahorros') && item.payment > 0">
                            <a class="success" [routerLink]="['/administration/savings/add', item.teacherId, 'reports/questions' ]">
                                <i class="fas fa-piggy-bank"></i>
                            </a></span>
                            <span>
                                | <a class="required" (click)="showConfirm( item.teacherId )" href="" data-toggle="modal" >
                                   <i class="far fa-trash-alt"></i>
                               </a>
                           </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

</article>
