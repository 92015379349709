<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/banks/collections', searchValue]" routerLinkActive="router-link-active">Cobranza</a></li>
            <li class="breadcrumb-item active" aria-current="page">Detalle</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Cobranza <small>Detalle</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">
        <app-loading-data *ngIf="loadingData"></app-loading-data>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

        <table class="table table-striped table-hover" *ngIf="collection.collectionDetails.length > 0">
            <thead>
                <tr>
                    <th>#</th>
                    <th>CURP</th>
                    <th>Código</th>
                    <th>Aplicado</th>
                    <th>No aplicado</th>
                    <th>Mensaje</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of collection.collectionDetails; let i = index" [ngClass]="{'required': item.active != true}" >
                    <td >{{ i + 1 }}</td>
                    <td>{{ item.curp }}</td>
                    <td>{{ item.code }}</td>
                    <td class="text-right">
                        <span *ngIf="item.valid && item.applied">{{ item.amount | currency }}</span>
                    </td>
                    <td class="text-right">
                        <span class="required" *ngIf="!item.applied">{{ item.amount | currency }}</span>
                    </td>
                    <td>
                        {{ getDescriptionError(item.code) }} {{ item.message }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="3" class="text-right">
                        TOTALES
                    </td>
                    <td class="text-right">
                        {{ amountApplied | currency }}
                    </td>
                    <td class="text-right required">
                        {{ amountNotApplied | currency }}
                    </td>
                    <td class="text-right">
                        {{ amountNotApplied + amountApplied | currency }}
                    </td>
                </tr>
            </tfoot>
        </table>

        <div class="row">
            <div class="col-12 text-center">
                    <button class="btn btn-outline-info" type="button" (click)="handlerClick()" *ngIf="accountService.havePermission(applications, 'Cobranza Revertir') && someValid()" >
                        <i class="fas fa-exchange-alt"></i> Revertir
                    </button>
            </div>
        </div>
        <div class="row">
            <a class="btn btn-outline-info " [routerLink]="['/banks/collections', searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>

</article>