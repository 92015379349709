<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Bancos</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3 class="title">Transferencias <small *ngIf="!isLoadingData">( {{ jsonArray.length }} )</small></h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="form-group text-right">
                        <a *ngIf="accountService.havePermission(applications, 'Transferencias Generar Archivo')" [routerLink]="['/banks/transfers/generate' ]" class="btn btn-outline-primary">
                            <i class="far fa-file-pdf"></i> Generate
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!isLoadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="isLoadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!isLoadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Bloque</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Fecha</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header fixed item-col-actions-dropdown"> </div>
                </div>
            </li>

            <li class="item" *ngFor="let transfer of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Block</div>
                        <div>
                            <span>{{ transfer.block }}</span>
                        </div>
                    </div>

                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Fecha</div>
                        <div>
                            <span>{{ transfer.acceptanceDate | date: 'dd/MM/yyyy' }}</span>
                        </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown">
                        <div class="item-list-actions text-center">
                            <span *ngIf="accountService.havePermission(applications, 'Transferencias Detalle')">
                                <a class="info" [routerLink]="['/banks/transfers/details', transfer.block ]">
                                    <i class="fas fa-info-circle"></i>
                                </a>
                            </span>
                            <span *ngIf="accountService.havePermission(applications, 'Transferencias Aplicar') && transfer.acceptanceDate === '0001-01-01T00:00:00' ">
                                 | <a class="edit" (click)="showConfirm( transfer.block )" href="" data-toggle="modal" >
                                    <i class="fas fa-check"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!isLoadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

    <ng-template appOpen></ng-template>
</article>
