import { DataUpdateListComponent } from './data-update-list.component';
import { Routes } from '@angular/router';
import { DataUpdateDetailsComponent } from './data-update-details.component';
import { DataUpdateProcessComponent } from './data-update-process.component';

export const DATAUPDATE_ROUTES: Routes = [
    { path: 'process', component: DataUpdateProcessComponent },
    { path: 'process/:searchValue', component: DataUpdateProcessComponent },
    { path: 'details/:id', component: DataUpdateDetailsComponent },
    { path: 'details/:id/:searchValue', component: DataUpdateDetailsComponent },
    { path: '', component: DataUpdateListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
