import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { SupportService } from './services/support.service';

@Component({
  selector: 'app-support-list',
  templateUrl: './support-list.component.html',
  styles: []
})
export class SupportListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray: any[] = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  pending = 0;
  applications: ApplicationInterface[];

  constructor( private supportService: SupportService,
    public accountService: AccountService,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.accountService.getApplications('administration', 'supports')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if ( params['searchValue'] ) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      }, error => this.errorMessage(error));
  }

  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    this.supportService.get(value, this.inicio, this.fin)
    .subscribe(data => {
      console.log( data );
      this.jsonArray = data;
      this.supportService.havePending( )
      .subscribe(pending => {
        this.pending = pending;
        this.loadingData = false;
      },
      error => this.errorMessage(error));
    },
    error => this.errorMessage(error));
  }

  search() {
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search);
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

}
