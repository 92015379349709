<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Polizas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Aportaciones ( {{ dataReport.length }} )</h3>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button type="button" class="btn btn-outline-success" [disabled]="dataReport.length === 0" (click)="exportData()">
                <i class="far fa-file-excel"></i> Exportar
              </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="input-box">
                    <div class="row form-group">
                        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                            <label class="control-label" >Nombre</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                            <input type="text" placeholder="Teclee el nombre" name="name" [value]="name" [(ngModel)]="name">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Inicio</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                            <input class="form-control underlined" type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label># Póliza</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                            <input type="number" name="poliza" [value]="poliza" [(ngModel)]="poliza">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                            <label>Forma de pago</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                            <select class="custom-select" [(ngModel)]="wayToPay" (change)="handlerChangeWayToPay(  )">
                              <option value="Efectivo">Efectivo</option>
                              <option value="Transferencia">Transferencia</option>
                            </select>
                        </div>


                        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                            <button type="button" class="btn btn-outline-primary" (click)="loadData()" [disabled]="loadingData">
                                Buscar
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>RFC</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Tipo</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Folio</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Importe</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="item" *ngFor="let item of dataReport; let i = index">
                <div class="item-row">
                    <div class="item-col item-col-item">
                        <div class="item-heading">RFC</div>
                        <div>
                            <span>{{ item.rfc }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Tipo</div>
                        <div>
                            <span>{{ item.type }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Folio</div>
                        <div>
                            <span>{{ item.folio }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Importe</div>
                        <div>
                            <span>{{ (item.amount + item.amountSaving + item.financialProducts ) - ( item.condoneInterest + item.condoneLifeInsurance ) | currency }}</span>
                        </div>
                    </div>

                </div>
            </li>
        </ul>
    </div>
</article>