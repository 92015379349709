import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SavingInterface } from '../interfeces/saving.interface';
import { Observable } from 'rxjs/Observable';
import { SavingsInterface } from '../interfeces/savings.interface';
import { WayToPayUpdateInterface } from '../../way-to-pay/interfaces/way-to-pay-update.interface';
import { PeriodInterface } from '../../../../interfaces/period.interface';

@Injectable()
export class SavingService {
  private apiURL = 'api/Administration/Savings';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( saving: SavingsInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', saving);
    }

    getSavingStatus( type: string, id: string, period: PeriodInterface ): Observable<any> {
      return this.http.post<any>(`${ this.apiURL }/TeacherStatus/${ type }/${ id }`, period);
    }

    get( searchValue: string, year: number ) {
      return this.http.get<any>(this.apiURL + '/Search/' + year + '/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    update( id: string, saving: SavingInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, saving );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }

    getYears() {
      return this.http.get<any>(this.apiURL + '/Years');
    }

    getByFolio( folio: string ) {
      return this.http.get<any>(this.apiURL + '/ByFolio/' + folio);
    }

    getByStatus( statusId: string ) {
      return this.http.get<any>(this.apiURL + '/ByStatus/' + statusId);
    }

    putInputsSaving( wayToPay: WayToPayUpdateInterface ) {
      return this.http.put<any>( `${ this.apiURL }/WayToPay`, wayToPay );
    }
}
