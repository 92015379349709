import { Component, OnInit, EventEmitter, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-add-question',
  template: `
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><i class="fas fa-exclamation-triangle"></i> Agregar Pregunta</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ message }}</p>
            <div class="row form-group">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="control-label">Ahorros</label>
                  <input type="number" [(ngModel)]="saving" class="form-control underlined" >
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="control-label">Abonos</label>
                  <input type="number" [(ngModel)]="payment" class="form-control underlined" >
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <label class="control-label">Comentario</label>
                  <input type="text" [(ngModel)]="comment" class="form-control underlined" >
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="ok()" >
              Si
            </button>
            <button type="button" class="btn btn-secondary" (click)="cancel()" >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class AddQuestionComponent implements OnInit {
  public message: string;
  public saving = 0;
  public payment = 0;
  public comment: string;

  @Output() public alertOutput = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  open() {
    $('#myModal').modal();
  }

  ok() {
    console.log(`Importe ahorro ${ this.saving } Importe abonos ${ this.payment } Comentario ${ this.comment }`);
    $('#myModal').modal('hide');
    this.alertOutput.emit( {
      saving: this.saving,
      payment: this.payment,
      comment: this.comment
    } );
  }

  cancel() {
    $('#myModal').modal('hide');
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

}
