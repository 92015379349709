import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SavingService } from '../../administration/savings/services/saving.service';
import { SavingReportService } from '../savings/services/saving-report.service';
import { SavingDetailInterface } from './interfaces/saving-detail.interface';
import { SavingInterface } from '../../administration/savings/interfeces/saving.interface';
import { ExcelService } from '../../../services/excel.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { ConfigurationsService } from '../../configuration/configurations/services/configurations.service';
import { ConfigurationsInterface } from '../../configuration/configurations/interfaces/configurations.interface';
declare let jsPDF;

@Component({
  selector: 'app-saving-details',
  templateUrl: './saving-details.component.html',
  styles: [],
})
export class SavingDetailsComponent implements OnInit {
  forma: UntypedFormGroup;
  errorMessage = '';
  isLoadingData = false;
  amount = 0;
  amountInterests = 0;
  percentageInterest = 0.00208;
  savings: any[];
  years: number[] = [];
  teachers: SavingDetailInterface[] = [];
  applications: ApplicationInterface[];
  fortnights: number[] = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
  ];
  configurations: ConfigurationsInterface =  {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };

  constructor(
    private savingService: SavingService,
    private savingreportService: SavingReportService,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService,
    public accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private configurationsService: ConfigurationsService
  ) {}

  ngOnInit() {
    this.isLoadingData = true;
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      percentage: [this.percentageInterest],
      type: ['']
    });

    this.configurationsService.get()
        .subscribe(configurations => {
          this.configurations = configurations;
          this.savingService.getYears().subscribe(
            (years) => {
              this.years = years;
              this.accountService
                .getApplications('reports', 'teachers-global')
                .subscribe( (applications) => {
                    this.applications = applications;
                    this.activatedRoute.params.subscribe(
                      (params) => {
                        if ( params['year'] && params['fortnight'] ) {
                          this.forma.controls['year'].patchValue(params['year']);
                          this.forma.controls['fortnight'].patchValue(params['fortnight']);
                          this.getData();
                        }
                        this.isLoadingData = false;
                      }, (error) => this.setErrorMessage(error));
                  }, (error) => this.setErrorMessage(error));
            },
            (error) => this.setErrorMessage(error)
          );
        }, err => this.setErrorMessage(err));
  }

  setErrorMessage(error) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.isLoadingData = false;
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date(new Date().getFullYear(), 0, 15);
    do {
      if (date >= new Date()) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while (findingFortnight === true);
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if (date.getDate() === 15) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
    } else {
      date.setDate(date.getDate() + 14);
    }
  }

  getData(): void {
    this.isLoadingData = true;
    this.savingreportService.getDataSavingDetails(
        this.forma.controls['year'].value,
        this.forma.controls['fortnight'].value
      )
      .subscribe(
        (data) => {
          this.savings = data;
          if ( this.forma.controls['type'].value === '' ) {
            this.calculateAmount(this.savings, this.percentageInterest);
          } else {
            this.calculateAmount(this.savings.filter(s => s.type === this.forma.controls['type'].value ), this.percentageInterest);
          }

          this.isLoadingData = false;
        },
        (error) => this.setErrorMessage(error)
      );
  }

  calculateAmount(data: any[], percentage: number) {
    this.amount = 0;
    this.amountInterests = 0;
    let interestWithImport = 0;
    this.teachers = [];
    let indexItem = 0;
    let savingPerItem = 0;
    let interestPeritem = 0;
    let interestPerTeacher = 0;
    let year = 0;
    let fortnight = '';

    data.forEach((item) => {
      interestPeritem = 0;
      const arrayColumns = Object.keys(item).map(function (key) {
        return [String(key), item[key]];
      });

      if ( year === 0 ) {
        year = item.year;
      }

      for (let index = 0; index < arrayColumns.length; index++) {
        if (arrayColumns[index][0].slice(0, 3).toUpperCase() === 'QNA') {
          if (arrayColumns[index][1] !== 0) {
            const interestFortnight = (interestWithImport + arrayColumns[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayColumns[index][1] + interestFortnight;
            if ( fortnight === '' ) {
              const numberFortnight = arrayColumns[index][0].slice(4, arrayColumns[index][0].length);
              if ( numberFortnight.length === 1 ) {
                fortnight = `0${ numberFortnight }`;
              } else {
                fortnight = numberFortnight;
              }
            }
          }
        }
      }
      item.interests = parseFloat(interestPeritem.toFixed(2));
      this.amountInterests += item.interests;
      this.amount += item.total;

      savingPerItem += item.total;
      interestPerTeacher += item.interests;
      if (indexItem < data.length - 1) {
        if (item.rfc !== data[indexItem + 1].rfc) {
          this.addTeacher(
            item.id,
            item.rfc,
            item.name,
            item.type,
            item.lastStatus,
            savingPerItem,
            interestPerTeacher,
            savingPerItem + interestPerTeacher,
            `${ year }-${ fortnight }`
          );
          savingPerItem = 0;
          interestPerTeacher = 0;
          interestWithImport = 0;
          year = 0;
          fortnight = '';
        }
      } else {
        this.addTeacher(
          item.id,
          item.rfc,
          item.name,
          item.type,
          item.lastStatus,
          savingPerItem,
          interestPerTeacher,
          savingPerItem + interestPerTeacher,
          `${ year }-${ fortnight }`
        );
      }
      indexItem++;
    });
  }

  addTeacher(
    id: string,
    rfc: string,
    name: string,
    type: string,
    status: string,
    savings: number,
    interest: number,
    amount: number,
    fortnight: string
  ): void {
    const teacher: SavingDetailInterface = {
      id: id,
      rfc: rfc,
      name: name,
      type: type,
      status: status,
      savings: savings,
      interests: interest,
      amount: amount,
      startFortnight: fortnight
    };
    this.teachers.push(teacher);
  }

  calculateSavings(percentage: number) {
    this.percentageInterest = percentage;
    // this.calculateAmount(this.savings, this.percentageInterest);
    if ( this.forma.controls['type'].value === '' ) {
      this.calculateAmount(this.savings, this.percentageInterest);
    } else {
      this.calculateAmount(this.savings.filter(s => s.type === this.forma.controls['type'].value ), this.percentageInterest);
    }
  }

  handlerType(): void {
    if ( this.forma.controls['type'].value === '' ) {
      this.calculateAmount(this.savings, this.percentageInterest);
    } else {
      this.calculateAmount(this.savings.filter(s => s.type === this.forma.controls['type'].value ), this.percentageInterest);
    }
  }

  getExcel(): void {
    // this.loadingData = true;
    const data = this.teachers.map( t => ({
      TeacerId : t.id,
      RFC: t.rfc,
      Nombre: t.name,
      EstatusEvaluando: t.type,
      EstatusActual: t.status,
      Ahorros: t.savings,
      Interes: t.interests,
      Total: t.amount,
      QuincenaInicial: t.startFortnight
    }));
    this.excelService.exportAsExcelFile(
      data, // this.clearData(),
      `Altas_${this.forma.controls['year'].value}_${this.forma.controls['fortnight'].value}`
    );
    // this.loadingData = false;
  }

  getPDF(): void {
    const totalPagesExp = '{total_pages_count_string}';
    const head = this.getHeader();
    const body = this.setBodyData( this.teachers );
    this.generatePDF(head,
      body,
      totalPagesExp,
      this.configurations.imageSNTE,
      this.configurations.imageFOCAPS,
      this.forma.controls.year.value,
      this.forma.controls.fortnight.value);
  }

  getHeader(): any[] {
    return [{ rfc: 'RFC', nombre: 'Nombre', tipo: 'Tipo',
    ahorro: 'Ahorro', interes: 'Interes', monto: 'Monto' } ];
  }

  setBodyData(data: any[]): any[] {
    const body = [];
    data.forEach(item => {
      body.push({
        rfc: item.rfc,
        nombre: item.name,
        tipo: item.type,
        ahorro: this.currencyPipe.transform( item.savings ),
        interes: this.currencyPipe.transform( item.interests ),
        monto: this.currencyPipe.transform( item.amount )
      });
    });

    body.push({
      tipo: 'Total General',
      ahorro: this.currencyPipe.transform( this.amount ),
      interes: this.currencyPipe.transform( this.amountInterests ),
      monto: this.currencyPipe.transform( this.amount + this.amountInterests )
    });

    body[body.length - 1].rfc = { content: `Total General`,
      colSpan: 3, styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].ahorro = { content: this.currencyPipe.transform(this.amount),
      tyles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].interes = { content: this.currencyPipe.transform(this.amountInterests),
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].interest = { content: this.currencyPipe.transform( this.amount + this.amountInterests),
      styles: { halign: 'right', fontStyle: 'bold' } };

    return body;
  }

  generatePDF( head: any[],
    body: any[],
    totalPagesExp: string,
    imageSNTE: string,
    imageFOCAPS: string,
    year: string,
    fortnight: string ): void {
    const doc = new jsPDF( 'landscape' );
    const pageContent = function ( data ) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if ( imageSNTE ) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
     }

     if ( imageFOCAPS ) {
       doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45 , 15, 30, 20);
     }
      doc.text(`AHORROS DETALLE ${ year }-${ fortnight }`, data.settings.margin.left + 55, 20);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 40 },
      headStyles: {
        halign: 'center'
      },
      columnStyles: {
        rfc: {
          halign: 'left'
        },
        nombre: {
          halign: 'left'
        },
        tipo: {
          halign: 'left'
        },
        ahorro: {
          halign: 'right'
        },
        interes: {
          halign: 'right'
        },
        monto: {
          halign: 'right'
        }
      },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`ahorros_detalle_${ year }_${ fortnight }.pdf`);
  }
}
