import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LowStaffService } from './services/low-staff.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-low-staff-list',
  templateUrl: './low-staff-list.component.html',
  styles: []
})
export class LowStaffListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  typeSelected = 'Todo';
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  jsonArray: any[] = [];
  applications: ApplicationInterface[];
  constructor( private activatedRoute: ActivatedRoute,
    private lowstaffService: LowStaffService, private router: Router,
    public accountService: AccountService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'lowstaffing')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['waytopay']) {
            this.typeSelected = params['waytopay'];
          }
          if (params['start']) {
            this.inicio = params['start'];
          }
          if (params['end']) {
            this.fin = params['end'];
          }

          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      }, error => console.error(error));
  }

  loadData(value: string) {
    this.lowstaffService.get( value, this.typeSelected, this.inicio, this.fin )
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  search() {
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.loadingData = false;
    }
  }

  generateChecks() {
    // if ( this.searchValue !== '') {
    //   this.router.navigate( ['administration/lowstaffing/pending', this.searchValue] );
    // } else {
    //   this.router.navigate( ['administration/lowstaffing/pending', this.typeSelected, this.inicio, this.fin] );
    // }
    this.router.navigate( ['administration/lowstaffing/pending', this.typeSelected, this.inicio, this.fin] );
  }

  exportData(): void {
    const newData = this.jsonArray.map(d => ({
      RFC: d.teacher.rfc,
      Nombre: d.teacher.name,
      Quincena: d.fortnigh,
      Interes: d.amountInterests,
      Ahorro: d.amountSavings,
      Adeudo: d.amountCredit,
      InteresSinIVA: d.amountCreditInterest - (d.amountCreditInterest * 0.16),
      IVAInteres: d.amountCreditInterest * 0.16,
      InteresAdeudo: d.amountCreditInterest,
      TotalAdeudo: d.amountCredit + d.amountCreditInterest,
      Importe: d.amount,
      Cheque: d.checkNumber
    }));

    this.excelService.exportAsExcelFile(newData,
      `Bajas_${ this.inicio }_${ this.fin }`);
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

}
