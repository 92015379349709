<div class="auth">
    <div class="auth-container">
        <div class="card">
            <header class="auth-header">
                <h1 class="auth-title">
                    Control de Acceso
                </h1>
            </header>
            <div class="auth-content">
                <p class="text-center">INGRESE SUS CREDENCIALES</p>
                <form role="form" [formGroup]="forma" (ngSubmit)="validateCredentials()" novalidate="">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['username'].valid }">
                        <label for="username ">Nombre de Usuario</label>
                        <input type="text" class="form-control underlined " placeholder="Su nombre de usuario" id="username" formControlName="username" autocomplete="off">
                        <span *ngIf="forma.controls['username'].touched && forma.controls['username'].errors?.required" class="has-error ">El campo Nombre de Usuario es requerido.</span>
                        <span *ngIf="forma.controls['username'].touched && forma.controls['username'].errors?.minlength" class="has-error ">El campo Nombre de Usuario debe tener almenos {{ forma.controls['username'].errors.minlength.requiredLength }} caracteres.</span>
                    </div>
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['password'].valid }">
                        <label for="password">Contraseña</label>
                        <input type="password" class="form-control underlined" placeholder="Su contraseña" id="password" formControlName="password">
                        <span *ngIf="forma.controls['password'].touched && forma.controls['password'].errors?.required" class="has-error ">El campo contraseña es requerido.</span>
                        <span *ngIf="forma.controls['password'].touched && forma.controls['password'].errors?.minlength" class="has-error ">El campo contraseña debe tener almenos {{ forma.controls['password'].errors.minlength.requiredLength }} caracteres.</span>
                    </div>
                    <div class="form-group">
                        <button [disabled]="!forma.valid || loading" class="btn btn-block btn-primary">
                        <i class="fas" [ngClass]="{ 'fa-user-check': !loading, 'fa-sync-alt fa-spin': loading }" ></i>
                        <span *ngIf="!loading" >Accesar</span>
                        <span *ngIf="loading" >Validando...</span>
                      </button>
                    </div>

                    <div class="alert alert-danger" role="alert" *ngIf="message">
                        {{ message }}
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
