import { Component, OnInit  } from '@angular/core';
import { ProfilesService } from './services/profiles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

declare var $: any;

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styles: []
})
export class ProfileListComponent {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];

  constructor( private profileService: ProfilesService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService ) {
      this.activatedRoute.params
      .subscribe(params => {
        if ( params['searchValue'] ) {
          this.searchValue = params['searchValue'];
          this.loadData(this.searchValue);
        } else {
          this.loadData('null');
        }
      });
  }

  showConfirm( id: string, name: string ) {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar el perfil ${ name }?`, 'Eliminar perfil')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(id);
      }
    });
  }

  deleteItem( id: string ) {
    this.profileService.delete( id )
    .subscribe(() => {
      this.loadData('null');
      this.notificationService.toastConfirm('Se elimino correctamente el perfil')
    }
    , error => this.helperService.setErrorMessage(error));
  }

  loadData(value: string) {
    this.loadingData = true;
    this.profileService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  searchProfile() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregarProfile() {
    const text = this.searchValue;
    this.router.navigate( ['configuration/profiles/profile', '', text] );
  }

  search(): void {

  }

}
