import { Component, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { TransferService } from './services/transfer.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { OpenDirective } from '../../modals/open.directive';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-transfer-list',
  templateUrl: './transfer-list.component.html',
  styles: []
})
export class TransferListComponent {
  isLoadingData = false;
  errorMessage: string;
  applications: ApplicationInterface[];
  jsonArray = [];
  currentPage = 1;
  itemsPerPage = 100;

  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor(private transferService: TransferService,
    public accountService: AccountService,
    private notificationService: NotificationService,
    private helperService: HelperService ) {
    this.isLoadingData = true;
    this.accountService.getApplications('banks', 'transfers')
      .subscribe(applications => {
        this.applications = applications;
        this.loadData();
      }, error => this.helperService.setErrorMessage(error));
  }

  loadData() {
    this.transferService.getTransfers()
      .subscribe(data => {
        this.jsonArray = data;
        this.isLoadingData = false;
      }, error => this.helperService.setErrorMessage(error));
  }

  async showConfirm( block: number ) {
    let date = await this.notificationService.showConfirmDate();

    if(date !== '' && date !== false) {
      this.apply( block, date );
    }
  }

  apply(block: number, date: string) {
    this.transferService.applyTransfers(block, date)
      .subscribe(success => {
        this.loadData();
      }, error => this.helperService.setErrorMessage(error));
  }

}
