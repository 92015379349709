import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../components/account/login/services/account.service';
import { isRegExp } from 'util';

@Injectable()
export class AuthGuardService  {

  constructor( public accountService: AccountService,
    private router: Router ) { }

  canActivate( route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot ): boolean {
      if ( this.accountService.isLogged() ) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
  }

}
