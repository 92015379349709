import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BankService {

  private apiURL = 'api/Administration/Banks';
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

    get(searchValue: string) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }
}
