import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TeacherService } from '../../administration/teachers/services/teacher.service';

@Component({
  selector: 'app-active-teachers',
  templateUrl: './active-teachers.component.html',
  styles: []
})
export class ActiveTeachersComponent implements OnInit {
  forma: UntypedFormGroup;
  loadingData = false;
  errorMessage = '';
  allData: Array<any>;
  data: Array<any>;
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

  constructor(
    private teacherService: TeacherService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.loadingData = true;
    this.fillYears();
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
    });
    this.teacherService.getAmountActiveTeachers( this.forma.controls['year'].value, this.forma.controls['fortnight'].value ).subscribe(data => {
      this.allData = data;
      this.loadData('Básico');
      this.loadingData = false;
    }, error => this.setErrorMessage(error) );
  }

  search( ): void {
    this.loadingData = true;
    this.teacherService.getAmountActiveTeachers( this.forma.controls['year'].value, this.forma.controls['fortnight'].value ).subscribe(data => {
      this.allData = data;
      this.loadData('Básico');
      this.loadingData = false;
    }, error => this.setErrorMessage(error) );
  }

  setErrorMessage( error ) {
    console.log(error);
    this.errorMessage = error.message;
    this.loadingData = false;
  }

  loadData(type: string): void {
    this.data = this.allData.filter( d => d.type === type);
  }

  getNumber( type: string ): number {
    return this.allData.filter( d => d.type === type ).length;
  }

  fillYears(): void {
    for (let index = new Date().getFullYear() - 10; index < new Date().getFullYear() + 2; index++) {
      this.years.push( index );
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

}
