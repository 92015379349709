import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AccountingPaymentService } from './services/payment.service';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styles: []
})
export class AccountingPaymentComponent implements OnInit {
  forma: UntypedFormGroup;
  errorMessage = '';
  isLoadingData = false;
  dataReport: any[] = [];
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  yearValue = 0;
  fortnightValue = 0;

  constructor(private paymentService: AccountingPaymentService, private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService ) { }

  /*ngOnInit() {
    this.paymentService.getYears().subscribe(data=>{
      console.log(data);
    });
  }*/

  ngOnInit() {
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
    });
    this.paymentService.getYears().subscribe(years =>{
      this.years = years;
    }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.isLoadingData = false;
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  getExcel(): void {
    /*this.isLoadingData = true;
    this.paymentService.get(this.forma.controls['year'].value, this.forma.controls['fortnight'].value)
    .subscribe(data => {
      this.dataReport = data;*/
      if ( this.dataReport.length > 0 ) {
        this.excelService.exportAsExcelFileWithoutHeader(this.dataReport,
          `Abonos_${ this.forma.controls['year'].value }_${ this.forma.controls['fortnight'].value }`);
        this.isLoadingData = false;
      }
   // }, error => this.setErrorMessage(error));
  }

  searchPayments() {
    this.isLoadingData = true;
    this.paymentService.get(this.yearValue, this.fortnightValue).subscribe((data) => {
      this.dataReport = data;
      this.isLoadingData = false;
    }, error => this.setErrorMessage(error));
  }
}
