 <article class="content">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Contabilidad</li>
      </ol>
  </nav>

 <div class="title-search-block">
      <div class="title-block">
          <div class="row">
              <div class="col-md-6">
                  <h3 class="title">
                      <h3 class="title">Abonos por Quincena</h3>
                  </h3>
              </div>
          </div>
      </div>
  </div>

  <div class="card card-block animated fadeIn fast">

      <form [formGroup]="forma">
          <div class="row form-group">

              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <div class="form-group ">
                      <label class="control-label ">Año</label>
                      <select class="custom-select" formControlName="year" [(ngModel)]="yearValue">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <div class="form-group ">
                      <label class="control-label ">Quincena</label>
                      <select class="custom-select" formControlName="fortnight" [(ngModel)]="fortnightValue" (change)="searchPayments()">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                  </div>
              </div>
          </div>
      </form>

      <div class="form-group row">
          <div class="col-12 text-center">
              <button [disabled]="isLoadingData" type="button" class="btn btn-outline-success" (click)="getExcel()">
                  <i [ngClass]="{ 'far fa-file-excel': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                  <span *ngIf="!isLoadingData">Generar</span>
                  <span *ngIf="isLoadingData" >
                      Procesando...
                  </span>
              </button>
          </div>
      </div>

      <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
      <app-not-data *ngIf="!isLoadingData && dataReport.length === 0"></app-not-data>
      <app-loading-data *ngIf="isLoadingData"></app-loading-data>

  </div>

  <div class="card items animated fadeIn fast" *ngIf="!isLoadingData && dataReport.length > 0">
    <table *ngIf="dataReport.length > 0" class="table table-striped">
        <thead class="text-center">
            <tr>
                <th scope="col">RFC</th>
                <th scope="col">Nombre</th>
                <th scope="col">CURP</th>
                <th scope="col">Tipo</th>
                <th scope="col">Monto</th>
                <th scope="col">Capital</th>
                <th scope="col">IVA</th>
                <th scope="col">Intereses</th>
                <th scope="col">Seguro de vida</th>
            </tr>
        </thead>

        <tbody>
            <tr class="text-right" *ngFor="let item of dataReport; let i = index">
                <td>{{ item.rfc }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.curp }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.discount }}</td>
                <td>{{ item.fortnightCapital }}</td>
                <td>{{ item.fortnightIva }}</td>
                <td>{{ item.fortnightInterest }}</td>
                <td>{{ item.fortnightLifeInsurance }}</td>                
            </tr>
        </tbody>
    </table>
 </div>
</article>