import { Routes } from '@angular/router';
import { SupportDetailComponent } from './support-detail.component';
import { SupportListComponent } from './support-list.component';
import { SupportComponent } from './support.component';
import { SupportsPendingComponent } from './supports-pending.component';

export const SUPPORT_ROUTES: Routes = [
    { path: 'add/:teacher', component: SupportComponent },
    { path: 'pending', component: SupportsPendingComponent },
    { path: 'pending/:searchValue', component: SupportsPendingComponent },
    { path: 'details/:id', component: SupportDetailComponent },
    { path: 'details/:id/:searchValue', component: SupportDetailComponent },
    { path: ':searchValue', component: SupportListComponent },
    { path: '', component: SupportListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
