<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Ahorros</h3>
                    </h3>
                </div>
                <div *ngIf="dataReport.length !== 0" class="col-md-6 text-right">
                    <button type="button" class="btn btn-outline-info" (click)="getPDF()"><i class="far fa-file-pdf"></i> PDF</button>
                </div>
            </div>
        </div>
    </div>

    <form [formGroup]="forma">
        <div class="box-search">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Porcentage</label>
                        <select class="custom-select" formControlName="percentage" (change)="searchDataDetailed( $event.target.value )">
                          <option value="0.00208">5 %</option>
                          <option value="0.00167">4 %</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <button [disabled]="loadingData" type="button" class="btn btn-outline-success" (click)="searchData()">
                    <i [ngClass]="{ 'fas fa-search': !loadingData, 'fas fa-sync-alt fa-spin': loadingData }"></i>
                    <span *ngIf="!loadingData">Consultar</span>
                    <span *ngIf="loadingData" >
                        Procesando...
                    </span>
                  </button>
                </div>
            </div>
        </div>
    </form>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">

        <table *ngIf="dataReportGlobal.length > 0" class="table table-striped">
            <thead class="text-center">
                <tr>
                    <th scope="col">Año</th>
                    <th scope="col">Subtotal</th>
                    <th scope="col">Interes</th>
                </tr>
            </thead>

            <tbody>
                <tr class="text-right" *ngFor="let item of dataReportGlobal; let i = index">
                    <td>{{ item.year }}</td>
                    <td>{{ item.amount | currency }}</td>
                    <td>{{ item.interest | currency }}</td>
                </tr>
            </tbody>
        </table>

        <div class="row">
            <div class="col-12 text-right">
                <h3 class="m-3 info">Subtotal {{ amount | currency }}</h3>
                <h3 class="m-3 info">Interes {{ amountInterests | currency }}</h3>
                <h3 class="m-3 info">Total {{ amount + amountInterests | currency }}</h3>
            </div>
        </div>
    </div>
</article>