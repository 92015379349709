import { Routes } from '@angular/router';
import { TeacherGlobalComponent } from './teacher-global.component';
import { TeacherGlobalDetailsComponent } from './teacher-global-details.component';
import { TeacherGlobalStatusComponent } from './teacher-global-status.component';
import { AddPaymentComponent } from './add-payment.component';

export const TEACHERGLOBAL_ROUTES: Routes = [
    { path: 'details/:id', component: TeacherGlobalDetailsComponent },
    { path: 'status/:id/:year/:fortnight/:pathReturn', component: TeacherGlobalStatusComponent },
    { path: 'status/:id', component: TeacherGlobalStatusComponent },
    { path: 'add-payment/:id', component: AddPaymentComponent },
    { path: '', component: TeacherGlobalComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
