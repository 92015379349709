<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <h3 class="title">
                        Apoyos <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <a *ngIf="accountService.havePermission(applications, 'Apoyos Pendientes') && pending > 0" class="btn btn-outline-dark" [routerLink]="['/administration/supports/pending', searchValue]">
                        <i class="fas fa-print"></i> Pendientes ({{ pending }})
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-7 col-lg-7">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar...">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>

            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="input-box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Inicio</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                            <input type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Al</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                            <input type="date" name="fin" [value]="fin" [(ngModel)]="fin">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                <button type="button" class="btn btn-outline-primary" (click)="search( )">
                    Buscar
                  </button>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header">
                        <div class="no-overflow">
                            <span>Maestro</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>C.U.R.P</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span># Cheque</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Monto</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Estatus</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Fecha</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Fecha Aplicación</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Bloque</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header fixed item-col-actions-dropdown-3"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row" [ngClass]="{ required : item.status === 'Cancelado' }">
                    <div class="item-col item-col-item">
                        <div class="item-heading">Maestro</div>
                        <div>
                            <span>{{ item.teacher }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">C.U.R.P</div>
                        <div>
                            <span>{{ item.curp }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Nombre</div>
                        <div> {{ item.name }} </div>
                    </div>

                    <div class="item-col item-col-item text-right">
                        <div class="item-heading"># Cheque</div>
                        <div> {{ item.checkNumber }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Monto</div>
                        <div> {{ item.amount | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Estatus</div>
                        <div> {{ item.status }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Fecha</div>
                        <div> {{ item.date | date : 'dd/MM/yyyy' }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Fecha Aplicación</div>
                        <div> {{ item.acceptanceDate | date : 'dd/MM/yyyy' }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Bloque</div>
                        <div> {{ item.block }} </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown">
                        <div class="item-list-actions text-center">
                            <span>
                              <a class="primary" [routerLink]="['/administration/supports/details', item.id, searchValue ]" *ngIf="accountService.havePermission(applications, 'Apoyos Detalle')" >
                                <i class="fas fa-info-circle"></i>
                              </a>
                          </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

</article>