<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="['/administration/teachers', searchValue]" routerLinkActive="router-link-active">Maestros</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Baja</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h2 class="m-1">{{ teacher.name }}<small> ({{ teacher.rfc }})</small></h2>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Tipo</label>
                    <select class="custom-select" name="type" formControlName="type" (change)="onTypeChange()">
                <option value="">Seleccionar...</option>
                <option [value]="type.name" *ngFor="let type of types ">{{ type.name }}</option>
            </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['movementDate'].valid && forma.controls['movementDate'].touched, 'has-success': forma.controls['movementDate'].valid && forma.controls['movementDate'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Fecha</label>
                        <input type="date" class="form-control underlined" formControlName="movementDate">
                        <span *ngIf="forma.controls['movementDate'].touched && forma.controls['movementDate'].errors?.required" class="has-error ">
                    El campo es requerido.
                </span>
                        <span *ngIf="forma.controls['movementDate'].touched && forma.controls['movementDate'].errors?.minlength" class="has-error">
                    El campo debe tener al menos {{ forma.controls['movementDate'].errors.minlength.requiredLength }}
                    caracteres.
                </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" *ngIf="forma.controls['type'].value !== 'Defunción' && forma.controls['type'].value !== ''" >
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Forma de Pago</label>
                    <select class="custom-select" name="movementType" formControlName="movementType">
                <option value="">Seleccionar...</option>
                <option value="Cheque">Cheque</option>
                <option value="Transferencia">Transferencia</option>
            </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" *ngIf="forma.controls['type'].value === 'Defunción'">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Apoyo</label>
                    <select class="custom-select" name="applySupport" formControlName="applySupport">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" *ngIf="forma.controls['type'].value !== 'Defunción' && forma.controls['type'].value !== ''">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Condonar Interes</label>
                    <select class="custom-select" name="condoneInterest" formControlName="condoneInterest" (change)="onCondoneInterestChange()">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>

            <div class="row mb-2" *ngIf="forma.controls['movementType'].value === 'Transferencia' && forma.controls['type'].value !== 'Defunción'">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Banco</label>
                    <select class="custom-select" formControlName="bankId">
                        <option value="">Seleccionar...</option>
                        <option [value]="bank.id" *ngFor="let bank of banks ">{{ bank.name }}</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Cuenta Bancaria</label>
                    <input type="text" class="form-control underlined" maxlength="18" formControlName="account">
                </div>
            </div>

            <legend *ngIf="LowStaffLoans?.length > 0">
                <legend>Prestamos</legend>

                <div class="row">
                    <div class="col-12">
                        <div formArrayName="lowStaffLoans" *ngFor="let loanPending of lowStaffLoansControls; let i = index">
                            <div [formGroupName]="i">
                                <hr>
                                <div class="row">
                                    <div class="col-12">
                                        Monto: {{LowStaffLoans[i].loan.amount|currency}} # Hoja: {{LowStaffLoans[i].loan.loanNumber}} # Cheque / Transferencia: {{LowStaffLoans[i].loan.checkNumber}} Qna Inicial: {{LowStaffLoans[i].loan.fortnightStart}} Qna Final: {{LowStaffLoans[i].loan.fortnightEnd}} Estatus: {{LowStaffLoans[i].loan.status}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" *ngIf="forma.controls['type'].value !== 'Defunción'">
                                        <div class="form-group" >
                                            <label class="control-label">Capital</label>
                                            <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="capital">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" *ngIf="forma.controls['type'].value === 'Defunción'">
                                        <div class="form-group" [ngClass]="{'has-error': !loanPending.controls['capitalCondone'].valid && loanPending.controls['capitalCondone'].touched, 'has-success': loanPending.controls['capitalCondone'].valid && loanPending.controls['capitalCondone'].touched }" >
                                            <label class="control-label">Capital Condonado</label>
                                            <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="capitalCondone" (blur)="onCondoneBlur(loanPending, 'capital')">
                                            <span *ngIf="loanPending.controls['capitalCondone'].touched && loanPending.controls['capitalCondone'].errors?.required" class="has-error ">
                                                El campo es requerido.
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" *ngIf="forma.controls['type'].value !== 'Defunción' && (!forma.controls['condoneInterest'].value || forma.controls['condoneInterest'].value  === 'false')">
                                        <div class="form-group" >
                                            <label class="control-label">Interes</label>
                                            <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="interest">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <div class="form-group" [ngClass]="{'has-error': !loanPending.controls['interestCondone'].valid && loanPending.controls['interestCondone'].touched, 'has-success': loanPending.controls['interestCondone'].valid && loanPending.controls['interestCondone'].touched }" >
                                            <label class="control-label">Interes Condonado</label>
                                            <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="interestCondone" (blur)="onCondoneBlur(loanPending, 'interest')">
                                            <span *ngIf="loanPending.controls['interestCondone'].touched && loanPending.controls['interestCondone'].errors?.required" class="has-error ">
                                                El campo es requerido.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1" *ngIf="forma.controls['type'].value !== 'Defunción' && (!forma.controls['condoneInterest'].value || forma.controls['condoneInterest'].value  === 'false')">
                                        <div class="form-group" >
                                            <label class="control-label">IVA</label>
                                            <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="iva">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <div class="form-group" [ngClass]="{'has-error': !loanPending.controls['ivaCondone'].valid && loanPending.controls['ivaCondone'].touched, 'has-success': loanPending.controls['ivaCondone'].valid && loanPending.controls['ivaCondone'].touched }" >
                                            <label class="control-label">IVA Condonado</label>
                                            <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="ivaCondone" (blur)="onCondoneBlur(loanPending, 'iva')">
                                            <span *ngIf="loanPending.controls['ivaCondone'].touched && loanPending.controls['ivaCondone'].errors?.required" class="has-error ">
                                                El campo es requerido.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1" *ngIf="forma.controls['type'].value !== 'Defunción' && (!forma.controls['condoneInterest'].value || forma.controls['condoneInterest'].value  === 'false')">
                                        <div class="form-group" >
                                            <label class="control-label">Prima</label>
                                            <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="lifeInsurance">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <div class="form-group" [ngClass]="{'has-error': !loanPending.controls['ivaCondone'].valid && loanPending.controls['ivaCondone'].touched, 'has-success': loanPending.controls['ivaCondone'].valid && loanPending.controls['ivaCondone'].touched }" >
                                            <label class="control-label">Prima Condonado</label>
                                            <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="lifeInsuranceCondone" (blur)="onCondoneBlur(loanPending, 'lifeInsurance')">
                                            <span *ngIf="loanPending.controls['ivaCondone'].touched && loanPending.controls['ivaCondone'].errors?.required" class="has-error ">
                                                El campo es requerido.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </legend>

            <legend *ngIf="forma.controls['type'].value === 'Defunción'">
                <legend>Beneficiarios</legend>
                <div class="row" >
                    <div class="col-12">
                        <div formArrayName="beneficiaries" *ngFor="let beneficiary of forma.get('beneficiaries').controls; let i = index">
                            <div [formGroupName]="i">
                                <div class="row" *ngIf="beneficiary.get('active').value">
                                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">Nombre </label>
                                            <input type="text" readonly maxlength="250" class="form-control underlined" name="name" formControlName="name">
                                        </div>
                                    </div>
    
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                        <label class="control-label">Parentesco</label>
                                        <select disabled class="custom-select" name="relationshipId" formControlName="relationshipId">
                                    <option value="">Seleccionar...</option>
                                    <option [value]="relationship.id" *ngFor="let relationship of relationships ">{{ relationship.name }}</option>
                                </select>
                                    </div>
    
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label class="control-label">Porcentaje</label>
                                            <input type="number" readonly min="0" max="100" step=".01" class="form-control underlined" name="percentage" formControlName="percentage">
                                        </div>
                                    </div>
    
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label class="control-label">Teléfono <small>(614)111-22-44</small> </label>
                                            <input type="tel" maxlength="14" class="form-control underlined" name="phone" formControlName="phone">
                                        </div>
                                    </div>
    
                                    <!--<div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                 <button type="button" class="btn btn-outline-danger" (click)="deleteBeneficiary( i )">
                                    <i class="far fa-trash-alt"></i> Eliminar
                                </button> 
                            </div>-->
                                </div>
                                <div class="row" *ngIf="beneficiary.get('active').value">
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" >
                                        <label class="control-label"><i class="fas fa-asterisk required"></i> Forma de Pago</label>
                                        <select class="custom-select" name="wayToPay" formControlName="wayToPay">
                                    <option value="">Seleccionar...</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Transferencia">Transferencia</option>
                                </select>
                                    </div>
    
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                        <label class="control-label"><i class="fas fa-asterisk required"></i> RFC</label>
                                        <input type="text" class="form-control underlined" maxlength="13" formControlName="rfc">
                                    </div>
    
                                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                        <label class="control-label"> Banco</label>
                                        <select class="custom-select" formControlName="bankId">
                                            <option value="">Seleccionar...</option>
                                            <option [value]="bank.id" *ngFor="let bank of banks ">{{ bank.name }}</option>
                                        </select>
                                    </div>
    
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                        <label class="control-label"> Cuenta Bancaria</label>
                                        <input type="text" class="form-control underlined" maxlength="18" formControlName="account">
                                    </div>
    
                                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                        <label class="control-label">Correo Electrónico</label>
                                        <input type="text" maxlength="50" class="form-control underlined" formControlName="email">
                                    </div>
    
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </legend>

            <div class="form-group text-center ">
                <button class="btn btn-outline-danger" [disabled]="sendingData || loadingData || !validData()">
                  <i [ngClass]="{ 'far fa-trash-alt': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                  <span > Dar de baja</span>
              </button>
            </div>

            <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
        </form>

        <div class="form-group ">
            <a class="btn btn-outline-info " [routerLink]="['/administration/teachers', searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>

</div>