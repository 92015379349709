<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/banks/collections']" routerLinkActive="router-link-active">Cobranza</a></li>
            <li class="breadcrumb-item active" aria-current="page">Aplicar</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Cobranza <small>Aplicar</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Jubilados</label>
                        <select class="custom-select" name="fortnightJubilados" formControlName="fortnightJubilados">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Media Superior</label>
                        <select class="custom-select" name="fortnightMediaSuperior" formControlName="fortnightMediaSuperior">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Tipo</label>
                        <select class="custom-select" formControlName="type">
                            <option value="" ></option>
                                  <option value="Ahorros" >Ahorros</option>
                                  <option value="Abonos" >Abonos</option>
                              </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Fecha</label>
                        <input type="date" class="form-control" [value]="movementDate" formControlName="movementDate">
                    </div>
                </div>
            -->
            </div>


            <div class="row form-group">
                <div class="col-12">
                    <div class="custom-file">
                        <input #fileUpload type="file" (change)="fileChangeEvent($event)" class="custom-file-input" id="customFileLang" lang="es" accept=".txt">
                        <label class="custom-file-label" for="customFileLang">Seleccionar Archivo...</label>
                    </div>
                </div>
            </div>

            <div *ngIf="selectedFileNames.length > 0" class="row form-group">
                <div class="col-12">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let fileName of selectedFileNames; let i = index">
                            {{fileName}}
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="uploadResult" class="row form-group">
                <div class="col-12" [innerHtml]="uploadResult">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12 text-center">
                    <button [disabled]="isLoadingData || selectedFileNames.length === 0 || !forma.valid" type="button" class="btn btn-outline-success" (click)="upload()">
                  <i [ngClass]="{ 'far fa-save': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                  <span *ngIf="!isLoadingData">Procesar</span>
                  <span *ngIf="isLoadingData" >
                      Procesando...
                  </span>
              </button>
                    <button type="button" class="btn btn-outline-danger" (click)="reserForm()"><i class="fas fa-broom"></i> Limpiar</button>
                </div>
            </div>

            <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

            <table class="table table-striped table-hover" formArrayName="affilations">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>CURP</th>
                        <th>Tipo</th>
                        <th>Quincena</th>
                        <th>Fecha Aplicación</th>
                        <th>Importe</th>
                        <th>Código</th>
                        <th>Estatus</th>
                        <th *ngIf="showError">Error</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of forma.get('affilations')['controls']; let i =index;" [formGroupName]="i">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item.controls['curp'].value }}</td>
                        <td>
                            <select class="custom-select" formControlName="type">
                                <option value="AHORRO" >Ahorro</option>
                                <option value="PRESTA" >Abono</option>
                            </select>
                        </td>
                        <td>
                            <select class="custom-select" name="fortnight" formControlName="fortnight">
                                <option [value]="fortnight" *ngFor="let fortnight of fortnights">{{ fortnight }}</option>
                            </select>
                        </td>
                        <td>{{ item.controls['movementDate'].value | date: 'dd/MM/yyyy' }}</td>
                        <td>{{ item.controls['amount'].value | currency }}</td>
                        <td>{{ item.controls['code'].value }}</td>
                        <td>
                            <b [ngClass]="{ 'success': item.controls['valid'].value, 'required': !item.controls['valid'].value }">
                                <i [ngClass]="{ 'fas fa-trash-alt': !item.controls['valid'].value, 'fas fa-check': item.controls['valid'].value }" ></i> {{ item.controls['status'].value }}
                            </b>
                        </td>
                        <td *ngIf="showError">
                            <b [ngClass]="{ 'success': item.controls['success'].value, 'required': !item.controls['success'].value }">
                                {{ item.controls['message'].value }}
                            </b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>

</article>