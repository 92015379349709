import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUserInfo } from '../interfaces/iUserInfo';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApplicationInterface } from '../../../configuration/applications/interface/application.interface';

@Injectable()
export class AccountService {
  private apiURL = this.baseUrl + 'api/Account';
  constructor( private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
              private route: Router ) {}

  login(userInfo: IUserInfo): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Login', userInfo);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  getExpirationToken(): string {
    return localStorage.getItem('tokenExpiration');
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
  }

  isLogged(): boolean {
    const expiration = this.getExpirationToken();
    if ( !expiration ) {
      return false;
    }
    const now = new Date().getTime();
    const dateExpiration = new Date(expiration);

    if ( now >= dateExpiration.getTime() ) {
      this.logout();
      return false;
    } else {
      return true;
    }
  }

  getUserId() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.user_id;
  }

  getUserName() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.name;
  }

  getUserProfile() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.profile;
  }

  getUserProfileId() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.profileID;
  }

  getMenu() {
    const profileId = this.getUserProfileId();
    return this.http.get<any>(this.apiURL + '/' + profileId);
  }

  getApplicationsMenu() {
    const profileId = this.getUserProfileId();
    return this.http.get<any>(this.apiURL + '/applications/' + profileId);
  }

  postCheckChangeStatus(userInfo: IUserInfo) {
    const profileId = this.getUserProfileId();
    return this.http.post<any>(this.apiURL + '/CheckChangeStatus/' + profileId, userInfo);
  }

  getApplications(module: string, app: string) {
    const profileId = this.getUserProfileId();
    return this.http.get<any>(this.apiURL + '/' + profileId + '/' + module + '/' + app);
  }

  havePermission(apps: ApplicationInterface[], appName: string): boolean {
    let permission = false;
    if ( apps ) {
      apps.forEach(app => {
        if ( app.name === appName ) {
          permission = true;
        }
      });
    }
    return permission;
  }
}
