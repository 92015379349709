import { Component, OnInit } from '@angular/core';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { ImproperPaymentService } from './services/improper-payment.service';
import { AccountService } from '../../account/login/services/account.service';
import { ImproperPaymentInterface } from './interfaces/improper-payment.interface';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { SelectOption } from '../../../../app/interfaces/select-option.interface';

@Component({
  selector: 'app-improper-payment-list',
  templateUrl: './improper-payment-list.component.html',
  styles: [
  ]
})
export class ImproperPaymentListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  applications: ApplicationInterface[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private improperPaymentService: ImproperPaymentService,
    public accountService: AccountService,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.accountService.getApplications('administration', 'improper-payments').subscribe(
      applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  loadData(value: string) {
    this.loadingData = true;
    this.improperPaymentService.get(value).subscribe(
      data => {
        this.jsonArray = data;
        this.loadingData = false;
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  search() {
    let search = this.searchValue;
    if (search === '') {
      search = 'null';
    }
    this.loadData(search);
  }

  async showApply( improper: ImproperPaymentInterface ) {

    const inputOptions = improper.teacher.loans.map(l => ({
      value: l.id,
      text: `${l.loanNumber} - ${l.checkNumber} / ${l.status} ( ${l.fortnightStart} - ${l.fortnightEnd} )`
    }) as SelectOption);

    const loanId = await this.notificationService.showConfirmSelect(
      `¿Seguro de asignar el cobro indebido a un prestamo?`,
      inputOptions,
      'Asignar cobro indebido al prestamo',
      'Seleccione un prestamo',
      'Debe de seleccionar un prestamo');
    if(loanId !== null) {
      this.applyItem( loanId, improper );
    }
  }

  showConfirm(id: string, improper: ImproperPaymentInterface) {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar el cobro indebido de ${ improper.name }`)
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem( id, improper );
      }
    });
  }

  applyItem( id: string, improper: ImproperPaymentInterface ) {
    this.improperPaymentService.apply( id, improper ).subscribe(
      success => {
        this.loadData('null');
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  deleteItem( id: string, improper: ImproperPaymentInterface ) {
    this.improperPaymentService.delete(id).subscribe(
      () => {
        this.loadData('null');
        this.notificationService.toastConfirm(`Se elimino el cobro ${improper.name}`);
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  updateItem( id: string, improper: ImproperPaymentInterface ) {
    this.improperPaymentService.update(id, improper).subscribe(
      success => {
        this.loadData('null');
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

}
