import { CollectionInterface } from './../interfaces/collection.interface';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AffilationItem } from '../../affiliations/interfaces/affilation-item';
import { ResponseInterface } from '../../../../interfaces/response.interface';
import { PeriodInterface } from '../../../../interfaces/period.interface';

@Injectable()
export class CollectionService {
  private apiURL = 'api/Banks/Collections';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    get( teacherStatus: string ) {
      return this.http.get<any>(`${ this.apiURL }/${ teacherStatus }`);
    }

    getSavings( year: number, fortnight: number ) {
      return this.http.get<any>(`${ this.apiURL }/Savings/${ year }/${ fortnight }`);
    }

    postApply( userId: string, affiliationItems: Array<AffilationItem> ) {
      return this.http.post<any>(`${ this.apiURL }/Apply/${ userId }`, affiliationItems);
    }

    postReverse( collection: CollectionInterface ) {
      return this.http.post<any>(`${ this.apiURL }/Reverse`, collection).toPromise();
    }

    getSearch( value: string, origin: string ) {
      return this.http.get<ResponseInterface>(`${ this.apiURL }/Search/${ value }/${ origin }`);
    }

    getDetails( id: string ) {
      return this.http.get<any>(`${ this.apiURL }/Details/${ id }`);
    }

    getApply( id: string, date: string ) {
      return this.http.get<any>(`${ this.apiURL }/AccountingDate/${ id }/${ date }`);
    }

    getCollectionDetails( teacher: string, type: string, period: PeriodInterface ) {
      return this.http.post<any>(`${ this.apiURL }/CollectionDetails/${ teacher }/${ type }`, period);
    }

    getCollectionDetailRecover( teacher: string, type: string, fortnight: string ) {
      return this.http.get<any>(`${ this.apiURL }/CollectionDetailRecover/${ teacher }/${ type }/${ fortnight }`);
    }
}
