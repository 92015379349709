import { Routes } from '@angular/router';
import { LowStaffingReportDetailsComponent } from './low-staffing-report-details.component';
import { LowStaffingReportComponent } from './low-staffing-report.component';

export const LOWSTAFFREPORT_ROUTES: Routes = [
    { path: 'details/:id/:type/:waytopay/:start/:end', component: LowStaffingReportDetailsComponent },
    { path: 'details/:id/:type/:waytopay/:start/:end/:searchValue', component: LowStaffingReportDetailsComponent },
    { path: '', component: LowStaffingReportComponent },
    { path: ':waytopay/:start/:end/:searchValue', component: LowStaffingReportComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
