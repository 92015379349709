<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Prestamos</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">{{ title }}</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="LoadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4 class="info"><b>{{ loan.teacher.name | uppercase }} <small>({{ loan.teacher.rfc | uppercase }})</small></b></h4>
                    <hr>
                </div>
            </div>

            <div class="row">

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Prefijos</label>
                        <select class="custom-select" *ngIf="!editing" name="prefix" (change)="setPrefixParameter( )" formControlName="prefix" >
                            <option value="">Seleccionar...</option>
                            <option [value]="parameter" *ngFor="let parameter of prefixes " >{{ parameter }} </option>
                        </select>
                        <select class="custom-select" *ngIf="editing" disabled name="prefix" (change)="setPrefixParameter( )" formControlName="prefix" >
                            <option value="">Seleccionar...</option>
                            <option [value]="parameter" *ngFor="let parameter of prefixes " >{{ parameter }} </option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['loanNumber'].valid && forma.controls['loanNumber'].touched, 'has-success': forma.controls['loanNumber'].valid && forma.controls['loanNumber'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> # Hoja</label>
                        <input type="text" [readonly]="editing" class="form-control underlined" maxlength="30" formControlName="loanNumber">
                        <span *ngIf="forma.controls['loanNumber'].touched && forma.controls['loanNumber'].errors?.required" class="has-error ">
                                El campo es requerido.
                            </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="row">
                        <label class="control-label">Sorteo</label>
                    </div>
                    <div class="row">
                        <h3 class="control-label">{{ loan.draw.name }}</h3>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="row">
                        <label class="control-label">Estatus
                          <span *ngIf="loan.status == 'Cancelado'" >
                            <a class="primary" (click)="showChangeStatus(  )" href="" data-toggle="modal" >
                               <i class="fas fa-exchange-alt"></i>
                           </a>
                       </span></label>
                    </div>
                    <div class="row">
                        <h3 class="control-label" [ngClass]="{'text-danger': loan.status == 'Cancelado', 'text-success': loan.status == 'Activo'}">{{ loan.status }}</h3>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Monto / Quincenas</label>
                         <select *ngIf="!editing" class="custom-select" name="loanParameterId" (change)="changeParameter(  )" formControlName="loanParameterId">
                            <option value="">Seleccionar...</option>
                            <option [value]="parameter.id" *ngFor="let parameter of prefixesParameters">{{ parameter.amount | currency }} / {{
                                parameter.fortnight.numberFortnight }}</option>
                        </select>
                        <select *ngIf="editing" disabled class="custom-select" name="loanParameterId" (change)="changeParameter(  )" formControlName="loanParameterId">
                            <option value="">Seleccionar...</option>
                            <option [value]="parameter.id" *ngFor="let parameter of prefixesParameters">{{ parameter.amount | currency }} / {{
                                parameter.fortnight.numberFortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="row">
                        <label class="control-label">Interes</label>
                    </div>
                    <div class="row">
                        <h3 class="control-label text-danger">{{ loan.interests | currency }}</h3>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="row">
                        <label class="control-label">Descuento</label>
                    </div>
                    <div class="row">
                        <h3 class="control-label text-danger">{{ loan.discount | currency }}</h3>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Región</label>
                        <select class="custom-select" name="regionId" disabled formControlName="regionId" *ngIf="editing">
                            <option [value]="region.id" *ngFor="let region of regions ">{{ region.name }}</option>
                        </select>
                        <select class="custom-select" name="regionId" formControlName="regionId" *ngIf="!editing">
                            <option [value]="region.id" *ngFor="let region of regions ">{{ region.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Inicial</label>
                        <select class="custom-select" name="fortnightStart" (change)="changeFortnightStart()" formControlName="fortnightStart">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnightsStart ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Final</label>
                        <select class="custom-select" name="fortnightEnd" formControlName="fortnightEnd">
                            <option value="">Seleccionar...</option>
                            <option [value]="fortnight" *ngFor="let fortnight of fortnightsEnd ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngIf="!editing">
                    <div class="form-group">
                        <label class="control-label">Cumple con la liquidez</label>
                        <select class="custom-select" name="haveFinancialLiquidity" (change)="changeParameter(  )" formControlName="haveFinancialLiquidity">
                            <option value="false" selected="selected" >No</option>
                            <option value="true">Si</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngIf="!haveAdmission">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['teacherAdmission'].valid && forma.controls['teacherAdmission'].touched, 'has-success': forma.controls['teacherAdmission'].valid && forma.controls['teacherAdmission'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Fecha Admisión del maestro</label>
                        <input type="date" #admission class="form-control underlined" formControlName="teacherAdmission" (blur)="blurAdmission( admission.value )">
                        <span *ngIf="forma.controls['teacherAdmission'].touched && forma.controls['teacherAdmission'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="!loan.teacher.street">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['street'].valid && forma.controls['street'].touched, 'has-success': forma.controls['street'].valid && forma.controls['street'].touched }">
                        <label class="control-label"> Calle</label>
                        <input type="text" class="form-control underlined" maxlength="150" formControlName="street">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-1" *ngIf="!loan.teacher.street">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['number'].valid && forma.controls['number'].touched, 'has-success': forma.controls['number'].valid && forma.controls['number'].touched }">
                        <label class="control-label"> Número</label>
                        <input type="text" maxlength="10" class="form-control underlined" formControlName="number">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="!loan.teacher.street">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['colony'].valid && forma.controls['colony'].touched, 'has-success': forma.controls['colony'].valid && forma.controls['colony'].touched }">
                        <label class="control-label"> Colonia</label>
                        <input type="text" maxlength="150" class="form-control underlined" formControlName="colony">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-1" *ngIf="!loan.teacher.street">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['zipCode'].valid && forma.controls['zipCode'].touched, 'has-success': forma.controls['zipCode'].valid && forma.controls['zipCode'].touched }">
                        <label class="control-label"> C. P.</label>
                        <input type="number" minlength="5" maxlength="5" class="form-control underlined" formControlName="zipCode">
                        <span *ngIf="forma.controls['zipCode'].touched && forma.controls['zipCode'].errors?.minlength" class="has-error">
                            El campo debe tener al menos {{ forma.controls['zipCode'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                        <span *ngIf="forma.controls['zipCode'].touched && forma.controls['zipCode'].errors?.maxlength" class="has-error">
                            El campo debe tener máximo {{ forma.controls['zipCode'].errors.maxlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="!loan.teacher.email && !editing">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['email'].valid && forma.controls['email'].touched, 'has-success': forma.controls['email'].valid && forma.controls['email'].touched }">
                        <label class="control-label"> Email</label>
                        <input type="text" maxlength="50" class="form-control underlined" formControlName="email">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="loan.teacher.teacherPhones.length === 0 && !editing">
                    <div class="form-group">
                        <label class="control-label"> Celular</label>
                        <input type="text" maxlength="10" class="form-control underlined" formControlName="phone">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngIf="!editing">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Tipo de Movimiento</label>
                        <select class="custom-select" name="movementType" (change)="handlerCHangeMovement(  )" formControlName="movementType">
                            <option value="">Seleccionar...</option>
                            <option [value]="movementType" *ngFor="let movementType of movementTypes ">{{ movementType }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="forma.controls['movementType'].value === 'Transferencia' && !editing">
                    <div class="form-group">
                        <label class="control-label">Banco</label>
                        <select class="custom-select" name="bankId" formControlName="bankId" (change)="handlerBanks()">
                            <option value="00000000-0000-0000-0000-000000000000">Seleccionar...</option>
                            <option [value]="bank.id" *ngFor="let bank of banks ">{{ bank.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="forma.controls['movementType'].value === 'Transferencia' && forma.controls['bankId'].value === 'be28ff92-e845-41bb-8bee-3060267a7023' && !editing">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['account'].valid && forma.controls['account'].touched, 'has-success': forma.controls['account'].valid && forma.controls['account'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Cuenta</label>
                        <input type="text" class="form-control underlined" minlength="10" maxlength="10" formControlName="account">
                        <span *ngIf="forma.controls['account'].touched && forma.controls['account'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['account'].touched && forma.controls['account'].errors?.minlength" class="has-error ">
                            El campo debe de tener una longitud de 10 caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="forma.controls['movementType'].value === 'Transferencia' && forma.controls['bankId'].value !== 'be28ff92-e845-41bb-8bee-3060267a7023' && !editing">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['clabe'].valid && forma.controls['clabe'].touched, 'has-success': forma.controls['clabe'].valid && forma.controls['clabe'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> CLABE</label>
                        <input type="text" class="form-control underlined" minlength="18" maxlength="18" formControlName="clabe">
                        <span *ngIf="forma.controls['clabe'].touched && forma.controls['clabe'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['clabe'].touched && forma.controls['clabe'].errors?.minlength" class="has-error ">
                            El campo debe de tener una longitud de 18 caracteres.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group" [ngClass]="{ 'has-success': forma.controls['comment'].touched }">
                        <label class="control-label">Comentario</label>
                        <input type="text" class="form-control underlined" maxlength="200" formControlName="comment">
                        <span *ngIf="forma.controls['comment'].touched && forma.controls['comment'].errors?.minlength" class="has-error">
                            El campo comentario debe tener {{ forma.controls['comment'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>
            </div>

            <div class="form-group text-center ">
                <button [disabled]="!validData() || sendingData " class="btn " [ngClass]="{ 'btn-outline-success': title=='Agregar' , 'btn-outline-warning': title=='Editar' } ">
                    <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                    <span *ngIf="!sendingData "> Guardar</span>
                    <span *ngIf="sendingData "> Guardando...</span>
                </button>
            </div>

            <div class="alert alert-danger " role="alert " *ngIf="message ">
                {{ message }}
            </div>
        </form>

        <table *ngIf="financialLiquidity.length > 0" class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Año</th>
                    <th scope="col">Qna 1<br />Qna 13</th>
                    <th scope="col">Qna 2<br />Qna 14</th>
                    <th scope="col">Qna 3<br />Qna 15</th>
                    <th scope="col">Qna 4<br />Qna 16</th>
                    <th scope="col">Qna 5<br />Qna 17</th>
                    <th scope="col">Qna 6<br />Qna 18</th>
                    <th scope="col">Qna 7<br />Qna 19</th>
                    <th scope="col">Qna 8<br />Qna 20</th>
                    <th scope="col">Qna 9<br />Qna 21</th>
                    <th scope="col">Qna 10<br />Qna 22</th>
                    <th scope="col">Qna 11<br />Qna 23</th>
                    <th scope="col">Qna 12<br />Qna 24</th>
                </tr>
            </thead>

            <tbody>
                <tr class="text-right" *ngFor="let item of financialLiquidity; let i = index">
                    <td>{{ item.year }}</td>
                    <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
                    <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
                    <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
                    <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
                    <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
                    <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
                    <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
                    <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
                    <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
                    <td>{{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}</td>
                    <td>{{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}</td>
                    <td>{{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}</td>
                </tr>
            </tbody>
        </table>

        <div class="row">
            <a class="btn btn-outline-info " [routerLink]="[returnPath, searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>

    <ng-template appOpen></ng-template>
</div>
