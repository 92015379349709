import { LowStaffService } from './../../administration/low-staffing/services/low-staff.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { NotificationService } from '../../../../app/services/notification.service';

@Component({
  selector: 'app-transfers-lowstaff-list',
  templateUrl: './transfers-lowstaff-list.component.html',
  styles: []
})
export class TransfersLowstaffListComponent implements OnInit {
  isLoadingData = false;
  errorMessage: string;
  applications: ApplicationInterface[];
  jsonArray = [];
  currentPage = 1;
  itemsPerPage = 100;

  constructor( private lowStaffService: LowStaffService,
    public accountService: AccountService,
    private notificationService: NotificationService ) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.accountService.getApplications('banks', 'transfers-lowstaff')
      .subscribe(applications => {
        this.applications = applications;
        this.loadData();
      }, error => {
        this.setErrorMessage(error);
      });
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.isLoadingData = false;
    }
  }

  loadData() {
    this.lowStaffService.getTransfers()
      .subscribe(data => {
        this.jsonArray = data;
        console.log( this.jsonArray );
        this.isLoadingData = false;
      }, error => {
        this.setErrorMessage(error);
      });
  }

  async showConfirm( block: number ) {
    let date = await this.notificationService.showConfirmDate();

    if(date !== '' && date !== false) {
      this.apply( block, date );
    }
  }

  apply(block: number, date: string) {
    this.lowStaffService.applyTransfers(block, date)
      .subscribe(success => {
        console.log( success );
        this.loadData();
      }
        , error => this.setErrorMessage(error));
  }

}
