<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Consultas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3 class="title">
                        <h3 class="title">Altas por quincena ({{ dataReport.length }})</h3>
                    </h3>
                </div>
                <div *ngIf="dataReport.length !== 0" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <!-- <button type="button" class="btn btn-outline-info" (click)="getPDF()"><i class="far fa-file-pdf"></i>
                      PDF</button> -->
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast">
        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                          <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight" (change)="searchInterest()">
                          <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 text-right">
                    <button [disabled]="dataReport.length === 0" style="margin: 15px;" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i class="far fa-file-excel"></i>
                        Generar
                    </button>
                </div>
            </div>
        </form>

        <table *ngIf="dataReport.length > 0" class="table table-striped table-hover">
            <thead class="text-center">
                <tr>
                    <th scope="col">RFC</th>
                    <th scope="col">CURP</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Estatus</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Región</th>
                    <th scope="col">Porcentage</th>
                    <th scope="col">Movimiento</th>
                </tr>
            </thead>

            <tbody>
                <tr class="text-right" *ngFor="let item of dataReport; let i = index">
                    <td>{{ item.rfc }}</td>
                    <td>{{ item.curp }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.lastStatus }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.region.name }}</td>
                    <td>{{ item.contribution.percentage }} %</td>
                    <td>
                        <span *ngIf="item.contributionPercentages.length === 1">Alta</span>
                        <span *ngIf="item.contributionPercentages.length > 1">Cambio</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</article>