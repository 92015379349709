import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PeriodInterface } from '../../../../interfaces/period.interface';

@Injectable()
export class BankDepositsService {
  private apiURL = 'api/policies/BankDeposits';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

    getDataPolicy( period: PeriodInterface ) {
      return this.http.post<any>(this.apiURL, period);
    }
}
