<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"> Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="['/administration/deadlines', searchValue]" routerLinkActive="router-link-active">Tipos de Préstamos</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Detalle</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>
    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card card-block animated fadeIn fast">

        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Importe</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.amount | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Quincenas</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.fortnight.numberFortnight }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Tipo</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.type }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Maestros</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.teachers }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Descuento</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.discount | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Percepciones</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.totalPerceptions | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Ahorros</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.saving | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Prima apertura</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.openingBonus | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">% garantia</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.guaranteedPercentage | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">IVA</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.iva | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Seguro de vida</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.lifeInsurance | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Concepto Conta</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.accountingConcept }}">
                </div>
            </div>

        </div>

        <label>Desglose Quincenal</label>
        <hr />

        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Capital</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.fortnightCapital | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Interes</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.fortnightInterest | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">IVA</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.fortnightIVA | currency }}">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Seguro de vida</label>
                    <input type="text" class="form-control underlined" readonly value="{{ deadline.fortnightLifeInsurance | currency }}">
                </div>
            </div>
        </div>

    </div>

    <div class="form-group ">
        <a class="btn btn-outline-info " [routerLink]="['/administration/deadlines', searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
    </div>

</div>