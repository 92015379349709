import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CollectionService } from '../../banks/collections/services/collection.service';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-collection-recover',
  templateUrl: './collection-recover.component.html',
  styles: [
  ]
})
export class CollectionRecoverComponent implements OnInit {
  forma: UntypedFormGroup;
  errorMessage: string;
  loadingData = false;
  grandTotal = 0;
  fortnights: string[] = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ];
  dataReport: Array<any>;

  constructor( private collectionService: CollectionService,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService ) { }

  ngOnInit(): void {
  const numberFortnight = this.getNumberFortnight();
    this.fortnights = this.getFortnights(8, parseInt(numberFortnight, 0));
    const fortnight = `${ new Date().getFullYear() }-${ numberFortnight }`;
    this.forma = this.formBuilder.group({
      teachers: ['All'],
      type: ['All'],
      fortnight: [fortnight]
    });
  }

  getFortnights(numberFortnights: number, startFortnight: number): string[] {
    const arrFortnight = [];
    const divider = (startFortnight / 2).toString().split('.');
    let counter = 0;
    let numberFortnight = startFortnight;
    let date = new Date( new Date().getFullYear(), 0, 15 );
    if ( parseInt(divider[1], 0) > 0 ) {
      date = new Date(new Date().getFullYear(), parseInt(divider[0], 0), 15);
    }

    do {
      arrFortnight.push( date.getFullYear().toString() + '-' + this.setFormatDay(numberFortnight) );
      // if ( date >= new Date() ) {
      //   counter++;
      // }
      numberFortnight++;
      if ( numberFortnight === 25 ) {
        numberFortnight = 1;
      }
      counter++;
      this.getDateFortnight(date);
    } while ( counter <= numberFortnights );
    return arrFortnight;
  }

  setFormatDay(day: number): string {
    if ( day.toString().length === 1 ) {
      return '0' + day.toString();
    }
    return day.toString();
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date(new Date().getFullYear(), 0, 15);
    do {
      if (date >= new Date()) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while (findingFortnight === true);
    numberFortnight ++;
    return ( numberFortnight.toString().length === 1 ) ? `0${ numberFortnight.toString() }` : numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if (date.getDate() === 15) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
    } else {
      date.setDate(date.getDate() + 14);
    }
  }

  loadData(): void {
    this.loadingData = true;
    this.collectionService.getCollectionDetailRecover(
      this.forma.controls['teachers'].value,
      this.forma.controls['type'].value,
      this.forma.controls['fortnight'].value ).subscribe(data => {
        this.dataReport = data;
        if ( this.dataReport ) {
          this.grandTotal = this.dataReport
            .reduce((sum, current) => sum + current.discount, 0);
        }
        this.loadingData = false;
    },
      (error: any) => this.setErrorMessage(error)
    );
  }

  getExcel(): void {
    const data = this.dataReport.map(i => ({
      'RFC': i.rfc,
      'CURP': i.curp,
      'Nombre': i.name,
      'Importe': i.discount,
      'Tipo': i.movement
    }));

    this.excelService.exportAsExcelFile(data,
      `Cobranza_Detalle_Recuperar`
    );
  }

  setErrorMessage(error) {
    this.errorMessage = error;
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

}
