import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { TeacherService } from '../../administration/teachers/services/teacher.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';

@Component({
  selector: 'app-account-statements',
  templateUrl: './account-statements.component.html',
  styles: []
})
export class AccountStatementsComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  applications: ApplicationInterface[];
  loadingData = false;
  searchValue = '';
  jsonArray = [];

  constructor( private teacherService: TeacherService,
    public accountService: AccountService,
    public activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.accountService.getApplications('reports', 'account-statements')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      }, error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    this.loadingData = true;
    this.teacherService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  search() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  haveLoanActive( teacher: any ): boolean {
    if( !teacher.active ) {
      return true;
    }
    return teacher.loans.some( l => l.status === 'Activo');
  }

}
