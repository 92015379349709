import { Routes } from '@angular/router';
import { LowStaffComponent } from './low-staff.component';
import { LowStaffListComponent } from './low-staff-list.component';
import { LowStaffingDetailsComponent } from './low-staffing-details.component';

export const LOWSTAFF_ROUTES: Routes = [
    { path: 'pending/:waytopay/:start/:end', component: LowStaffComponent },
    { path: 'pending/:waytopay/:start/:end/:searchValue', component: LowStaffComponent },
    { path: 'details/:id/:type/:waytopay/:start/:end', component: LowStaffingDetailsComponent },
    { path: 'details/:id/:type/:waytopay/:start/:end/:searchValue', component: LowStaffingDetailsComponent },
    { path: '', component: LowStaffListComponent },
    { path: ':waytopay/:start/:end/:searchValue', component: LowStaffListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
