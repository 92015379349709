<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Consultas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <h3 class="title">
                        Cheques y Transferencias Bajas <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-right">
                    <button type="button" class="btn btn-outline-success" (click)="exportData()">
                        <i class="far fa-file-excel"></i> Exportar
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="RFC, Nombre, # Cheque">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                <label>Forma de Pago</label>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <select name="tipo" class="form-control" [value]="typeSelected" [(ngModel)]="typeSelected">
                    <Option value="3" Selected >Todos</Option>
                    <Option value="2">Cheque</Option>
                    <Option value="1">Transferencia</Option>
                </select>
            </div>

            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="input-box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Inicio</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                            <input type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Al</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                            <input type="date" name="fin" [value]="fin" [(ngModel)]="fin">
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 row">
                <label class="col-xs-3 col-sm-3 col-md-3 col-lg-5">Forma de Pago</label>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-7">
                    <select class="custom-select" [(ngModel)]="typeSelected">
                      <option value="null" selected >TODOS</option>
                      <option value="Cheque" >Cheque</option>
                      <option value="Transferencia" >Transferencia</option>
                  </select>
                </div>
            </div>-->

            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                <button type="button" class="btn btn-outline-primary" (click)="search( )">
                    Buscar
                  </button>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>R.F.C. / C.U.R.P.</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span># Cheque</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Fecha</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Monto</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Tipo</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header fixed item-col-actions-dropdown"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col item-col-item">
                        <div class="item-heading">R.F.C. / C.U.R.P.</div>
                        <div>
                            <span>{{ item.curp }}</span>
                        </div>
                    </div>

                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading"># Cheque</div>
                        <div> {{ item.number }} </div>
                    </div>

                    <div class="item-col item-col-item text-right">
                        <div class="item-heading">Fecha</div>
                        <div> {{ item.movementDate | date }} </div>
                    </div>

                    <div class="item-col item-col-sales">
                        <div class="item-heading">Monto</div>
                        <div> {{ item.amount | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Tipo</div>
                        <div> {{ item.type }} </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown">
                        <div class="item-list-actions text-center">
                            <span *ngIf="accountService.havePermission(applications, 'Cheques y Transferencias Bajas Detalle')">
                                <a class="primary" [routerLink]="['/reports/lowstaffing/details', item.id, item.type, typeSelected, inicio, fin, searchValue ]">
                                    <i class="fas fa-info-circle"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>
</article>