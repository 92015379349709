<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="['/administration/lowstaffing', waytopay, start, end, searchValue]" routerLinkActive="router-link-active">Bajas</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Detalle
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card card-block animated fadeIn fast">
        <div class="row">
            <div class="col-12 text-center">
                <h3 class="m-1">{{ lowstaffing?.name }} <small>({{ lowstaffing?.curp }})</small></h3>
            </div>
        </div>

        <div class="row">
            <label class="col-md-3"># Cheque</label>
            <label class="col-md-3">{{ lowstaffing?.number }}</label>
            <label class="col-md-3">Tipo</label>
            <label class="col-md-3">{{ lowstaffing?.type }}</label>
            <label class="col-md-3">Ahorro</label>
            <label class="col-md-3">{{ lowstaffing?.amountSavings  | currency }}</label>
            <label class="col-md-3">Interes</label>
            <label class="col-md-3">{{ lowstaffing?.amountInterests  | currency }}</label>
            <label class="col-md-3">Crédito</label>
            <label class="col-md-3">{{ lowstaffing?.amountCredit | currency }}</label>
            <label class="col-md-3">Crédito Interes</label>
            <label class="col-md-3">{{ lowstaffing?.amountCreditInterest | currency }}</label>
            <label class="col-md-3">Importe</label>
            <label class="col-md-3">{{ lowstaffing?.amount | currency }}</label>
            <label class="col-md-3">Quincena</label>
            <label class="col-md-3">{{ lowstaffing?.fortnigh }}</label>
            <label class="col-md-3">Fecha de tramite</label>
            <label class="col-md-3">{{ lowstaffing?.registeredDate | date:'dd/MM/yyyy' }}</label>
            <label class="col-md-3">Fecha de movimiento</label>
            <label class="col-md-3">{{ lowstaffing?.movementDate | date:'dd/MM/yyyy' }}</label>
            <!-- <label class="col-md-3">Estatus</label>
            <label class="col-md-3">{{ lowstaffing.status }}</label> -->
        </div>

        <table class="table table-striped table-hover" *ngIf="lowstaffing?.movmemtnDetails.length > 0">
            <thead>
                <tr>
                    <th>#</th>
                    <th># Cheque</th>
                    <th>Tipo</th>
                    <th>Parentesco</th>
                    <th>Nombre</th>
                    <th>Importe</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of lowstaffing.movmemtnDetails; let i =index;">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.number }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.relationship }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.amount | currency }}</td>
                </tr>
            </tbody>
        </table>

        <div class="row">
            <a class="btn btn-outline-info " [routerLink]="['/administration/lowstaffing', waytopay, start, end, searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>

</article>