import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeathsReportService {

  private apiURL = 'api/Reports/LowStaffing';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    get( anio: number ) {
      return this.http.get<any>(this.apiURL + '/' + anio);
    }

}
