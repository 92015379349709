<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Cobranza Detalle por Recuperar</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Detalle ( {{ dataReport?.length }} )</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Maestros</label>
                        <select class="custom-select" formControlName="teachers">
                            <option value="All" >Todos</option>
                            <option value="Media Superior" >Media Superior</option>
                            <option value="Alta Jubilación" >Alta Jubilación</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Tipo</label>
                        <select class="custom-select" formControlName="type">
                        <option value="All" >Todos</option>
                        <option value="Ahorros" >Ahorros</option>
                        <option value="Abonos" >Abonos</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                        <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                    </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 text-center">
                    <button [disabled]="loadingData" type="button" class="btn btn-outline-primary" (click)="loadData()">
                        <i [ngClass]="{ 'fas fa-search': !loadingData, 'fas fa-sync-alt fa-spin': loadingData } "></i>
                        <!-- <i class="fas fa-search"></i> {{ loadingData }} -->
                        <span *ngIf="!loadingData ">Buscar</span>
                        <span *ngIf="loadingData">Buscando...</span>
                  </button>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-right">
                    <button [disabled]="dataReport?.length === 0 || !dataReport" style="margin: 15px;" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i class="far fa-file-excel"></i>
                        Generar
                    </button>
                </div>
            </div>
        </form>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
        <app-not-data *ngIf="dataReport?.length === 0 || !dataReport"></app-not-data>

        <div class="row" *ngIf="dataReport?.length > 0">
            <div class="col-12">
                <h3 class="text-right" >Total {{ grandTotal| currency }}</h3>
            </div>
        </div>

        <table class="table table-striped table-hover" *ngIf="dataReport?.length > 0">
            <thead>
                <tr>
                    <th>RFC</th>
                    <th>CURP</th>
                    <th>Nombre</th>
                    <th>Importe</th>
                    <th>Tipo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataReport; let i = index">
                    <td>{{ item.rfc }}</td>
                    <td>{{ item.curp }}</td>
                    <td>{{ item.name }}</td>
                    <td class="text-right">
                        {{ item.discount | currency }}
                    </td>
                    <td>
                        {{ item.movement }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</article>
