import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TeacherService } from './services/teacher.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TeacherInterface } from './interface/teacher.interface';
import { SavingReportService } from '../../reports/savings/services/saving-report.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styles: []
})
export class UnsubscribeComponent implements OnInit {
  returnPath = '';
  errorMessage: string;
  loadingData = false;
  working = false;
  searchValue = '';
  amountSavings = 0;
  amountInterests = 0;
  forma: UntypedFormGroup;
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    lastStatus: 'Activo',
    applied: true,
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    credit: 0,
    type: '',
    fortnight: '',
    lastUpdated: new Date,
    clabe: '',
    curp: '',
    bankId: '00000000-0000-0000-0000-000000000000',
    member: false,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: '',
    NumberOfLoans: 0,
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: '',
      code: ''
    },
    userIdLastUpdated: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: []
  };
  status: any[] = [{
    id: 1,
    name: 'Baja',
    percentage: 1.00167
  }, {
    id: 2,
    name: 'Jubilación',
    percentage: 1.00208
  }];

  constructor( private teacherService: TeacherService,
    public accountService: AccountService, private activatedRoute: ActivatedRoute,
    private reportService: SavingReportService ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.loadingData = true;

      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.teacherService.getById(params['id']).subscribe(
          teacher => {
            this.teacher = Object.assign({}, teacher);
            this.calculateAmount(this.teacher.rfc, 1.00167);
          },
          error => this.setErrorMessage(error)
        );
      }
    }, err => this.setErrorMessage(err));
  }

  statusChanged( value: any ): void {
    this.amountInterests = 0;
    this.amountSavings = 0;
    this.calculateAmount(this.teacher.rfc, value);
  }

  calculateAmount(rfc: string, percentage: number) {
    this.loadingData = true;
    this.reportService.get(rfc)
      .subscribe(data => {
        data.forEach(year => {
          let interests = 0.00;
            const arrayYear = Object.keys(year).map(function(key) {
              return [String(key), year[key]];
            });
            for (let index = 0; index < arrayYear.length; index++) {
              if ( arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA' ) {
                interests = (interests + arrayYear[index][1]) * percentage;
              }
            }
            year.interests = parseFloat(interests.toFixed(2)) - year.total;
            this.amountInterests += year.interests;
            this.amountSavings += year.total;
        });
        this.loadingData = false;
      }, err => this.setErrorMessage(err));
  }

  setErrorMessage( error ) {
    if (error && error.error) {
      console.log(error);
      this.errorMessage = error.message;
      this.loadingData = false;
    }
  }

  GetCheck(): void {

  }

}
