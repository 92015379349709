<article class="content items-list-page">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Consultas</li>
      </ol>
  </nav>

  <div class="title-search-block">
      <div class="title-block">
          <div class="row form-group">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h3 class="title">
                      Maestros <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                  </h3>
              </div>
          </div>
      </div>
  </div>

  <div class="box-search">
      <div class="row">
          <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <form role="search">
                  <div class="input-box">
                      <i class="fas fa-search"></i>
                      <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar..." (keyup.enter)="search()">
                      <div class="underline"></div>
                  </div>
              </form>
          </div>
      </div>
  </div>

  <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

  <app-loading-data *ngIf="loadingData"></app-loading-data>

  <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
      <ul class="item-list striped">
          <li class="item item-list-header">
              <div class="row">
                  <div class="item-col item-col-header item-col-header">
                      <div>
                          <span>Número</span>
                      </div>
                  </div>
                  <div class="item-col item-col-header item-col-header">
                      <div class="no-overflow">
                          <span>Nombre</span>
                      </div>
                  </div>
                  <div class="item-col item-col-header item-col-header">
                      <div>
                          <span>RFC</span>
                      </div>
                  </div>
                  <div class="item-col item-col-header item-col-header">
                    <div>
                        <span>CURP</span>
                    </div>
                  </div>
                  <div class="item-col item-col-header fixed item-col-actions-dropdown-teacher"></div>
              </div>
          </li>

          <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
              <div class="item-row" [ngClass]="{'required': item.active != true}">
                  <div class="item-col item-col-item">
                      <div class="item-heading">Número</div>
                      <div> {{ item.identifier }} </div>
                  </div>
                  <div class="item-col fixed pull-left item-col-title">
                      <div class="item-heading">Nombre</div>
                      <div>
                          <span>{{ item.name }}</span>
                      </div>
                  </div>

                  <div class="item-col item-col-item">
                      <div class="item-heading">RFC</div>
                      <div> {{ item.rfc }} </div>
                  </div>

                  <div class="item-col item-col-item">
                    <div class="item-heading">CURP</div>
                    <div> {{ item.curp }} </div>
                </div>

                  <div class="item-col fixed item-col-actions-dropdown">
                      <div class="item-list-actions text-center">
                          <span *ngIf="haveLoanActive( item ) && accountService.havePermission(applications, 'Estados de Cuenta Detalle')">
                          <a class="info" [routerLink]="['/reports/account-statements/account-status', item.id, searchValue ]">
                            <i class="fas fa-file-invoice"></i>
                          </a> </span>
                      </div>
                  </div>
              </div>
          </li>
      </ul>
  </div>

  <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>
</article>
