import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-way-to-pay-update',
  template: `
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><i class="fas fa-exclamation-triangle"></i> Alerta</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>¿Seguro que desea cambiar la forma de pago?</p>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label class="control-label"> Forma de pago</label>
                  <select class="custom-select" [formControl]="waytopay">
                    <option value="Efectivo">Efectivo</option>
                    <option value="Transferencia">Transferencia</option>
                    <option value="Otro">Otro</option>
                  </select>
                </div>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" [disabled]="waytopay.value === ''" class="btn btn-primary" (click)="ok()" >
              Si
            </button>
            <button type="button" class="btn btn-secondary" (click)="cancel()" >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class WayToPayUpdateComponent implements OnInit {
  public teacherId: string;
  waytopay = new UntypedFormControl('');
  @Output() public alertOutput = new EventEmitter<any>();

  constructor( ) { }

  ngOnInit() {
  }

  open() {
    $('#myModal').modal();
  }

  ok() {
    $('#myModal').modal('hide');
    /* const contribution: ContributionInfointerface = {
      teacherId: this.teacherId,
      contributionId: this.contribution.value,
      fortnight: this.fortnight.value,
      userId: ''
    }; */
    this.alertOutput.emit( this.waytopay.value );
  }

  cancel() {
    $('#myModal').modal('hide');
  }

  setErrorMessage( error ) {
    console.log(error);
  }

}
