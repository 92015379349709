<article class="content">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/administration/data-update', searchValue]" routerLinkActive="router-link-active">Actualización de datos</a></li>
          <li class="breadcrumb-item active" aria-current="page">Procesar</li>
      </ol>
  </nav>

  <div class="title-search-block">
      <div class="title-block">
          <div class="row">
              <div class="col-md-6">
                  <h3 class="title">
                      <h3 class="title">Procesar</h3>
                  </h3>
              </div>
          </div>
      </div>
  </div>

  <div class="card card-block animated fadeIn fast">

    <div class="row form-group">
      <div class="col-12">
          <div class="custom-file">
              <input type="file" (change)="onFileSelect($event.target)" class="custom-file-input" name="myfile" accept=".csv">
              <label class="custom-file-label" for="customFileLang">Seleccionar Archivo...</label>
          </div>
      </div>
  </div>

  <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

  <div *ngIf="selectedFileNames.length > 0" class="row form-group">
    <div class="col-12">
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let fileName of selectedFileNames; let i = index">
                {{ fileName }} ( {{ teachers.length }} )
            </li>
        </ul>
    </div>
  </div>

      <div class="form-group row">
          <div class="col-12 text-center">
              <button [disabled]="isProcessingData || teachers.length === 0 || !teachersToProcess || isLoadingData" type="button" class="btn btn-outline-success" (click)="handlerClick()">
                <i [ngClass]="{ 'far fa-save': !isProcessingData, 'fas fa-sync-alt fa-spin': isProcessingData }"></i>
                <span *ngIf="!isProcessingData">Procesar</span>
                <span *ngIf="isProcessingData" >
                    Procesando...
                </span>
            </button>
            <button type="button" [disabled]="isProcessingData || teachers.length === 0 || isLoadingData" class="btn btn-outline-danger" (click)="reserForm()"><i class="fas fa-broom"></i> Limpiar</button>
          </div>
      </div>

      <app-loading-data *ngIf="isLoadingData" ></app-loading-data>

      <table class="table table-striped table-hover" >
        <thead>
            <tr>
                <th># Empleado</th>
                <th>RFC</th>
                <th>CURP</th>
                <th>Nombre</th>
                <th>Nacimento</th>
                <th>Genero</th>
                <th>Correo</th>
                <th># Bene.</th>
                <th></th>
                <!-- <th>Procesar</th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of teachers; let i =index;" >
                <td [ngClass]="{'required': !item.active}" >{{ item.employeeNumber }}</td>
                <td [ngClass]="{'required': !item.active}" >{{ item.rfc }}</td>
                <td [ngClass]="{'required': !item.active}" >{{ item.curp }}</td>
                <td [ngClass]="{'required': !item.active}" >{{ item.lastName }} {{ item.motherLastName }} {{ item.name }}</td>
                <td [ngClass]="{'required': !item.active}" >{{ item.birthdate | date:'dd/MM/yyyy' }}</td>
                <td [ngClass]="{'required': !item.active}" >{{ item.gender }}</td>
                <td [ngClass]="{'required': !item.active}" >{{ item.email }}</td>
                <td [ngClass]="{'required': !item.active}" >{{ item.beneficiaries.length }}</td>
                <td [ngClass]="{'required': !item.active}" >{{ item.errorMessage }}</td>
                <!-- <td>
                    <input (change)="setPermission( $event, item )" type="checkbox" [name]="'process' + i" id="'process' + i" [checked]="item.active">
                </td> -->
            </tr>
        </tbody>
    </table>
  </div>
</article>