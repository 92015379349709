import { Routes } from '@angular/router';
import { LoanComponent } from './loan.component';
import { LoanListComponent } from './loan-list.component';
import { LoansPendingComponent } from './loans-pending.component';

export const LOANS_ROUTES: Routes = [
    { path: 'add/:teacher', component: LoanComponent },
    { path: 'edit/:id', component: LoanComponent },
    { path: 'edit/:id/:returnPath', component: LoanComponent },
    { path: '', component: LoanListComponent },
    { path: 'pending', component: LoansPendingComponent },
    { path: 'pending/:searchValue', component: LoansPendingComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
