import { Component, OnInit } from '@angular/core';
import { PeriodInterface } from '../../../interfaces/period.interface';
import { SavingPolicyService } from '../savings/services/saving-policy.service';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-settlement-policy',
  templateUrl: './settlement-policy.component.html',
  styles: []
})
export class SettlementPolicyComponent implements OnInit {
  errorMessage = '';
  loadingData = false;
  dataReport: any[] = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  period: PeriodInterface = {
    start: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`,
    end: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`
  };

  constructor( private savingService: SavingPolicyService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.loadData();
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  loadData() {
    this.errorMessage = '';
    if ( this.isValidDate(this.inicio) && this.inicio.length === 10 ) {
      if ( this.isValidDate(this.fin) && this.inicio.length === 10 ) {
        this.period.start = this.inicio;
        this.period.end = this.fin;
        this.savingService.getDataSettlement( this.period )
          .subscribe( (data) => {
            this.dataReport = data;
            this.loadingData = false;
          }, error => this.setErrorMessage(error));
      } else {
        this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
      }
    } else {
      this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
    }
    this.loadingData = false;
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  exportData() {
    this.excelService.exportAsExcelFileWithoutHeader(this.clearData(this.dataReport),
      `Liquidaciones_${ this.inicio }_${ this.fin }_`);
  }

  clearData(data: any[]): any[] {
    const array = [];
    let bankAmount = 0;

    this.addEmptyItemToArray( array, 2 );

    array.push( this.createItem( 'Ig', '0',
    `Liquidaciones ${ this.inicio } al ${ this.fin }`,
    '15', '', '', '' ) );

    // tslint:disable-next-line:no-shadowed-variable
    data.forEach(element => {
      bankAmount += parseFloat(element.abono);
      array.push(
        this.createItem( '',
          '1150-001-000',
          '',
          `CREDITOS ORDINARIOS Y EXTRAORDINARIOS/ CAPITAL DEL PRESTAMO (${ element.name })`,
          '',
          element.abono,
          '')
        );
      if ( !element.condoneInterest ) {
        array.push(
          this.createItem( '',
            '4100-001-000',
            '',
            `PRODUCTOS FINANCIEROS/ INTERESES COBRADOS (${ element.name })`,
            '',
            element.interest,
            '')
          );

          bankAmount += parseFloat(element.interest);
      }

      array.push(
        this.createItem( '',
          '2140-001-000',
          '',
          `INTERESES POR PRESTAMOS (${ element.name })`,
          '',
          '',
          element.interest)
        );

      array.push(
        this.createItem( '',
          '1160-002-000',
          '',
          `INTERESES POR COBRAR (${ element.name })`,
          '',
          element.interest,
          '')
        );

        if ( element.applySavings ) {
          array.push(
            this.createItem( '',
              '2110-001-000',
              '',
              `AHORRO DEL MAESTRO (${ element.name })`,
              '',
              element.residue,
              '')
            );
            bankAmount += parseFloat(element.residue);
        }
    });

    array.push(
      this.createItem( '',
        '1120-007-000',
        '',
        `BANCO CUENTA 5480`,
        '',
        '',
        bankAmount.toString()
        )
      );

    return array;
  }

  addEmptyItemToArray(data: any[], numberItems: number): any {
    for (let index = 0; index < numberItems; index++) {
      data.unshift(this.createItem('', '', '', '', '', '', ''));
    }
  }

  createItem(col: string, cuenta: string, depto: string, concepto: string, col2: string, abono: string, cargo: string ): any {
    return {
        col: col,
        cuenta: cuenta,
        depto: depto,
        concepto: concepto,
        col2: col2,
        cargo: cargo,
        abono: abono,
      };
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
