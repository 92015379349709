import { Component, OnInit } from '@angular/core';
import { PeriodInterface } from '../../../interfaces/period.interface';
import { BankDepositsService } from './services/bank-deposits.service';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-bank-deposits',
  templateUrl: './bank-deposits.component.html',
  styles: []
})
export class BankDepositsComponent implements OnInit {
  errorMessage = '';
  loadingData = false;
  data: any[] = [];
  dataReport: any[] = [];
  poliza = 0;
  name = '';
  wayToPay = 'Efectivo';
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  period: PeriodInterface = {
    start: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`,
    end: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`
  };

  constructor( private bankDepositsService: BankDepositsService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.loadData();
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  loadData(): void {
    this.errorMessage = '';
    if ( this.isValidDate(this.inicio) && this.inicio.length === 10 ) {
      this.period.start = this.inicio;
        this.bankDepositsService.getDataPolicy( this.period )
          .subscribe( (data) => {
            this.data = data;
            this.dataReport = data.filter(d => d.wayToPay === this.wayToPay);
            this.loadingData = false;
          }, error => this.setErrorMessage(error));
    } else {
      this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
      this.loadingData = false;
    }
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  exportData(): void {
    this.excelService.exportAsExcelFileWithoutHeader(this.clearData(this.dataReport),
      `Depositos_${ this.wayToPay }_${ this.inicio }`);
  }

  handlerChangeWayToPay( ): void {
    this.dataReport = this.data.filter(d => d.wayToPay === this.wayToPay);
  }

  clearData(data: any[]): any[] {
    let bankDeposit = 0;
    const array = [];

    this.addEmptyItemToArray( array, 2 );

    array.push(
      this.createItem( 'IG',
        `4`,
        '',
        `INGRESOS ${ this.inicio }`,
        `${ this.poliza } ${ this.name }`,
        '',
        '')
      );

    data.forEach(element => {
      if ( element.type === 'Liquidación' ) {
        bankDeposit += element.amountDeposit;
        array.push(
          this.createItem( '',
            `1150-001-000`,
            '0',
            `CREDITOS ORDINARIOS Y EXTRAORDINARIOS / ${ element.name }`,
            '',
            '0',
            (element.amountDeposit - (element.amountSaving + element.interest + element.lifeInsurance + element.financialProducts)).toFixed(2)
          ));
        array.push(
            this.createItem( '',
              `4100-001-000`,
              '0',
              `INTERESES GANADOS POR PRESTAMOS / ${ element.name }`,
              '',
              '0',
              element.interest)
            );
        array.push(
            this.createItem( '',
              `2140-001-000`,
              '0',
              `INTERESES SOBRE PRESTAMOS / ${ element.name }`,
              '',
              element.interest + element.condoneInterest,
              '0')
            );

        array.push(
            this.createItem( '',
              `1160-002-000`,
              '0',
              `INTERESES POR COBRAR A PRESTAMOS DE / ${ element.name }`,
              '',
              '0',
              element.interest + element.condoneInterest)
            );
        array.push(
            this.createItem( '',
              `2110-001-000`,
              '0',
              `AHORRO / ${ element.name }`,
              '',
              '0',
              element.amountSaving)
            );
        array.push(
            this.createItem( '',
              `2120-005-000`,
              '0',
              `SOBRANTES Y FALTANTES / ${ element.name }`,
              '',
              '0',
              element.financialProducts)
            );
// AQUI EMPIEZA LA PRIMA
        array.push(
            this.createItem( '',
              `4100-002-003`,
              '0',
              `PRIMA. PRTEC AL AHORRO / ${ element.name }`,
              '',
              '0',
              element.lifeInsurance)
            );
        array.push(
            this.createItem( '',
              `2140-002-000`,
              '0',
              `PRIMA. PRTEC AL AHORRO / ${ element.name }`,
              '',
              element.lifeInsurance + element.condoneLifeInsurance,
              '0')
            );
        array.push(
            this.createItem( '',
              `1160-001-000`,
              '0',
              `PRIMA. PRTEC AL AHORRO / ${ element.name }`,
              '',
              '0',
              element.lifeInsurance + element.condoneLifeInsurance)
            );



      } else if ( element.type === 'Aportación') {
        bankDeposit += element.amountDeposit;
        array.push(
          this.createItem( '',
            '2110-001-000',
            '0',
            `AHORRO DE LOS MAESTROS / ${ element.name }`,
            '',
            '0',
            element.amountDeposit)
          );
      }
    });

    array.push(
      this.createItem( '',
        `1120-007-000`,
        '0',
        `BANORTE DEPOSITOS 1087275480`,
        '',
        bankDeposit.toFixed(2),
        '0')
      );
    return array;
  }

  addEmptyItemToArray(data: any[], numberItems: number): any {
    for (let index = 0; index < numberItems; index++) {
      data.unshift(this.createItem('', '', '', '', '', '', ''));
    }
  }

  createItem(col: string, cuenta: string, depto: string, concepto: string, col2: string, abono: string, cargo: string ): any {
    return {
        col: col,
        cuenta: cuenta,
        depto: depto,
        concepto: concepto,
        col2: col2,
        abono: abono,
        cargo: cargo
      };
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
