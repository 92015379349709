import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../account/login/services/account.service';
import { LowStaffService } from '../../administration/low-staffing/services/low-staff.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { OpenDirective } from '../../modals/open.directive';
import { TransferInterface } from '../transfers/interfaces/transfer.interface';
import { EditDataLowstaffComponent } from './edit-data-lowstaff.component';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { BankService } from '../../administration/banks/services/bank.service';
import { BankInterface } from '../../administration/banks/interfaces/bank.interface';

@Component({
  selector: 'app-suppliers-lowstaff',
  templateUrl: './suppliers-lowstaff.component.html',
  styles: []
})
export class SuppliersLowstaffComponent implements OnInit {
  isLoadingData = false;
  errorMessage: string;
  isWorking = false;
  datasource: Array<TransferInterface> = [];
  applications: ApplicationInterface[];
  banks: BankInterface[] = [];

  constructor( private lowStaffService: LowStaffService,
    public accountService: AccountService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private bankService: BankService ) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.accountService.getApplications('banks', 'suppliers-lowstaff')
    .subscribe(applications => {
      this.applications = applications;
      this.lowStaffService.getTreansferSupports( )
        .subscribe(data => {
          this.bankService.get('null').subscribe(
            banks => {
              this.banks = banks;
              this.datasource = data.filter( d => d.itIsSupplier === false);
              this.selectAll();
              this.isLoadingData = false;
            }, error => {
              this.isLoadingData = false;
              this.helperService.setErrorMessage(error);
            });
        }, error => {
          this.helperService.setErrorMessage(error);
        });
    }, error => this.helperService.setErrorMessage(error));
  }

  selectAll(): void {
    this.datasource.forEach(element => {
      if ( element.bankId !== '00000000-0000-0000-0000-000000000000' ) {
        element.itIsSupplier = true;
      } else {
        element.itIsSupplier = false;
      }
    });
  }

  dataValid(): boolean {
    return this.datasource.some( l => l.itIsSupplier === true );
  }

  handlerChecbox(checked: boolean, item: TransferInterface): void {
    item.itIsSupplier = checked;
  }

  handlerClick(): void {
    this.isWorking = true;
    // this.descargarArchivo(this.generarTexto(this.datasource), `Alta Proveedores ${new Date().getTime()}.txt`);
    this.lowStaffService.putProvider(this.datasource.filter(d => d.itIsSupplier === true))
      .then(response => {
        if ( response.isSuccess ) {
          this.descargarArchivo(this.generarTexto(this.datasource.filter(d => d.itIsSupplier === true)),
           `Alta Proveedores ${new Date().getTime()}.txt`);
           this.isLoadingData = true;
          this.lowStaffService.getTreansferSupports( )
           .subscribe(data => {
             this.datasource = data.filter( d => d.itIsSupplier === false);
             this.selectAll();
             this.isLoadingData = false;
             this.isWorking = false;
           }, error => {
             this.helperService.setErrorMessage(error);
           });
        } else {
          this.errorMessage = response.message;
        }
      }).catch( error => {
        this.helperService.setErrorMessage(error);
      });
  }

  generarTexto(teachers: Array<TransferInterface>) {
    const texto = [];
    teachers.forEach(element => {
      let code = '040';
      if ( element.bankId.toString().toUpperCase() === 'BE28FF92-E845-41BB-8BEE-3060267A7023' ) {
        code = '001';
      }
      // tslint:disable-next-line:max-line-length
      texto.push(`${ element.rfc }\tAR\t${ this.completeRight(element.name, 60, ' ') }\t${ element.rfc }\t${ this.completeLeft(element.phone, 15, '0') }\t${ element.name.substring(0, 20) }\t${ element.email }\t000\t0\t0000\t000000000000000000\n`);
      // tslint:disable-next-line:max-line-length
      texto.push(`${ element.rfc }\tAC\t${ this.completeRight('', 60, ' ') }\t${ this.completeRight('', 13, ' ') }\t000000000000000\t${ this.completeRight('', 20, ' ') }\t \t${ code }\tPESOS\t${ this.completeLeft(element.key, 4, '0') }\t${ element.clabe }\n`);
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  completeLeft(text: string, numberCharacters: number, character: string): string {
    let textReturned = '';
    for (let index = text.length; index < numberCharacters; index++) {
      textReturned = `${ character }${ textReturned }`;
    }
    return textReturned + text;
  }

  completeRight(text: string, numberCharacters: number, character: string): string {
    for (let index = text.length; index < numberCharacters; index++) {
      text = `${ text }${ character }`;
    }
    return text;
  }

  descargarArchivo(contenidoEnBlob, nombreArchivo) {
    const reader: FileReader = new FileReader();
    reader.onload = function (event: Event) {
      const save = document.createElement('a');
      save.href = reader.result as string; // event.target.result;
      save.target = '_blank';
      save.download = nombreArchivo || 'archivo.txt';
      const clicEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      save.dispatchEvent(clicEvent);
      (window.URL).revokeObjectURL(save.href);
    };
    reader.readAsDataURL(contenidoEnBlob);
  }

  async showEditModal( provider: TransferInterface) {
    var options = this.getBankOptions(provider.bankId);
    const bankInfo = await this.notificationService.showConfirmBankData(`${provider.name} ${provider.rfc}`, provider.email, options, provider.clabe);
    if(bankInfo !== null) {
      this.isWorking = true;
      provider.email = bankInfo.email;
      provider.bankId = bankInfo.bank;
      provider.clabe = bankInfo.account;
      provider.active = true;
      const update: Array<TransferInterface> = [];
      update.push( provider );
      this.lowStaffService.putProvider( update ).then(response => {
        if ( !response.isSuccess ) {
          this.errorMessage = response.message;
        }
        this.isWorking = false;
      }).catch( error => this.helperService.setErrorMessage(error) );
    }
  }

  getBankOptions(code: string): string {
    let options = '';
    this.banks.forEach(bank => {
      if(code === bank.code) {
        options += `<option selected value="${bank.code}">${bank.name}</option>`
      } else {
        options += `<option value="${bank.code}">${bank.name}</option>`
      }
    });
    return options
  }

}
