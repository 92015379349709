import { Component, OnInit } from '@angular/core';
import { AffilationItem } from '../affiliations/interfaces/affilation-item';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { CollectionService } from './services/collection.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router } from '@angular/router';
import { Console } from 'console';

@Component({
  selector: 'app-collection-apply',
  templateUrl: './collection-apply.component.html',
  styles: []
})
export class CollectionApplyComponent implements OnInit {
  forma: UntypedFormGroup;
  working = false;
  errorMessage: string;
  isLoadingData = false;
  selectedFileNames: string[] = [];
  uploadResult: any;
  filesToUpload: Array<File>;
  movementDate = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  fortnights: string[] = [];
  data: Array<AffilationItem> = new Array<AffilationItem>();
  showError = false;

  constructor( private collectionService: CollectionService,
    private formBuilder: UntypedFormBuilder,
    public accountService: AccountService,
    private router: Router ) { }

  ngOnInit() {
    const fortnight = this.getNumberFortnight();
    this.fortnights = this.getFortnights(40);
    this.forma = this.formBuilder.group({
      /* type: ['', [Validators.required]],
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      movementDate: [this.movementDate],
      */
     fortnightJubilados: [`${ new Date().getFullYear() }-${ fortnight }`, [Validators.required]],
     fortnightMediaSuperior: [`${ new Date().getFullYear() }-${ fortnight }`, [Validators.required]],
     affilations: this.formBuilder.array([]),
    });
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  reserForm() {
    const affilationsArr = this.forma.get('affilations') as UntypedFormArray;
    this.clearFormArray(affilationsArr);
    this.filesToUpload = [];
    this.selectedFileNames = [];
    this.data = [];
    this.uploadResult = '';
    this.errorMessage = '';
    this.isLoadingData = false;
    this.showError = false;
  }

  fileChangeEvent(fileInput: any) {
    this.errorMessage = '';
    this.uploadResult = '';
    this.filesToUpload = <Array<File>>fileInput.target.files;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      if (!this.validFile(this.filesToUpload[i].name)) {
        this.errorMessage = `Solo puede procesar archivos con la extencion .txt`;
      } else {
        this.selectedFileNames = [];
        this.selectedFileNames.push(this.filesToUpload[i].name);
        const reader = new FileReader();
        reader.onloadend = () => this.onLoad(reader.result.toString().split('\n'), this.filesToUpload[i].name);
        reader.readAsText(this.filesToUpload[i]);
      }
    }
  }

  validFile( fileName: string ): boolean {
    if ( fileName.slice(-3).toUpperCase() === 'TXT') {
      return true;
    } else {
      return false;
    }
  }

  onLoad(lines: Array<string>, fileName: string) {
    this.data = [];
    let dateMovement = new Date();

    lines.forEach(line => {

      if ( line.startsWith('H') ) {
        // tslint:disable-next-line:max-line-length
        dateMovement = new Date( parseFloat(line.substring(8, 12)), parseFloat(line.substring(12, 14)) - 1, parseFloat(line.substring(14, 16)));
      }

      if ( line.startsWith('D') ) {
        const code = line.substring(165, 167);
        let fortnight = this.forma.controls['fortnightJubilados'].value;
        if ( line.substring(19, 20).trim() === 'M' ) {
          fortnight = this.forma.controls['fortnightMediaSuperior'].value;
        }
        let valid = true;
        let status = 'Valido';
        if ( code !== '22' ) {
          if ( code !== '00' ) {
            valid = false;
            status = 'Invalido';
          }
        }

        const affilationsArr = this.forma.get('affilations') as UntypedFormArray;
        const affilationFG = this.BuildAffilation( line.substring(20, 59).trim(), line.substring(59, 99).trim(),
        code, valid, status, +`${ line.substring(99, 112) }.${ line.substring(112, 114) }`, true, fileName,
        dateMovement, line.substring(59, 65).trim().toUpperCase(), fortnight, '');
        affilationsArr.push(affilationFG);
      }
    });
  }

  BuildAffilation(curp: string, name: string, code: string, valid: boolean, status: string,
    amount: number, success: boolean, fileName: string, movementDate: Date, type: string, fortnight: string,
    message: string ) {
    return this.formBuilder.group({
      curp: curp,
      name: name,
      code: code,
      valid: valid,
      status: status,
      amount: amount,
      success: success,
      fileName: fileName,
      movementDate: movementDate,
      type: type,
      fortnight: fortnight,
      message: message
    });
  }

  upload() {
    if (this.filesToUpload.length > 1) {
      alert('Por favor solo seleccione un máximo de 1 archivo.');
    } else {
      this.isLoadingData = true;
      this.data = this.forma.controls['affilations'].value;
      this.collectionService.postApply( this.accountService.getUserId(),
      this.data)
      .subscribe( response => {
        if ( response.length > 0 ) {
          this.reserForm();
          const affilationsArr = this.forma.get('affilations') as UntypedFormArray;
          this.clearFormArray(affilationsArr);
          response.forEach(element => {
            const affilationFG = this.BuildAffilation( element.curp, element.name,
              element.code, element.valid, element.status, element.amount, element.success, element.fileName,
              element.movementDate, element.type, element.fortnight, element.message);
            affilationsArr.push(affilationFG);
          });
          this.showError = true;
        } else {
          this.router.navigate(['banks/collections']);
        }
      }, error => this.errorMessage = error.error);
    }
  }

  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  getFortnights(numberFortnights: number): string[] {
    const arrFortnight = [];
    let counter = 1;
    let numberFortnight = 1;
    let year = new Date().getFullYear();
    let month = new Date().getMonth() - 13;
    if ( month < 0 ) {
      year = year - 1;
      month = month + 12;
    }
    const date = new Date( year, 0, 15 );
    do {
      if ( date >= new Date(year, month, 1) ) {
        if ( numberFortnight === 24 ) {
          arrFortnight.push( ( date.getFullYear() - 1 ).toString() + '-' + this.setFormatDay(numberFortnight) );
        } else {
          arrFortnight.push( date.getFullYear().toString() + '-' + this.setFormatDay(numberFortnight) );
        }
        counter++;
      }
      numberFortnight++;
      if ( numberFortnight === 25 ) {
        numberFortnight = 1;
      }
      this.getDateFortnight(date);
    } while ( counter <= numberFortnights );
    return arrFortnight;
  }

  setFormatDay(day: number): string {
    if ( day.toString().length === 1 ) {
      return '0' + day.toString();
    }
    return day.toString();
  }

}
