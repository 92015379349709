import { Component } from '@angular/core';
import { UsersService } from './services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../applications/interface/application.interface';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styles: []
})
export class UserListComponent {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  applications: ApplicationInterface[];

  constructor( public accountService: AccountService,
    private usersService: UsersService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {
    this.accountService.getApplications('configuration', 'users')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      }, error => console.error(error));
  }

  showConfirm(id: string, name: string) {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar el usuario ${name}?`, 'Eliminar usuario')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(id);
      }
    });
  }

  deleteItem(id: string) {
    this.usersService.delete( id ).subscribe(
      () => {
        this.loadData('null');
        this.notificationService.toastConfirm('Se elimino correctamente el usuario');
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  loadData(value: string) {
    this.loadingData = true;
    this.usersService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  searchUser() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregarUser() {
    const text = this.searchValue;
    this.router.navigate( ['configuration/users/user', '', text] );
  }

}
