import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExcelService } from '../../../services/excel.service';
import { CollectionService } from '../../banks/collections/services/collection.service';
import { PeriodInterface } from '../../../interfaces/period.interface';

@Component({
  selector: 'app-collection-bank-list',
  templateUrl: './collection-bank-list.component.html',
  styles: []
})
export class CollectionBankListComponent implements OnInit {
  forma: UntypedFormGroup;
  errorMessage: string;
  loadingData = false;
  years: number[] = [];
  fortnights: string[] = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ];
  dataReport: Array<any>;
  bankErrors: any = [
    {
      code: '00',
      description: 'Ok'
    },
    {
      code: '01',
      description: 'Cuenta inexistente'
    },
    {
      code: '02',
      description: 'Cuenta bloqueada'
    },
    {
      code: '03',
      description: 'Cuenta cancelada'
    },
    {
      code: '04',
      description: 'Cuenta con insuficiencia de fondos'
    },
    {
      code: '05',
      description: 'Cuenta en otra divisa'
    },
    {
      code: '11',
      description: 'Indomiciliado'
    },
    {
      code: '08',
      description: 'Baja por oficina'
    },
    {
      code: '10',
      description: 'Domiciliación dada de baja'
    },
    {
      code: '09',
      description: 'El importe actual supera al importe original o viceversa'
    },
    {
      code: '14',
      description: 'Banco no válido o no participante'
    },
    {
      code: '15',
      description: 'Fuera de horario'
    },
    {
      code: '33',
      description: 'Supera el límite (también hace referencia a la fecha de transferencia no autorizada)'
    },
    {
      code: '46',
      description: 'Cuenta del receptor inválida'
    },
    {
      code: '6',
      description: 'Cuenta no pertenece al banco receptor'
    },
    {
      code: '7',
      description: 'Transaccion duplicada'
    },
    {
      code: '13',
      description: 'Cliente desconoce el cargo'
    },
    {
      code: '99',
      description: 'Verificación de cuentas'
    }
  ];
  amountApplied = 0;
  amountNotApplied = 0;

  constructor( private collectionService: CollectionService,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.getYears();
    this.forma = this.formBuilder.group({
      teachers: ['All'],
      type: ['All'],
      // tslint:disable-next-line: max-line-length
      startDate: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`],
      // tslint:disable-next-line: max-line-length
      endDate: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`]
    });
  }

  getYears(): void {
    for (
      let index = new Date().getFullYear() - 5;
      index < new Date().getFullYear() + 5;
      index++
    ) {
      this.years.push(index);
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date(new Date().getFullYear(), 0, 15);
    do {
      if (date >= new Date()) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while (findingFortnight === true);
    return ( numberFortnight.toString().length === 1 ) ? `0${ numberFortnight.toString() }` : numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if (date.getDate() === 15) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
    } else {
      date.setDate(date.getDate() + 14);
    }
  }

  loadData(): void {
    if ( this.isValidDate(this.forma.controls['startDate'].value) && this.forma.controls['startDate'].value.length === 10 ) {
      if ( this.isValidDate(this.forma.controls['endDate'].value) && this.forma.controls['endDate'].value.length === 10 ) {
        this.loadingData = true;
        const period: PeriodInterface = {
          start: this.forma.controls['startDate'].value,
          end: this.forma.controls['endDate'].value
        };
        this.collectionService.getCollectionDetails(
          this.forma.controls['teachers'].value,
          this.forma.controls['type'].value,
          period ).subscribe(data => {
            this.dataReport = data;
            this.amountApplied = this.dataReport
              .filter(d => d.applied === true)
              .reduce((sum, current) => sum + current.amount, 0);
            this.amountNotApplied = this.dataReport
              .filter(d => d.applied === false)
              .reduce((sum, current) => sum + current.amount, 0);
            this.loadingData = false;
        },
          (error: any) => this.setErrorMessage(error)
        );
      }
    }
  }

  setErrorMessage(error) {
    this.errorMessage = error;
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  getDescriptionError(code: string): string {
    const bankError = this.bankErrors.find( e => e.code === code);
    if ( bankError ) {
      return bankError.description;
    }
    return '';
  }

  getExcel(): void {
    console.log( this.dataReport );
    const data = this.dataReport.map(i => ({
      'RFC': i.rfc,
      'Nombre': i.name,
      'Codigo': i.code,
      'Fecha': i.movementDate,
      'Quincena': i.fortnight,
      'Aplicado': ( i.applied === true ) ? i.amount : 0,
      'No Aplicado': ( i.applied === false ) ? i.amount : 0,
      'Tipo': i.type,
      'Mensaje': `${ this.getDescriptionError( i.code ) } ${ i.message }`
    }));

    this.excelService.exportAsExcelFile(data,
      `Cobranza_Detalle`
    );
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

}
