<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/administration/supports', searchValue]" routerLinkActive="router-link-active">Administración</a></li>
            <li class="breadcrumb-item active" aria-current="page">Detalle</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Apoyos <small>Detalle</small></h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>
    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card card-block animated fadeIn fast">
        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <h2>{{ support.teacher.name }} <small>({{ support.teacher.rfc }})</small></h2>
            </div>
            <hr />
        </div>

        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Parentesco</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.relationship">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="form-group">
                    <label class="control-label">CURP</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.curp">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                    <label class="control-label">Nombre</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.name">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="form-group">
                    <label class="control-label">Forma de pago</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.wayToPay">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="form-group">
                    <label class="control-label">Fecha</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.movementDate | date:'dd/MMM/yyyy'">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Edad</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.age">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="form-group">
                    <label class="control-label">Ocupación</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.occupation">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Estatus</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.status">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                    <label class="control-label">Comentarios</label>
                    <input type="text" class="form-control underlined" readonly [value]="support.comment">
                </div>
            </div>
        </div>
    </div>

    <div class="form-group ">
        <a class="btn btn-outline-info " [routerLink]="['/administration/supports', searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
    </div>

</div>