<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/banks/affiliations']" routerLinkActive="router-link-active">Bancos</a></li>
            <li class="breadcrumb-item active" aria-current="page">Aplicar</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Maestros <small>Aplicar</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">
        <div class="row form-group">
            <div class="col-12">
                <div class="custom-file">
                    <input #fileUpload type="file" (change)="fileChangeEvent($event)" class="custom-file-input" id="customFileLang" lang="es" accept=".txt">
                    <label class="custom-file-label" for="customFileLang">Seleccionar Archivo...</label>
                </div>
            </div>
        </div>

        <div *ngIf="selectedFileNames.length > 0" class="row form-group">
            <div class="col-12">
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let fileName of selectedFileNames; let i = index">
                        {{fileName}}
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="uploadResult" class="row form-group">
            <div class="col-12" [innerHtml]="uploadResult">
            </div>
        </div>

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="isLoadingData || selectedFileNames.length === 0" type="button" class="btn btn-outline-success" (click)="upload()">
                    <i [ngClass]="{ 'far fa-save': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                    <span *ngIf="!isLoadingData">Procesar</span>
                    <span *ngIf="isLoadingData" >
                        Procesando...
                    </span>
                </button>
                <button type="button" class="btn btn-outline-danger" (click)="reserForm()"><i class="fas fa-broom"></i> Limpiar</button>
            </div>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

        <table class="table table-striped table-hover" *ngIf="data.length > 0">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>CURP</th>
                    <th>Código</th>
                    <th>Estatus</th>
                    <th *ngIf="showError">Error</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data; let i = index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.curp }}</td>
                    <td>{{ item.code }}</td>
                    <td>
                        <b [ngClass]="{ 'success': item.valid, 'required': !item.valid }">
                            <i [ngClass]="{ 'fas fa-trash-alt': !item.valid, 'fas fa-check': item.valid }" ></i> {{ item.status }}
                        </b>
                    </td>
                    <td *ngIf="showError">
                        <b [ngClass]="{ 'success': item.success, 'required': !item.success }">
                            {{ item.message }}
                        </b>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</article>