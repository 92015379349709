<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Configuración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Permisos</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <form [formGroup]="forma">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="form-group ">
                    <label class="control-label "> Perfil</label>
                    <select class="custom-select" name="profileId" formControlName="profileId" (change)="changeProfile( $event )">
                      <option value="00000000-0000-0000-0000-000000000000" >Seleccionar...</option>
                      <option [value]="profile.id " *ngFor="let profile of profiles ">{{ profile.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div *ngIf="loadingData" class="alert alert-warning" role="alert">
            <i class="fas fa-sync-alt fa-spin"></i> Obteniendo información...
        </div>

        <div formArrayName="applications" class="row animated fadeIn fast" *ngIf="forma.controls['profileId'].value !== '00000000-0000-0000-0000-000000000000'">
            <div class="items-list-page col-xs-12 col-sm-12 col-md-4 col-lg-4" *ngFor="let module of modules; let i = index">
                <div class="card items">
                    <ul class="item-list striped">
                        <li class="item item-list-header">
                            <div class="row">
                                <div class="item-col item-col-header item-col-item">
                                    <div class="no-overflow">
                                        <span>{{ module.name }}</span>
                                    </div>
                                </div>
                                <div class="item-col item-col-header fixed item-col-actions-dropdown"> </div>
                            </div>
                        </li>
                        <li class="item" *ngFor="let application of forma.get('applications').controls; let i=index">
                            <div class="item-row" [formGroupName]="i" *ngIf="application.controls.moduleId.value===module.id">
                                <div class="item-col item-col-item">
                                    <div class="item-heading">{{ module.name }}</div>
                                    <div>
                                        <span>{{ application.controls.name.value }}</span>
                                    </div>
                                </div>

                                <div class="item-col fixed item-col-actions-dropdown">
                                    <div class="item-list-actions text-center">
                                        <label>
                                            <input (change)="setPermission( $event.target.checked, application.controls.id.value )" type="checkbox" [name]="'haveAccess' + i" id="'haveAccess' + i" [checked]="haveAccess" formControlName="haveAccess">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </form>

    <div class="alert alert-danger" role="alert" *ngIf="message">
        {{ message }}
    </div>

</div>