import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SavingInterface } from './interfeces/saving.interface';
import { SavingService } from './services/saving.service';
import { TeacherService } from '../teachers/services/teacher.service';
import { TeacherInterface } from '../teachers/interface/teacher.interface';
import { TeacherStatusInterface } from '../../catalogs/teacher-status/interfaces/teacher-status.interface';
import { SavingsInterface } from './interfeces/savings.interface';
import { SavingDTOInterface } from './interfeces/savingDTO.interface';

@Component({
  selector: 'app-saving',
  templateUrl: './saving.component.html',
  styles: []
})
export class SavingComponent implements OnInit {
  title = 'Agregar';
  teacherName = '';
  pathReturn = '../../';
  forma: UntypedFormGroup;
  message: string;
  gettingData = false;
  sendingData = false;
  searchValue = '';
  amount = 0;
  years: number[] = [];
  savings: SavingsInterface = {
    savings: []
  };
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  saving: SavingInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    teacherId: '',
    year: new Date().getFullYear(),
    lastUpdated : new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    fortnight1: 0,
    fortnight2: 0,
    fortnight3: 0,
    fortnight4: 0,
    fortnight5: 0,
    fortnight6: 0,
    fortnight7: 0,
    fortnight8: 0,
    fortnight9: 0,
    fortnight10: 0,
    fortnight11: 0,
    fortnight12: 0,
    fortnight13: 0,
    fortnight14: 0,
    fortnight15: 0,
    fortnight16: 0,
    fortnight17: 0,
    fortnight18: 0,
    fortnight19: 0,
    fortnight20: 0,
    fortnight21: 0,
    fortnight22: 0,
    fortnight23: 0,
    fortnight24: 0,
    folio: '',
    type: 'Aportación',
    movementDate : `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-
        ${ this.formatDate(new Date().getDate()) }`,
    teacherStatusId: '00000000-0000-0000-0000-000000000000',
    teacherStatus: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date,
      active: true,
      name: 'Activo',
      percentageSaving: 0,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000'
    },
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      lastUpdated: new Date(),
      userIdLastUpdated: this.accountService.getUserId(),
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      lastStatus: 'Activo',
      email: '',
      admission: '',
      birthdate: '',
      applied: true,
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      document: 0,
      documentNumber: '',
      NumberOfLoans: 0,
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      supports: [],
      lowStaffing: []
    }
  };

  constructor( public accountService: AccountService, private formBuilder: UntypedFormBuilder,
    private router: Router, private activatedRoute: ActivatedRoute,
    private savingService: SavingService, private teacherService: TeacherService ) { }

  ngOnInit() {
    this.gettingData = true;
    this.buildForm();
    this.getYears();

    this.activatedRoute.params.subscribe(params => {
      if ( params['pathReturn'] ) {
        this.pathReturn = params['pathReturn'];
        console.log(this.pathReturn);
      }

      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.title = 'Editar';
        this.LoadSaving(params['id']);
      } else if (params['teacher']) {
        this.saving.teacherId = params['teacher'];
        this.teacherService.getById(params['teacher'])
          .subscribe(teacher => {
            this.teacherName = teacher.name;
            this.saving.teacherStatusId = this.findStatusActive(teacher.teacherStatus);
            this.saving.teacher = teacher;
            // this.forma.setValue(this.saving);
            this.gettingData = false;
          }, error => this.setErrorMessage( error ) );
      }
    });
  }

  findStatusActive( status: TeacherStatusInterface[] ): string {
    let statusId = '';
    status.forEach(item => {
      if ( item.active === true ) {
        statusId = item.id;
      }
    });
    return statusId;
  }

  LoadSaving(id: string): void {
    this.savingService.getById(id)
      .subscribe(data => {
        this.teacherName = data.teacher.name;
        this.saving = data;
        this.saving.movementDate = data.movementDate.substring(0, 10);
        this.forma.setValue(this.saving);
        this.calculateAmount();
        this.gettingData = false;
      }, error => this.setErrorMessage(error));
  }

  haveSavingThisYear( teacher: TeacherInterface ): boolean {
    const BreakException = { };
    try {
      teacher.savings.forEach(saving => {
        if ( saving.year === new Date().getFullYear() ) {
          this.LoadSaving(saving.id);
          throw BreakException;
        }
      });
      return false;
    } catch ( ex ) {
      if ( ex === BreakException ) {
        return true;
      }
    }
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      type: ['Aportación'],
      movementDate: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${
          this.formatDate(new Date().getDate()) }`, [Validators.required]],
      folio: ['', [Validators.required]],
      year: [new Date().getFullYear() , [Validators.required]],
      fortnight: ['', [Validators.required]],
      amount: ['0', [Validators.required, Validators.min(0)]],
      savings: this.formBuilder.array([]),
    });
  }

  setErrorMessage( error: any ) {
    if (error && error.error) {
      this.message = error.message;
      this.gettingData = false;
      this.sendingData = false;
    }
  }

  sendForm(): void {
    this.sendingData = true;
    /* this.saving = Object.assign({}, this.forma.value);
    this.saving.date = new Date(); */

    if ( this.title === 'Agregar' ) {
      this.savings.savings[0].type = this.forma.controls['type'].value;
      this.savings.savings[0].movementDate = this.forma.controls['movementDate'].value;
      this.savings.savings[0].folio = this.forma.controls['folio'].value;
      this.savings.savings[0].amount = this.amount;

      this.savingService.create( this.savings )
        .subscribe(success => {
          this.router.navigate(['administration/savings', this.searchValue]);
        }, error => this.setErrorMessage(error));
    } else {
      this.saving.userIdLastUpdated = this.accountService.getUserId();
      this.savingService.update( this.saving.id, this.saving )
      .subscribe(success => {
        this.router.navigate(['administration/savings', this.searchValue]);
      }, error => this.setErrorMessage(error));
    }
  }

  calculateAmount(): void {
    let amount = 0;
    for (let index = 1; index <= 24; index++) {
      amount += this.forma.controls['fortnight' + index].value;
    }
    this.amount = amount;
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  getYears(): void {
    for (let index = new Date().getFullYear() - 15; index < new Date().getFullYear() + 5; index++) {
      this.years.push(index);
    }
  }

  addSaving() {
    this.message = '';
      const savingsArr = this.forma.get('savings') as UntypedFormArray;

      if ( this.existYearFortnight( savingsArr ) === false ) {
        const beneficiariesFG = this.BuildSaving();
        savingsArr.push(beneficiariesFG);
        this.amount += this.forma.controls['amount'].value;

        if ( this.existYear( this.forma.controls['year'].value ) ) {
          this.updateFortnight();
        } else {
          this.addSavingList();
        }
        this.forma.controls['amount'].patchValue('0');
      } else {
        this.message = 'La quincena ya se encuentra registrada.';
      }
  }

  existYearFortnight(arrForm: UntypedFormArray): boolean {
    let result = false;

    for (let i = 0; i < arrForm.length; i++) {
      const element = arrForm.at(i) as UntypedFormGroup;
      if ( element.controls['year'].value === this.forma.controls['year'].value
        && element.controls['fortnight'].value === this.forma.controls['fortnight'].value) {
          result = true;
        }
    }

    return result;
  }

  BuildSaving() {
    return this.formBuilder.group({
      year: this.forma.controls['year'].value,
      fortnight: this.forma.controls['fortnight'].value,
      amount: this.forma.controls['amount'].value
    });
  }

  existYear(year: number): boolean {
    const result = this.savings.savings.filter(p => p.year === year);
    if ( result.length > 0 ) {
      return true;
    }
    return false;
  }

  updateFortnight(): void {

    this.savings.savings.forEach(pay => {
      if ( pay.year === this.forma.controls['year'].value ) {
        switch ( this.forma.controls['fortnight'].value ) {
          case '1':
              pay.fortnight1 = this.forma.controls['amount'].value;
            break;
          case '2':
              pay.fortnight2 = this.forma.controls['amount'].value;
            break;
          case '3':
              pay.fortnight3 = this.forma.controls['amount'].value;
            break;
          case '4':
              pay.fortnight4 = this.forma.controls['amount'].value;
            break;
          case '5':
              pay.fortnight5 = this.forma.controls['amount'].value;
            break;
          case '6':
              pay.fortnight6 = this.forma.controls['amount'].value;
            break;
          case '7':
              pay.fortnight7 = this.forma.controls['amount'].value;
            break;
          case '8':
              pay.fortnight8 = this.forma.controls['amount'].value;
            break;
          case '9':
              pay.fortnight9 = this.forma.controls['amount'].value;
            break;
          case '10':
              pay.fortnight10 = this.forma.controls['amount'].value;
            break;
          case '11':
              pay.fortnight11 = this.forma.controls['amount'].value;
            break;
          case '12':
              pay.fortnight12 = this.forma.controls['amount'].value;
            break;
          case '13':
              pay.fortnight13 = this.forma.controls['amount'].value;
            break;
          case '14':
              pay.fortnight14 = this.forma.controls['amount'].value;
            break;
          case '15':
              pay.fortnight15 = this.forma.controls['amount'].value;
            break;
          case '16':
              pay.fortnight16 = this.forma.controls['amount'].value;
            break;
          case '17':
              pay.fortnight17 = this.forma.controls['amount'].value;
            break;
          case '18':
              pay.fortnight18 = this.forma.controls['amount'].value;
            break;
          case '19':
              pay.fortnight19 = this.forma.controls['amount'].value;
            break;
          case '20':
              pay.fortnight20 = this.forma.controls['amount'].value;
            break;
          case '21':
              pay.fortnight21 = this.forma.controls['amount'].value;
            break;
          case '22':
              pay.fortnight22 = this.forma.controls['amount'].value;
            break;
          case '23':
              pay.fortnight23 = this.forma.controls['amount'].value;
            break;
          case '24':
              pay.fortnight24 = this.forma.controls['amount'].value;
            break;
        }
      }
    });
  }

  addSavingList(): void {
    const saving: SavingDTOInterface = {
      userId: this.saving.userId,
      teacherId: this.saving.teacherId,
      teacherStatusId: this.saving.teacherStatusId,
      type: this.forma.controls['type'].value,
      movementDate: this.forma.controls['movementDate'].value,
      folio: this.forma.controls['folio'].value,
      residue: 0,
      amount: 0,
      year: this.forma.controls['year'].value,
      fortnight1: 0,
      fortnight2: 0,
      fortnight3: 0,
      fortnight4: 0,
      fortnight5: 0,
      fortnight6: 0,
      fortnight7: 0,
      fortnight8: 0,
      fortnight9: 0,
      fortnight10: 0,
      fortnight11: 0,
      fortnight12: 0,
      fortnight13: 0,
      fortnight14: 0,
      fortnight15: 0,
      fortnight16: 0,
      fortnight17: 0,
      fortnight18: 0,
      fortnight19: 0,
      fortnight20: 0,
      fortnight21: 0,
      fortnight22: 0,
      fortnight23: 0,
      fortnight24: 0
    };

    switch ( this.forma.controls['fortnight'].value ) {
      case '1':
        saving.fortnight1 = this.forma.controls['amount'].value;
        break;
      case '2':
        saving.fortnight2 = this.forma.controls['amount'].value;
        break;
      case '3':
        saving.fortnight3 = this.forma.controls['amount'].value;
        break;
      case '4':
        saving.fortnight4 = this.forma.controls['amount'].value;
        break;
      case '5':
        saving.fortnight5 = this.forma.controls['amount'].value;
        break;
      case '6':
        saving.fortnight6 = this.forma.controls['amount'].value;
        break;
      case '7':
        saving.fortnight7 = this.forma.controls['amount'].value;
        break;
      case '8':
        saving.fortnight8 = this.forma.controls['amount'].value;
        break;
      case '9':
        saving.fortnight9 = this.forma.controls['amount'].value;
        break;
      case '10':
        saving.fortnight10 = this.forma.controls['amount'].value;
        break;
      case '11':
        saving.fortnight11 = this.forma.controls['amount'].value;
        break;
      case '12':
        saving.fortnight12 = this.forma.controls['amount'].value;
        break;
      case '13':
        saving.fortnight13 = this.forma.controls['amount'].value;
        break;
      case '14':
        saving.fortnight14 = this.forma.controls['amount'].value;
        break;
      case '15':
        saving.fortnight15 = this.forma.controls['amount'].value;
        break;
      case '16':
        saving.fortnight16 = this.forma.controls['amount'].value;
        break;
      case '17':
        saving.fortnight17 = this.forma.controls['amount'].value;
        break;
      case '18':
        saving.fortnight18 = this.forma.controls['amount'].value;
        break;
      case '19':
        saving.fortnight19 = this.forma.controls['amount'].value;
        break;
      case '20':
        saving.fortnight20 = this.forma.controls['amount'].value;
        break;
      case '21':
        saving.fortnight21 = this.forma.controls['amount'].value;
        break;
      case '22':
        saving.fortnight22 = this.forma.controls['amount'].value;
        break;
      case '23':
        saving.fortnight23 = this.forma.controls['amount'].value;
        break;
      case '24':
        saving.fortnight24 = this.forma.controls['amount'].value;
        break;
    }

    this.savings.savings.push(saving);
  }

  deleteSaving(index: number) {
    this.message = '';
    const savingsArr = this.forma.get('savings') as UntypedFormArray;
    const savingDelete = savingsArr.at(index) as UntypedFormGroup;
    this.amount -= savingDelete.controls['amount'].value;
    savingsArr.removeAt(index);
  }

}
