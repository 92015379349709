<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Consultas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Cheques y Transferencias Prestamos <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small></h3>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button type="button" class="btn btn-outline-success" (click)="exportData()">
                        <i class="far fa-file-excel"></i> Exportar
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Prestamo, RFC, Nombre, # Cheque, Localidad, Estatus">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>

            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="input-box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Inicio</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                            <input type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Al</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                            <input type="date" name="fin" [value]="fin" [(ngModel)]="fin">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 row">
                <label class="col-xs-3 col-sm-3 col-md-3 col-lg-5">Forma de Pago</label>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-7">
                    <select class="custom-select" [(ngModel)]="typeSelected">
                      <option value="null" selected >TODOS</option>
                      <option value="Cheque" >Cheque</option>
                      <option value="Transferencia" >Transferencia</option>
                  </select>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                <button type="button" class="btn btn-outline-primary" (click)="searchData( )">
                    Buscar
                  </button>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header">
                        <div class="no-overflow">
                            <span>Prestamo</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Fecha</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>RFC</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span># Cheque</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span># Qnas</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Localidad</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Monto</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Qna Inicial</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Qna Final</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Descuento</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Interes</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Protección</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>IVA</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Estatus</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col item-col-item">
                        <div class="item-heading">Prestamo</div>
                        <div>
                            <span>{{ item.loanNumber }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Fecha</div>
                        <div>
                            <span>{{ item.acceptanceDate | date:'dd/MM/yyyy' }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">RFC</div>
                        <div> {{ item.rfc }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Nombre</div>
                        <div> {{ item.name }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading"># Cheque</div>
                        <div> {{ item.checkNumber }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading"># Qnas</div>
                        <div> {{ item.numberFortnight }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Localidad</div>
                        <div> {{ item.locality }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Monto</div>
                        <div> {{ item.amount | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Qna Inicial</div>
                        <div> {{ item.fortnightStart }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Qna Final</div>
                        <div> {{ item.fortnightEnd }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Descuento</div>
                        <div> {{ item.discount | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Interes</div>
                        <div> {{ item.interests | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Proteccón</div>
                        <div> {{ item.protectionPremium | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">IVA</div>
                        <div> {{ item.iva | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Estatus</div>
                        <div> {{ item.status }} </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>
</article>