<article class="content items-list-page">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">Reportes</li>
    </ol>
  </nav>

  <div class="title-search-block">
    <div class="title-block">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title">
            <h3 class="title">Defunciones</h3>
          </h3>
        </div>
        <div *ngIf="dataReport.length !== 0" class="col-md-6 text-right">
          <button type="button" class="btn btn-outline-info" (click)="getPDF()"><i class="far fa-file-pdf"></i>
            PDF</button>
        </div>
      </div>
    </div>
  </div>

  <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

  <app-loading-data *ngIf="loadingData"></app-loading-data>

  <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

  <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">

    <table *ngIf="dataReport.length > 0" class="table table-striped table-hover">
      <thead class="text-center">
          <tr>
              <th scope="col" colspan="7">ADHERENTE</th>
              <th scope="col" colspan="2" style="background: #1384968f">BENEFICIARIO</th>
            </tr>
        <tr>
          <th scope="col">Año</th>
          <th scope="col">#</th>
          <th scope="col">Monto Cheque Devolución</th>
          <th scope="col">Apoyo a Gastos Funerarios</th>
          <th scope="col">Devolución Ahorro</th>
          <th scope="col">Interes</th>
          <th scope="col">Adeudo Prestamo</th>
          <th scope="col" style="background: #1384968f">#</th>
          <th scope="col" style="background: #1384968f">Apoyo a Gastos Funerarios</th>
        </tr>
      </thead>

      <tbody>
        <tr class="text-right" *ngFor="let item of dataReport; let i = index">
            <td>{{ item.anio }}</td>
          <td>{{ item.defunciones }}</td>
          <td>{{ item.devolucion | currency }}</td>
          <td>{{ item.apoyo | currency }}</td>
          <td>{{ item.ahorro | currency }}</td>
          <td>{{ item.interes | currency }}</td>
          <td>{{ item.credit | currency }}</td>
          <td style="background: #1384968f" >{{ item.defunciones_Hijos }}</td>
          <td style="background: #1384968f">{{ item.apoyo_Hijos | currency }}</td>
        </tr>
      </tbody>
    </table>
  </div>

</article>