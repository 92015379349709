import { UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const validateCURPLength: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
    if (formGroup.controls['type'].value === '') {
        return {
            invalidCURP: true,
            message: 'Debe de seleccionar el tipo de maestro.'
        };
    }

    switch (formGroup.controls['type'].value) {
        case 'Básico':
            if ( formGroup.controls['curp'].value !== null && formGroup.controls['curp'].value.length > 0 && (formGroup.controls['curp'].value.length < 18 || formGroup.controls['curp'].value.length > 18) ) {
                return {
                    invalidCURP: true,
                    message: 'La longitud del campo debe ser de 18 caracteres.'
                }
            }
            return null;
        default:
            if ( formGroup.controls['curp'].value !== null && (formGroup.controls['curp'].value.length < 18 || formGroup.controls['curp'].value.length > 19) ) {
                return {
                    invalidCURP: true,
                    message: 'La longitud del campo debe ser de 18 o 19 caracteres.'
                }
            }
            return null;
    }
};
