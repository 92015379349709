import { Component } from '@angular/core';
import { ModulesService } from './services/modules.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styles: []
})
export class ModuleListComponent {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];

  constructor( private moduleService: ModulesService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService ) {
    this.activatedRoute.params
      .subscribe(params => {
        if (params['searchValue']) {
          this.searchValue = params['searchValue'];
          this.loadData(this.searchValue);
        } else {
          this.loadData('null');
        }
      });
  }

  loadData(value: string) {
    this.loadingData = true;
    this.moduleService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
      console.log(this.jsonArray);
      console.clear();
    },
    error => this.helperService.setErrorMessage(error));
  }

  deleteItem( id: string ) {
    this.moduleService.delete( id )
    .subscribe(() => {
      this.loadData('null');
      this.notificationService.toastConfirm('Se elimino correctamente el módulo');
    }
    , error => this.helperService.setErrorMessage(error));
  }

  searchModule() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregarModule() {
    const text = this.searchValue;
    this.router.navigate( ['configuration/modules/module', '', text] );
  }

  showConfirm( id: string, name: string ) {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar el módulo ${ name }?`, 'Eliminar Módulo')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(id);
      }
    });
  }

}
