import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { SupportService } from './services/support.service';
import { SupportTypes } from '../../../../app/enums/administration.enum';
declare let jsPDF;

@Component({
  selector: 'app-supports-pending',
  templateUrl: './supports-pending.component.html',
  styles: []
})
export class SupportsPendingComponent implements OnInit {
  loadingData = false;
  applications: ApplicationInterface[];
  searchValue = '';
  errorMessage = '';
  jsonArray: any[] = [];
  sendingData = false;

  constructor( private supportService: SupportService,
    public accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router ) {
    }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'supports')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if ( params.searchValue ) {
            this.searchValue = params.searchValue;
          }
          this.loadData();
        });
      },
      error => this.setErrorMessage(error));
  }

  loadData() {
    this.supportService.getPending( )
    .subscribe(data => {
      this.jsonArray = data;
      this.selectAll();
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    if (error && error.error) {
      this.errorMessage = error.message;
      this.loadingData = false;
    }
  }

  getExcel(): void {
    this.generateDocument(this.jsonArray.filter(l => l.active === true));
  }

  validData(): boolean {
    return this.jsonArray.some(j => j.active === true);
  }

  apply(): void {
    this.sendingData = true;
    //this.generateDocument(this.jsonArray);
    this.supportService.apply( this.accountService.getUserId(), this.jsonArray.filter(l => l.active === true) )
      .subscribe(response => {
        console.log(response);
        this.generateDocument(response);
        this.router.navigate( ['/administration/supports', this.searchValue] );
    }, err => this.setErrorMessage(err));
  }

  handlerChecbox(checked: boolean, item: any): void {
    item.active = checked;
  }

  generateDocument(data: any[]): void {
    const doc = new jsPDF('portrait');
    // doc.addPage('letter', 'portrait');
    doc.setFont('arial');
    doc.setFontSize(9);
    let contador = 0;
    let phone = '';
    data.forEach(element => {
      if ( contador > 0 ) {
        doc.addPage();
      }

      if ( element.teacher.teacherPhones.length !== 0 ) {
        phone = element.teacher.teacherPhones[0].phone;
      } else {
        phone = '';
      }
      const amount = element.amount;
      doc.text(`${ this.formatDate(new Date().getDate()) }/${ this.formatDate(new Date().getMonth()  + 1) }/${ new Date().getFullYear() }`,
      doc.internal.pageSize.width - 70, 23);
      // doc.setFont('arial');
      doc.setFontSize(9);
      doc.setFontStyle('bold');
      if(element.type === SupportTypes.DefuncionAdherente) {
        doc.text(element.name.toUpperCase(), 45, 35);
      } else {
        doc.text(element.teacher.name.toUpperCase(), 45, 35);
      }
      doc.text(`${ this.numberFormat(amount.toString(), 2) }`, 175 , 35);
      doc.text(this.numberByLetter(amount), 30, 50);
      doc.text(`${ this.leftPad(element.checkNumber, 7, '0') }`, 90, 148);
      doc.setFontSize(14);
      doc.text('NO NEGOCIABLE', 10, 80);
      // doc.setFont('arial');
      doc.setFontSize(9);
      doc.setFontStyle('normal');
      doc.text(`${ this.numberFormat(amount.toString(), 2) }`, 150 , 168);

      if(element.type === SupportTypes.DefuncionAdherente) {
        if(element.comment.indexOf('Ahorro') > -1) {
          doc.text('DEVOLUCIÓN DE AHORRO FOCAPS POR DEFUNCIÓN DE', 50, 168);
          doc.text(`${ element.teacher.name.toUpperCase() }`, 50, 173);
        } else if(element.comment.indexOf('Apoyo') > -1) {
          doc.text('PAGO DE APOYO A GASTOS FUNERARIOS', 50, 168);
          doc.text(`${ element.teacher.name.toUpperCase() }`, 50, 173);
        }
      } else {
        doc.text('PAGO DE APOYO A GASTOS FUNERARIOS', 50, 168);
        if ( element.relationship === null) {
          doc.text(`${ element.name.toUpperCase() }`, 50, 173);
        } else {
          doc.text(`${ element.relationship.toUpperCase() } ${ element.name.toUpperCase() }`, 50, 173);
        }
      }

      doc.text('DATOS PERSONALES: ', 10, 245);
      if(element.type === SupportTypes.DefuncionAdherente) {
        doc.text(`NOMBRE: ${ element.name.toUpperCase() }`, 10, 250);
        doc.text(`RFC: ${ element.curp.toUpperCase() }`, 10, 255);
      } else {
        doc.text(`NOMBRE: ${ element.teacher.name.toUpperCase() }`, 10, 250);
        doc.text(`RFC: ${ element.teacher.rfc.toUpperCase() }`, 10, 255);
      }
      doc.text(`TEL: ${ phone }`, 10, 260);
      doc.text(`REGIÓN: ${ element.teacher.region.name.toUpperCase() }`, 10, 265);
      contador++;
    });

    doc.save(`Apoyos.pdf`);
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  numberFormat( amount: any, decimals: number ): string {
    amount += '';
    amount = parseFloat(amount.replace(/[^0-9\.]/g, ''));
    decimals =  decimals || 0;
    if ( isNaN(amount) || amount === 0 ) {
      return parseFloat('0').toFixed(decimals);
    }

    amount = '' + amount.toFixed(decimals);

    const amountParts = amount.split('.'), regexp = /(\d+)(\d{3})/;
    while (regexp.test(amountParts[0])) {
    amountParts[0] = amountParts[0].replace(regexp, '$1' + ',' + '$2');
    }

    return amountParts.join('.');
  }

  numberByLetter(amount: number): string {
    const num2Let = require('numalet')();
    const number2letter = num2Let(amount).toString().substring(0, );
    const letter = number2letter.toString().substring(0, number2letter.indexOf(' MXN'));
    const arrQuantity = amount.toString().split('.');
    if ( arrQuantity.length > 1 ) {
      return `${ letter } PESOS ${ arrQuantity[1] }/100 M.N.`;
    }
    return `${ letter } PESOS 00/100 M.N.`;
  }

  leftPad(str: string, len: number, ch= '.'): string {
    len = len - str.length + 1;
    return len > 0 ?
      new Array(len).join(ch) + str : str;
  }

  handlerSelectAll( value: boolean): void {
    this.jsonArray.forEach(element => {
      element.active = value
    });
  }

  selectAll( ): void {
    this.jsonArray.forEach(element => {
      element.active = true;
    });
  }

}
