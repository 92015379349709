import { Component, OnInit } from '@angular/core';
import { LoanService } from '../../../administration/loans/services/loan.service';
import { AccountService } from '../../../account/login/services/account.service';
import { ApplicationInterface } from '../../../configuration/applications/interface/application.interface';
import { NotificationService } from '../../../../../app/services/notification.service';
import { HelperService } from '../../../../../app/services/helper.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styles: []
})
export class QuestionsComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray: any[] = [];
  applications: ApplicationInterface[];
  applicationsTeachers: ApplicationInterface[];

  constructor( private loanService: LoanService,
    public accountService: AccountService,
    private notificationService: NotificationService,
    private helperService: HelperService ) { }

  ngOnInit(  ) {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'loans')
      .subscribe(applications => {
        this.applications = applications;
        this.accountService.getApplications('administration', 'teachers')
        .subscribe(applicationsTeachers => {
          this.applicationsTeachers = applicationsTeachers;
          this.loadData(this.searchValue);
        }, error => this.helperService.setErrorMessage(error));
      }, error => this.helperService.setErrorMessage(error));
  }

  loadData(value: string) {
    if ( value === '' ) {
      value = 'null';
    }

    this.loanService.getQuestions(value)
      .subscribe(data => {
        this.jsonArray = data;
        this.loadingData = false;
      },
    error => this.helperService.setErrorMessage(error));
  }

  search() {
    this.loadingData = true;
    this.loadData( this.searchValue );
  }

  showConfirm( teacherId: string ) {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar el interesado?`, 'Eliminar interesado')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(teacherId);
      }
    });
  }

  deleteItem( teacherId: string ) {
    this.loanService.deleteQuestion( teacherId )
      .subscribe(() => {
        this.loadData(this.searchValue);
        this.notificationService.toastConfirm('Se elimino correctamente el interesado')
      },
    error => this.helperService.setErrorMessage(error));
  }

}
