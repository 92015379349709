<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Maestros</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">{{ title }}</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['rfc'].valid && forma.controls['rfc'].touched, 'has-success': forma.controls['rfc'].valid && forma.controls['rfc'].touched }">
                        <label class="control-label">Número</label>
                        <input type="text" class="form-control underlined" readonly="true" formControlName="identifier">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Tipo</label>
                    <select *ngIf="title !== 'Editar'" class="custom-select" formControlName="type" (change)="handlerType( $event.target.value )">
                        <option value="Alta Jubilación">Alta Jubilación</option>
                        <option value="Básico">Básico</option>
                        <option value="Media Superior">Media Superior</option>
                    </select>

                    <select *ngIf="title === 'Editar'" disabled class="custom-select" formControlName="type" (change)="handlerType( $event.target.value )">
                        <option value="Alta Jubilación">Alta Jubilación</option>
                        <option value="Básico">Básico</option>
                        <option value="Media Superior">Media Superior</option>
                        <option value="Reingreso" >Reingreso</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['rfc'].valid && forma.controls['rfc'].touched, 'has-success': forma.controls['rfc'].valid && forma.controls['rfc'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required" *ngIf="forma.controls['type'].value === 'Básico' || forma.controls['type'].value === 'Reingreso'" ></i> RFC</label>
                        <input type="text" class="form-control underlined" maxlength="13" formControlName="rfc">
                        <span *ngIf="forma.controls['rfc'].touched && forma.controls['rfc'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['rfc'].touched && forma.controls['rfc'].errors?.minlength" cwlass="has-error ">
                            El campo debe tener al menos {{ forma.controls['rfc'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': forma.errors?.invalidCURP && forma.controls['curp'].touched, 'has-success': forma.controls['curp'].valid && forma.controls['curp'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required" *ngIf="forma.controls['type'].value !== 'Básico' && forma.controls['type'].value !== '' && forma.controls['type'].value !== 'Reingreso'"></i> CURP</label>
                        <input type="text" class="form-control underlined" formControlName="curp">
                        <span *ngIf="forma.controls['curp'].touched && forma.errors?.invalidCURP" class="has-error ">
                            {{ forma.errors?.message }}
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['name'].valid && forma.controls['name'].touched, 'has-success': forma.controls['name'].valid && forma.controls['name'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Nombre</label>
                        <input type="text" class="form-control underlined" maxlength="250" formControlName="name">
                        <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.minlength" class="has-error">
                            El campo debe tener al menos {{ forma.controls['name'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>
            </div>


            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Sexo</label>
                    <select class="custom-select" formControlName="gender">
                                <option value="Masculino">Masculino</option>
                                <option value="Femenino">Femenino</option>
                            </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['birthdate'].valid && forma.controls['birthdate'].touched, 'has-success': forma.controls['birthdate'].valid && forma.controls['birthdate'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Nacimiento</label>
                        <input type="date" class="form-control underlined" formControlName="birthdate">
                        <span *ngIf="forma.controls['birthdate'].touched && forma.controls['birthdate'].errors?.required" class="has-error ">
                                        El campo es requerido.
                                    </span>
                        <span *ngIf="forma.controls['birthdate'].touched && forma.controls['birthdate'].errors?.minlength" class="has-error">
                                        El campo debe tener al menos {{ forma.controls['birthdate'].errors.minlength.requiredLength }}
                                        caracteres.
                                    </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['street'].valid && forma.controls['street'].touched, 'has-success': forma.controls['street'].valid && forma.controls['street'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Calle</label>
                        <input type="text" class="form-control underlined" maxlength="150" formControlName="street">
                        <span *ngIf="forma.controls['street'].touched && forma.controls['street'].errors?.required" class="has-error">
                                        El campo es requerido.
                                    </span>
                        <span *ngIf="forma.controls['street'].touched && forma.controls['street'].errors?.minlength" class="has-error">
                                        El campo debe tener al menos. {{ forma.controls['street'].errors.minlength.requiredLength }}
                                        caracteres.
                                    </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['number'].valid && forma.controls['number'].touched, 'has-success': forma.controls['number'].valid && forma.controls['number'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Número</label>
                        <input type="text" maxlength="10" class="form-control underlined" formControlName="number">
                        <span *ngIf="forma.controls['number'].touched && forma.controls['number'].errors?.required" class="has-error ">
                                            El campo es requerido.
                                        </span>
                        <span *ngIf="forma.controls['number'].touched && forma.controls['number'].errors?.minlength" class="has-error ">
                                            El campo debe tener al menos. {{ forma.controls['number'].errors.minlength.requiredLength }}
                                            caracteres.
                                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['colony'].valid && forma.controls['colony'].touched, 'has-success': forma.controls['colony'].valid && forma.controls['colony'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Colonia</label>
                        <input type="text" maxlength="150" class="form-control underlined" formControlName="colony">
                        <span *ngIf="forma.controls['colony'].touched && forma.controls['colony'].errors?.required" class="has-error">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['colony'].touched && forma.controls['colony'].errors?.minlength" class="has-error">
                            El campo debe tener al menos {{ forma.controls['colony'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['zipCode'].valid && forma.controls['zipCode'].touched, 'has-success': forma.controls['zipCode'].valid && forma.controls['zipCode'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Código Postal</label>
                        <input type="text" mask="00000" class="form-control underlined" formControlName="zipCode">
                        <span *ngIf="forma.controls['zipCode'].touched && forma.controls['zipCode'].errors?.required" class="has-error">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['zipCode'].touched && forma.controls['zipCode'].errors?.minlength" class="has-error">
                            El campo debe tener al menos {{ forma.controls['zipCode'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                        <span *ngIf="forma.controls['zipCode'].touched && forma.controls['zipCode'].errors?.maxlength" class="has-error">
                            El campo debe tener máximo {{ forma.controls['zipCode'].errors.maxlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['admission'].valid && forma.controls['admission'].touched, 'has-success': forma.controls['admission'].valid && forma.controls['admission'].touched }">
                        <label class="control-label">Ingreso</label>
                        <input type="date" class="form-control underlined" formControlName="admission">
                        <span *ngIf="forma.controls['admission'].touched && forma.controls['admission'].errors?.required" class="has-error ">
                                            El campo es requerido.
                            </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['email'].valid && forma.controls['email'].touched, 'has-success': forma.controls['email'].valid && forma.controls['email'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Email</label>
                        <input type="text" maxlength="50" class="form-control underlined" formControlName="email">
                        <span *ngIf="forma.controls['email'].touched && forma.controls['email'].errors?.required" class="has-error">
                                        El campo es requerido.
                                    </span>
                        <span *ngIf="forma.controls['email'].touched && forma.controls['email'].errors?.email" class="has-error">
                                            Formato invalido.
                                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Aportación</label>
                    <input type="text" *ngIf="title === 'Editar'" class="form-control underlined" readonly="true" [value]="teacher.contribution?.percentage + ' %'">
                    <select *ngIf="title === 'Agregar'" class="custom-select" formControlName="contributionId">
                        <option value="">Seleccionar...</option>
                        <option [value]="contribution.id" *ngFor="let contribution of contributions ">{{ contribution.percentage }}
                            %</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <label class="control-label row">
                        <i class="fas fa-asterisk required"></i> Quincena
                        <span *ngIf="title === 'Editar'" style="padding-left:10px">
                             <a class="primary" (click)="showChangeStatus(  )" href="" data-toggle="modal" >
                                <i style="font-size: 20px" class="fas fa-exchange-alt"></i>
                            </a>
                        </span>
                    </label>
                    <input type="text" *ngIf="title === 'Editar'" class="form-control underlined" readonly="true" [value]="teacher.fortnight">
                    <select *ngIf="title === 'Agregar'" class="custom-select" formControlName="fortnight">
                        <option value="">Seleccionar...</option>
                        <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Región</label>
                    <select class="custom-select" formControlName="regionId">
                        <option value="">Seleccionar...</option>
                        <option [value]="region.id" *ngFor="let region of regions ">{{ region.name }}</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['locality'].valid && forma.controls['locality'].touched, 'has-success': forma.controls['locality'].valid && forma.controls['locality'].touched }">
                        <label class="control-label">Localidad</label>
                        <input type="text" maxlength="50" class="form-control underlined" formControlName="locality">
                        <span *ngIf="forma.controls['locality'].touched && forma.controls['locality'].errors?.required" class="has-error">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['locality'].touched && forma.controls['locality'].errors?.minlength" class="has-error">
                            El campo nombre debe tener {{ forma.controls['locality'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group" *ngIf="showBankInformation">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Banco</label>
                    <select class="custom-select" formControlName="bankId">
                                <option value="">Seleccionar...</option>
                                <option [value]="bank.id" *ngFor="let bank of banks ">{{ bank.name }}</option>
                            </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['clabe'].valid && forma.controls['clabe'].touched, 'has-success': forma.controls['clabe'].valid && forma.controls['clabe'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> CLABE</label>
                        <input type="text" maxlength="18" class="form-control underlined" formControlName="clabe">
                        <span *ngIf="forma.controls['clabe'].touched && forma.controls['clabe'].errors?.required" class="has-error">
                                        El campo es requerido.
                                    </span>
                        <span *ngIf="forma.controls['clabe'].touched && forma.controls['clabe'].errors?.minlength" class="has-error">
                                        El campo nombre debe tener {{ forma.controls['CLABE'].errors.minlength.requiredLength }}
                                        caracteres.
                                    </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Identificación</label>
                    <select class="custom-select" formControlName="document">
                                <option value="">Seleccionar...</option>
                                <option value="1">IFE</option>
                                <option value="2">Pasaporte</option>
                                <option value="3">Otro</option>
                            </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['documentNumber'].valid && forma.controls['documentNumber'].touched, 'has-success': forma.controls['documentNumber'].valid && forma.controls['documentNumber'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Número</label>
                        <input type="text" maxlength="18" class="form-control underlined" formControlName="documentNumber">
                        <span *ngIf="forma.controls['documentNumber'].touched && forma.controls['documentNumber'].errors?.required" class="has-error">
                                        El campo es requerido.
                                    </span>
                        <span *ngIf="forma.controls['documentNumber'].touched && forma.controls['documentNumber'].errors?.minlength" class="has-error">
                                        El campo nombre debe tener {{ forma.controls['documentNumber'].errors.minlength.requiredLength }}
                                        caracteres.
                                    </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['amountSavingsFortnight'].valid && forma.controls['amountSavingsFortnight'].touched, 'has-success': forma.controls['amountSavingsFortnight'].valid && forma.controls['amountSavingsFortnight'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Importe Ahorro</label>
                        <input type="number" class="form-control underlined" formControlName="amountSavingsFortnight">
                        <span *ngIf="forma.controls['amountSavingsFortnight'].touched && forma.controls['amountSavingsFortnight'].errors?.required" class="has-error">
                            El campo es requerido.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <a class="btn btn-outline-secondary" (click)="addPhone()">
                        <i class="far fa-plus-square"></i> Agregar <small>Teléfono</small>
                    </a>

                    <div formArrayName="teacherPhones">
                        <div *ngFor="let phoneType of forma.get('teacherPhones')['controls']; let i =index;">
                            <div [formGroupName]="i">
                                <div class="row" *ngIf="phoneType.get('active').value">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <label class="control-label">Tipo</label>
                                        <select class="custom-select" name="phoneTypeId" formControlName="phoneTypeId">
                                            <option value="">Seleccionar...</option>
                                            <option [value]="type.id" *ngFor="let type of phoneTypes ">{{ type.name }}</option>
                                        </select>
                                    </div>

                                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                        <div class="form-group">
                                            <label class="control-label">Teléfono <small>(614)111-22-44</small> </label>
                                            <input type="text" mask="(000)000-00-00" class="form-control underlined" name="phone" formControlName="phone">
                                        </div>
                                    </div>

                                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <button type="button" class="btn btn-outline-danger" (click)="deletePhone( i )">
                                            <i class="far fa-trash-alt"></i> Eliminar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="form-group">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <a class="btn btn-outline-secondary" (click)="addBeneficiary()">
                                <i class="far fa-plus-square"></i> Agregar <small>Beneficiario</small>
                            </a>
                        </div>
                    </div>

                    <div formArrayName="beneficiaries" *ngFor="let beneficiary of forma.get('beneficiaries')['controls']; let i = index">
                        <div [formGroupName]="i">
                            <div class="row" *ngIf="beneficiary.get('active').value">
                                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                                    <div class="form-group">
                                        <label class="control-label">Nombre </label>
                                        <input type="text" maxlength="250" class="form-control underlined" name="name" formControlName="name">
                                    </div>
                                </div>

                                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <button type="button" class="btn btn-outline-danger" (click)="deleteBeneficiary( i )">
                                        <i class="far fa-trash-alt"></i> Eliminar
                                    </button>
                                </div>
                            </div>
                            <div class="row" *ngIf="beneficiary.get('active').value">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <label class="control-label">Parentesco</label>
                                    <select class="custom-select" name="relationshipId" formControlName="relationshipId">
                                        <option value="">Seleccionar...</option>
                                        <option [value]="relationship.id" *ngFor="let relationship of relationships ">{{ relationship.name }}</option>
                                    </select>
                                </div>

                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label">Porcentaje</label>
                                        <input type="text" suffix=" %" mask="percent.2" class="form-control underlined" name="percentage" formControlName="percentage">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group text-center ">
                <button [disabled]="!validData() || sendData "  class="btn " [ngClass]="{ 'btn-outline-success': title=='Agregar' , 'btn-outline-warning': title=='Editar' } ">
                    <i [ngClass]="{ 'far fa-save': !sendData, 'fas fa-sync-alt fa-spin': sendData } "></i>
                    <span *ngIf="!sendData "> Guardar</span>
                    <span *ngIf="sendData "> Guardando...</span>
                </button>
            </div>
        </form>

        <div class="form-group ">
            <a class="btn btn-outline-info " [routerLink]="[returnPath, searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>

</div>
