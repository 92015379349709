import { Component, OnInit } from '@angular/core';
import { PaymentPolicyService } from './services/payment-policy.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ExcelService } from '../../../services/excel.service';
import { PaymentPolicyInterface } from './interfaces/payment-policy.interface';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-payment-policy',
  templateUrl: './payment-policy.component.html',
  styles: []
})
export class PaymentPolicyComponent implements OnInit {
  forma: UntypedFormGroup;
  errorMessage = '';
  isLoadingData = false;
  dataReport: any[] = [];
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;

  constructor( private paymentService: PaymentPolicyService,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService,
    private helperService: HelperService ) { }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()]
    });
    this.paymentService.getYears()
    .subscribe(years => {
      this.years = years;
    }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.isLoadingData = false;
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  getExcel(): void {
    this.isLoadingData = true;
    this.paymentService.getSeechData( this.forma.controls['year'].value, this.forma.controls['fortnight'].value)
    .subscribe(data => {
      console.log('data', data);
      if ( data.length > 0 ) {
        this.dataReport = this.clearData(data);
        this.excelService.exportAsExcelFileWithoutHeader(this.dataReport,`Poliza_SEECH_`);
      }
      this.isLoadingData = false;
    }, error => this.setErrorMessage(error));
  }

  getExcelData(): void {
    this.isLoadingData = true;
    this.paymentService.getSeechData( this.forma.controls['year'].value, this.forma.controls['fortnight'].value)
    .subscribe(data => {
      if ( data.length > 0 ) {
        this.dataReport = this.clearData(data);
        this.excelService.exportAsExcelFile(data, `Datos_${ this.forma.controls['source'].value }_`);
      }
      this.isLoadingData = false;
    }, error => this.setErrorMessage(error));
  }

  clearData(data: any[]): any[] {
    let capitalTotal = 0;
    let interestTotal = 0;
    let primaTotal = 0;
    const input = data.reduce((sum, current) => sum + current.fortnight, 0);
    const fortnight = (this.forma.controls['fortnight'].value.length === 1 ) ? `0${ this.forma.controls['fortnight'].value }` : this.forma.controls['fortnight'].value;
    const array = [];

    this.addEmptyItemToArray( array, 2 );
    array.push( this.createItem( 'Tr', '0',
    `CREDITOS ORDINARIOS Y EXTRAORDINARIOS ${ this.forma.controls['year'].value }-${ fortnight }`,
    '15', '', '', '' ) );

    array.push( this.createItem( '', '1170-021-000',
    `0`,
    `APORTACION AL FONDO ${ this.forma.controls['year'].value }-${ fortnight }`, '', input.toFixed(2), '' ) );

    data.forEach(element => {
      element.capital = 0;
      element.interest = 0;
      element.prima = 0;
      let amountFortnight = parseFloat( element.fortnight );

      while (amountFortnight > 0) {
        const capital = this.getValueFortnight( amountFortnight, parseFloat( element.fortnightCapital ) );
        element.capital += capital;
        capitalTotal += capital;
        amountFortnight -= capital;
        if( amountFortnight > 0 ) {
          const interest = this.getValueFortnight( amountFortnight, parseFloat( element.fortnightInterest ) + parseFloat( element.fortnightIVA ) );
          element.interest += interest
          interestTotal += interest;
          amountFortnight -= interest;
        }

        if( amountFortnight > 0 ) {
          const prima = this.getValueFortnight( amountFortnight, parseFloat( element.fortnightLifeInsurance ) );
          element.prima += prima;
          primaTotal += prima;
          amountFortnight -= prima;
        }
      }

      array.push(
        this.createItem( '',
          '1150-001-000',
          '0',
          `${ element.rfc }-${ element.name }`,
          '',
          '',
          element.capital
          )
        );
    });

    array.push(
      this.createItem( '',
        '4100-001-000',
        '0',
        'INTERESES SOBRE PRESTAMOS/INTERES GANADO',
        '',
        '',
        interestTotal.toFixed(2)
        )
      );

    array.push(
      this.createItem( '',
        '2140-001-000',
        '0',
        'INTERESES POR DEVENGAR',
        '',
        interestTotal.toFixed(2),
        ''
        )
      );
    array.push(
      this.createItem( '',
        '1160-002-000',
        '0',
        'INTERESES POR COBRAR',
        '',
        '',
        interestTotal.toFixed(2)
        )
      );

    array.push(
      this.createItem( '',
        '4100-002-003',
        '0',
        'PRIMA DE PROTECCION AL AHORRO COBRADA',
        '',
        '',
        primaTotal.toFixed(2)
        )
      );
    array.push(
      this.createItem( '',
        '2140-002-000',
        '0',
        'PROVISION PRIMA DE PROTECCION AL AHORRO',
        '',
        primaTotal.toFixed(2),
        ''
        )
      );
    array.push(
      this.createItem( '',
        '1160-001-000',
        '0',
        'PROVISION PRIMA DE PROTECCION AL AHORRO',
        '',
        '',
        primaTotal.toFixed(2)
        )
      );

    return array;
  }

  getValueFortnight(
    amountFortnight: number,
    fortnightvalue: number,
  ): number {
    if( amountFortnight >= fortnightvalue ) {
      amountFortnight -= fortnightvalue;
      return parseFloat( fortnightvalue.toFixed(2) );
    } else {
      return amountFortnight;
    }
  }

  addEmptyItemToArray(data: any[], numberItems: number): any {
    for (let index = 0; index < numberItems; index++) {
      data.push(this.createItem('', '', '', '', '', '', ''));
    }
  }

  createItem(col: string, cuenta: string, depto: string, concepto: string, col2: string, abono: string, cargo: string ): any {
    return {
        col: col,
        cuenta: cuenta,
        depto: depto,
        concepto: concepto,
        col2: col2,
        abono: abono,
        cargo: cargo,
      };
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
