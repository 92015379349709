import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PeriodInterface } from '../../../../interfaces/period.interface';

@Injectable()
export class MoneyOutPolicyService {
  private apiURL = 'api/policies/MoneyOut';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    getData( period: PeriodInterface ) {
      return this.http.post<any>(this.apiURL, period);
    }

}
