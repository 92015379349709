import { Component, OnInit } from '@angular/core';
import { AffilationItem } from './interfaces/affilation-item';
import { AffiliationService } from './services/affiliation.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-affiliations-apply',
  templateUrl: './affiliations-apply.component.html',
  styles: []
})
export class AffiliationsApplyComponent implements OnInit {
  working = false;
  errorMessage: string;
  isLoadingData = false;
  selectedFileNames: string[] = [];
  uploadResult: any;
  filesToUpload: Array<File>;
  data: Array<AffilationItem> = new Array<AffilationItem>();
  showError = false;

  constructor(private affiliationService: AffiliationService,
    public accountService: AccountService,
    private router: Router) { }

  ngOnInit() {
  }

  apply() {

  }

  fileChangeEvent(fileInput: any) {
    this.errorMessage = '';
    this.uploadResult = '';
    this.filesToUpload = <Array<File>>fileInput.target.files;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      if (!this.validFile(this.filesToUpload[i].name)) {
        this.errorMessage = `Solo puede procesar archivos con la extencion .txt`;
      } else {
        this.selectedFileNames = [];
        this.selectedFileNames.push(this.filesToUpload[i].name);
        const reader = new FileReader();
        reader.onloadend = () => this.onLoad(reader.result.toString().split('\n'));
        reader.readAsText(this.filesToUpload[i]);
      }
    }
  }

  upload() {
    if (this.filesToUpload.length > 1) {
      alert('Por favor solo seleccione un máximo de 1 archivo.');
    } else {
      this.isLoadingData = true;
      this.affiliationService.apply(this.accountService.getUserId(), this.data.filter(d => d.valid === true))
      .subscribe( response => {
        console.log(response);
        if ( response.length > 0 ) {
          this.reserForm();
          this.data = response;
          this.showError = true;
        } else {
          this.router.navigate(['banks/affiliations']);
        }
      }, error => this.errorMessage = error.error);
    }
  }

  onLoad(lines: Array<string>) {
    this.data = [];
    lines.forEach(line => {
      if ( line.startsWith('D') ) {
        const reverseLine = line.split('').reverse().join('');
        const code = reverseLine.substring(reverseLine.indexOf('A') + 1, reverseLine.indexOf('A') + 3);
        let valid = true;
        let status = 'Valido';

        if ( code !== '22' ) {
          if ( code !== '00' ) {
            valid = false;
            status = 'Invalido';
          }
        }

        this.data.push({
          curp: line.substring(2, line.indexOf('   ')),
          name: line.substring(60, 99).trim(),
          code: code,
          valid: valid,
          status: status,
          message: '',
          amount: 0,
          success: true,
          fileName: '',
          movementDate: new Date(),
          type: '',
          fortnight: ''
        });
      }
    });
  }

  functionmostrarContenido( content: any) {
    this.errorMessage = `${ content }`;
  }

  validFile( fileName: string ): boolean {
    if ( fileName.slice(-3).toUpperCase() === 'TXT') {
      return true;
    } else {
      return false;
    }
  }

  reserForm() {
    this.filesToUpload = [];
    this.selectedFileNames = [];
    this.data = [];
    this.uploadResult = '';
    this.errorMessage = '';
    this.isLoadingData = false;
    this.showError = false;
  }
}
