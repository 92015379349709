import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { DataUpdateService } from './service/data-update.service';
import { ExcelService } from '../../../services/excel.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-data-update-list',
  templateUrl: './data-update-list.component.html',
  styles: []
})
export class DataUpdateListComponent implements OnInit {
  sendingMails = false;
  currentPage = 1;
  canSendMails = false;
  countSendMails = 0;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  errorMessage = '';
  applications: Array<ApplicationInterface>;

  constructor( public accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private dataUpdateService: DataUpdateService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.activatedRoute.params.subscribe(params => {
      if ( params['searchValue'] ) {
        this.searchValue = params['searchValue'];
      }

      this.accountService.getApplications('administration', 'data-update')
        .subscribe(applications => {
          this.applications = applications;
          this.loadData();
        }, error => this.setErrorMessage(error));
    }, error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    this.errorMessage = error.message;
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  search(): void {
    this.loadingData = true;
    this.loadData();
  }

  loadData(): void {
    this.dataUpdateService.get(this.searchValue).subscribe( data => {
      this.jsonArray = data;
      this.canSendMails = this.jsonArray.some( j => j.active === true && j.sendMail === false );
      this.countSendMails = this.jsonArray.filter( j => j.active === true && j.sendMail === false ).length;
      this.loadingData = false;
    }, error => this.setErrorMessage(error));
  }

  sendMails(): void {
    this.sendingMails = true;
    this.dataUpdateService.sendMails().subscribe( response => {
      this.canSendMails = false;
      this.countSendMails = 0;
      this.sendingMails = false;
    }, error => this.setErrorMessage(error));
  }

  exportData(): void {
    const data = this.jsonArray.map(j => ({
      numero: j.employeeNumber,
      rfc: j.rfc,
      curp: j.curp,
      paterno: j.lastName,
      materno: j.motherLastName,
      nombre: j.name,
      telefono: j.phone,
      valido: (j.active) ? 'SI' : 'NO'
    }));
    this.excelService.exportAsExcelFile(data,
      `Actualizacion_Datos`);
  }

}
