import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { DeadlineInterface } from '../interfaces/deadline.interface';

@Injectable()
export class DeadlineService {
  private apiURL = 'api/Administration/Deadlines';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  create(deadline: DeadlineInterface): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', deadline);
  }

  get(searchValue: string) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  getFortnights( ) {
    return this.http.get<any>(this.apiURL + '/Fortnights');
  }

  getPrefixes( ) {
    return this.http.get<any>(this.apiURL + '/Prefixes');
  }

  getById(id: string) {
    return this.http.get<any>(this.apiURL + '/' + id);
  }

  update(id: string, user: DeadlineInterface) {
    return this.http.put<any>(this.apiURL + '/' + id, user);
  }

  delete(id: string, userId: string, active: boolean) {
    return this.http.delete<any>( `${ this.apiURL }/${ id }/${ userId }/${ active }`);
  }
}
