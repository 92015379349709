import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentInterface } from '../interfaces/payment.interface';
import { Observable } from 'rxjs/Observable';
import { PaymentsInterface } from '../interfaces/payments.interface';
import { PeriodInterface } from '../../../../interfaces/period.interface';
import { WayToPayUpdateInterface } from '../../way-to-pay/interfaces/way-to-pay-update.interface';
import { PaymentDTOInterface } from '../interfaces/paymentDTO.interface';

@Injectable()
export class PaymentService {
  private apiURL = 'api/Administration/Payments';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( payments: Array<PaymentDTOInterface> ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', payments);
    }

    createPayment( payments: PaymentsInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/CreatePayment', payments);
    }

    get( searchValue: string, year: number ) {
      return this.http.get<any>(this.apiURL + '/Search/' + year + '/' + searchValue);
    }

    getYears() {
      return this.http.get<any>(this.apiURL + '/Years');
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    getLiquidationsPayments( period: PeriodInterface ) {
      return this.http.post<any>(`${ this.apiURL }/GetLiquidationsPayments`, period);
    }

    getPaymentsByFolio( folio: string ) {
      return this.http.get<any>(`${ this.apiURL }/PaymentsByFolio/${ folio }`);
    }

    putLiquidationsPayments( wayToPay: WayToPayUpdateInterface ) {
      return this.http.put<any>(`${ this.apiURL }/WayToPay`, wayToPay);
    }

    delete( paymentId: string ) {
      return this.http.delete<any>(`${ this.apiURL }/${ paymentId }`);
    }

    putPayment( paymentId: string, payment: PaymentInterface ) {
      return this.http.put<any>(`${ this.apiURL }/${ paymentId }`, payment);
    }
}
