<aside class="sidebar">
    <div class="sidebar-container">
        <div class="sidebar-header">
            <div class="brand">
                <div class="logo">
                    <img [src]="configurations.imageFOCAPS" alt="FOCAPS" class="img-thumbnail">
                </div> Administración
            </div>
        </div>

        <nav class="menu">
            <ul class="sidebar-menu metismenu" id="sidebar-menu">
                <li routerLinkActive="active">
                    <a [routerLink]="['/home']">
                        <i class="fas fa-home"></i> Inicio
                    </a>
                </li>
                <li *ngIf="loadingData">
                    <a>
                        <i class="fas fa-sync-alt fa-spin"></i> Cargando Menú
                    </a>
                </li>
                <li routerLinkActive="active" *ngFor="let item of menu">
                    <a href="" aria-expanded="false">
                        <i class="{{ item.icon }}"></i> {{ item.name }}
                        <i class="fas fa-angle-right"></i>
                    </a>
                    <ul routerLinkActive="active" class="sidebar-nav">
                        <li routerLinkActive="active" *ngFor="let application of sortApplications( item.applications )">
                            <a [routerLink]="[application.path]"> {{ application.name }} </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</aside>