<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Polizas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Domiciliaciones</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group ">
                        <label class="control-label ">Fecha Inicio</label>
                        <input type="date" class="custom-select" formControlName="startDate" />
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group ">
                        <label class="control-label ">Fecha Fin</label>
                        <input type="date" class="custom-select" formControlName="endDate" />
                    </div>
                </div>
            </div>
        </form>

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="sendingData" type="button" class="btn btn-outline-success" (click)="getExcel()">
                  <i [ngClass]="{ 'far fa-file-excel': !sendingData, 'fas fa-sync-alt fa-spin': sendingData }"></i>
                  <span *ngIf="!sendingData"> Generar</span>
                  <span *ngIf="sendingData" > Procesando...</span>
              </button>
            </div>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
    </div>
</article>