import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AccountingPaymentService {
  private apiURL = 'api/Accounting/Payments';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  get(year: number, fortnight: number) {
    return this.http.get<any>(this.apiURL + '/' + year + '/' + fortnight);
  }

  getYears() {
    return this.http.get<any>(this.apiURL);
  }
}
