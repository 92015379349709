import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ModulesInterface } from './interfaces/modules.interface';
import { ModulesService } from './services/modules.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styles: []
})

export class ModuleComponent {
  titulo = 'Agregar';
  returnPath = '../../../';
  forma: UntypedFormGroup;
  message: string;
  loadingData = false;
  sendData = false;
  searchValue = '';
  module: ModulesInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    name: '',
    icon: '',
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    applications: []
  };

  constructor( private modulesService: ModulesService, public accountService: AccountService,
    private router: Router, private activatedRoute: ActivatedRoute ) {
    this.loadingData = true;
    this.forma = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      userId: new UntypedFormControl(''),
      date: new UntypedFormControl(''),
      active: new UntypedFormControl(''),
      name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      icon: new UntypedFormControl(''),
      applications: new UntypedFormControl(''),
      lastUpdated: new UntypedFormControl(''),
      userIdLastUpdated: new UntypedFormControl('')
    });

    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.titulo = 'Editar';
        this.module.id = params['id'];
        this.modulesService.getById(params['id']).subscribe(
          data => {
            this.returnPath = '../../';
            this.module = Object.assign({}, data);
            this.forma.setValue(this.module);
          },
          error => this.errorMessage(error)
        );
      } else {
        this.forma.setValue(this.module);
      }
      this.loadingData = false;
    });
  }

  sendForm() {
    this.sendData = true;
    this.module = Object.assign({}, this.forma.value);
    this.module.date = new Date();
    if (this.titulo === 'Agregar') {
      this.modulesService
        .create(this.module)
        .subscribe(
          success =>
            this.router.navigate(['/configuration/modules', this.searchValue]),
          error => this.errorMessage(error)
        );
    } else {
      this.module.userIdLastUpdated = this.accountService.getUserId();
      this.modulesService
        .update(this.module.id, this.module)
        .subscribe(
          success =>
            this.router.navigate(['/configuration/modules', this.searchValue]),
          error => this.errorMessage(error)
        );
    }
  }

  errorMessage(error) {
    console.clear();
    console.log(error);
    if (error && error.error) {
      this.message = error.error;
      this.sendData = false;
    }
  }
}
