import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExcelService } from '../../../services/excel.service';
import { TeacherService } from '../../administration/teachers/services/teacher.service';

@Component({
  selector: 'app-employment-fortnight',
  templateUrl: './employment-fortnight.component.html',
  styles: []
})
export class EmploymentFortnightComponent implements OnInit {
  errorMessage = '';
  dataReport: any[] = [];
  forma: UntypedFormGroup;
  years: number[] = [];
  // tslint:disable-next-line:max-line-length
  fortnights: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
  loadingData = false;

  constructor( private teacherService: TeacherService,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
    });
    this.loadingData = true;
    this.teacherService.getYears()
        .subscribe(years => {
          this.years = years;
          this.loadData();
        }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  searchInterest() {
    this.loadingData = true;
    this.loadData();
  }

  loadData( ) {
    this.teacherService.getEmploymentFortnight( `${ this.forma.controls['year'].value }-${ this.forma.controls['fortnight'].value }` )
      .subscribe(data => {
        this.dataReport = data;
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  getExcel(): void {
    this.loadingData = true;
    this.excelService.exportAsExcelFileWithoutHeader(this.clearData(),
      `Altas_${ this.forma.controls['year'].value }_${ this.forma.controls['fortnight'].value }`);
    this.loadingData = false;
  }

  clearData(): any[] {
    const array = [];

    array.push(
      this.createItem( 'RFC',
        'CURP',
        'Nombre',
        'Estatus',
        'Tipo',
        'Región',
        'Porcentage',
        'Movimiento'
      ));

    this.dataReport.forEach(element => {
      array.push(
        this.createItem( element.rfc,
          element.curp,
          element.name,
          element.lastStatus,
          element.type,
          element.region.name,
          element.contribution.percentage,
          ( element.contributionPercentages.length === 1 ) ? 'Alta' : 'Cambio'
        ));
    });
    return array;
  }

  // tslint:disable-next-line:max-line-length
  createItem(rfc: string, curp: string, name: string, status: string, type: string, region: string, percentage: string, movement: string ): any {
    return {
        rfc,
        curp,
        name,
        status,
        type,
        region,
        percentage,
        movement
      };
  }

}
