import { Component, OnInit } from '@angular/core';
import { ExcelService } from '../../../services/excel.service';
import { LowStaffService } from '../../administration/low-staffing/services/low-staff.service';

@Component({
  selector: 'app-lowstaffing-policy',
  templateUrl: './lowstaffing-policy.component.html',
  styles: []
})
export class LowstaffingPolicyComponent implements OnInit {
  selectedItems = 0;
  loadingData = false;
  processing = false;
  errorMessage = '';
  tipo = 'Cheque';
  filtro = '1';
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  jsonArray: any[] = [];
  allData: any[] = [];
  exporting = false;

  constructor( private lowstaffService: LowStaffService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.loadData( );
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  loadData( ) {
    this.lowstaffService.getPolicy( this.inicio, this.fin, this.tipo, this.filtro )
    .subscribe(data => {
      this.allData = data;
      this.jsonArray = data.filter((item, index, self) =>
        index === self.findIndex((p) =>
            p.rfc === item.rfc && p.name === item.name && p.checkNumber === item.checkNumber && p.lastUpdated === item.lastUpdated && 
            p.amount === item.amount && p.type === item.type && p.amountSavings === item.amountSavings && p.amountInterests === item.amountInterests &&
            p.interest5Percentage === item.interest5Percentage && p.percentage === item.percentage
        )
      );
      this.clearSelection();
      this.selectAll();
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.loadingData = false;
    }
  }

  search() {
    this.loadingData = true;
    if( this.filtro === '1' ) {
      if ( !this.isValidDate(this.inicio) && this.inicio.length === 10 ) {
        this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
        this.loadingData = false;
      }

      if ( !this.isValidDate(this.fin) && this.fin.length === 10 ) {
        this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
        this.loadingData = false;
        return;
      }
    } else {
      if( this.inicio === '' || this.fin === '' ) {
        this.errorMessage = 'Debe de seleccionar un periodo de busqueda';
        this.loadingData = false;
        return;
      }
    }

    this.loadData( );
  }

  exportData() {
    this.processing = true;
    const toDownload = this.jsonArray.filter(j => j.active === true);
    const policies = [];
    this.addEmptyItemToArray(policies, 2);
    
    toDownload.forEach(item => {
      const loans = this.allData.filter(x => x.id === item.id);

      policies.push(
        this.createItem('Tr',
          item.checkNumber,
          `${item.type}-${item.name}`,
          `${item.lastUpdated.toString().substring(8, 10)}`,
          '0',
          '',
          ''
        )
      );

      policies.push(
        this.createItem('',
          '2110-001-000',
          '0',
          'AHORRO',
          '',
          item.amountSavings,
          ''
        )
      );
        
      policies.push(
        this.createItem('',
          '2110-002-000',
          '0',
          'INTERESES DEL AHORRO',
          '',
          item.interest5Percentage,
          ''
        )
      );

      if(item.amountInterests !== item.interest5Percentage) {
        policies.push(
          this.createItem('',
            '7100-004-001',
            '0',
            'PRODUCTOS FINANCIEROS',
            '',
            '',
            (parseFloat(item.interest5Percentage) - parseFloat(item.amountInterests)).toFixed(2)
          )
        );
      }

      loans.forEach(loan => {
        const totalInterest = parseFloat(loan.interest) + parseFloat(loan.interestCondone);
        const totalLifeinsurance = parseFloat(loan.lifeInsurance) + parseFloat(loan.lifeInsuranceCondone);
        if(parseFloat(loan.capital) > 0) {
          policies.push(
            this.createItem('',
              '1150-001-000',
              '0',
              `CREDITOS ORDINARIOS Y EXTRAORDINARIOS ${loan.loanNumber}`,
              '',
              '',
              loan.capital
            )
          );
        }

        if(parseFloat(loan.interestCondone) === 0) {
          policies.push(
            this.createItem('',
              '4100-001-000',
              '0',
              'INTERESES GANADOS POR PRESTMOS',
              '',
              '',
              totalInterest.toFixed(2)
            )
          );
        }

        policies.push(
          this.createItem('',
            '2140-001-000',
            '0',
            `INTERESES SOBRE PRESTAMOS`,
            '',
            totalInterest.toFixed(2),
            ''
          )
        );

        policies.push(
          this.createItem('',
            '1160-002-000',
            '0',
            `INTERESES POR COBRAR A PRESTAMOS DE MAES`,
            '',
            '',
            totalInterest.toFixed(2)
          )
        );

        if(totalLifeinsurance > 0) {
          policies.push(
            this.createItem('',
              '2140-002-000',
              '0',
              `PRIMA DE PROTECCION AL AHORRO`,
              '',
              totalLifeinsurance.toFixed(2),
              ''
            )
          );
        }

        if(totalLifeinsurance > 0) {
          policies.push(
            this.createItem('',
              '1160-002-000',
              '0',
              `PRIMA DE PROTECCION AL AHORRO`,
              '',
              '',
              totalLifeinsurance.toFixed(2)
            )
          );
        }
      });

      policies.push(
        this.createItem('',
          '1120-008-000',
          '0',
          'BANCO',
          '',
          '',
          item.amount)
      );

      policies.push(
        this.createItem('',
          'FIN_PARTIDAS',
          '',
          '',
          '',
          '',
          '')
      );
    });
    
    this.excelService.exportAsExcelFileWithoutHeader(policies, `Poliza_Bajas`);
    this.errorMessage = '';
    this.processing = false;









      // const amountSavings = Math.round((lowstaffing.amountSavings * (lowstaffing.percentage / 100)) * 100) / 100;
      // var amountCredit = lowstaffing.amountCredit;
      // var amountCreditLifeInsurance = lowstaffing.amountCreditLifeInsurance;
      // var amountCreditInterest = lowstaffing.amountCreditInterest;
      // var amountSavingInterest = Math.round((lowstaffing.interest5Percentage * (lowstaffing.percentage / 100)) * 100) / 100;

      // if(lowstaffing.type === 'Defunción') {
      //   amountCredit = Math.round((lowstaffing.amountCredit * (lowstaffing.percentage / 100)) * 100) / 100;
      //   amountCreditLifeInsurance = Math.round((lowstaffing.amountCreditLifeInsurance * (lowstaffing.percentage / 100)) * 100) / 100;
      //   amountCreditInterest = (Math.round((lowstaffing.amountCreditInterest * (lowstaffing.percentage / 100)) * 100) / 100) - amountCreditLifeInsurance;
      // }
      // policies.push(
      //   this.createItem('Tr',
      //     lowstaffing.checkNumber,
      //     `${lowstaffing.type}-${lowstaffing.name}`,
      //     `${lowstaffing.lastUpdated.toString().substring(8, 10)}`,
      //     '0',
      //     '',
      //     '')
      // );

      // if (lowstaffing.type === 'Gastos Funerarios') {
      //   policies.push(
      //     this.createItem('',
      //       '6200-001-000',
      //       '0',
      //       'GASTOS DE OPERACIÓN',
      //       '',
      //       lowstaffing.amount,
      //       '')
      //   );
      // } else {
      //   const financialProduct = parseFloat(lowstaffing.interest5Percentage) - parseFloat(lowstaffing.amountInterests);
      //   policies.push(
      //     this.createItem('',
      //       '2110-001-000',
      //       '0',
      //       'AHORRO',
      //       '',
      //       amountSavings.toString(),
      //       '')
      //   );
        
      //   policies.push(
      //     this.createItem('',
      //       '2110-002-000',
      //       '0',
      //       'INTERESES DEL AHORRO',
      //       '',
      //       amountSavingInterest.toFixed(2).toString(),
      //       '')
      //   );

      //   if (lowstaffing.type !== 'Defunción' && lowstaffing.type !== 'Jubilación') {
      //     policies.push(
      //       this.createItem('',
      //         '7100-004-001',
      //         '0',
      //         'PRODUCTOS FINANCIEROS',
      //         '',
      //         '',
      //         financialProduct.toFixed(2).toString())
      //     );
      //   }

      //   if (amountCredit > 0) {
      //     if (lowstaffing.type === 'Defunción') {
      //       policies.push(
      //         this.createItem('',
      //           '6200-004-000',
      //           '0',
      //           'CREDITO A MAESTROS INCOBRABLES',
      //           '',
      //           amountCredit,
      //           '')
      //       );
      //     }
          
      //     policies.push(
      //       this.createItem('',
      //         '1150-001-000',
      //         '0',
      //         'CREDITOS ORDINARIOS Y EXTRAORDINARIOS',
      //         '',
      //         '',
      //         amountCredit)
      //     );

      //     if (lowstaffing.type !== 'Defunción'  && lowstaffing.type !== 'Jubilación' && lowstaffing.type !== 'Renuncia' && lowstaffing.type !== 'Baja Voluntaria') {
      //       policies.push(
      //         this.createItem('',
      //           '4100-001-000',
      //           '0',
      //           'INTERESES GANADOS POR PRESTAMOS',
      //           '',
      //           '',
      //           amountCreditInterest.toString())
      //       );
      //     }
      //     policies.push(
      //       this.createItem('',
      //         '2140-001-000',
      //         '0',
      //         'INTERESES SOBRE PRESTAMOS',
      //         '',
      //         amountCreditInterest.toString(),
      //         '')
      //     );
      //     policies.push(
      //       this.createItem('',
      //         '1160-002-000',
      //         '0',
      //         'INTERESES POR COBRAR A PRESTAMOS DE MAES',
      //         '',
      //         '',
      //         amountCreditInterest.toString())
      //     );
      //     policies.push(
      //       this.createItem('',
      //         '2140-002-000',
      //         '0',
      //         'PRIMA DE PROTECCION AL AHORRO',
      //         '',
      //         amountCreditLifeInsurance,
      //         '')
      //     );
      //     policies.push(
      //       this.createItem('',
      //         '1160-001-000',
      //         '0',
      //         'PRIMA DE PROTECCION AL AHORRO',
      //         '',
      //         '',
      //         amountCreditLifeInsurance)
      //     );
      //   }
      // }
  }

  createItem(col: string, cuenta: string, depto: string, concepto: string, col2: string, cargo: string, abono: string ): any {
    return {
        col: col,
        cuenta: cuenta,
        depto: depto,
        concepto: concepto,
        col2: col2,
        cargo: cargo,
        abono: abono,
      };
  }

  addEmptyItemToArray(data: any[], numberItems: number): any {
    for (let index = 0; index < numberItems; index++) {
      data.push(this.createItem('', '', '', '', '', '', ''));
    }
  }

  setPermission(checked: boolean, item: any): void {
    this.errorMessage = '';
      if ( checked ) {
        item.active = true;
        this.selectedItems ++;
      } else {
        this.selectedItems --;
        item.active = true;
      }
  }

  clearSelection(): void {
    this.jsonArray.forEach(element => {
      element.active = false;
    });
    this.selectedItems = 0;
  }

  selectAll(): void {
    this.jsonArray.forEach(element => {
      element.active = true;
    });
    this.selectedItems = this.jsonArray.length;
  }

  getExcel(): void {
    this.exporting = true;
    const newData = this.jsonArray.map(d => ({
      Registro: `${ d.lastUpdated.substring(8, 10) }/${ d.lastUpdated.substring(5, 7) }/${ d.lastUpdated.substring(0, 4) }`,
      Aplicacion: `${ d.lastUpdated.substring(8, 10) }/${ d.lastUpdated.substring(5, 7) }/${ d.lastUpdated.substring(0, 4) }`,
      concepto: d.type,
      FormaPago: d.wayToPay,
      Beneficiario: d.beneficiary,
      Referencia: d.reference,
      Referencia2: d.checkNumber,
      Monto: d.amount
    }));
    this.excelService.exportAsExcelFile(newData,
      `Bajas_`);
    this.exporting = false;
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
