import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelService } from '../../../services/excel.service';
import { AccountService } from '../../account/login/services/account.service';
import { CollectionInterface } from '../../banks/collections/interfaces/collection.interface';
import { CollectionService } from '../../banks/collections/services/collection.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';

@Component({
  selector: 'app-collection-details-seech',
  templateUrl: './collection-details-seech.component.html',
  styles: []
})
export class CollectionDetailsSeechComponent implements OnInit {
  loadingData = false;
  processing = false;
  errorMessage = '';
  searchValue = '';
  exporting = false;
  applications: ApplicationInterface[] = [];
  collection: CollectionInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    fileName: '',
    movementDate: new Date(),
    accountingDate: new Date(),
    fortnight: '',
    type: '',
    payroll: '',
    origin: '',
    movementNumber: 0,
    appliedAmount: 0,
    notAppliedAmount: 0,
    collectionDetails: [],
    active: false,
    userIdLastUpdated: ''
  };

  constructor( private collectionService: CollectionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private excelService: ExcelService,
    public accountService: AccountService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.collectionService.getDetails( params['id'] ).subscribe(
          (data: CollectionInterface) => {
            this.collection = data;
            this.loadingData = false;
          },
          (error) => this.setErrorMessage(error)
        );
      } else {
        this.router.navigate(['/administration/collections-seech', this.searchValue]);
      }
    });
  }

  setErrorMessage(error) {
    this.errorMessage = error;
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  getExcel(): void {
    const newData = this.collection.collectionDetails.map(d => ({
      RFC: d.curp,
      Aplicado: ( d.applied ) ? d.amount : 0,
      NoAplicado: ( !d.applied ) ? d.amount : 0,
      Mensaje: d.message
    }));
    this.excelService.exportAsExcelFile(newData,
      `${ this.collection.fileName.substr(0, this.collection.fileName.indexOf('.xlsx')) }`);
  }

  handlerClick(): void {
    this.processing = true;
    this.collection.userIdLastUpdated = this.accountService.getUserId();
    this.collectionService.postReverse( this.collection )
    .then( response => {
      if ( response.isSuccess ) {
        this.router.navigate(['/administration/collections-seech', this.searchValue]);
      } else {
        this.errorMessage = response.message;
      }
      this.processing = false;
    }). catch( error => this.setErrorMessage(error) );
  }

  someValid(): boolean {
    return this.collection.collectionDetails.some(d => d.active === true);
  }

}
