<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3 class="title">
                        <h3 class="title">Prestamos Otorgados ({{ dataReport.length }})</h3>
                    </h3>
                </div>
                <!-- <div *ngIf="dataReport.length !== 0" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button type="button" class="btn btn-outline-info" (click)="getPDF()"><i class="far fa-file-pdf"></i>
                        PDF</button>
                </div> -->
                <div *ngIf="dataReport.length !== 0" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i class="far fa-file-excel"></i>
                        <span> Generar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group ">
                        <label class="control-label ">Fecha Inicio</label>
                        <input type="date" class="custom-select" formControlName="startDate" />
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group ">
                        <label class="control-label ">Fecha Fin</label>
                        <input type="date" class="custom-select" formControlName="endDate" />
                    </div>
                </div>

                <div class="col-3 text-center">
                    <button [disabled]="loadingData" type="button" class="btn btn-outline-primary" (click)="searchData()">
                      <i [ngClass]="{ 'fas fa-search': !loadingData, 'fas fa-sync-alt fa-spin': loadingData }"></i>
                      <span *ngIf="!loadingData"> Consultar</span>
                      <span *ngIf="loadingData" > Procesando...</span>
                  </button>
                </div>
            </div>
        </form>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
        <table *ngIf="dataReport.length > 0" class="table table-striped table-hover">
            <thead class="text-center">
              <tr>
                <th scope="col">Prestamo</th>
                <th scope="col">Monto Cheque</th>
                <th scope="col"># Qnas</th>
                <th scope="col">Descuento</th>
                <th scope="col"># Prestamos</th>
                <th scope="col">Capital</th>
                <th scope="col">Interes</th>
                <th scope="col">IVA</th>
                <th scope="col">Prima de Apertura</th>
                <th scope="col">Prima de Garantia</th>
                <th scope="col">Seguro de vida</th>
              </tr>
            </thead>
      
            <tbody>
              <tr class="text-right" *ngFor="let item of dataReport; let i = index">
                  <td>{{ item.amount | currency }}</td>
                <td>{{ item.import | currency }}</td>
                <td>{{ item.numberFortnight }}</td>
                <td>{{ item.discount | currency }}</td>
                <td>{{ item.loans }}</td>
                <td>{{ item.capital | currency }}</td>
                <td>{{ item.interest | currency }}</td>
                <td>{{ item.iva | currency }}</td>
                <td>{{ item.openingBonus | currency }}</td>
                <td>{{ item.warranty | currency }}</td>
                <td>{{ item.lifeinsurance | currency }}</td>
              </tr>

            <tr class="text-right">
                <td class="info" colspan="4">Total General</td>
                <td ><b>{{ numberLoans }}</b></td>
                <td><b>{{ amountCapital | currency }}</b></td>
                <td><b>{{ amountInteres | currency }}</b></td>
                <td><b>{{ amountIVA | currency }}</b></td>
                <td><b>{{ amountOpeningBonus | currency }}</b></td>
                <td><b>{{ amountWarranty | currency }}</b></td>
                <td><b>{{ amountLifeinsurance | currency }}</b></td>
            </tr>
            </tbody>
          </table>
    </div>
</article>