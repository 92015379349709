import { DataUpdateInterface } from './interfaces/data-update.interface';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { DataUpdateService } from './service/data-update.service';

@Component({
  selector: 'app-data-update-details',
  templateUrl: './data-update-details.component.html',
  styles: []
})
export class DataUpdateDetailsComponent implements OnInit {
  searchValue = '';
  errorMessage = '';
  loadingData = false;
  dataupdate: DataUpdateInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    employeeNumber: 0,
    rfc: '',
    curp: '',
    lastName: '',
    motherLastName: '',
    name: '',
    birthdate: new Date(),
    gender: '',
    email: '',
    phone: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    errorMessage: '',
    sendMail: false,
    movementDate: new Date(),
    beneficiaries: []
  };

  constructor( private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    private dataUpdateService: DataUpdateService,
    private router: Router ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if ( params['searchValue'] ) {
        this.searchValue = params['searchValue'];
      }

      if ( params[ 'id' ] ) {
        this.dataUpdateService.getById( params[ 'id' ] ).subscribe( data => {
          this.dataupdate = data;
          console.log(this.dataupdate);
        }, error => this.setErrorMessage(error));
      } else {
        this.router.navigate(['/administration/data-update', this.searchValue]);
      }
    }, error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    this.errorMessage = error;
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

}
