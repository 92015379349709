import { Component, OnInit } from '@angular/core';
import { SavingPolicyService } from '../savings/services/saving-policy.service';
import { ExcelService } from '../../../services/excel.service';
import { PeriodInterface } from '../../../interfaces/period.interface';

@Component({
  selector: 'app-inputs-policy',
  templateUrl: './inputs-policy.component.html',
  styles: []
})
export class InputsPolicyComponent implements OnInit {
  errorMessage = '';
  loadingData = false;
  dataReport: any[] = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  period: PeriodInterface = {
    start: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`,
    end: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`
  };

  constructor(private savingService: SavingPolicyService,
    private excelService: ExcelService) { }

  ngOnInit() {
    this.loadingData = true;
    this.loadData();
  }

  loadData() {
    this.errorMessage = '';
    if ( this.isValidDate(this.inicio) && this.inicio.length === 10 ) {
      if ( this.isValidDate(this.fin) && this.inicio.length === 10 ) {
        this.period.start = this.inicio;
        this.period.end = this.fin;
        this.savingService.getDataInputs( this.period )
          .subscribe( (data) => {
            this.dataReport = data;
            this.loadingData = false;
          }, error => this.setErrorMessage(error));
      } else {
        this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
      }
    } else {
      this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
    }
    this.loadingData = false;
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  exportData() {

    this.excelService.exportAsExcelFileWithoutHeader(this.clearData(this.dataReport),
      `Aportaciones_${ this.inicio }_${ this.fin }`);
  }

  clearData(data: any[]): any[] {
    const array = [];

    this.addEmptyItemToArray( array, 2 );

    data.forEach(element => {
      array.push(
        this.createItem( element.col,
          element.cuenta,
          element.depto,
          element.concepto,
          element.col2,
          (element.abono === 0) ? '' : element.abono,
          (element.cargo === 0) ? '' : element.cargo)
        );
    });
    return array;
  }

  addEmptyItemToArray(data: any[], numberItems: number): any {
    for (let index = 0; index < numberItems; index++) {
      data.unshift(this.createItem('', '', '', '', '', '', ''));
    }
  }

  createItem(col: string, cuenta: string, depto: string, concepto: string, col2: string, abono: string, cargo: string ): any {
    return {
        col: col,
        cuenta: cuenta,
        depto: depto,
        concepto: concepto,
        col2: col2,
        cargo: cargo,
        abono: abono,
      };
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
