import { Component, OnInit } from '@angular/core';
import { ConfigurationsInterface } from '../../configuration/configurations/interfaces/configurations.interface';
import { DeathsReportService } from './services/deaths-report.service';
import { AccountService } from '../../account/login/services/account.service';
import { ConfigurationsService } from '../../configuration/configurations/services/configurations.service';
import { CurrencyPipe } from '@angular/common';
declare let jsPDF;

@Component({
  selector: 'app-deaths',
  templateUrl: './deaths.component.html',
  styles: []
})
export class DeathsComponent implements OnInit {
  dataReport: any[] = [];
  errorMessage = '';
  loadingData = false;
  configurations: ConfigurationsInterface =  {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };

  constructor(private reportService: DeathsReportService, public accountService: AccountService,
    private currencyPipe: CurrencyPipe, private configurationsService: ConfigurationsService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.configurationsService.get()
      .subscribe(configurations => {
        this.configurations = configurations;
        this.loadData(0);
      }, err => this.setErrorMessage(err));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  loadData(value: number) {
    this.reportService.get(value)
      .subscribe((data) => {
        this.dataReport = data;
        console.log(data);
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  getPDF() {
    const totalPagesExp = '{total_pages_count_string}';
    const head = this.getHeader();
    const body = this.setBodyData(this.dataReport);
    this.generatePDF(head, body, totalPagesExp, this.configurations.imageSNTE, this.configurations.imageFOCAPS);
  }

  getHeader(): any[] {
    return [{ anio: 'Año', defunciones: '#', devolucion: 'Monto Cheque Devolución', apoyo: 'Apoyo a Gastos Funerarios', 
    ahorro: 'Devolución Ahorro', 
    interes: 'Interes', credit: 'Adeudo Prestamo', defunciones_Hijos: '#', apoyo_Hijos: 'poyo a Gastos Funerarios' }]
  }

  setBodyData(data: any[]): any[] {
    const body = [];
    data.forEach(item => {
      body.push({
        anio: item.anio,
        defunciones: item.defunciones,
        devolucion: this.currencyPipe.transform(item.devolucion),
        apoyo: this.currencyPipe.transform(item.apoyo),
        ahorro: this.currencyPipe.transform(item.ahorro),
        interes: this.currencyPipe.transform(item.interes),
        credit: this.currencyPipe.transform(item.credit),
        defunciones_Hijos: item.defunciones_Hijos,
        apoyo_Hijos: this.currencyPipe.transform(item.apoyo_Hijos)
      });
    });
    return body;
  }

  generatePDF(head: any[], body: any[], totalPagesExp: string, imageSNTE: string, imageFOCAPS: string): void {
    const doc = new jsPDF('landscape');
    const pageContent = function (data) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if ( imageSNTE ) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
     }

     if ( imageFOCAPS ) {
       doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45 , 15, 30, 20);
     }
      doc.text('INFORME DEFUNCIONES FOCAPS', data.settings.margin.left + 55, 20);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 40 },
      headStyles: {
        halign: 'center'
      },
      columnStyles: {
        devolucion: {
          halign: 'right'
        },
        apoyo: {
          halign: 'right'
        },
        ahorro: {
          halign: 'right'
        },
        interes: {
          halign: 'right'
        },
        credit: {
          halign: 'right'
        },
        apoyo_Hijos: {
          halign: 'right'
        }
      },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`Defunciones.pdf`);
  }

}
