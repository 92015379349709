import { Component, OnInit } from '@angular/core';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { ActivatedRoute } from '@angular/router';
import { TeacherService } from '../../administration/teachers/services/teacher.service';

@Component({
  selector: 'app-teacher-global',
  templateUrl: './teacher-global.component.html',
  styles: []
})
export class TeacherGlobalComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  applications: ApplicationInterface[];

  constructor( public accountService: AccountService,
    public activatedRoute: ActivatedRoute,
    private teacherService: TeacherService) { }

  ngOnInit() {
    this.accountService.getApplications('reports', 'teachers-global')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      }, error => this.errorMessage(error));
  }

  search() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  loadData(value: string) {
    this.loadingData = true;
    this.teacherService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.errorMessage(error));
  }

  errorMessage(error) {
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

}
