import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../payments/services/payment.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SavingService } from '../savings/services/saving.service';
import { PaymentInterface } from '../payments/interfaces/payment.interface';
import { WayToPayUpdateInterface } from './interfaces/way-to-pay-update.interface';
import { AccountService } from '../../account/login/services/account.service';

@Component({
  selector: 'app-way-to-pay-details',
  templateUrl: './way-to-pay-details.component.html',
  styles: []
})
export class WayToPayDetailsComponent implements OnInit {
  errorMessage: string;
  gettingData = false;
  saving = false;
  forma: UntypedFormGroup;
  amount = 0;
  index = 0;
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  payments: Array<PaymentInterface> = [];

  constructor( private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private savingService: SavingService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public accountService: AccountService ) { }

  ngOnInit() {
    this.gettingData = true;
    this.buildForm();

    this.activatedRoute.params.subscribe(params => {
      if (params['folio'] && params['type']) {
        this.inicio = params['start'];
        this.fin = params['end'];
        if ( params['type'] === 'Liquidación' ) {
          this.paymentService.getPaymentsByFolio(params['folio'])
            .subscribe(payments => {
              this.payments = payments;
              this.calculateAmount();
              // tslint:disable-next-line:max-line-length
              this.amount =  (this.amount + this.payments[0].amountSaving + this.payments[0].financialProducts) - (this.payments[0].condoneLifeInsurance + this.payments[0].condoneInterest);
              this.gettingData = false;
          }, error => this.setErrorMessage(error));
        } else if ( params['type'] === 'Aportación' ) {
          this.savingService.getByFolio(params['folio'])
            .subscribe(payments => {
              this.payments = payments;
              this.calculateAmount();
              this.gettingData = false;
          }, error => this.setErrorMessage(error));
        }
      } else {
        this.router.navigate(['administration/ways-to-pay', '']);
      }
    }, error => this.setErrorMessage(error));
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      id: [''],
      teacherId: [''],
      rfc: [''],
      name: [''],
      year: ['', [Validators.required]],
      qna_1: ['', [Validators.required]],
      qna_2: ['', [Validators.required]],
      qna_3: ['', [Validators.required]],
      qna_4: ['', [Validators.required]],
      qna_5: ['', [Validators.required]],
      qna_6: ['', [Validators.required]],
      qna_7: ['', [Validators.required]],
      qna_8: ['', [Validators.required]],
      qna_9: ['', [Validators.required]],
      qna_10: ['', [Validators.required]],
      qna_11: ['', [Validators.required]],
      qna_12: ['', [Validators.required]],
      qna_13: ['', [Validators.required]],
      qna_14: ['', [Validators.required]],
      qna_15: ['', [Validators.required]],
      qna_16: ['', [Validators.required]],
      qna_17: ['', [Validators.required]],
      qna_18: ['', [Validators.required]],
      qna_19: ['', [Validators.required]],
      qna_20: ['', [Validators.required]],
      qna_21: ['', [Validators.required]],
      qna_22: ['', [Validators.required]],
      qna_23: ['', [Validators.required]],
      qna_24: ['', [Validators.required]],
      type: [''],
      movementDate: [''],
      folio: [''],
      financialProducts: [''],
      interest: [''],
      condoneInterest: [''],
      amountSaving: [''],
      lifeInsurance: [''],
      condoneLifeInsurance: [''],
      wayToPay: [''],
      total: ['']
    });
  }

  calculateAmount(): void {
    let amount = 0;
    this.payments.forEach(payment => {
      // tslint:disable-next-line:max-line-length
      amount += payment.fortnight1 + payment.fortnight2 + payment.fortnight3 + payment.fortnight4 + payment.fortnight5 + payment.fortnight6 + payment.fortnight7 + payment.fortnight8 + payment.fortnight9 + payment.fortnight10 + payment.fortnight11 + payment.fortnight12 + payment.fortnight13 + payment.fortnight14 + payment.fortnight15 + payment.fortnight16 + payment.fortnight17 + payment.fortnight18 + payment.fortnight19 + payment.fortnight20 + payment.fortnight21 + payment.fortnight22 + payment.fortnight23 + payment.fortnight24; 
    });
    this.amount += amount;
  }

  setErrorMessage( error: any ) {
    console.error(error);
    if (error && error.error) {
      this.errorMessage = error.message;
      this.gettingData = false;
    }
  }

  sendForm() {
    this.saving = true;
    const wayToPayUpdate: WayToPayUpdateInterface = {
      teacherId : this.payments[0].teacherId,
      wayToPay: this.forma.controls['wayToPay'].value,
      folio: this.payments[0].folio,
      userId: this.accountService.getUserId()
    };
    if ( this.payments[0].type === 'Liquidación' ) {
      this.paymentService.putLiquidationsPayments(wayToPayUpdate)
        .subscribe(response => {
          this.router.navigate(['administration/ways-to-pay', this.inicio, this.fin]);
        }, error => this.setErrorMessage(error));
    } else if ( this.payments[0].type === 'Aportación' ) {
      this.savingService.putInputsSaving( wayToPayUpdate )
        .subscribe(response => {
          this.router.navigate(['administration/ways-to-pay', this.inicio, this.fin]);
        }, error => this.setErrorMessage(error));
    }
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

}
