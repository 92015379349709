import { Component, OnInit } from '@angular/core';
import { MoneyOutPolicyService } from './services/money-out-policy.service';
import { PeriodInterface } from '../../../interfaces/period.interface';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-money-out',
  templateUrl: './money-out.component.html',
  styles: []
})

export class MoneyOutComponent implements OnInit {
  dataReport: any[] = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  period: PeriodInterface = {
    start: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`,
    end: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`
  };
  errorMessage = '';
  loadingData = false;
  balancePositive = true;

  constructor( private moneyOutService: MoneyOutPolicyService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.loadData();
  }

  loadData() {
    this.errorMessage = '';
    if ( this.isValidDate(this.inicio) && this.inicio.length === 10 ) {
      if ( this.isValidDate(this.fin) && this.inicio.length === 10 ) {
        this.period.start = this.inicio;
        this.period.end = this.fin;
        this.moneyOutService.getData( this.period )
          .subscribe( (data) => {
            this.dataReport = data;
            this.loadingData = false;
          }, error => this.setErrorMessage(error));
      } else {
        this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
      }
    } else {
      this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
    }
    this.loadingData = false;
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  exportData() {
    this.excelService.exportAsExcelFileWithoutHeader(this.dataReport,
      `Egresos_${ this.inicio }_${ this.fin }`);
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
