<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="['/administration/supports', searchValue]" routerLinkActive="router-link-active">Apoyos</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <h3 class="title">
                        Pendientes <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 text-right" >
                    <input id="chkSelectAll" (change)="handlerSelectAll( $event.target.checked)" type="checkbox"  checked ><label for="chkSelectAll" > Seleccionar Todo</label>
                </div>

                <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="form-group text-right">
                        <button class="btn btn-outline-primary" (click)="getExcel()" [disabled]="jsonArray.length === 0">
                      <i class="far fa-file-pdf"></i>
                    Generar Excel</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
            <ul class="item-list striped">
                <li class="item item-list-header">
                    <div class="row">
                        <div class="item-col item-col-header">
                            <div class="no-overflow">
                                <span>Maestro</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>C.U.R.P</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>Nombre</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>Monto</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>Estatus</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header fixed item-col-actions-dropdown-3"></div>
                    </div>
                </li>

                <li class="item" *ngFor="let item of jsonArray; let i = index">
                    <div class="item-row" [ngClass]="{ required : item.status === 'Cancelado' }">
                        <div class="item-col item-col-item">
                            <div class="item-heading">Maestro</div>
                            <div>
                                <span>{{ item.teacher.name }}</span>
                            </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">C.U.R.P</div>
                            <div>
                                <span>{{ item.curp }}</span>
                            </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">Nombre</div>
                            <div> {{ item.name }} </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">Monto</div>
                            <div> {{ item.amount | currency }} </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">Estatus</div>
                            <div> {{ item.status }} </div>
                        </div>

                        <div class="item-col fixed item-col-actions-dropdown">
                            <div class="item-list-actions text-center">
                                <input (change)="handlerChecbox( $event.target.checked, item )" type="checkbox" [checked]="item.active" *ngIf="item.status !== 'Cancelado'">
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="form-group text-center">
            <button class="btn btn-outline-warning" (click)="apply()" [disabled]="sendingData || loadingData || !validData()">
          <i [ngClass]=" { 'far fa-file-pdf': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
        Generar PDF</button>
        </div>

        <div class="form-group ">
            <a class="btn btn-outline-info " [routerLink]="['/administration/supports', searchValue] "><i class="fas fa-arrow-left "></i>
        Regresar</a>
        </div>
    </div>

</article>