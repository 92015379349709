import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { TeacherService } from '../../administration/teachers/services/teacher.service';

@Component({
  selector: 'app-ups',
  templateUrl: './ups.component.html',
  styles: []
})
export class UpsComponent implements OnInit {
  loadingData = false;
  errorMessage = '';
  typeSelected = 1;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  // tslint:disable-next-line:max-line-length
  public barChartLabels: String[] = ['Qna 1', 'Qna 2', 'Qna 3', 'Qna 4', 'Qna 5', 'Qna 6', 'Qna 7', 'Qna 8', 'Qna 9', 'Qna 10', 'Qna 11', 'Qna 12', 'Qna 13', 'Qna 14', 'Qna 15', 'Qna 16', 'Qna 17', 'Qna 18', 'Qna 19', 'Qna 20', 'Qna 21', 'Qna 22', 'Qna 23', 'Qna 24'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  // public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];

  constructor( private teacherService: TeacherService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.loadData(new Date().getFullYear());
  }

  fillFortnight(data: any[]) {
    // tslint:disable-next-line:max-line-length
    const arrayReturned = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    data.forEach(item => {
      arrayReturned[item.fortnight - 1] = item.items;
    });
    return arrayReturned;
  }

  setErrorMessage( error ) {
    console.log(error);
    this.errorMessage = error.message;
    this.loadingData = false;
  }

  loadData(year: number): void {
    this.teacherService.itemsFortnight(year - 1, this.typeSelected).subscribe(
      data => {
        console.log(`${ year - 1 } typo ${ this.typeSelected }`);
        console.log(data);
        this.barChartData[0].data = this.fillFortnight(data);
        this.barChartData[0].label = (year - 1).toString();
        this.teacherService.itemsFortnight(year, this.typeSelected).subscribe(
          data2 => {
            console.log(`${ year } typo ${ this.typeSelected }`);
            console.log(data2);
            this.barChartData[1].data = this.fillFortnight(data2);
            this.barChartData[1].label = year.toString();
            this.loadingData = false;
          },
          error => this.setErrorMessage(error)
        );
      },
      error => this.setErrorMessage(error)
    );
  }

  changeYear(event: Event): void {
    this.loadingData = true;
    const value: string = (<HTMLSelectElement>event.srcElement).value;

    this.loadData(parseFloat(value));
  }

  searchData() {
    this.loadingData = true;
    this.loadData( new Date().getFullYear() );
  }

}
