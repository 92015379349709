import { Component, OnInit } from '@angular/core';
import { SupplierInterface } from './interfaces/supplier.interface';
import { SupplierService } from './services/supplier.service';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { BankService } from '../../administration/banks/services/bank.service';
import { BankInterface } from '../../administration/banks/interfaces/bank.interface';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styles: []
})
export class SuppliersComponent implements OnInit {
  isLoadingData = false;
  errorMessage: string;
  isWorking = false;
  banks: BankInterface[] = [];
  datasource: Array<SupplierInterface> = [];
  unaffiliated: Array<SupplierInterface> = [];
  applications: ApplicationInterface[];

  constructor(private supplierService: SupplierService,
    public accountService: AccountService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private bankService: BankService) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.accountService.getApplications('banks', 'suppliers')
    .subscribe(applications => {
      this.applications = applications;
      this.supplierService.get()
      .subscribe(data => {
        this.bankService.get('null').subscribe(
          banks => {
            this.banks = banks;
            this.datasource = data.filter(d => d.bank !== null && d.clabe !== null);
            this.unaffiliated = data.filter(d => d.bank === null && d.clabe === null);
            this.isLoadingData = false;
          }, error => {
            this.isLoadingData = false;
            this.helperService.setErrorMessage(error);
          });
      }, error => {
        this.isLoadingData = false;
        this.helperService.setErrorMessage(error);
      });
    }, error => {
      this.isLoadingData = false;
      this.helperService.setErrorMessage(error);
    });
  }

  handlerClick(): void {
    this.isWorking = true;
    // this.descargarArchivo(this.generarTexto(this.datasource), `Alta Proveedores ${new Date().getTime()}.txt`);
    this.supplierService.post(this.datasource.filter(d => d.toProcess === true))
      .subscribe((data) => {
        this.descargarArchivo(this.generarTexto( data ),
         `Alta Proveedores ${new Date().getTime()}.txt`);
        this.supplierService.get()
          .subscribe(data => {
            this.datasource = data;
            this.isWorking = false;
          }, error => {
            this.helperService.setErrorMessage(error);
          });
      }, error => {
        this.helperService.setErrorMessage(error);
      });
  }

  generarTexto(teachers: Array<SupplierInterface>) {
    const texto = [];
    teachers.forEach(element => {
      let code = '040';
      if ( element.bank === '72' ) {
        code = '001';
      }
      if ( element.insert ) {
        // tslint:disable-next-line:max-line-length
        texto.push(`${ element.rfc }\tAR\t${ this.completeRight(element.name, 60, ' ') }\t${ element.rfc }\t${ this.completeLeft(element.phone, 15, '0') }\t${ element.name.substring(0, 20) }\t${ element.email }\t000\t0\t0000\t000000000000000000\n`);
      }
      // tslint:disable-next-line:max-line-length
      texto.push(`${ element.rfc }\tAC\t${ this.completeRight('', 60, ' ') }\t${ this.completeRight('', 13, ' ') }\t000000000000000\t${ this.completeRight('', 20, ' ') }\t \t${ code }\tPESOS\t${ this.completeLeft(element.bank, 4, '0') }\t${ element.clabe }\n`);
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  descargarArchivo(contenidoEnBlob, nombreArchivo) {
    const reader: FileReader = new FileReader();
    reader.onload = function (event: Event) {
      const save = document.createElement('a');
      save.href = reader.result as string; // event.target.result;
      save.target = '_blank';
      save.download = nombreArchivo || 'archivo.txt';
      const clicEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      save.dispatchEvent(clicEvent);
      (window.URL).revokeObjectURL(save.href);
    };
    reader.readAsDataURL(contenidoEnBlob);
  }

  completeLeft(text: string, numberCharacters: number, character: string): string {
    let textReturned = '';
    for (let index = text.length; index < numberCharacters; index++) {
      textReturned = `${ character }${ textReturned }`;
    }
    return textReturned + text;
  }

  completeRight(text: string, numberCharacters: number, character: string): string {
    for (let index = text.length; index < numberCharacters; index++) {
      text = `${ text }${ character }`;
    }
    return text;
  }

  async showEditModal( provider: SupplierInterface) {
    var options = this.getBankOptions(provider.bank);
    const bankInfo = await this.notificationService.showConfirmBankData(`${provider.name} ${provider.rfc}`, provider.email, options, provider.clabe);
    if(bankInfo !== null) {
      this.isWorking = true;
      provider.email = bankInfo.email;
      provider.bank = bankInfo.bank;
      provider.clabe = bankInfo.account;
      this.supplierService.put(provider)
      .subscribe(() => {
        this.supplierService.get()
          .subscribe(data2 => {
            this.datasource = data2.filter(d => d.bank !== null && d.clabe !== null);
            this.unaffiliated = data2.filter(d => d.bank === null && d.clabe === null);
            this.isWorking = false;
          }, error => {
            this.helperService.setErrorMessage(error);
          });
        }, error => {
          this.helperService.setErrorMessage(error);
        });
    }
  }

  getBankOptions(code: string): string {
    let options = '';
    this.banks.forEach(bank => {
      if(code === bank.code) {
        options += `<option selected value="${bank.code}">${bank.name}</option>`
      } else {
        options += `<option value="${bank.code}">${bank.name}</option>`
      }
    });
    return options
  }



  handlerChecbox(checked: boolean, item: SupplierInterface): void {
    item.toProcess = checked;
  }

  dataValid(): boolean {
    return this.datasource.some( l => l.toProcess === true );
  }

  handlerSelectAll( value: boolean): void {
    this.datasource.forEach(element => {
      element.toProcess = value
    });
  }

}
