import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AccountService } from './services/account.service';
import { Router } from '@angular/router';
import { IUserInfo } from './interfaces/iUserInfo';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  forma: UntypedFormGroup;
  loginModel: IUserInfo = {
    'username': '',
    'password': ''
  };
  message: string;
  loading = false;

  constructor( public accountService: AccountService,
    private router: Router ) { }

  ngOnInit() {
    this.forma = new UntypedFormGroup( {
      'username': new UntypedFormControl('', [Validators.required,
                                      Validators.minLength(6)] ),
      'password': new UntypedFormControl('', [Validators.required,
                                      Validators.minLength(3)] )
    } );

    this.forma.setValue(this.loginModel);
  }

  validateCredentials() {
    this.loading = true;
    const model: IUserInfo = Object.assign({}, this.forma.value );
    this.accountService.login(model)
      .subscribe(token => this.toReceiveToken(token),
      error => this.errorMessage(error));
  }

  toReceiveToken(token) {
    localStorage.setItem('token', token.token);
    localStorage.setItem('tokenExpiration', token.expiration);
    const userId = this.accountService.getUserId();
    this.router.navigate(['']);
    this.loading = false;
  }

  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      this.message = error.error[''][0];
      this.loading = false;
      console.clear();
    }
  }

}
