import { Routes } from '@angular/router';
import { TransfersLowstaffDetailComponent } from './transfers-lowstaff-detail.component';
import { TransfersLowstaffListComponent } from './transfers-lowstaff-list.component';
import { TransfersLowstaffingComponent } from './transfers-lowstaffing.component';

export const TRANSFERSLOWSTAFF_ROUTES: Routes = [
    { path: 'details/:block', component: TransfersLowstaffDetailComponent },
    { path: 'generate', component: TransfersLowstaffingComponent },
    { path: '', component: TransfersLowstaffListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
