<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="['/administration/deadlines', searchValue]" routerLinkActive="router-link-active">Tipos de Préstamos</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">{{ title }}</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="gettingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="row form-group">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                <label class="control-label"> Prefijo</label>
                <select class="custom-select" formControlName="prefix">
                  <option [value]="prefix" *ngFor="let prefix of prefixes" >{{ prefix }}</option>
              </select>
            </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['amount'].valid && forma.controls['amount'].touched, 'has-success': forma.controls['amount'].valid }">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Importe</label>
                    <input type="number" min="1" class="form-control underlined" formControlName="amount">
                    <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.required" class="has-error ">
                      El campo es requerido.
                    </span>
                    <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.min" class="has-error ">
                      El valor mínimo es 1.
                    </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Quincenas</label>
                    <select class="custom-select" formControlName="fortnightId">
                    <option [value]="fortnight.id" *ngFor="let fortnight of fortnights" >{{ fortnight.numberFortnight }}</option>
                  </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Tipo</label>
                    <select class="custom-select" formControlName="type">
                      <option value="Especiales">Especiales</option>
                      <option value="Extraordinario">Extraordinario</option>
                      <option value="Ordinario">Ordinario</option>
                  </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Maestros</label>
                    <select class="custom-select" formControlName="teachers">
                      <option value="Básico">Básico</option>
                      <option value="Media Superior">Media Superior</option>
                      <option value="Alta Jubilación">Alta Jubilación</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['discount'].valid && forma.controls['discount'].touched, 'has-success': forma.controls['discount'].valid }">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Descuento</label>
                    <input type="number" min="1" class="form-control underlined" formControlName="discount">
                    <span *ngIf="forma.controls['discount'].touched && forma.controls['discount'].errors?.required" class="has-error ">
                      El campo es requerido.
                    </span>
                    <span *ngIf="forma.controls['discount'].touched && forma.controls['discount'].errors?.min" class="has-error ">
                      El valor mínimo es 1.
                    </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['interests'].valid && forma.controls['interests'].touched, 'has-success': forma.controls['interests'].valid }">
                  <label class="control-label"><i class="fas fa-asterisk required"></i> Interes</label>
                  <input type="number" min="1" class="form-control underlined" formControlName="interests">
                  <span *ngIf="forma.controls['interests'].touched && forma.controls['interests'].errors?.required" class="has-error ">
                    El campo es requerido.
                  </span>
                  <span *ngIf="forma.controls['interests'].touched && forma.controls['interests'].errors?.min" class="has-error ">
                    El valor mínimo es 1.
                  </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['percentageInterest'].valid && forma.controls['percentageInterest'].touched, 'has-success': forma.controls['percentageInterest'].valid }">
                  <label class="control-label"><i class="fas fa-asterisk required"></i> % Interes</label>
                  <input type="number" min="0" class="form-control underlined" formControlName="percentageInterest">
                  <span *ngIf="forma.controls['percentageInterest'].touched && forma.controls['percentageInterest'].errors?.required" class="has-error ">
                  El campo es requerido.
                </span>
                  <span *ngIf="forma.controls['percentageInterest'].touched && forma.controls['percentageInterest'].errors?.min" class="has-error ">
                  El valor mínimo es 1.
                </span>
                  <span *ngIf="forma.controls['percentageInterest'].touched && forma.controls['percentageInterest'].errors?.max" class="has-error ">
                  El valor máximo es 100.
                </span>
              </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['totalPerceptions'].valid && forma.controls['totalPerceptions'].touched, 'has-success': forma.controls['totalPerceptions'].valid }">
                    <label class="control-label"> Percepciones</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="totalPerceptions">
                    <span *ngIf="forma.controls['totalPerceptions'].touched && forma.controls['totalPerceptions'].errors?.required" class="has-error ">
                      El campo es requerido.
                    </span>
                    <span *ngIf="forma.controls['totalPerceptions'].touched && forma.controls['totalPerceptions'].errors?.min" class="has-error ">
                      El valor mínimo es 0.
                    </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['saving'].valid && forma.controls['saving'].touched, 'has-success': forma.controls['saving'].valid }">
                    <label class="control-label">Ahorros</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="saving">
                    <span *ngIf="forma.controls['saving'].touched && forma.controls['saving'].errors?.required" class="has-error ">
                    El campo es requerido.
                  </span>
                    <span *ngIf="forma.controls['saving'].touched && forma.controls['saving'].errors?.min" class="has-error ">
                    El valor mínimo es 0.
                  </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['openingBonus'].valid && forma.controls['openingBonus'].touched, 'has-success': forma.controls['openingBonus'].valid }">
                    <label class="control-label"> Prima apertura</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="openingBonus">
                    <span *ngIf="forma.controls['openingBonus'].touched && forma.controls['openingBonus'].errors?.required" class="has-error ">
                      El campo es requerido.
                    </span>
                    <span *ngIf="forma.controls['openingBonus'].touched && forma.controls['openingBonus'].errors?.min" class="has-error ">
                      El valor mínimo es 0.
                    </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['guaranteedPercentage'].valid && forma.controls['guaranteedPercentage'].touched, 'has-success': forma.controls['guaranteedPercentage'].valid }">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> % garantia</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="guaranteedPercentage">
                    <span *ngIf="forma.controls['guaranteedPercentage'].touched && forma.controls['guaranteedPercentage'].errors?.required" class="has-error ">
                    El campo es requerido.
                  </span>
                    <span *ngIf="forma.controls['guaranteedPercentage'].touched && forma.controls['guaranteedPercentage'].errors?.min" class="has-error ">
                    El valor mínimo es 1.
                  </span>
                    <span *ngIf="forma.controls['guaranteedPercentage'].touched && forma.controls['guaranteedPercentage'].errors?.max" class="has-error ">
                    El valor máximo es 100.
                  </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['iva'].valid && forma.controls['iva'].touched, 'has-success': forma.controls['iva'].valid }">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> IVA</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="iva">
                    <span *ngIf="forma.controls['iva'].touched && forma.controls['iva'].errors?.required" class="has-error ">
                    El campo es requerido.
                  </span>
                    <span *ngIf="forma.controls['iva'].touched && forma.controls['iva'].errors?.min" class="has-error ">
                    El valor mínimo es 1.
                  </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['lifeInsurance'].valid && forma.controls['lifeInsurance'].touched, 'has-success': forma.controls['lifeInsurance'].valid }">
                    <label class="control-label"> Seguro de vida</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="lifeInsurance">
                    <span *ngIf="forma.controls['lifeInsurance'].touched && forma.controls['lifeInsurance'].errors?.required" class="has-error ">
                  El campo es requerido.
                </span>
                    <span *ngIf="forma.controls['lifeInsurance'].touched && forma.controls['lifeInsurance'].errors?.min" class="has-error ">
                  El valor mínimo es 0.
                </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['accountingConcept'].valid && forma.controls['accountingConcept'].touched, 'has-success': forma.controls['accountingConcept'].valid }">
                    <label class="control-label"> Concepto conta</label>
                    <input type="text" maxlength="100" class="form-control underlined" formControlName="accountingConcept">
                    <span *ngIf="forma.controls['accountingConcept'].touched && forma.controls['accountingConcept'].errors?.required" class="has-error ">
                    El campo es requerido.
                  </span>
                    <span *ngIf="forma.controls['accountingConcept'].touched && forma.controls['accountingConcept'].errors?.min" class="has-error ">
                    El valor mínimo es 0.
                  </span>
                </div>
            </div>

            <label>Desglose Quincenal</label>
            <hr />

            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['fortnightCapital'].valid && forma.controls['lifeInsurance'].touched, 'has-success': forma.controls['lifeInsurance'].valid }">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Capital</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="fortnightCapital">
                    <span *ngIf="forma.controls['fortnightCapital'].touched && forma.controls['fortnightCapital'].errors?.required" class="has-error ">
                      El campo es requerido.
                    </span>
                    <span *ngIf="forma.controls['fortnightCapital'].touched && forma.controls['fortnightCapital'].errors?.min" class="has-error ">
                      El valor mínimo es 0.
                    </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['fortnightInterest'].valid && forma.controls['fortnightInterest'].touched, 'has-success': forma.controls['fortnightInterest'].valid }">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Interes</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="fortnightInterest">
                    <span *ngIf="forma.controls['fortnightInterest'].touched && forma.controls['fortnightInterest'].errors?.required" class="has-error ">
                    El campo es requerido.
                  </span>
                    <span *ngIf="forma.controls['fortnightInterest'].touched && forma.controls['fortnightInterest'].errors?.min" class="has-error ">
                    El valor mínimo es 0.
                  </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['fortnightIVA'].valid && forma.controls['fortnightInterest'].touched, 'has-success': forma.controls['fortnightInterest'].valid }">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> IVA</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="fortnightIVA">
                    <span *ngIf="forma.controls['fortnightIVA'].touched && forma.controls['fortnightIVA'].errors?.required" class="has-error ">
                  El campo es requerido.
                </span>
                    <span *ngIf="forma.controls['fortnightIVA'].touched && forma.controls['fortnightIVA'].errors?.min" class="has-error ">
                  El valor mínimo es 0.
                </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2" [ngClass]="{'has-error': !forma.controls['fortnightLifeInsurance'].valid && forma.controls['fortnightLifeInsurance'].touched, 'has-success': forma.controls['fortnightLifeInsurance'].valid }">
                    <label class="control-label"> Seguro de vida</label>
                    <input type="number" min="0" class="form-control underlined" formControlName="fortnightLifeInsurance">
                    <span *ngIf="forma.controls['fortnightLifeInsurance'].touched && forma.controls['fortnightLifeInsurance'].errors?.required" class="has-error ">
                    El campo es requerido.
                  </span>
                    <span *ngIf="forma.controls['fortnightLifeInsurance'].touched && forma.controls['fortnightLifeInsurance'].errors?.min" class="has-error ">
                    El valor mínimo es 0.
                  </span>
                </div>
            </div>

            <div class="form-group text-center">
                <button [disabled]="!forma.valid ||sendingData" class="btn" [ngClass]="{'btn-outline-success': title=='Agregar', 'btn-outline-warning': title=='Editar' }">
                  <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData }"></i>
                  <span *ngIf="!sendingData">Guardar</span>
                  <span *ngIf="sendingData">Guardando...</span>
              </button>

                <!-- <button type="button" *ngIf="draw.status === 'Pendiente'" (click)="openDraw()" [disabled]="!forma.valid ||sendingData" class="btn btn-outline-primary">
                  <i [ngClass]="{ 'far fa-folder-open': !sendingData, 'fas fa-sync-alt fa-spin': sendingData }"></i>
                  <span *ngIf="!sendingData">Abrir</span>
                  <span *ngIf="sendingData">Abriendo...</span>
              </button>

               <button type="button" *ngIf="draw.status === 'Abierto'" (click)="closeDraw()" [disabled]="!forma.valid ||sendingData" class="btn btn-outline-danger">
                  <i [ngClass]="{ 'fas fa-folder-minus': !sendingData }"></i>
                  <span *ngIf="!sendingData">Cerrar</span>
                  <span *ngIf="sendingData">Cerrando...</span>
              </button> -->
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="message">
                {{ message }}
            </div>
        </form>

        <div class="form-group">
            <a class="btn btn-outline-info" [routerLink]="['/administration/deadlines', searchValue]"><i class="fas fa-arrow-left"></i>
              Regresar</a>
        </div>
    </div>
</article>