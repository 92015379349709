import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { LowStaffService } from './services/low-staff.service';

@Component({
  selector: 'app-low-staffing-savings',
  templateUrl: './low-staffing-details.component.html',
  styles: []
})
export class LowStaffingDetailsComponent implements OnInit {
  searchValue = '';
  loadingData = false;
  waytopay = 'Todo';
  start = '';
  end = '';
  errorMessage = '';
  amount = 0;
  amountInterests = 0;
  dataReport: any[] = [];
  percentageInterest = 0.00208;
  // supports: Array<SupportInterface>;
  lowstaffing: any;

  constructor( private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    private lowStaffingService: LowStaffService ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.loadingData = true;
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }
      if (params['waytopay']) {
        this.waytopay = params['waytopay'];
      }
      if (params['start']) {
        this.start = params['start'];
      }
      if (params['end']) {
        this.end = params['end'];
      }

      if (params['id']) {
        this.lowStaffingService.getById(params['id'], 'Baja').subscribe(
          lowstaffing => {
            this.lowstaffing = lowstaffing;
            // this.supports = this.lowstaffing.teacher.supports.filter( s => s.type === 'Defunción Adherente' );
            // this.loadData(this.teacher.id);
            this.loadingData = false;
          },
          error => this.setErrorMessage(error));
      }
    });
  }

  loadData(teacherId: string) {
    this.lowStaffingService.getSavings(teacherId)
      .subscribe((data) => {
        this.dataReport = data;
        if (this.dataReport.length > 0) {
          this.calculateAmount(data, this.percentageInterest);
        }
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }
  searchDataDetailed( percentage: number) {
    this.percentageInterest = percentage;
    this.calculateAmount(this.dataReport, this.percentageInterest);
  }

  calculateAmount(data: any[], percentage: number) {
    this.amount = 0;
    this.amountInterests = 0;
    let interestWithImport = 0;
    let teacherRFC = '';

    data.forEach(year => {
      if ( year.rfc !== teacherRFC ) {
        teacherRFC = year.rfc;
        interestWithImport = 0;
      }
      let interestPeritem = 0;
      const arrayYear = Object.keys(year).map(function(key) {
        return [String(key), year[key]];
      });

      for (let index = 0; index < arrayYear.length; index++) {
        if ( arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA' ) {
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
          }
        }
      }
      year.interests = parseFloat(interestPeritem.toFixed(2));
      this.amountInterests += year.interests;
      this.amount += year.total;
    });
  }

}
