export enum InputTypes {
  Text = 'text',
  Password = 'password',
  Number = 'number',
  Telephone = "tel",
  Range = 'range',
  Textarea = 'textarea',
  File = 'file',
  Url = 'url'
}
