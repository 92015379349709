<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Bancos</li>
            <li class="breadcrumb-item"><a [routerLink]="['/banks/transfers']" routerLinkActive="router-link-active">Transferencias</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Proveedores</h3>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="form-group text-right">
                        <button class="btn btn-outline-primary" (click)="getExcel()" [disabled]="jsonArray.length === 0">
                        <i class="far fa-file-pdf"></i>
                      Generar Excel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">
        <div class="row mb-3">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <label class="control-label">Tipo</label>
                <select class="custom-select" (change)="handlerType( $event.target.value )">
                    <option value="false">Terceros</option>
                    <option value="true">SPEI</option>
                </select>
            </div>
        </div>

        <app-not-data *ngIf="!isLoadingData && datasource.length === 0"></app-not-data>

        <app-loading-data *ngIf="isLoadingData"></app-loading-data>

        <div class="table-responsive-sm">
            <div class="row">
                <label *ngIf="datasource.length > 0" class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    ({{ datasource.length }}) Trasferencias
                </label>
                <label *ngIf="datasource.length === 1" class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    Una trasferencia
                </label>
                <div class="col-9 text-right">
                    <input id="chkSelectAll" (change)="handlerSelectAll( $event.target.checked)" type="checkbox"  checked ><label for="chkSelectAll" > Seleccionar Todo</label>
                </div>
            </div>

            <table class="table table-striped table-hover responsive" *ngIf="datasource.length > 0">
                <thead>
                    <tr>
                        <th>RFC</th>
                        <th>Nombre</th>
                        <th>Importe</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of datasource; let i = index">
                        <td>{{ item.rfc }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.amount | currency }}</td>
                        <td>
                            <span *ngIf="accountService.havePermission(applications, 'Transferencias Cancelar')">
                                <a class="required" (click)="showCancelConfirm( item.id, item.rfc, item.name )" href="" data-toggle="modal" >
                                   <i class="far fa-trash-alt"></i>
                               </a> |
                            </span>
                            <span>
                                <input (change)="handlerChecbox( $event.target.checked, item )" type="checkbox" [checked]="item.active" > |
                            </span>
                            <span *ngIf="accountService.havePermission(applications, 'Prestamos Editar')">
                                <a class="edit" [routerLink]="['/administration/loans/edit', item.id, '/banks/transfers/generate' ]">
                                    <i class="far fa-edit"></i>
                                </a>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="isWorking  || !dataValid()" type="button" class="btn btn-outline-success" (click)="handlerClick()">
                <i [ngClass]="{ 'far fa-save': !isWorking, 'fas fa-sync-alt fa-spin': isWorking }"></i>
                <span *ngIf="!isWorking"> Procesar</span>
                <span *ngIf="isWorking" > Procesando...</span>
            </button>
            </div>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

        <div *ngIf="unaffiliated.length > 0">
            <h2>Pendientes ( {{ unaffiliated.length }} )</h2>
        </div>

        <div class="table-responsive-sm">
            <table class="table table-striped table-hover responsive" *ngIf="unaffiliated.length > 0">
                <thead>
                    <tr>
                        <th>RFC</th>
                        <th>Nombre</th>
                        <th>Importe</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of unaffiliated; let i = index">
                        <td>{{ item.rfc }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.amount | currency }}</td>
                        <td>
                            <span *ngIf="accountService.havePermission(applications, 'Transferencias Cancelar')">
                                <a class="required" (click)="showCancelConfirm( item.id, item.rfc, item.name )" href="" data-toggle="modal" >
                                   <i class="far fa-trash-alt"></i>
                               </a>
                           </span>
                            <span *ngIf="accountService.havePermission(applications, 'Prestamos Editar')">
                                | <a class="edit" [routerLink]="['/administration/loans/edit', item.id, '/banks/transfers/generate' ]">
                                    <i class="far fa-edit"></i>
                                </a>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="form-group ">
            <a class="btn btn-outline-info " [routerLink]="['/banks/transfers'] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>

</article>
