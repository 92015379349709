import { Component, OnInit } from '@angular/core';
import { TeacherService } from '../../administration/teachers/services/teacher.service';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styles: []
})
export class BeneficiariesComponent implements OnInit {
  isLoadingData = false;
  errorMessage = '';

  constructor( private teacherService: TeacherService,
    private excelService: ExcelService ) { }

  ngOnInit() {
  }

  getExcel( ) {
    this.isLoadingData = true;
    this.teacherService.getBeneficiaries().subscribe(
      data => {
        this.excelService.exportAsExcelFileWithoutHeader(data,
          `Beneficiarios`);
        this.isLoadingData = false;
      }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.isLoadingData = false;
    }
  }

}
