import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransferInterface } from '../interfaces/transfer.interface';
import { CancelTransferInterface } from '../interfaces/cancelTransfer.interface';

@Injectable()
export class TransferService {
  private apiURL = this.baseUrl + 'api/Banks/Transfers';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  get() {
    return this.http.get<any>(`${ this.apiURL }`);
  }

  post(userId: string, suppliers: Array<TransferInterface>) {
    return this.http.post<any>(`${ this.apiURL }/${ userId }`, suppliers);
  }

  getTransfers() {
    return this.http.get<any>(`${ this.apiURL }/Transfers`);
  }

  applyTransfers(block: number, date: string) {
    return this.http.get<any>(`${ this.apiURL }/Apply/${ block }/${ date }`);
  }

  getDetails(block: number) {
    return this.http.get<any>(`${ this.apiURL }/Details/${ block }`);
  }

  update( transfer: CancelTransferInterface ) {
    return this.http.put<any>( this.apiURL, transfer );
  }
}
