import { Routes } from '@angular/router';
import { PaymentListComponent } from './payment-list.component';
import { PaymentDetailComponent } from './payment-detail.component';
import { PaymentComponent } from './payment.component';

export const PAYMENTS_ROUTES: Routes = [
    { path: 'detail/:id', component: PaymentDetailComponent },
    { path: 'detail/:id/:searchValue', component: PaymentDetailComponent },
    { path: 'add/:loanId', component: PaymentComponent },
    { path: 'add/:loanId/:pathReturn', component: PaymentComponent },
    { path: '', component: PaymentListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
