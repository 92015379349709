import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationsInterface } from '../interfaces/configurations.interface';

@Injectable()
export class ConfigurationsService {
  private apiURL = 'api/Configuration/Configurations';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  get() {
    return this.http.get<any>(this.apiURL);
  }

  update( id: string, configurations: ConfigurationsInterface ) {
    return this.http.put<any>( this.apiURL + '/' + id, configurations );
  }
}
