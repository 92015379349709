<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Bancos</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Proveedores ( {{ datasource.length }} )</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!isLoadingData && datasource.length === 0"></app-not-data>

    <app-loading-data *ngIf="isLoadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <div class="table-responsive-sm">
            <table class="table table-striped table-hover responsive" *ngIf="datasource.length > 0">
                <thead>
                    <tr>
                        <th>RFC / CURP</th>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>CLABE</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of datasource; let i = index">
                        <td>{{ item.rfc }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.clabe }}</td>
                        <td>
                            <span *ngIf="accountService.havePermission(applications, 'Proveedores Bajas Editar Datos')">
                              <a class="edit" (click)="showEditModal( item )" href="" data-toggle="modal" >
                                  <i class="far fa-edit"></i>
                              </a> |
                          </span>
                            <span *ngIf="item.bankId !== '00000000-0000-0000-0000-000000000000'">
                              <input (change)="handlerChecbox( $event.target.checked, item )" type="checkbox" checked >
                          </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="isWorking || !dataValid()" type="button" class="btn btn-outline-success" (click)="handlerClick()">
              <i [ngClass]="{ 'far fa-save': !isWorking, 'fas fa-sync-alt fa-spin': isWorking }"></i>
              <span *ngIf="!isWorking"> Procesar</span>
              <span *ngIf="isWorking" >
                  Procesando...
              </span>
          </button>
            </div>
        </div>

    </div>

</article>
