import { Routes } from '@angular/router';
import { TeacherDetailsComponent } from './teacher-details.component';
import { TeacherListComponent } from './teacher-list.component';
import { TeacherLowStaffComponent } from './teacher-low-staff.component';
import { TeacherComponent } from './teacher.component';
import { UnsubscribeComponent } from './unsubscribe.component';

export const TEACHERS_ROUTES: Routes = [
    { path: 'add', component: TeacherComponent },
    { path: 'add/:id/:searchValue', component: TeacherComponent },
    { path: 'edit/:id', component: TeacherComponent },
    { path: 'edit/:id/:searchValue', component: TeacherComponent },
    { path: 'details/:id', component: TeacherDetailsComponent },
    { path: 'details/:id/:searchValue', component: TeacherDetailsComponent },
    { path: 'unsubscribe/:id', component: UnsubscribeComponent },
    { path: 'unsubscribe/:id/:searchValue', component: UnsubscribeComponent },
    { path: 'low-staff/:id', component: TeacherLowStaffComponent },
    { path: 'low-staff/:id/:searchValue', component: TeacherLowStaffComponent },
    { path: '', component: TeacherListComponent },
    { path: ':searchValue', component: TeacherListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
