<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Cobranza</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                    <h3 class="title">
                        Maestros <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-right">
                    <a class="btn btn-outline-success" [routerLink]="['/banks/collections/proccess']">
                        <i class="fas fa-exchange-alt"></i>
                        <span>Procesar</span>
                    </a>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-right">
                    <a class="btn btn-outline-warning" [routerLink]="['/banks/collections/apply']">
                        <i class="fas fa-user-check"></i>
                        <span>Aplicar</span>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar..." (keyup.enter)="search()">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">

                    <!-- <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Tipo</span>
                        </div>
                    </div>
                -->
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Archivo</span>
                        </div>
                    </div>
                    <!-- <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Año</span>
                        </div>
                    </div>-->

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Aplicado</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>No Aplicado</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Importe</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Movimientos</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Fecha Movimento</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Fecha Aplicación</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header fixed item-col-actions-dropdown-teacher"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row" [ngClass]="{'required': item.active != true}">

                    <!-- <div class="item-col item-col-item">
                        <div class="item-heading">Tipo</div>
                        <div> {{ item.type }} </div>
                    </div> -->

                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Archivo</div>
                        <div>
                            <span>{{ item.fileName }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Aplicado</div>
                        <div> {{ item.appliedAmount | currency }} </div>
                    </div>

                    <div class="item-col item-col-item required">
                        <div class="item-heading">No Aplicado</div>
                        <div> {{ item.notAppliedAmount | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Importe</div>
                        <div> {{ item.notAppliedAmount + item.appliedAmount | currency }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Movimientos</div>
                        <div> {{ item.movementNumber }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Fecha Movimento</div>
                        <div> {{ item.movementDate | date: 'dd/MM/yyyy' }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Fecha Aplicación</div>
                        <div *ngIf="item.accountingDate !== '0001-01-01T00:00:00'"> {{ item.accountingDate | date: 'dd/MM/yyyy' }} </div>
                        <div *ngIf="item.accountingDate === '0001-01-01T00:00:00'"> N/A </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown">
                        <div class="item-list-actions text-center">
                            <span *ngIf="accountService.havePermission(applications, 'Cobranza Detalle')">
                                <a class="info" [routerLink]="['/banks/collections/details', item.id ]">
                                    <i class="fas fa-info-circle"></i>
                                </a>
                            </span>
                            <span *ngIf="accountService.havePermission(applications, 'Cobranza Fecha') && item.accountingDate === '0001-01-01T00:00:00' ">
                                | <a class="edit" (click)="showConfirm( item.id )" href="" data-toggle="modal" >
                                   <i class="fas fa-check"></i>
                               </a>
                           </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

    <ng-template appOpen></ng-template>

</article>