import { Routes } from '@angular/router';
import { CollectionHistoryComponent } from './collection-history.component';
import { CollectionComponent } from './collection.component';
import { CollectionDetailsComponent } from './collection-details.component';
import { CollectionApplyComponent } from './collection-apply.component';

export const COLLECTION_ROUTES: Routes = [
    // { path: 'history', component: CollectionHistoryComponent },
    { path: 'details/:id', component: CollectionDetailsComponent },
    { path: 'details/:id/:searchValue', component: CollectionDetailsComponent },
    { path: 'apply', component: CollectionApplyComponent },
    { path: 'proccess', component: CollectionComponent },
    { path: ':searchValue', component: CollectionHistoryComponent },
    { path: '', component: CollectionHistoryComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
