import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProfilesComponent } from './components/configuration/profiles/profiles.component';
import { PROFILES_ROUTES } from './components/configuration/profiles/profiles.routes';
import { AuthGuardService } from './services/auth-guard.service';
import { ModulesComponent } from './components/configuration/modules/modules.component';
import { MODULES_ROUTES } from './components/configuration/modules/modules.routes';
import { ApplicationsComponent } from './components/configuration/applications/applications.component';
import { APPLICATIONS_ROUTES } from './components/configuration/applications/applications.routes';
import { PermissionsComponent } from './components/configuration/permissions/permissions.component';
import { UsersComponent } from './components/configuration/users/users.component';
import { USERS_ROUTES } from './components/configuration/users/users.routes';
import { TeachersComponent } from './components/administration/teachers/teachers.component';
import { TEACHERS_ROUTES } from './components/administration/teachers/teachers.routes';
import { LOANS_ROUTES } from './components/administration/loans/loans.routes';
import { LoansComponent } from './components/administration/loans/loans.component';
import { SavingsComponent } from './components/administration/savings/savings.component';
import { SAVINGS_ROUTES } from './components/administration/savings/savings.routes';
import { DrawsComponent } from './components/administration/draws/draws.component';
import { DRAWS_ROUTES } from './components/administration/draws/draws.routes';
import { BulkLoadComponent } from './components/administration/bulk-load/bulk-load.component';
import { SavingReportComponent } from './components/reports/savings/saving-report.component';
import { PaymentReportComponent } from './components/reports/payments/payment-report.component';
import { LoanReportComponent } from './components/reports/loans/loan-report.component';
import { BalanceComponent } from './components/reports/balance/balance.component';
import { ConfigurationsComponent } from './components/configuration/configurations/configurations.component';
import { LOWSTAFF_ROUTES } from './components/administration/low-staffing/low-staff.routes';
import { LowStaffingComponent } from './components/administration/low-staffing/low-staffing.component';
import { PaymentsComponent } from './components/administration/payments/payments.component';
import { PAYMENTS_ROUTES } from './components/administration/payments/payments.routes';
import { DeathsComponent } from './components/reports/death/deaths.component';
import { IncomingComponent } from './components/reports/incoming/incoming.component';
import { ExpensesComponent } from './components/reports/expenses/expenses.component';
import { LoanIssuedReportComponent } from './components/reports/loans/loan-issued-report.component';
import { InterestsRecoverReportComponent } from './components/reports/loans/interests-recover-report.component';
import { PaymentPolicyComponent } from './components/policies/payments/payment-policy.component';
import { SavingPolicyComponent } from './components/policies/savings/saving-policy.component';
import { InterestsPolicyComponent } from './components/policies/savings/interests-policy.component';
import { AffiliationsComponent } from './components/banks/affiliations/affiliations.component';
import { AffiliationsApplyComponent } from './components/banks/affiliations/affiliations-apply.component';
import { CollectionApplyComponent } from './components/banks/collections/collection-apply.component';
import { SuppliersComponent } from './components/banks/suppliers/suppliers.component';
import { AccountingPaymentComponent } from './components/accounting/payments/payment.component';
import { MoneyOutComponent } from './components/policies/money-out/money-out.component';
import { TransferComponent } from './components/banks/transfers/transfer.component';
import { TRANSFERS_ROUTES } from './components/banks/transfers/transfers.routes';
import { AccountingSavingsComponent } from './components/accounting/accounting-savings/accounting-savings.component';
import { BeneficiariesComponent } from './components/accounting/beneficiaries/beneficiaries.component';
import { TEACHERGLOBAL_ROUTES } from './components/reports/teacher-global/teacher-global.routes';
import { TeacherGlobalsComponent } from './components/reports/teacher-global/teacher-globals.component';
import { InputsPolicyComponent } from './components/policies/inputs/inputs-policy.component';
import { UpsComponent } from './components/statistic/ups/ups.component';
import { SettlementPolicyComponent } from './components/policies/settlement/settlement-policy.component';
import { QuestionsComponent } from './components/reports/questions/questions/questions.component';
import { COLLECTION_ROUTES } from './components/banks/collections/collection.routes';
import { CollectionsComponent } from './components/banks/collections/collections.component';
import { WayToPaysComponent } from './components/administration/way-to-pay/way-to-pays.component';
import { WAYTOPAY_ROUTES } from './components/administration/way-to-pay/way-to-pay.routes';
import { SavingDetailsComponent } from './components/reports/saving-details/saving-details.component';
import { BankDepositsComponent } from './components/policies/bank-deposits/bank-deposits.component';
import { EmploymentFortnightComponent } from './components/reports/employment-fortnight/employment-fortnight.component';
import { InterestRecoverDetailsComponent } from './components/reports/loans/interest-recover-details.component';
import { SupportsComponent } from './components/administration/supports/supports.component';
import { SUPPORT_ROUTES } from './components/administration/supports/supports.routes';
import { DebitsComponent } from './components/policies/debits/debits.component';
import { DeadlinesComponent } from './components/administration/deadlines/deadlines.component';
import { DEADLINES_ROUTES } from './components/administration/deadlines/deadlines.routes';
import { ActiveTeachersComponent } from './components/reports/active-teachers/active-teachers.component';
import { CollectionsSeechComponent } from './components/administration/collections/collections-seech.component';
import { COLLECTION_SEECH_ROUTES } from './components/administration/collections/collection-seech.routes';
import { LowstaffingPolicyComponent } from './components/policies/lowstaffing/lowstaffing-policy.component';
import { CollectionBankListComponent } from './components/reports/collection-banks/collection-bank-list.component';
import { DataUpdatesComponent } from './components/administration/data-update/data-updates.component';
import { DATAUPDATE_ROUTES } from './components/administration/data-update/data-updates.routes';
import { LowStaffingGlobalComponent } from './components/reports/low-staffing-global/low-staffing-global.component';
import { LowStaffingGlobalAddComponent } from './components/reports/low-staffing-global/low-staffing-global-add.component';
import { LoanChecksTransfersComponent } from './components/reports/loans/loan-checks-transfers.component';
import { LoanChecksTransfersAddComponent } from './components/reports/loans/loan-checks-transfers-add.component';
import { AccountStatementsComponent } from './components/reports/account-statements/account-statements.component';
import { AccountStatusComponent } from './components/reports/account-statements/account-status.component';
import { TRANSFERSLOWSTAFF_ROUTES } from './components/banks/transfers-lowstaff/transfers-lowstaff.routes';
import { TransfersLowstaffComponent } from './components/banks/transfers-lowstaff/transfers-lowstaff.component';
import { SuppliersLowstaffComponent } from './components/banks/suppliers-lowstaff/suppliers-lowstaff.component';
import { LowStaffingReportsComponent } from './components/reports/low-staffing-report/low-staffing-reports.component';
import { LOWSTAFFREPORT_ROUTES } from './components/reports/low-staffing-report/low-staff-report.routes';
import { ImproperPaymentsComponent } from './components/administration/improper-payments/improper-payments.component';
import { IMPROPERPAYMNETS_ROUTES } from './components/administration/improper-payments/improper-payments.routes';
import { CollectionRecoverComponent } from './components/reports/collection-banks/collection-recover.component';
import { DebtorMasterComponent } from './components/reports/debtor-master/debtor-master.component';

const APP_ROUTES: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'configuration/profiles', component: ProfilesComponent,
        children: PROFILES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/profiles/:searchValue', component: ProfilesComponent,
        children: PROFILES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/modules', component: ModulesComponent,
        children: MODULES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/modules/:searchValue', component: ModulesComponent,
        children: MODULES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/applications', component: ApplicationsComponent,
        children: APPLICATIONS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/applications/:searchValue', component: ApplicationsComponent,
        children: APPLICATIONS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/permissions', component: PermissionsComponent,
        canActivate: [AuthGuardService] },
    { path: 'configuration/users', component: UsersComponent,
        children: USERS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/users/:searchValue', component: UsersComponent,
        children: USERS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/teachers', component: TeachersComponent,
        children: TEACHERS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/loans', component: LoansComponent,
        children: LOANS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/loans/:searchValue', component: LoansComponent,
        children: LOANS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/savings', component: SavingsComponent,
        children: SAVINGS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/savings/:searchValue', component: SavingsComponent,
        children: SAVINGS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/draws', component: DrawsComponent,
        children: DRAWS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/draws/:searchValue', component: DrawsComponent,
        children: DRAWS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/lowstaffing', component: LowStaffingComponent,
        children: LOWSTAFF_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/lowstaffing/:searchValue', component: LowStaffingComponent,
        children: LOWSTAFF_ROUTES, canActivate: [AuthGuardService] },
    { path: 'reports/lowstaffing', component: LowStaffingReportsComponent,
        children: LOWSTAFFREPORT_ROUTES, canActivate: [AuthGuardService] },
    { path: 'reports/lowstaffing/:searchValue', component: LowStaffingReportsComponent,
        children: LOWSTAFFREPORT_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/payments', component: PaymentsComponent,
        children: PAYMENTS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/payments/:searchValue', component: PaymentsComponent,
        children: PAYMENTS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/ways-to-pay', component: WayToPaysComponent,
        children: WAYTOPAY_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/supports', component: SupportsComponent,
        children: SUPPORT_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/deadlines', component: DeadlinesComponent,
        children: DEADLINES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/improper-payments', component: ImproperPaymentsComponent,
        children: IMPROPERPAYMNETS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/bulkload', component: BulkLoadComponent, canActivate: [AuthGuardService] },
    { path: 'reports/savings', component: SavingReportComponent, canActivate: [AuthGuardService] },
    { path: 'reports/payments', component: PaymentReportComponent, canActivate: [AuthGuardService] },
    { path: 'reports/loans', component: LoanReportComponent, canActivate: [AuthGuardService] },
    { path: 'reports/balance', component: BalanceComponent, canActivate: [AuthGuardService] },
    { path: 'reports/death', component: DeathsComponent, canActivate: [AuthGuardService] },
    { path: 'reports/incoming', component: IncomingComponent, canActivate: [AuthGuardService] },
    { path: 'reports/expenses', component: ExpensesComponent, canActivate: [AuthGuardService] },
    { path: 'reports/employment-fortnight', component: EmploymentFortnightComponent, canActivate: [AuthGuardService] },
    { path: 'reports/debtor-masters', component: DebtorMasterComponent, canActivate: [AuthGuardService] },
    { path: 'reports/loansgranted', component: LoanIssuedReportComponent, canActivate: [AuthGuardService] },
    { path: 'reports/loans-check-transfers', component: LoanChecksTransfersComponent, canActivate: [AuthGuardService] },
    { path: 'reports/loans-check-transfers/add', component: LoanChecksTransfersAddComponent, canActivate: [AuthGuardService] },
    { path: 'reports/active-teachers', component: ActiveTeachersComponent, canActivate: [AuthGuardService] },
    { path: 'reports/interestsrecover', component: InterestsRecoverReportComponent, canActivate: [AuthGuardService] },
    { path: 'reports/collection-banks', component: CollectionBankListComponent, canActivate: [AuthGuardService] },
    { path: 'reports/collection-recover', component: CollectionRecoverComponent, canActivate: [AuthGuardService] },
    { path: 'reports/interestsrecover/:year/:fortnight', component: InterestsRecoverReportComponent, canActivate: [AuthGuardService] },
    // tslint:disable-next-line:max-line-length
    { path: 'reports/interestsrecover/details/:year/:fortnight', component: InterestRecoverDetailsComponent, canActivate: [AuthGuardService] },
    { path: 'reports/teachers-global', component: TeacherGlobalsComponent,
        children: TEACHERGLOBAL_ROUTES, canActivate: [AuthGuardService] },
    { path: 'banks/collections', component: CollectionsComponent,
        children: COLLECTION_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/collections-seech', component: CollectionsSeechComponent,
        children: COLLECTION_SEECH_ROUTES, canActivate: [AuthGuardService] },
    { path: 'reports/questions', component: QuestionsComponent, canActivate: [AuthGuardService] },
    { path: 'configuration/configurations', component: ConfigurationsComponent, canActivate: [AuthGuardService] },
    { path: 'policies/payments', component: PaymentPolicyComponent, canActivate: [AuthGuardService] },
    { path: 'policies/savings', component: SavingPolicyComponent, canActivate: [AuthGuardService] },
    { path: 'policies/interests', component: InterestsPolicyComponent, canActivate: [AuthGuardService] },
    { path: 'policies/money-out', component: MoneyOutComponent, canActivate: [AuthGuardService] },
    { path: 'policies/inputs', component: InputsPolicyComponent, canActivate: [AuthGuardService] },
    { path: 'policies/settlement', component: SettlementPolicyComponent, canActivate: [AuthGuardService] },
    { path: 'policies/bank-deposits', component: BankDepositsComponent, canActivate: [AuthGuardService] },
    { path: 'policies/debits', component: DebitsComponent, canActivate: [AuthGuardService] },
    { path: 'policies/lowstaffing', component: LowstaffingPolicyComponent, canActivate: [AuthGuardService] },
    { path: 'banks/affiliations', component: AffiliationsComponent, canActivate: [AuthGuardService] },
    { path: 'banks/affiliations/apply', component: AffiliationsApplyComponent, canActivate: [AuthGuardService] },
    { path: 'banks/collections/apply', component: CollectionApplyComponent, canActivate: [AuthGuardService] },
    { path: 'accounting/payments', component: AccountingPaymentComponent, canActivate: [AuthGuardService] },
    { path: 'accounting/savings', component: AccountingSavingsComponent, canActivate: [AuthGuardService] },
    { path: 'accounting/beneficiaries', component: BeneficiariesComponent, canActivate: [AuthGuardService] },
    { path: 'banks/suppliers', component: SuppliersComponent, canActivate: [AuthGuardService] },
    { path: 'banks/suppliers-lowstaff', component: SuppliersLowstaffComponent, canActivate: [AuthGuardService] },
    { path: 'statistic/ups', component: UpsComponent, canActivate: [AuthGuardService] },
    { path: 'reports/saving-details', component: SavingDetailsComponent, canActivate: [AuthGuardService] },
    { path: 'reports/saving-details/:year/:fortnight', component: SavingDetailsComponent, canActivate: [AuthGuardService] },
    { path: 'reports/low-staffing-global', component: LowStaffingGlobalComponent, canActivate: [AuthGuardService] },
    { path: 'reports/low-staffing-global/add', component: LowStaffingGlobalAddComponent, canActivate: [AuthGuardService] },
    { path: 'reports/account-statements', component: AccountStatementsComponent, canActivate: [AuthGuardService] },
    { path: 'reports/account-statements/:searchValue', component: AccountStatementsComponent, canActivate: [AuthGuardService] },
    { path: 'reports/account-statements/account-status/:id', component: AccountStatusComponent, canActivate: [AuthGuardService] },
    // tslint:disable-next-line:max-line-length
    { path: 'reports/account-statements/account-status/:id/:searchValue', component: AccountStatusComponent, canActivate: [AuthGuardService] },
    { path: 'banks/transfers', component: TransferComponent ,
        children: TRANSFERS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'banks/transfers-lowstaff', component: TransfersLowstaffComponent ,
        children: TRANSFERSLOWSTAFF_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/data-update', component: DataUpdatesComponent,
        children: DATAUPDATE_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/data-update/:searchValue', component: DataUpdatesComponent,
        children: DATAUPDATE_ROUTES, canActivate: [AuthGuardService] },
    { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
