<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="['/administration/ways-to-pay', inicio, fin]" routerLinkActive="router-link-active">Formas de pago</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Detalle</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="gettingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4 class="info"><b>{{ payments[0]?.teacher.name | uppercase }} ({{ payments[0]?.teacher.rfc }})</b> <small>({{ amount | currency }})</small></h4>
                    <hr>
                </div>
            </div>

            <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

            <div class="row">
                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Tipo</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.type }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Tipo</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.movementDate | date:'dd/MM/yyyy' }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Folio</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.folio }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Importe Depósito</label>
                        <input readonly type="text" class="form-control underlined" value="{{ amount | currency }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <label class="control-label"> Forma de pago</label>
                    <select class="custom-select" formControlName="wayToPay">
                        <option value="Efectivo">Efectivo</option>
                        <option value="Transferencia">Transferencia</option>
                        <option value="Otro">Otro</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-right pt-3">
                    <button type="submit" class="btn btn-outline-primary" [disabled]="this.forma.controls['wayToPay'].value == '' || this.forma.controls['wayToPay'].value === null">
                        <i [ngClass]="{ 'far fa-save': !saving, 'fas fa-sync-alt fa-spin': saving } "></i>
                        <span *ngIf="!saving">Guardar</span>
                        <span *ngIf="saving">Guardaando</span>
                    </button>
                </div>
            </div>


            <div class="row" *ngIf="payments[0]?.type === 'Liquidación'">
                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Aportación</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.amountSaving | currency }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Producto Financiero</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.financialProducts | currency }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Prima de Garantia</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.lifeInsurance | currency }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Prima de Garantia Condonada</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.condoneLifeInsurance | currency }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Interes</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.interest | currency }}">
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" class="has-success">
                        <label class="control-label">Interes Condonado</label>
                        <input readonly type="text" class="form-control underlined" value="{{ payments[0]?.condoneInterest | currency }}">
                    </div>
                </div>
            </div>

            <hr>

            <div *ngFor="let payment of payments">
                <div class="row">
                    <label class="col-12">{{ payment.year }}</label>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 1</label>
                            <input readonly type="text" class="form-control underlined" value="{{ payment.fortnight1| currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 2</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight2 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 3</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight3 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 4</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight4 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 5</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight5 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 6</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight6 | currency }}">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 7</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight7 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 8</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight8 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 9</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight9 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 10</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight10 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 11</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight11 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 12</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight12 | currency }}">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 13</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight13 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 14</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight14 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 15</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight15 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 16</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight16 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 17</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight17 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 18</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight18 | currency }}">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 19</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight19 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 20</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight20 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 21</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight21 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 22</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight22 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 23</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight23 | currency }}">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <div class="form-group" class="has-success">
                            <label class="control-label"> Qna. # 24</label>
                            <input type="text" readonly class="form-control underlined" value="{{ payment.fortnight24 | currency }}">
                        </div>
                    </div>
                </div>
            </div>





        </form>

        <div class="row m-4">
            <a class="btn btn-outline-info " [routerLink]="['/administration/ways-to-pay', inicio, fin] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>

    </div>
</div>