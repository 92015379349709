import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../account/login/services/account.service';
import { ConfigurationsService } from '../../configuration/configurations/services/configurations.service';
import { SavingReportService } from '../../reports/savings/services/saving-report.service';
import { CurrencyPipe } from '@angular/common';
import { ConfigurationsInterface } from '../../configuration/configurations/interfaces/configurations.interface';
import { GlobalReportInterface } from '../../reports/savings/interfaces/global-report.interface';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SavingPolicyService } from '../../policies/savings/services/saving-policy.service';
declare let jsPDF;

@Component({
  selector: 'app-accounting-savings',
  templateUrl: './accounting-savings.component.html',
  styles: []
})
export class AccountingSavingsComponent implements OnInit {
  loadingData = false;
  errorMessage = '';
  forma: UntypedFormGroup;
  amount = 0;
  amountInterests = 0;
  percentageInterest = 0.00208;
  dataReport: any[] = [];
  dataReportGlobal: GlobalReportInterface[] = [];
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  configurations: ConfigurationsInterface =  {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };

  constructor( private reportService: SavingReportService, public accountService: AccountService,
    private currencyPipe: CurrencyPipe, private configurationsService: ConfigurationsService,
    private formBuilder: UntypedFormBuilder, private savingService: SavingPolicyService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      percentage: [0.00208]
    });

    this.configurationsService.get()
      .subscribe(configurations => {
        this.configurations = configurations;
        this.savingService.getYears()
        .subscribe(years => {
          this.years = years;
          this.loadData( );
        }, error => this.setErrorMessage(error));
      }, err => this.setErrorMessage(err));
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  loadData() {
    this.reportService.getAccountingSavings(this.forma.controls['year'].value, this.forma.controls['fortnight'].value)
      .subscribe((data) => {
        this.dataReport = data;
        if (this.dataReport.length > 0) {
          this.calculateAmount(data, this.forma.controls['percentage'].value);
        }
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  calculateAmount(data: any[], percentage: number) {
    this.amount = 0;
    this.dataReportGlobal = [];
    this.amountInterests = 0;
    let interestWithImport = 0;
    let teacherRFC = '';

    data.forEach(year => {
      if ( year.rfc !== teacherRFC ) {
        teacherRFC = year.rfc;
        interestWithImport = 0;
      }
      let interestPeritem = 0;
      const arrayYear = Object.keys(year).map(function(key) {
        return [String(key), year[key]];
      });

      for (let index = 0; index < arrayYear.length; index++) {
        if ( arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA' ) {
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
          }
        }
      }
      year.interests = parseFloat(interestPeritem.toFixed(2));
      this.amountInterests += year.interests;
      const globalYear = this.dataReportGlobal.find(d => d.year === year.year);
        const indexp = this.dataReportGlobal.indexOf(globalYear);
        if ( indexp === -1 ) {
          const itemToAdd: GlobalReportInterface = {
            year: year.year,
            amount: year.total,
            interest: year.interests
          };
          this.dataReportGlobal.push(itemToAdd);
        } else {
          this.dataReportGlobal[indexp].amount += year.total;
          this.dataReportGlobal[indexp].interest += year.interests;
        }
      this.amount += year.total;
    });
  }

  getPDF(): void {
    const totalPagesExp = '{total_pages_count_string}';
    const head = this.getHeader();
    const body = this.setBodyData(this.dataReport);
    this.generateGlobalDocument(head, body, totalPagesExp, this.configurations.imageSNTE,
      this.configurations.imageFOCAPS, this.configurations.messagePDF);
  }

  getHeader(): any[] {
    return [
      { year: 'Año', amount: 'Subtotal', interest: 'Insteres' }
    ];
  }

  setBodyData(data: any[]): any[] {
    const body = [];
    this.dataReportGlobal.forEach(item => {
      body.push({
        year: item.year,
        amount: this.currencyPipe.transform(item.amount),
        interest: this.currencyPipe.transform(item.interest)
      });
    });

    body.push({ year: `Subtotal :${ this.currencyPipe.transform(this.amount) }` });
    body[body.length - 1].year = { content: `Subtotal : ${ this.currencyPipe.transform(this.amount) }
    INTERES : ${ this.currencyPipe.transform(this.amountInterests) }
    TOTAL : ${ this.currencyPipe.transform( this.amount + this.amountInterests ) }`,
      colSpan: 3, styles: { halign: 'right', fontStyle: 'bold' } };
    return body;
  }

  generateGlobalDocument(head: any[], body: any[], totalPagesExp: string, imageSNTE: string, imageFOCAPS: string,
    message: string): void {
    const doc = new jsPDF();
    const pageContent = function (data) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if ( imageSNTE ) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
     }

     if ( imageFOCAPS ) {
       doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45 , 15, 30, 20);
     }
      doc.text('FONDO DE CAPITALIZACION Y AHORRO', data.settings.margin.left + 55, 20);
      doc.text('PARA APOYO A PRESTACIONES SOCIALES', data.settings.margin.left + 55, 24);

      doc.setFontSize(10);
      const lines = doc.splitTextToSize(message,
        doc.internal.pageSize.width - data.settings.margin.left - data.settings.margin.right);

      doc.text(lines, data.settings.margin.left + 3, 44);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 58 },
      headStyles: {
        halign: 'center'
      },
      columnStyles: {
        interest: {
          halign: 'right'
        },
        amount: {
          halign: 'right'
        },
        year: {
          halign: 'right'
        }
      },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`Global.pdf`);
  }

  searchDataDetailed( percentage: number) {
    this.percentageInterest = percentage;
    this.calculateAmount(this.dataReport, this.percentageInterest);
  }

  searchData(): void {
    this.loadingData = true;
    this.loadData();
  }

}
