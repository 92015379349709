import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { BankInterface } from '../banks/interfaces/bank.interface';
import { BankService } from '../banks/services/bank.service';
import { TeacherInterface } from '../teachers/interface/teacher.interface';
import { TeacherService } from '../teachers/services/teacher.service';
import { SupportCreateInterface } from './interfaces/support-create.interface';
import { SupportService } from './services/support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styles: []
})
export class SupportComponent implements OnInit {
  loadingData = false;
  sendingData = false;
  errorMessage = '';
  forma: UntypedFormGroup;
  banks: BankInterface[] = [];
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    lastStatus: 'Activo',
    applied: true,
    credit: 0,
    lastUpdated: new Date,
    type: '',
    fortnight: '',
    userIdLastUpdated: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    clabe: '',
    curp: '',
    bankId: '00000000-0000-0000-0000-000000000000',
    member: true,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: '',
    NumberOfLoans: 0,
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: '',
      code: ''
    },
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: []
  };
  supportCreate: SupportCreateInterface;

  constructor(
    private teacherService: TeacherService,
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private supportService: SupportService,
    private router: Router,
    private bankService: BankService
  ) { }

  ngOnInit() {
    this.loadingData = true;
    this.buildForm();
    this.activatedRoute.params.subscribe(params => {
      this.bankService.get('null').subscribe(
        banks => {
          this.banks = banks;
          if ( params['teacher'] ) {
            this.teacherService.getById(params['teacher']).subscribe( teacher => {
              this.teacher = Object.assign({}, teacher);
              this.forma.controls['teacherId'].patchValue(this.teacher.id);
              this.forma.controls['userId'].patchValue(this.accountService.getUserId());
              this.loadingData = false;
            }, error => this.setErrorMessage(error));
          } else {
            this.errorMessage = 'No se encontro el maestro';
            this.loadingData = true;
          }
        }, error => this.setErrorMessage(error));
    }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if ( error.error ) {
      this.errorMessage = error.error;
    } else {
      this.errorMessage = error.message;
    }

    this.loadingData = false;
    this.sendingData = false;
  }

  buildForm() {
    this.forma = this.formBuilder.group({
      teacherId: ['', [Validators.required]],
      curp: ['', [Validators.required, Validators.minLength(18)]],
      name: ['', [Validators.required, Validators.minLength(6)]],
      movementType: ['', [Validators.required]],
      relationship: ['', [Validators.required]],
      // tslint:disable-next-line:max-line-length
      movementDate: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`, [Validators.required]],
      comment: [''],
      occupation: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      age: ['0', [Validators.required, Validators.min(0), Validators.max(120)]],
      proceeds: ['true'],
      account: [''],
      email: ['', [Validators.email]],
      phone: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      // phone: ['', [Validators.pattern('/^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/')]],
      bankId: ['']
    });
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  sendForm() {
    this.sendingData = true;
    this.supportCreate = this.forma.value;
    console.log( this.forma );
    console.log(this.supportCreate);
    this.supportService.create(this.supportCreate).subscribe((response) => {
      this.router.navigate(['administration/teachers']);
    }, error => this.setErrorMessage(error));
  }

  formValid(): boolean {
    if ( !this.forma.valid ) {
      return false;
    }
    if ( this.forma.controls['movementType'].value === 'Transferencia' ) {
      if ( this.forma.controls['email'].value === '' || this.forma.controls['phone'].value === '' || this.forma.controls['bankId'].value === '' || this.forma.controls['account'].value === '' ) {
        return false;
      }
    }
    return true;
  }

}
