import { ChecksAddInterface } from './../interfaces/checks-add.interface';
import { ChecksInterface } from './../interfaces/checks.interface';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ResponseInterface } from '../../../../interfaces/response.interface';

@Injectable()
export class ChecksService {
  private apiURL = 'api/Reports/Checks';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    get( searchValue: string, startDate: string, endDate: string ) {
      return this.http.get<any>( `${ this.apiURL }/Search/${ startDate }/${ endDate }/${ searchValue }` );
    }

    delete( id: string ) {
      return this.http.delete<any>( `${ this.apiURL }/${ id }`);
    }

    create( check: ChecksAddInterface ) {
      return this.http.post<ResponseInterface>(`${ this.apiURL }/Create`, check).toPromise();
    }

}
