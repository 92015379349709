<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Póliza Egresos ( {{ dataReport.length }} )</h3>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button type="button" class="btn btn-outline-success" (click)="exportData()">
                    <i class="far fa-file-excel"></i> Exportar
                  </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="input-box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Inicio</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <input type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Al</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <input type="date" name="fin" [value]="fin" [(ngModel)]="fin">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <button type="button" class="btn btn-outline-primary" (click)="loadData()">
                                Buscar
                              </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Registro</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Aplicación</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Concepto</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Forma de Pago</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Beneficiario</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Referencia 1</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Referencia 2</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Monto</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="item" *ngFor="let item of dataReport; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Registro</div>
                        <div>
                            <span>{{ item.registro }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Aplicación</div>
                        <div>
                            <span>{{ item.aplicacion }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Forma de Pago</div>
                        <div>
                            <span>{{ item.formaPago }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Beneficiario</div>
                        <div>
                            <span>{{ item.beneficiario }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Referencia 1</div>
                        <div>
                            <span>{{ item.referencia }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Referencia 2</div>
                        <div>
                            <span>{{ item.referencia2 }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Monto</div>
                        <div>
                            <span>{{ item.monto | currency }}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</article>