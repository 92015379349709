import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExcelService } from '../../../services/excel.service';
import { AccountService } from '../../account/login/services/account.service';
import { LowStaffService } from '../../administration/low-staffing/services/low-staff.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { CancelTransferInterface } from '../transfers/interfaces/cancelTransfer.interface';
import { TransferInterface } from '../transfers/interfaces/transfer.interface';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { InputTypes } from '../../../../app/enums/sweetalert.enum';

@Component({
  selector: 'app-transfers-lowstaffing',
  templateUrl: './transfers-lowstaffing.component.html',
  styles: []
})
export class TransfersLowstaffingComponent implements OnInit {
  isLoadingData = false;
  errorMessage: string;
  isWorking = false;
  datasource: Array<TransferInterface> = [];
  unaffiliated: Array<TransferInterface> = [];
  jsonArray: Array<TransferInterface> = [];
  years: number[] = [];
  forma: UntypedFormGroup;
  // tslint:disable-next-line:max-line-length
  fortnights: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
  SPEI = 'false';
  applications: ApplicationInterface[];

  constructor( private lowStaffService: LowStaffService,
    public accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService,
    private notificationService: NotificationService,
    private helperService: HelperService ) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.accountService.getApplications('banks', 'transfers-lowstaff')
      .subscribe(applications => {
        this.applications = applications;
        this.getYears();
        this.forma = this.formBuilder.group({
          year: [new Date().getFullYear()],
          fortnight: [this.getNumberFortnight()],
        });
        this.loadData( );
      }, error => this.helperService.setErrorMessage(error));
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    if ( numberFortnight.toString().length === 1 ) {
      return `0${ numberFortnight.toString() }`;
    }

    return numberFortnight.toString();
  }

  getYears(): void {
    for (let index = new Date().getFullYear() - 5; index < new Date().getFullYear() + 5; index++) {
      this.years.push(index);
    }
  }

  loadData( ) {
    this.isLoadingData = true;
    this.lowStaffService.getTreansferPendings( )
    .subscribe(data => {
      this.jsonArray = data;
      if ( this.SPEI === 'true' ) {
        this.datasource = data.filter( f => f.itIsSupplier === true && f.bankId.toUpperCase() !== 'BE28FF92-E845-41BB-8BEE-3060267A7023');
        // tslint:disable-next-line: max-line-length
        this.unaffiliated = data.filter( f => f.itIsSupplier === false && f.bankId.toUpperCase() !== 'BE28FF92-E845-41BB-8BEE-3060267A7023');
      } else {
        this.datasource = data.filter( f => f.itIsSupplier === true && f.bankId.toUpperCase() === 'BE28FF92-E845-41BB-8BEE-3060267A7023');
        // tslint:disable-next-line: max-line-length
        this.unaffiliated = data.filter( f => f.itIsSupplier === false && f.bankId.toUpperCase() === 'BE28FF92-E845-41BB-8BEE-3060267A7023');
      }
      this.selectAll();
      this.isLoadingData = false;
    }, error => {
      this.helperService.setErrorMessage(error);
    });
  }

  selectAll(): void {
    this.datasource.forEach(element => {
      element.active = true;
    });
  }

  getExcel() {
    const datos = this.jsonArray.map( function(item) {
      return {
        'Nombre': item.name,
        'RFC': item.rfc,
        'CURP': item.curp,
        'Ahorro': item.saving,
        'Interes': item.interest,
        'Prestamo': item.credit,
        'Prestamo Interes': item.creditInterest,
        'Importe': item.amount,
        'Cuenta': item.clabe,
        'Banco': item.bankDescription,
        'tipo': item.type
      };
    });
    this.excelService.exportAsExcelFile(datos,
      `Transferencias`);
  }

  handlerType( spei: string) {
    this.SPEI = spei;
    this.loadData( );
  }

  async showCancelConfirm(id: string, rfc: string, name: string) {
    const comment = await this.notificationService.showConfirmInput(`¿Seguro que desea cancelar la transferencia ${ rfc } ( ${ name } )?`, 'Cancelación de transferencias', InputTypes.Textarea );
    if(comment !== null) {
      const transfer: CancelTransferInterface = {
        loanId: id,
        userId: this.accountService.getUserId(),
        comment: comment
      };
      this.deleteItem(transfer);
    }
  }

  deleteItem(transfer: CancelTransferInterface) {
    this.lowStaffService.update(transfer).then( response => {
        this.loadData( );
        this.notificationService.toastConfirm('Se cancelo correctamente la transferencia');
      }).catch( error => this.helperService.setErrorMessage(error));
  }

  handlerChecbox(checked: boolean, item: any): void {
    item.active = checked;
  }

  dataValid(): boolean {
    return this.datasource.some( l => l.active === true );
  }

  handlerClick(): void {
    this.isWorking = true;
    // tslint:disable-next-line:max-line-length
    this.lowStaffService.postProvider(`${ this.forma.controls['year'].value }-${ this.forma.controls['fortnight'].value }`, this.datasource.filter(d => d.active === true))
      .then((response) => {
        if ( response.isSuccess ) {
          if ( this.SPEI === 'true' ) {
          // tslint:disable-next-line:max-line-length
            this.descargarArchivo(this.generarTextoSPEI(response.result), `Transferencias_Bajas_SPEI_${new Date().getTime()}.txt`);
          } else {
          // tslint:disable-next-line:max-line-length
            this.descargarArchivo(this.generarTextoTerceros(response.result), `Transferencias_Bajas_Terceros_${new Date().getTime()}.txt`);
          }
          // this.descargarArchivo(this.generarTextoTerceros(response.result), `Transferencias_Bajas_Terceros_${new Date().getTime()}.txt`);
          this.lowStaffService.getTreansferPendings( )
            .subscribe(data => {
              this.datasource = data;
              this.isWorking = false;
            }, error => {
              this.helperService.setErrorMessage(error);
            });
        } else {
          this.errorMessage = response.message;
        }
      }).catch(error => {
        this.helperService.setErrorMessage(error);
      });
  }

  descargarArchivo(contenidoEnBlob, nombreArchivo) {
    const reader: FileReader = new FileReader();
    reader.onload = function (event: Event) {
      const save = document.createElement('a');
      save.href = reader.result as string; // event.target.result;
      save.target = '_blank';
      save.download = nombreArchivo || 'archivo.txt';
      const clicEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      save.dispatchEvent(clicEvent);
      (window.URL).revokeObjectURL(save.href);
    };
    reader.readAsDataURL(contenidoEnBlob);
  }

  generarTextoSPEI(transfers: Array<TransferInterface>) {
    const texto = [];
    transfers.forEach(element => {
      // tslint:disable-next-line:max-line-length
      texto.push(`${ element.key }\t${ element.rfc }\t${ element.accountSNTE }\t'${ element.clabe }\t${ this.completeLeft( this.evaluateDecimals( element.amount ), 16, '0') }\t${ element.loanNumber }\t${ this.completeRight( this.clearDescription( element.description ), 40, ' ')}\t${ element.rfcsnte }\t00000000000000\t\t${ this.completeRight( this.clearDescription( element.description ) , 100, ' ') }\t0000000\n`);
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  evaluateDecimals(amount: number): string {
    let amountString = '';
    const arrAmount = amount.toString().split('.');
    if ( arrAmount.length > 1 ) {
      if ( arrAmount[1].length === 1 ) {
        amountString = `${ arrAmount[0] }.${ arrAmount[1] }0`;
      } else {
        amountString = `${ arrAmount[0] }.${ arrAmount[1] }`;
      }
    } else {
      amountString = `${ arrAmount[0] }.00`;
    }
    return amountString;
  }

  completeLeft(text: string, numberCharacters: number, character: string): string {
    let textReturned = '';
    for (let index = text.length; index < numberCharacters; index++) {
      textReturned = `${ character }${ textReturned }`;
    }
    return textReturned + text;
  }

  completeRight(text: string, numberCharacters: number, character: string): string {
    for (let index = text.length; index < numberCharacters; index++) {
      text = `${ text }${ character }`;
    }
    return text;
  }

  generarTextoTerceros(transfers: Array<TransferInterface>) {
    const texto = [];
    transfers.forEach(element => {
      // tslint:disable-next-line:max-line-length
      texto.push(`${ element.key }\t${ element.rfc }\t${ element.accountSNTE }\t'${ element.clabe }\t${ this.completeLeft( this.evaluateDecimals( element.amount ), 16, '0') }\t${ element.loanNumber }\t${ this.completeRight( this.clearDescription( element.description ), 40, ' ')}\t${ element.rfcsnte }\t00000000000000\t\t${ this.completeRight('x', 100, ' ') }\t0000000\n`);
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  clearDescription(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

}
