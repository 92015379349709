import { Routes } from '@angular/router';
import { WayToPayListComponent } from './way-to-pay-list.component';
import { WayToPayDetailsComponent } from './way-to-pay-details.component';

export const WAYTOPAY_ROUTES: Routes = [
    { path: 'detail/:folio/:type/:start/:end', component: WayToPayDetailsComponent },
    { path: ':start/:end', component: WayToPayListComponent },
    { path: '', component: WayToPayListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
