import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AffiliationService } from './services/affiliation.service';

@Component({
  selector: 'app-affiliations',
  templateUrl: './affiliations.component.html',
  styles: []
})
export class AffiliationsComponent implements OnInit {
  loadingData = false;
  working = false;
  numberCharacters = 180;
  selectedType = '';
  jsonArray = [];
  applications: ApplicationInterface[];

  constructor(private affiliationService: AffiliationService, public accountService: AccountService) { }

  ngOnInit() {
    this.accountService.getApplications('administration', 'teachers')
      .subscribe(applications => {
        this.applications = applications;
        this.selectedType = 'Media Superior';
        this.loadData( this.selectedType );
      }, error => this.errorMessage( error ));
  }

  errorMessage(error) {
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  loadData( type: string) {
    this.loadingData = true;
    this.affiliationService.get( type )
      .subscribe(data => {
        this.jsonArray = data;
        this.loadingData = false;
      },
        error => this.errorMessage(error));
  }

  generateFile(): void {
    this.working = true;

    this.descargarArchivo(this.generarTexto( this.jsonArray ), this.selectedType + new Date().getTime() + '.txt');
    this.working = false;

    /* this.affiliationService.apply(this.accountService.getUserId(), this.selectedType)
    .subscribe(data => {
      this.descargarArchivo(this.generarTexto( data ), this.selectedType + new Date().getTime() + '.txt');
      this.loadData( this.selectedType );
    this.working = false;
    }, error => this.errorMessage(error)); */
  }

  descargarArchivo(contenidoEnBlob, nombreArchivo) {
    const reader: FileReader = new FileReader();
    reader.onload = function (event: Event) {
      const save = document.createElement('a');
      save.href = reader.result as string; // event.target.result;
      save.target = '_blank';
      save.download = nombreArchivo || 'archivo.alt';
      const clicEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      save.dispatchEvent(clicEvent);
      (window.URL).revokeObjectURL(save.href);
    };
    reader.readAsDataURL(contenidoEnBlob);
  }

  generarTexto(data: any[]) {
    const texto = [];
    texto.push(this.completeRight(this.getHeader(), this.numberCharacters, ' '));
    data.forEach(element => {
      let referencia = 'J' + element.curp;
      if ( this.selectedType === 'Media Superior' ) {
        referencia = 'M' + element.curp;
      }

      if ( element.bank !== null && element.documentNumber !== null) {
        // tslint:disable-next-line:max-line-length
        texto.push(this.completeRight(`\nD${ this.completeRight(referencia, 40, ' ') }${ this.completeLeft('2500000', 15, '0') }${ this.completeLeft(element.bank.code, 3, '0') }${ this.completeRight(element.name, 40, ' ') }40${ element.clabe }2${ element.document }${ this.completeLeft(element.documentNumber, 20, '0') }${ element.curp }0100A`, this.numberCharacters + 1, ' '));
      }
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  getHeader(): string {
    // tslint:disable-next-line:max-line-length
    return `H01205${ new Date().getFullYear().toString() }${ this.completeLeft((new Date().getMonth() + 1).toString(), 2, '0') }${ this.completeLeft(new Date().getDate().toString(), 2, '0') }${ this.completeLeft(this.jsonArray.length.toString(), 6, '0') }000`;
  }

  completeLeft(text: string, numberCharacters: number, character: string): string {
    let textReturned = '';
    for (let index = text.length; index < numberCharacters; index++) {
      textReturned = `${ character }${ textReturned }`;
    }
    return textReturned + text;
  }

  completeRight(text: string, numberCharacters: number, character: string): string {
    for (let index = text.length; index < numberCharacters; index++) {
      text = `${ text }${ character }`;
    }
    return text;
  }

  handlerType( type: string) {
    this.selectedType = type;
    this.loadData( type );
  }
}
