<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Polizas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Aportaciones ( {{ dataReport.length }} )</h3>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button type="button" class="btn btn-outline-success" [disabled]="dataReport.length === 1" (click)="exportData()">
                  <i class="far fa-file-excel"></i> Exportar
                </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="input-box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Inicio</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <input type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Al</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <input type="date" name="fin" [value]="fin" [(ngModel)]="fin">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <button type="button" class="btn btn-outline-primary" (click)="loadData()">
                              Buscar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Cuenta</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Concepto</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Cargo</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Abono</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="item" *ngFor="let item of dataReport; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Cuenta</div>
                        <div>
                            <span>{{ item.cuenta }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Concepto</div>
                        <div>
                            <span>{{ item.concepto }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Cargo</div>
                        <div>
                            <span>{{ (item.cargo === 0) ? '' : item.cargo }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Abono</div>
                        <div>
                            <span>{{ (item.abono === 0) ? '' : item.abono }}</span>
                        </div>
                    </div>

                </div>
            </li>
        </ul>
    </div>
</article>