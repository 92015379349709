import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LowStaffService } from '../../administration/low-staffing/services/low-staff.service';

@Component({
  selector: 'app-transfers-lowstaff-detail',
  templateUrl: './transfers-lowstaff-detail.component.html',
  styles: []
})
export class TransfersLowstaffDetailComponent implements OnInit {
  isLoadingData = false;
  errorMessage: string;
  jsonArray = [];
  currentPage = 1;
  itemsPerPage = 100;

  constructor( private lowStaffService: LowStaffService,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.activatedRoute.params.subscribe(params => {

      this.lowStaffService.getDetails(params['block'])
        .subscribe(data => {
          this.jsonArray = data;
          console.log( this.jsonArray );
          this.isLoadingData = false;
        }, error => {
          this.setErrorMessage(error);
          this.isLoadingData = false;
        });
    }, error => {
      this.setErrorMessage(error);
      this.isLoadingData = false;
    });
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.isLoadingData = false;
    }
  }

}
