<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="[pathReturn, searchValue]" routerLinkActive="router-link-active">Ahorros</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">{{ title }}</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="gettingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4 class="info"><b>{{ teacherName | uppercase }}</b> <small>({{ amount | currency }})</small></h4>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <label class="control-label"> Tipo</label>
                    <select class="custom-select" formControlName="type" *ngIf="title != 'Editar'">
                        <option value="Aportación">Aportación</option>
                        <option value="Ahorro">Ahorro</option>
                    </select>
                    <select class="custom-select" formControlName="type" disabled *ngIf="title == 'Editar'">
                        <option value="Aportación">Aportación</option>
                        <option value="Ahorro">Ahorro</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2" *ngIf="forma.controls['type'].value == 'Aportación'">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['movementDate'].valid && forma.controls['movementDate'].touched, 'has-success': forma.controls['movementDate'].valid && forma.controls['movementDate'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Fecha</label>
                        <input type="date" class="form-control underlined" formControlName="movementDate">
                        <span *ngIf="forma.controls['movementDate'].touched && forma.controls['movementDate'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['folio'].valid && forma.controls['folio'].touched, 'has-success': forma.controls['folio'].valid }">
                        <label class="control-label">Folio</label>
                        <input type="text" class="form-control underlined" formControlName="folio">
                        <span *ngIf="forma.controls['folio'].touched && forma.controls['folio'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['amount'].valid && forma.controls['amount'].touched, 'has-success': forma.controls['amount'].valid }">
                        <label class="control-label">Importe</label>
                        <input type="text" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." class="form-control underlined" formControlName="amount">
                        <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group">
                        <label class="control-label">Acumulado</label>
                        <input type="text" maxlength="250" class="form-control underlined" readonly value="{{ amount | currency  }}">
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <button type="button" class="btn btn-outline-secondary" [disabled]="!forma.valid || this.forma.controls['amount'].value < 0" (click)="addSaving()">
                            <i class="far fa-plus-square"></i> Agregar
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div formArrayName="savings" *ngFor="let payment of forma.get('savings')['controls']; let i = index">
                    <div [formGroupName]="i">
                        <div class="row" *ngIf="payment.get('year').value">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label">Año</label>
                                    <input type="text" maxlength="250" class="form-control underlined" readonly name="year" formControlName="year">
                                </div>
                            </div>

                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label">Quincena</label>
                                    <input type="text" maxlength="250" class="form-control underlined" readonly name="fortnight" formControlName="fortnight">
                                </div>
                            </div>

                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label class="control-label">Importe</label>
                                    <input type="text" maxlength="250" class="form-control underlined" readonly name="amount" formControlName="amount">
                                </div>
                            </div>

                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <button type="button" class="btn btn-outline-danger" (click)="deleteSaving( i )">
                                    <i class="far fa-trash-alt"></i> Eliminar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group text-center ">
                <button [disabled]="!forma.valid || sendingData || amount == 0" class="btn " [ngClass]="{ 'btn-outline-success': title=='Agregar' , 'btn-outline-warning': title=='Editar' } ">
                    <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                    <span *ngIf="!sendingData ">Guardar</span>
                    <span *ngIf="sendingData ">Guardando...</span>
                </button>
            </div>

            <app-error-message *ngIf="message" [message]="message"></app-error-message>
        </form>

        <div class="row">
            <a class="btn btn-outline-info " [routerLink]="[pathReturn, searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>

    </div>
</div>
