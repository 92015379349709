import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ChecksAddInterface } from './interfaces/checks-add.interface';
import { ChecksService } from './services/checks.service';

@Component({
  selector: 'app-low-staffing-global-add',
  templateUrl: './low-staffing-global-add.component.html',
  styles: []
})
export class LowStaffingGlobalAddComponent implements OnInit {
  forma: UntypedFormGroup;
  sendingData = false;
  errorMessage = '';
  check: ChecksAddInterface = {
    movementDate: new Date,
    number: 0,
    origin: '',
    amount: 0,
    comment: ''
  }

  constructor( private checksService: ChecksService,
    private router: Router ) { }

  ngOnInit() {
    this.createForm();
    this.forma.setValue( this.check );
  }

  createForm(): void {
    this.forma = new UntypedFormGroup({
      movementDate: new UntypedFormControl('', [Validators.required]),
      number: new UntypedFormControl('0', [Validators.required, Validators.min(1)]),
      amount: new UntypedFormControl('0', [Validators.required, Validators.min(0)]),
      origin: new UntypedFormControl('', [Validators.required]),
      comment: new UntypedFormControl('', [Validators.maxLength(250)])
    });
  }

  sendForm(): void {
    this.sendingData = true;
    this.check = Object.assign({}, this.forma.value);
    this.checksService.create(this.check).then( (response) => {
      if( response.isSuccess ) {
        this.router.navigate( ['reports/low-staffing-global'] );
      } else {
        this.errorMessage = response.message;
      }
    } ).catch( (error => this.setErrorMessage(error)));
  }
  setErrorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.sendingData = false;
    }
  }

}
