import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentPolicyInterface } from '../interfaces/payment-policy.interface';

@Injectable()
export class PaymentPolicyService {
  private apiURL = 'api/policies/Payments';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  /* get(year: number, fortnight: number, source: string) {
    return this.http.get<any>(this.apiURL + '/' + year + '/' + fortnight + '/' + source);
  } */

  getDomiciliations( paymentPolicy: PaymentPolicyInterface ) {
    return this.http.post<any>(`${this.apiURL}/Domiciliations`, paymentPolicy);
  }

  getSeechData( year: number, fortnight: number ) {
    return this.http.get<any>(`${this.apiURL}/Seech/${year}/${fortnight}`);
  }

  getYears() {
    return this.http.get<any>(`${this.apiURL}`);
  }
}
