<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3 class="title">
                        Maestros <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button *ngIf="accountService.havePermission(applications, 'Maestros Agregar')" type="button" class="btn btn-outline-primary" (click)="agregar()">
                        <i class="fas fa-plus"></i> Agregar
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar..." (keyup.enter)="search()">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Número</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>RFC</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>CURP</span>
                        </div>
                      </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Estatus</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header fixed item-col-actions-dropdown-teacher"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row" [ngClass]="{'required': item.active != true}">
                    <div class="item-col item-col-item">
                        <div class="item-heading">Número</div>
                        <div> {{ item.identifier }} </div>
                    </div>
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">RFC</div>
                        <div> {{ item.rfc }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">CURP</div>
                        <div> {{ item.curp }} </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Estatus</div>
                        <div> {{ item.type }} </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown-teacher">
                        <div class="item-list-actions text-center" *ngIf="item.type !== 'Inactivo'">
                            <span *ngIf="item.active === true && accountService.havePermission(applications, 'Maestros Prestamos') && !haveLoanActive(item.loans)">
                            <a class="info" [routerLink]="['/administration/loans/add', item.id ]" data-bs-toggle="tooltip" data-bs-title="Default tooltip">
                              <i class="fas fa-money-check"></i>
                            </a>|</span>
                            <span *ngIf="item.active === true && accountService.havePermission(applications, 'Maestros Ahorros')">
                            <a class="success" [routerLink]="['/administration/savings/add', item.id ]">
                                <i class="fas fa-piggy-bank"></i>
                            </a>|</span>
                            <span *ngIf="item.active === true && accountService.havePermission(applications, 'Maestros Baja')">
                            <a class="required" [routerLink]="['/administration/teachers/low-staff', item.id ]">
                                <i class="far fa-trash-alt"></i>
                            </a>|
                            </span>


                            <span *ngIf="item.active === true && accountService.havePermission(applications, 'Maestros Apoyos')">
                                <a class="required" [routerLink]="['/administration/supports/add', item.id ]" >
                                    <i class="fas fa-chalkboard-teacher"></i>
                                </a>|</span>

                            <span *ngIf="accountService.havePermission(applications, 'Maestros Cobros Indebidos')">
                                <a class="secundary" [routerLink]="['/administration/improper-payments/add', item.id ]" >
                                    <i class="fab fa-creative-commons-nc"></i>
                                </a>|
                            </span>

                            <span *ngIf="item.active === true && accountService.havePermission(applications, 'Maestros Editar')">
                            <a class="edit" [routerLink]="['/administration/teachers/edit', item.id ]">
                                <i class="far fa-edit"></i>
                            </a>|
                            </span>
                            <span *ngIf="item.active === false && item.applied === true && item.type !== 'Defunción' && accountService.havePermission(applications, 'Maestros Reingreso')">
                                <a class="primary" (click)="showConfirmReentry(item)" href="" data-toggle="modal" >
                                    <i class="fas fa-user-check"></i>
                                </a> |
                            </span>
                            <span *ngIf="item.type === 'Básico' && accountService.havePermission(applications, 'Maestros Domiciliación')">
                                <a class="primary" (click)="showConfirmDirectDebitPayment( item )" href="" data-toggle="modal" >
                                    <i class="fas fa-donate"></i>
                                </a> |
                            </span>

                            <span *ngIf="item.type === 'Básico Domiciliado' && accountService.havePermission(applications, 'Maestros Domiciliación')">
                                <a class="required" (click)="showConfirmRemoveDirectDebitPayment( item )" href="" data-toggle="modal" >
                                    <i class="fas fa-donate"></i>
                                </a> |
                            </span>

                            <span *ngIf="item.active === false && item.applied === true && accountService.havePermission(applications, 'Maestros Detalle')">
                                <a class="primary" [routerLink]="['/administration/teachers/details', item.id, searchValue ]" >
                                    <i class="fas fa-info-circle"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

</article>
