import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExcelService } from '../../../services/excel.service';
import { PaymentService } from '../../administration/payments/services/payment.service';
import { TeacherService } from '../../administration/teachers/services/teacher.service';
import { PaymentPolicyService } from '../../policies/payments/services/payment-policy.service';

@Component({
  selector: 'app-debtor-master',
  templateUrl: './debtor-master.component.html',
  styles: [
  ]
})
export class DebtorMasterComponent implements OnInit {
  forma: UntypedFormGroup;
  dataReport: any[] = [];
  types: string[] = ['Todos', 'Alta Jubilación', 'Básico', 'Media Superior', 'Reingreso'];
  errorMessage = '';
  loadingData = false;
  firstLoad = false;
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

  constructor(
    private teacherService: TeacherService,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService,
    private paymentService: PaymentPolicyService
  ) {
  }

  ngOnInit(): void {
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      type: [ 'Todos' ]
    });
    
    this.paymentService.getYears()
      .subscribe(years => {
        this.years = years;
      }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  search() {
    this.loadingData = true;
    this.teacherService.getDebtorMasters( this.forma.controls.year.value, this.forma.controls.fortnight.value, this.forma.controls.type.value )
      .subscribe(data => {
        this.dataReport = data;
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  getExcel(): void {
    this.excelService.exportAsExcelFileWithoutHeader(this.clearData(),
      `Mestros_deudores_${ this.forma.controls['type'].value }_${ this.forma.controls.year.value }_${ this.forma.controls.fortnight.value }`);
  }
  clearData(): any[] {
    const array = [];

    array.push(
      this.createItem( 'RFC',
        'Nombre',
        'Tipó',
        '# Prestamo',
        'Número',
        'Importe',
        'Qna Inicial',
        'Qna Final',
        '# Qnas',
        'Descuento',
        'Qna último pago',
        '# qnas desde último pago',
        '# qnas pagadas',
        'Deuda',
        'Teléfono'
      ));

    this.dataReport.forEach(element => {
      array.push(
        this.createItem(
          element.rfc,
          element.name,
          element.type,
          element.loanNumber,
          element.checkNumber,
          element.amount,
          element.fortnightStart,
          element.fortnightEnd,
          element.numberFortnight,
          element.discount,
          element.fortnightLastPayment,
          element.fortnightsElapsedSinceLastPayment,
          element.fortnightsPaid,
          element.owe,
          element.phone
        ));
    });
    return array;
  }

  createItem(
    // id: string,
    rfc: string,
    name: string,
    type: string,
    // loanId: string,
    loanNumber: string,
    number: string,
    amount: string,
    fortnightStart: string,
    fortnightEnd: string,
    numberFortnight: string,
    discount: string,
    fortnightLastPayment: string,
    fortnightsElapsedSinceLastPayment: string,
    fortnightsPaid: string,
    // fortnightPending: string,
    owe: string,
    phone: string ): any {
    return {
        // id,
        rfc,
        name,
        type,
        // loanId,
        loanNumber,
        number,
        amount,
        fortnightStart,
        fortnightEnd,
        numberFortnight,
        discount,
        fortnightLastPayment,
        fortnightsElapsedSinceLastPayment,
        fortnightsPaid,
        // fortnightPending,
        owe,
        phone
    }
  }

}
