import { LowStaffService } from './../low-staffing/services/low-staff.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { RelationshipInterface } from '../../catalogs/relationships/interfaces/relationship.interface';
import { RelationshipService } from '../../catalogs/relationships/services/relationship.service';
import { SavingReportService } from '../../reports/savings/services/saving-report.service';
import { LowStaffInterfaces } from '../low-staffing/interfaces/low-staff.interfaces';
import { TeacherInterface } from './interface/teacher.interface';
import { TeacherService } from './services/teacher.service';
import { BankInterface } from '../banks/interfaces/bank.interface';
import { BankService } from '../banks/services/bank.service';
import { LoanLowStaffInterface } from '../loans/interfaces/loan.lowstaff.interface';

@Component({
  selector: 'app-teacher-low-staff',
  templateUrl: './teacher-low-staff.component.html',
  styles: [],
})
export class TeacherLowStaffComponent implements OnInit {
  searchValue = '';
  errorMessage = '';
  loadingData = false;
  sendingData = false;
  banks: BankInterface[] = [];
  forma: UntypedFormGroup;
  relationships: RelationshipInterface[] = [];
  types: any[] = [
    {
      name: 'Baja Voluntaria',
      percentage: 0.00167
    },
    {
      name: 'Jubilación',
      percentage: 0.00208
    },
    {
      name: 'Renuncia',
      percentage: 0.00167
    },
    {
      name: 'Defunción',
      percentage: 0.00208
    },
  ];
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    applied: true,
    lastStatus: 'Activo',
    credit: 0,
    lastUpdated: new Date(),
    type: '',
    fortnight: '',
    userIdLastUpdated: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    clabe: '',
    curp: '',
    bankId: '00000000-0000-0000-0000-000000000000',
    member: true,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: '',
    NumberOfLoans: 0,
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: '',
      code: '',
    },
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: []
  };
  lowStaff: LowStaffInterfaces = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    lastUpdated: new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    active: true,
    teacherId: '00000000-0000-0000-0000-000000000000',
    checkNumber: 0,
    amountSavings: 0,
    amountInterests: 0,
    amountCredit: 0,
    amount: 0,
    status: 'Pendiente',
    type: '',
    movementDate: new Date(),
    interest5Percentage: 0,
    teacherStatusId: '',
    fortnigh: '',
    amountCreditInterest: 0,
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: 'N/A',
      name: 'N/A',
      street: 'N/A',
      number: '0',
      colony: 'N/A',
      zipCode: 0,
      locality: '',
      email: '',
      admission: '',
      birthdate: '1900-01-01',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: 'N/A',
      lastUpdated: new Date(),
      userIdLastUpdated: this.accountService.getUserId(),
      identifier: 0,
      lastStatus: 'Activo',
      amountSavings: 0,
      credit: 0,
      NumberOfLoans: 0,
      type: '',
      fortnight: '',
      applied: true,
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      document: 0,
      documentNumber: '',
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: 'N/A',
        code: 'N/A',
      },
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      supports: [],
      lowStaffing: []
    },
    movementType: '',
    condoneInterest: false,
    applySupport: true,
    account: '',
    bankId: '',
    beneficiaries: [],
    lowStaffLoans: []
  };
  LowStaffLoans: any;

  constructor(
    private teacherService: TeacherService,
    public accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private reportService: SavingReportService,
    private relationshipService: RelationshipService,
    private lowstaffService: LowStaffService,
    private bankService: BankService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.loadingData = true;
      this.buildForm();
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.relationshipService.get('null').subscribe(
          relationships => {
            this.relationships = relationships;
            this.bankService.get('null').subscribe(
              banks => {
                this.banks = banks;
                this.teacherService.getById(params['id']).subscribe(
                  (teacher) => {
                    this.teacher = Object.assign({}, teacher);
                    this.teacherService.getLoansPendings(this.teacher.id).subscribe(loans => {
                      this.LowStaffLoans = loans;
                      this.forma.controls['bankId'].patchValue( ( this.teacher.bankId ) ? this.teacher.bankId : '' );
                      this.forma.controls['account'].patchValue( ( this.teacher.clabe ) ? this.teacher.clabe : '' );
                      this.setBeneficiaries();
                      this.setLoansPendings();
                      this.loadingData = false;
                    },
                    (error) => this.setErrorMessage(error));
                  },
                  (error) => this.setErrorMessage(error));
              },
              (error) => this.setErrorMessage(error));
          }, error => this.setErrorMessage( error ));
      } else {
        this.router.navigate(['/administration/teachers', this.searchValue]);
      }
    });
  }

  setErrorMessage(error) {
    console.log(error);
    this.errorMessage = error.message;
    this.sendingData = false;
    this.loadingData = false;
  }

  buildForm() {
    this.forma = this.formBuilder.group({
      movementType: [''],
      type: ['', [Validators.required]],
      // tslint:disable-next-line:max-line-length
      movementDate: [ `${new Date().getFullYear()}-${this.formatDate( new Date().getMonth() + 1 )}-${this.formatDate(new Date().getDate())}`, [Validators.required] ],
      applySupport: [true, [Validators.required]],
      condoneInterest: [false],
      account: [''],
      bankId: [''],
      beneficiaries: this.formBuilder.array([]),
      lowStaffLoans: this.formBuilder.array([])
    });
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return `0${value}`;
    }
    return value.toString();
  }

  sendForm(): void {
    this.errorMessage = '';

    if ( this.validateDatabefore() ) {
      this.sendingData = true;
      this.lowStaff = Object.assign({}, this.forma.value);
      this.fillLoanPendings();
      this.reportService.get( this.teacher.rfc )
        .subscribe((data) => {
          const lowStaffType = this.types.find(t => t.name === this.forma.controls['type'].value);
          this.lowStaff.type = lowStaffType.name;
          this.lowStaff.userId = this.accountService.getUserId();
          this.lowStaff.userIdLastUpdated = this.accountService.getUserId();
          this.lowStaff.teacherId = this.teacher.id;
          this.lowStaff.teacherStatusId = this.teacher.teacherStatus.find(ts => ts.active === true).id;
          this.lowStaff.amount = 0;
          this.lowStaff.amountSavings = 0;
          this.lowStaff.amountInterests = 0;
          this.lowStaff.amountCredit = 0;
          this.lowStaff.amountCreditInterest = 0;
          this.lowStaff.status = 'Pendiente';
          this.lowStaff.movementType = this.forma.controls['movementType'].value;
          if ( data.length > 0 ) {
            this.calculateAmount(data, lowStaffType.percentage);
            this.lowStaff.interest5Percentage = this.getSavings5Percentage(data);
          }
          this.lowstaffService.create(this.lowStaff).subscribe( () => {
            this.router.navigate(['/administration/teachers', this.searchValue]);
          }, error => this.setErrorMessage( error ) );
      }, error => this.setErrorMessage( error ));
    }
  }

  fillLoanPendings(): void {
    var lowstaffLoans: LoanLowStaffInterface[] = [];
    this.lowStaffLoansControls.forEach(loan => {
      let LowStaffLoan = this.lowStaff.lowStaffLoans.find(x => x.loanId === loan.get('loanId').value) as LoanLowStaffInterface;
      LowStaffLoan.capital = parseFloat(loan.get('capital').value);
      LowStaffLoan.capitalCondone = parseFloat(loan.get('capitalCondone').value);
      LowStaffLoan.interest = parseFloat(loan.get('interest').value);
      LowStaffLoan.interestCondone = parseFloat(loan.get('interestCondone').value);
      LowStaffLoan.iva = parseFloat(loan.get('iva').value);
      LowStaffLoan.ivaCondone = parseFloat(loan.get('ivaCondone').value);
      LowStaffLoan.lifeInsurance = parseFloat(loan.get('lifeInsurance').value);
      LowStaffLoan.lifeInsuranceCondone = parseFloat(loan.get('lifeInsuranceCondone').value);
      lowstaffLoans.push(LowStaffLoan);
    });
    this.lowStaff.lowStaffLoans = lowstaffLoans;
  }

  validateDatabefore(): boolean {
    if ( this.forma.controls['type'].value === 'Defunción' ) {
      const beneficiaries = this.forma.get('beneficiaries') as UntypedFormArray;
      let validData = true;
      let percentage = 0;
      beneficiaries.controls.forEach(control => {
        if ( control.value.wayToPay === 'Transferencia' && ( control.value.bankId === '' || control.value.account === '') ) {
          validData = false;
        }
        percentage += control.value.percentage;
      });

      if ( !validData ) {
        this.errorMessage = 'Debe de seleccionar el banco e ingresar la cuenta bancaraia para la transferencia';
        return false;
      }

      if ( percentage !== 100 ) {
        this.errorMessage = 'El total del porcentage no debe exceder ni ser menor al 100%';
        return false;
      }
    }
    return true;
  }

  validData(): boolean {

    if ( !this.forma.valid ) {
      return false;
    }

    if ( this.forma.controls['movementType'].value === 'Transferencia' && this.forma.controls['type'].value !== 'Defunción' ) {
      if ( this.forma.controls['account'].value === '' || this.forma.controls['bankId'].value === '' ) {
        return false;
      }
    }
    if ( this.forma.controls['type'].value === 'Defunción' ) {
      const beneficiaries = this.forma.get('beneficiaries') as UntypedFormArray;
      let validData = true;
      beneficiaries.controls.forEach(control => {
        if ( control.value.rfc === '' ) {
          validData = false;
        }
      });

      if ( !validData ) {
        return false;
      }
    }
    return true;
  }

  setBeneficiaries() {
    const beneficiariesArr = this.forma.get('beneficiaries') as UntypedFormArray;
    this.teacher.beneficiaries.forEach( beneficiary => {
      const beneficiaryToAdd = this.formBuilder.group({
        id: beneficiary.id,
        userId: beneficiary.userId,
        date: beneficiary.date,
        active: beneficiary.active,
        lastUpdated: beneficiary.lastUpdated,
        userIdLastUpdated: beneficiary.userIdLastUpdated,
        teacherId: beneficiary.teacherId,
        relationshipId: beneficiary.relationshipId,
        name: beneficiary.name,
        percentage: beneficiary.percentage,
        rfc: ( beneficiary.rfc ) ? beneficiary.rfc : '',
        bankId: ( beneficiary.bankId ) ? beneficiary.bankId : '',
        account: ( beneficiary.account ) ? beneficiary.account : '',
        email: beneficiary.email,
        phone: beneficiary.phone,
        wayToPay: ( beneficiary.wayToPay ) ? beneficiary.wayToPay : '',
      });
      beneficiariesArr.push(beneficiaryToAdd);
    });
  }

  setLoansPendings() {
    const loansPendingsArr = this.forma.get('lowStaffLoans') as UntypedFormArray;
    this.LowStaffLoans.forEach( loan => {
      const loanToAdd = this.formBuilder.group({
        loanId: loan.loan.id,
        capital: [{value: loan.amountSaving, disabled: true}],
        capitalCollected: loan.amountSaving,
        capitalCondone: [{value: '0', disabled: true}, [Validators.required, Validators.min(0)]],
        interest: [{value: loan.interest, disabled: true}],
        interestCollected: loan.interest,
        interestCondone: [{value: '0', disabled: (loan.interest === 0)}, [Validators.required, Validators.min(0)]],
        iva: [{value: loan.iva, disabled: true}],
        ivaCollected: loan.iva,
        ivaCondone: [{value: '0', disabled: (loan.iva === 0)}, [Validators.required, Validators.min(0)]],
        lifeInsurance: [{value: loan.lifeInsurance, disabled: true}],
        lifeInsuranceCollected: loan.lifeInsurance,
        lifeInsuranceCondone: [{value: '0', disabled: (loan.lifeInsurance === 0)}, [Validators.required, Validators.min(0)]],
        fortnightPendings: loan.fortnightPendings
      });
      loansPendingsArr.push(loanToAdd);
    });
  }

  calculateAmount(data: any[], percentage: number) {
    let interestWithImport = 0;
    data.forEach(year => {
      // let interests = 0.00;
      let interestPeritem = 0;
      const arrayYear = Object.keys(year).map(function (key) {
        return [String(key), year[key]];
      });
      for (let index = 0; index < arrayYear.length; index++) {
        if (arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA') {
          // interests = (interests + arrayYear[index][1]) * percentage;
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
          }

          year.interests = parseFloat(interestPeritem.toFixed(2));
          // this.amountInterests += year.interests;
        }
      }
      // year.interests = parseFloat(interests.toFixed(2)) - year.total;
      this.lowStaff.amountInterests += year.interests;
      this.lowStaff.amountSavings += year.total;
      // this.lowStaff.amount = year.interests + year.total;
      // this.amount += year.total;
    });
  }

  getSavings5Percentage( data: any[] ): number {
    const percentage = 0.00208;
    let interestWithImport = 0;
    let amount = 0;
    data.forEach(year => {
      // let interests = 0.00;
      let interestPeritem = 0;
      const arrayYear = Object.keys(year).map(function (key) {
        return [String(key), year[key]];
      });
      for (let index = 0; index < arrayYear.length; index++) {
        if (arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA') {
          // interests = (interests + arrayYear[index][1]) * percentage;
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
          }

          year.interests = parseFloat(interestPeritem.toFixed(2));
          // this.amountInterests += year.interests;
        }
      }
      // year.interests = parseFloat(interests.toFixed(2)) - year.total;
      // this.lowStaff.amountInterests += year.interests;
      // this.lowStaff.amountSavings += year.total;
      amount += year.interests;
      // this.amount += year.total;
    });
    return amount;
  }

  onCondoneBlur(item: FormGroup, mainName: string): void {
    if(item.controls[`${mainName}Condone`].value !== '') {
      const value = parseFloat(item.controls[`${mainName}Collected`].value);
      const condone = parseFloat(item.controls[`${mainName}Condone`].value);
      if(condone > value) {
        item.controls[mainName].patchValue('0');
        item.controls[`${mainName}Condone`].patchValue(value);
      } else {
        item.controls[mainName].patchValue(value - condone);
      }
    }
  }

  onTypeChange(): void {
    if(this.forma.controls['type'].value === 'Defunción')
      this.disableInterest('true', true);
    else {
      this.forma.controls['condoneInterest'].patchValue(false);
      this.disableInterest('false');
    }
  }

  onCondoneInterestChange(): void {
    this.disableInterest(this.forma.controls['condoneInterest'].value);
  }

  disableInterest(condone: string, isDeath: boolean = false): void {
    if(condone === 'true') {
      this.lowStaffLoansControls.forEach(loan => {
        if(isDeath) {
          loan.get('capitalCondone').patchValue(loan.get('capitalCollected').value);
          loan.get('capital').patchValue('0');
        }
        loan.get('interestCondone').disable();
        loan.get('ivaCondone').disable();
        loan.get('lifeInsuranceCondone').disable();
        loan.get('interestCondone').patchValue(loan.get('interestCollected').value);
        loan.get('ivaCondone').patchValue(loan.get('ivaCollected').value);
        loan.get('lifeInsuranceCondone').patchValue(loan.get('lifeInsuranceCollected').value);
        loan.get('interest').patchValue('0');
        loan.get('iva').patchValue('0');
        loan.get('lifeInsurance').patchValue('0');
      });
    } else {
      this.lowStaffLoansControls.forEach(loan => {
        loan.get('capitalCondone').patchValue('0');
        loan.get('capital').patchValue(loan.get('capitalCollected').value);
        loan.get('interestCondone').enable();
        loan.get('ivaCondone').enable();
        loan.get('lifeInsuranceCondone').enable();
        loan.get('interestCondone').patchValue('0');
        loan.get('ivaCondone').patchValue('0');
        loan.get('lifeInsuranceCondone').patchValue('0');
        loan.get('interest').patchValue(loan.get('interestCollected').value);
        loan.get('iva').patchValue(loan.get('ivaCollected').value);
        loan.get('lifeInsurance').patchValue(loan.get('lifeInsuranceCollected').value);
      });
    }
  }

  get lowStaffLoansControls() {
    const loansPendingsArr = this.forma.get('lowStaffLoans') as UntypedFormArray;
    return loansPendingsArr.controls;
  }

}
