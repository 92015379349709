<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Consultas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Ahorros Detalle <small>({{ teachers.length }})</small></h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                      <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                  </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                      <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                  </select>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group ">
                        <label class="control-label ">Tipo</label>
                        <select class="custom-select" formControlName="type" (change)="handlerType( )">
                            <option value="">Todos</option>
                            <option value="Básico">Básico</option>
                            <option value="Reingreso">Reingreso</option>
                            <option value="Media Superior">Media Superior</option>
                            <option value="Alta Jubilación">Alta Jubilación</option>
                        </select>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group ">
                        <label class="control-label ">Porcentage</label>
                        <select class="custom-select" (change)="calculateSavings( $event.target.value )">
                            <option value="0.00208">5 %</option>
                            <option value="0.00167">4 %</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 text-right">
                    <button type="button" [disabled]="teachers.length === 0 || isLoadingData" class="btn btn-outline-warning" (click)="getPDF()">
                        <i class="fas fa-file-pdf"></i>
                        <span> Generar PDF</span>
                    </button>

                    <button [disabled]="teachers.length === 0" style="margin: 15px;" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i class="far fa-file-excel"></i>
                        Generar
                    </button>
                </div>

            </div>
        </form>

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="isLoadingData" type="button" class="btn btn-outline-primary" (click)="getData()">
                    <i [ngClass]="{ 'fas fa-search': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                    <span *ngIf="!isLoadingData">Consultar</span>
                    <span *ngIf="isLoadingData" >
                        Consultando...
                    </span>
                </button>
            </div>
        </div>

        <app-loading-data *ngIf="isLoadingData"></app-loading-data>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

        <div class="form-group row" *ngIf="teachers.length > 0">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-right title">
                Ahorro: {{ amount | currency }} Interes: {{ amountInterests | currency }} Total: {{ amount + amountInterests | currency }}
            </div>
        </div>

        <table class="table table-striped table-hover responsive" *ngIf="teachers.length > 0">
            <thead>
                <tr>
                    <td>Nombre</td>
                    <td>R.F.C.</td>
                    <td>Tipo</td>
                    <td>Ahorros</td>
                    <td>Interes</td>
                    <td>Monto</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of teachers; let i = index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.rfc }}</td>
                    <td>{{ item.type }}</td>
                    <td class="text-right">{{ item.savings | currency }}</td>
                    <td class="text-right">{{ item.interests | currency }}</td>
                    <td class="text-right">{{ item.amount | currency }}</td>
                    <td class="text-right">
                        <span *ngIf="accountService.havePermission(applications, 'Busqueda Global Detalle')">
                            <a class="primary" [routerLink]="['/reports/teachers-global/status', item.id, forma.controls['year'].value, forma.controls['fortnight'].value, '/reports/saving-details' ]" >
                               <i class="fas fa-layer-group"></i>
                           </a>
                       </span>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="3" class="text-right">
                        <b>TOTALES</b>
                    </td>
                    <td class="text-right">
                        {{ amount | currency }}
                    </td>
                    <td class="text-right">
                        {{ amountInterests | currency }}
                    </td>
                    <td class="text-right">
                        {{ amount + amountInterests | currency }}
                    </td>
                </tr>
            </tfoot>
        </table>

    </div>
</article>