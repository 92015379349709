<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Consultas</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row mb-2">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Maestros Deudoras {{ dataReport.length }} </h3>
                    </h3>
                </div>
                <div class="col-md-6 text-right">
                    <button type="button" class="btn btn-outline-info" (click)="getExcel()" >
                        <i class="far fa-file-excel"></i> Excel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <form [formGroup]="forma">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" >
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" >
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>
                
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Tipo</label>
                        <select class="custom-select" formControlName="type" >
                          <option [value]="type" *ngFor="let type of types ">{{ type }}</option>
                      </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <button type="button" class="btn btn-outline-primary" (click)="search()" >
                        <i class="fa-solid fa-magnifying-glass"></i> Buscar
                    </button>
                </div>
            </div>
        </form>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
        <table class="table table-striped table-hover">
            <thead class="text-center">
                <tr>
                    <th scope="col">RFC</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Tipo</th>
                    <th scope="col"># Prestamo</th>
                    <th scope="col">Numero</th>
                    <th scope="col">Adeudo</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of dataReport; let i = index">
                    <td>{{ item.rfc }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.loanNumber }}</td>
                    <td>{{ item.checkNumber }}</td>
                    <td class="text-right">{{ item.owe | currency }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</article>