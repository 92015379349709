import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class IncomingService {
  private apiURL = 'api/Reports/Incoming';
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }
 

  get(year: string,fortnight: string) {
    return this.http.get<any>(this.apiURL+'/'+year+"/"+fortnight);
  }

  getInterest(year:string,fortnight:string){
    return this.http.get<any>(this.apiURL+'/getInterest/'+year+"/"+fortnight);
  }
}
