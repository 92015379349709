import { Routes } from '@angular/router';
import { TransferListComponent } from './transfer-list.component';
import { TransferDetailComponent } from './transfer-detail.component';
import { TransfersComponent } from './transfers.component';

export const TRANSFERS_ROUTES: Routes = [
    { path: 'details/:block', component: TransferDetailComponent },
    { path: 'generate', component: TransfersComponent },
    { path: '', component: TransferListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
