<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="['/administration/teachers', searchValue]" routerLinkActive="router-link-active">Maestros</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Detalle</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                <div class="form-group" >
                    <label class="control-label">Número</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.identifier">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                <label class="control-label">Tipo</label>
                <input type="text" class="form-control underlined" readonly="true" [value]="teacher.type">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <div class="form-group">
                    <label class="control-label">RFC</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.rfc">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group" >
                    <label class="control-label">CURP</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.curp">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group" >
                    <label class="control-label">Nombre</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.name">
                </div>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-2">
                <label class="control-label">Sexo</label>
                <input type="text" class="form-control underlined" readonly="true" [value]="teacher.gender">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <div class="form-group">
                    <label class="control-label">Nacimiento</label>
                    <input type="date" class="form-control underlined" readonly="true" [value]="teacher.birthdate" >
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="form-group" >
                    <label class="control-label">Calle</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.street">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <div class="form-group" >
                    <label class="control-label">Número</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.number">
                </div>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                <div class="form-group" >
                    <label class="control-label">Colonia</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.colony">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <div class="form-group" >
                    <label class="control-label">Código Postal</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.zipCode">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <div class="form-group" >
                    <label class="control-label">Ingreso</label>
                    <input type="date" class="form-control underlined" readonly="true" [value]="teacher.admission">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group" >
                    <label class="control-label">Email</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.email">
                </div>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                <label class="control-label">Aportación</label>
                <input type="text" class="form-control underlined" readonly="true" [value]="teacher.contribution?.percentage + ' %'">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                <label class="control-label row">
                    Quincena 
                </label>
                <input type="text" class="form-control underlined" readonly="true" [value]="teacher.fortnight">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <label class="control-label">Región</label>
                <input type="text" class="form-control underlined" readonly="true" [value]="region">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                <div class="form-group" >
                    <label class="control-label">Localidad</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.locality">
                </div>
            </div>
        </div>

        <div class="row form-group" *ngIf="showBankInformation">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <label class="control-label">Banco</label>
                <input type="text" class="form-control underlined" readonly="true" [value]="teacher?.bank?.name">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group" >
                    <label class="control-label">CLABE</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.clabe">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <label class="control-label">Identificación</label>
                <input type="text" class="form-control underlined" readonly="true" [value]="getDocument( teacher.document )">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <div class="form-group" >
                    <label class="control-label">Número</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.documentNumber">
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <div class="form-group" >
                    <label class="control-label">Importe Ahorro</label>
                    <input type="text" class="form-control underlined" readonly="true" [value]="teacher.amountSavingsFortnight">
                </div>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div *ngFor="let phone of teacher.teacherPhones; let i =index;">
                    <div class="row">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                            <label class="control-label">Tipo</label>
                            <input type="text" class="form-control underlined" readonly="true" [value]="phone.phoneType.name">
                        </div>

                        <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                            <div class="form-group">
                                <label class="control-label">Teléfono </label>
                                <input type="tel" class="form-control underlined" readonly="true" [value]="phone.phone">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div *ngFor="let beneficiary of teacher.beneficiaries; let i = index">
                    <div class="row" >
                        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                            <div class="form-group">
                                <label class="control-label">Nombre </label>
                                <input type="text" class="form-control underlined" readonly="true" [value]="beneficiary.name">
                            </div>
                        </div>

                        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                            
                        </div>
                    </div>
                    <div class="row" >
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <label class="control-label">Parentesco</label>
                            <input type="text" class="form-control underlined" readonly="true" [value]="beneficiary.relationship?.name">
                        </div>

                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label class="control-label">Porcentaje</label>
                                <input type="text" class="form-control underlined" readonly="true" [value]="beneficiary.percentage + ' %'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <app-error-message *ngIf="message" [message]="message"></app-error-message>

        <div class="form-group ">
            <a class="btn btn-outline-info " [routerLink]="['/administration/teachers', searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>

    <ng-template appOpen></ng-template>

</div>