<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Asignación formas de pago <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="input-box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Inicio</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <input type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Al</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <input type="date" name="fin" [value]="fin" [(ngModel)]="fin">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <button type="button" class="btn btn-outline-primary" (click)="loadData()">
                              Buscar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>R.F.C.</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Tipo</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Importe</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Forma de pago</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Folio</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header fixed item-col-actions-dropdown"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">R.F.C.</div>
                        <div>
                            <span>{{ item.rfc }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Tipo</div>
                        <div class="text-right">
                            <span>{{ item.type }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Importe</div>
                        <div class="text-right">
                            <span>{{ ( item.total + item.financialProducts ) - ( item.condoneInterest + item.condoneLifeInsurance ) | currency }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Forma de pago</div>
                        <div class="text-right">
                            <span>{{ item.wayToPay }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Folio</div>
                        <div class="text-right">
                            <span>{{ item.folio }}</span>
                        </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown">
                        <div class="item-list-actions text-center">
                            <span style="padding-left:10px">
                                <!-- <a class="primary" (click)="handlerDetailsClick( item )" href="" data-toggle="modal" *ngIf="accountService.havePermission(applications, 'Formas de Pago (Abonos Detalle)')" >
                                   <i style="font-size: 20px" class="fas fa-exchange-alt"></i>
                               </a> -->
                               <a class="info" [routerLink]="['/administration/ways-to-pay/detail', item.folio, item.type, inicio, fin ]" *ngIf="accountService.havePermission(applications, 'Formas de Pago (Abonos Detalle)')" >
                                    <i class="fas fa-info"></i>
                                </a>
                           </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

    <ng-template appOpen></ng-template>

</article>