import { Component, OnInit } from '@angular/core';
import { LoanReportService } from './services/loan-report.service';
import { ConfigurationsService } from '../../configuration/configurations/services/configurations.service';
import { AccountService } from '../../account/login/services/account.service';
import { ConfigurationsInterface } from '../../configuration/configurations/interfaces/configurations.interface';
import { CurrencyPipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PeriodInterface } from '../../../interfaces/period.interface';
import { start } from 'repl';
import { ExcelService } from '../../../services/excel.service';
declare let jsPDF;

@Component({
  selector: 'app-loan-issued-report',
  templateUrl: './loan-issued-report.component.html',
  styles: []
})
export class LoanIssuedReportComponent implements OnInit {
  dataReport: any[] = [];
  errorMessage = '';
  forma: UntypedFormGroup;
  loadingData = false;
  // years: number[] = [];
  // yearSelected = 0;
  numberLoans = 0;
  amountCapital = 0;
  amountInteres = 0;
  amountIVA = 0;
  amountOpeningBonus = 0;
  amountWarranty = 0;
  amountLifeinsurance = 0;
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  configurations: ConfigurationsInterface =  {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };

  constructor( private reportService: LoanReportService,
    private configurationsService: ConfigurationsService,
    private currencyPipe: CurrencyPipe,
    public accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.forma = this.formBuilder.group({
      // tslint:disable-next-line: max-line-length
      startDate: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`, [Validators.required]],
      // tslint:disable-next-line: max-line-length
      endDate: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`, [Validators.required]]
    });
    this.configurationsService.get()
    .subscribe(configurations => {
      this.configurations = configurations;
      this.loadData();
            // this.loadData( this.yearSelected );
      }, err => this.setErrorMessage(err));
    // this.reportService.getYears( )
    //   .subscribe(data => {
    //     this.years = data;
    //     this.yearSelected = this.years[0]
    //     this.configurationsService.get()
    //       .subscribe(configurations => {
    //         this.configurations = configurations;
    //         this.loadData( this.yearSelected );
    //       }, err => this.setErrorMessage(err));
    //   }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  loadData( ) {
    const period: PeriodInterface = {
      start: this.forma.controls['startDate'].value,
      end: this.forma.controls['endDate'].value,
    };
    this.reportService.getLoansIssued( period )
      .subscribe( (data) => {
        this.dataReport = data;
        this.calculateGrandTotal();
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  searchData() {
    if ( this.isValidDate(this.forma.controls['startDate'].value) && this.inicio.length === 10 ) {
      if ( this.isValidDate(this.forma.controls['endDate'].value) && this.inicio.length === 10 ) {
        this.loadingData = true;
        this.loadData();
      } else {
        this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
      }
    } else {
      this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
    }
  }

  calculateGrandTotal(): void {
    this.numberLoans = 0;
    this.amountCapital = 0;
    this.amountInteres = 0;
    this.amountIVA = 0;
    this.amountOpeningBonus = 0;
    this.amountWarranty = 0;
    this.dataReport.forEach(element => {
      this.numberLoans += element.loans;
    this.amountCapital += element.capital;
    this.amountInteres += element.interest;
    this.amountIVA += element.iva;
    this.amountOpeningBonus += element.openingBonus;
    this.amountWarranty += element.warranty;
    this.amountLifeinsurance += element.lifeinsurance;
    });
  }

  getPDF() {
    const totalPagesExp = '{total_pages_count_string}';
    const head = this.getHeader();
    const body = this.setBodyData(this.dataReport);
    // this.generatePDF(head, body, totalPagesExp, this.configurations.imageSNTE, this.configurations.imageFOCAPS, this.yearSelected);
  }

  getHeader(): any[] {
    return [{ amount: 'Prestamo', import: 'Monto Cheque', numberFortnight: '# Qnas', discount: 'Descuento', 
    loans: '# Prestamos',
    capital: 'Capital', interest: 'Interes', iva: 'IVA', openingBonus: 'Prima de Apertura', warranty: 'Prima de Garantia' }]
  }

  setBodyData(data: any[]): any[] {
    const body = [];
    data.forEach(item => {
      body.push({
        amount: this.currencyPipe.transform(item.amount),
        import: this.currencyPipe.transform(item.import),
        numberFortnight: item.numberFortnight,
        discount: this.currencyPipe.transform(item.discount),
        loans: item.loans,
        capital: this.currencyPipe.transform(item.capital),
        interest: this.currencyPipe.transform(item.interest),
        iva: this.currencyPipe.transform(item.iva),
        openingBonus: this.currencyPipe.transform(item.openingBonus),
        warranty: this.currencyPipe.transform(item.warranty)
      });
    });

    body.push({
      amount: 'Total General',
      loans: this.numberLoans,
      capital: this.currencyPipe.transform(this.amountCapital),
      interest: this.currencyPipe.transform(this.amountInteres),
      iva: this.currencyPipe.transform(this.amountIVA),
      openingBonus: this.currencyPipe.transform(this.amountOpeningBonus),
      warranty: this.currencyPipe.transform(this.amountWarranty)
    });

    body[body.length - 1].amount = { content: `Total General`,
      colSpan: 4, styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].loans = { content: this.numberLoans,
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].capital = { content: this.currencyPipe.transform(this.amountCapital),
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].interest = { content: this.currencyPipe.transform(this.amountInteres),
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].iva = { content: this.currencyPipe.transform(this.amountIVA),
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].openingBonus = { content: this.currencyPipe.transform(this.amountOpeningBonus),
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].warranty = { content: this.currencyPipe.transform(this.amountWarranty),
      styles: { halign: 'right', fontStyle: 'bold' } };
    return body;
  }

  generatePDF(head: any[], body: any[], totalPagesExp: string, imageSNTE: string, imageFOCAPS: string, yearSelected: number): void {
    const doc = new jsPDF('landscape');
    const pageContent = function (data) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if ( imageSNTE ) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
     }

     if ( imageFOCAPS ) {
       doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45 , 15, 30, 20);
     }
      doc.text(`INFORME PRESTAMOS OTORGADOS ${ yearSelected }`, data.settings.margin.left + 55, 20);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 40 },
      headStyles: {
        halign: 'center'
      },
      columnStyles: {
        amount: {
          halign: 'right'
        },
        import: {
          halign: 'right'
        },
        numberFortnight: {
          halign: 'right'
        },
        discount: {
          halign: 'right'
        },
        loans: {
          halign: 'right'
        },
        capital: {
          halign: 'right'
        },
        interest: {
          halign: 'right'
        },
        iva: {
          halign: 'right'
        },
        openingBonus: {
          halign: 'right'
        },
        warranty: {
          halign: 'right'
        },
      },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`Prestamos_Otorgados_${ yearSelected }.pdf`);
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

  getExcel(): void {
    const data = this.dataReport.map( r => ({
      Prestamo: r.amount,
      MontoCheque: r.import,
      Quincenas: r.numberFortnight,
      Descuento: r.discount,
      Prestamos: r.loans,
      Capital: r.capital,
      Interes: r.interest,
      IVA: r.iva,
      PrimaApertura: r.warranty,
      PrimaGarantia: r.openingBonus,
      SeguroVida: r.lifeinsurance
    }));
    this.excelService.exportAsExcelFile(data,
      `Prestamos_Otorgados_`);
  }

}
