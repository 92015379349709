import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoanInterface } from '../interfaces/loan.interface';
import { Observable } from 'rxjs/Observable';
import { QuestionInterface } from '../interfaces/question.interface';
import { ResponseInterface } from '../../../../interfaces/response.interface';

@Injectable()
export class LoanService {
  private apiURL = 'api/Administration/Loans';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( loan: LoanInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', loan);
    }

    get( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    getLoansByRFC( rfc: string ) {
      return this.http.get<any>( this.apiURL + '/LoansByRFC/' + rfc );
    }

    update( id: string, loan: LoanInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, loan );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }

    havePending( ) {
      return this.http.get<any>( this.apiURL + '/HavePending' );
    }

    getPending( ) {
      return this.http.get<any>( this.apiURL + '/LoansPending' );
    }

    apply( userId: string, loans: any[] ) {
      return this.http.put<any>( this.apiURL + '/Apply/' + userId, loans );
    }

    createQuestion( question: QuestionInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Question', question);
    }

    getQuestions( searchValue: string ) {
      return this.http.get<any>( `${ this.apiURL }/Questions/${ searchValue }`);
    }

    deleteQuestion( teacherId: string ) {
      return this.http.delete<any>( this.apiURL + '/Question/' + teacherId );
    }

    getChecksTransfers( type: number, startDate: string, endDate: string, searchValue: string ) {
      return this.http.get<ResponseInterface>( `${ this.apiURL }/ChecksTransfers/${ type }/${ startDate }/${ endDate }/${ searchValue }` );
    }

}
