import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-low-staffing-reports',
  template: `
  <router-outlet></router-outlet>
  `,
  styles: [
  ]
})
export class LowStaffingReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
