import { Injectable } from '@angular/core';
import { SavingInterface } from '../components/administration/savings/interfeces/saving.interface';
import { SavingAmountInterface } from '../interfaces/saving-amount.interface';
import { NotificationService } from './notification.service';
// @Injectable()
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private notificationService: NotificationService
  ) { }

  setErrorMessage( objectError ) {
    console.error(objectError);
    this.notificationService.showErrorMessage(objectError.message);
  }

  calculateAmountGlobal(data: SavingInterface[], percentage: number): SavingAmountInterface {
    let amount = 0;
    let amountInterests = 0;
    let interestWithImport = 0;

    data.forEach(year => {
      let interestPeritem = 0;
      let amountPeritem = 0;
      const arrayYear = Object.keys(year).map(function(key) {
        return [String(key), year[key]];
      });

      for (let index = 0; index < arrayYear.length; index++) {
        if ( arrayYear[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
            amountPeritem += arrayYear[index][1];
          }
        }
      }
      year.folio = parseFloat(interestPeritem.toFixed(2)).toString();
      amountInterests += parseFloat(year.folio);
      amount += amountPeritem;
    });

    const objAmount: SavingAmountInterface = {
      amount: amount,
      amountInterest: amountInterests
    };

    return objAmount;
  }
}
