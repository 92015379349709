<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/administration/collections-seech']" routerLinkActive="router-link-active">Cobranza</a></li>
            <li class="breadcrumb-item active" aria-current="page">Detalle</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Cobranza <small>Detalle</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">
        <div class="row">
            <label class="col-md-7">Archivo: {{ collection.fileName }}</label>
            <label class="col-md-3">Quincena: {{ collection.fortnight }}</label>
            <label class="col-md-2">Documento: {{ collection.type }}</label>
        </div>

        <div class="row">
            <label class="col-md-4">Tipo: {{ collection.payroll }}</label>
            <label class="col-md-4">Fecha movimiento: {{ collection.movementDate | date:'dd/MM/yyyy' }}</label>
            <label class="col-md-4">Fecha aplicación: {{ collection.accountingDate | date:'dd/MM/yyyy' }}</label>
        </div>

        <div class="row">
            <label class="col-md-3"><strong>Importe aplicado: {{ collection.appliedAmount | currency }}</strong> </label>
            <label class="col-md-3 required"><strong>Importe no aplicado: {{ collection.notAppliedAmount | currency }}</strong> </label>
            <label class="col-md-3">Movimientos {{ collection.collectionDetails.length }} </label>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-right">
                <div class="row">
                    <div class="col-6">
                        <button [disabled]="processing || loadingData || collection.collectionDetails.length === 0" class="btn btn-outline-info" type="button" (click)="handlerClick()" *ngIf="someValid()" >
                            <i class="fas " [ngClass]="{ 'fa-exchange-alt': !processing, 'fa-sync-alt fa-spin': processing }"></i>
                            <span *ngIf="!processing"> Revertir</span>
                            <span *ngIf="processing" >
                                Revirtiendo...
                            </span>
                        </button>
                    </div>
                    <div class="col-6">
                        <button [disabled]="exporting || loadingData || collection.collectionDetails.length === 0" type="button" class="btn btn-outline-success" (click)="getExcel()">
                            <i [ngClass]="{ 'far fa-file-excel': !exporting, 'fas fa-sync-alt fa-spin': exporting }"></i>
                            <span *ngIf="!exporting"> Generar</span>
                            <span *ngIf="exporting" > Procesando...</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <app-loading-data *ngIf="loadingData"></app-loading-data>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

        <table class="table table-striped table-hover" *ngIf="collection.collectionDetails.length > 0">
            <thead>
                <tr>
                    <th>#</th>
                    <th>RFC</th>
                    <th>Aplicado</th>
                    <th>No aplicado</th>
                    <th>Mensaje</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of collection.collectionDetails; let i = index" [ngClass]="{'required': item.active != true}">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.curp }}</td>
                    <td class="text-right">
                        <span *ngIf="item.applied">{{ item.amount | currency }}</span>
                    </td>
                    <td class="text-right">
                        <span class="required" *ngIf="!item.applied">{{ item.amount | currency }}</span>
                    </td>
                    <td>
                        {{ item.message }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2" class="text-right">
                        TOTALES
                    </td>
                    <td class="text-right">
                        {{ collection.appliedAmount | currency }}
                    </td>
                    <td class="text-right required">
                        {{ collection.notAppliedAmount | currency }}
                    </td>
                    <td class="text-right">
                        {{ collection.appliedAmount + collection.notAppliedAmount | currency }}
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>

</article>