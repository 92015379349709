import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { OpenDirective } from '../../modals/open.directive';
import { AcceptanceDateComponent } from '../transfers/acceptance-date.component';
import { CollectionService } from './services/collection.service';

@Component({
  selector: 'app-collection-history',
  templateUrl: './collection-history.component.html',
  styles: []
})

export class CollectionHistoryComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  errorMessage = '';
  isLoadingData = false;
  applications: ApplicationInterface[];

  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor( public accountService: AccountService,
    private collectionService: CollectionService,
    private componentFactoryResolver: ComponentFactoryResolver ) { }

  ngOnInit() {
    this.accountService.getApplications('banks', 'collections').subscribe(
      (applications) => {
        this.applications = applications;
        this.loadData('null');
      },
      (error) => this.setErrorMessage(error)
    );
  }

  search() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  loadData(value: string) {
    this.loadingData = true;
    this.collectionService.getSearch(value, 'Banco')
    .subscribe(response => {
      console.log( response );
      if( response.isSuccess ) {
        this.jsonArray = response.result;
      } else {
        this.errorMessage = response.message;
      }
      
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    this.errorMessage = error;
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.isLoadingData = false;
    }
  }

  showConfirm( id: string ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory( AcceptanceDateComponent );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AcceptanceDateComponent>componentRef.instance).open();
    (<AcceptanceDateComponent>componentRef.instance).alertOutput
      .subscribe( (date: string) => {
        this.collectionService.getApply(id, date)
          .subscribe(success => {
            this.search();
          }
          , error => this.setErrorMessage(error));
      }, error => this.setErrorMessage(error) );
  }

}
