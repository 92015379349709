import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../account/login/services/account.service';
import { CollectionService } from '../../banks/collections/services/collection.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { OpenDirective } from '../../modals/open.directive';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-collection-list-seech',
  templateUrl: './collection-list-seech.component.html',
  styles: []
})
export class CollectionListSeechComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  errorMessage = '';
  applications: ApplicationInterface[];

  constructor( public accountService: AccountService,
    private collectionService: CollectionService,
    private notificationService: NotificationService,
    private helperService: HelperService ) { }

  ngOnInit() {
    this.accountService.getApplications('administration', 'collections-seech').subscribe(
      (applications) => {
        this.applications = applications;
        this.loadData('null');
      },
      (error) => this.helperService.setErrorMessage(error)
    );
  }

  loadData(value: string) {
    this.loadingData = true;
    this.collectionService.getSearch(value, 'SEECH')
    .subscribe(response => {
      console.log( response );
      if ( response.isSuccess ) {
        this.jsonArray = response.result;
      } else {
        this.errorMessage = response.message;
      }
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  search(): void {
    this.loadingData = true;
    if ( this.searchValue === '' ) {
      this.loadData('null');
    } else {
      this.loadData( this.searchValue );
    }
  }

  showConfirm( id: string ): void {
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory( AcceptanceDateComponent );

    // const viewContainerRef = this.adHost.viewContainerRef;
    // viewContainerRef.clear();

    // const componentRef = viewContainerRef.createComponent(componentFactory);
    // (<AcceptanceDateComponent>componentRef.instance).open();
    // (<AcceptanceDateComponent>componentRef.instance).alertOutput
    //   .subscribe( (date: string) => {
    //     this.collectionService.getApply(id, date)
    //       .subscribe(success => {
    //         this.search();
    //       }
    //       , error => this.helperService.setErrorMessage(error));
    //   }, error => this.helperService.setErrorMessage(error));
  }

}
