import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-acceptance-date',
  template: `
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><i class="fas fa-exclamation-triangle"></i> Acción</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="forma">
              <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label class="control-label"><i class="fas fa-asterisk" ></i> Fecha de aplicación</label>
                    <input type="date" class="form-control underlined" maxlength="18" formControlName="AcceptanceDate">
                    <span *ngIf="forma.controls['AcceptanceDate'].touched && forma.controls['AcceptanceDate'].errors?.required" class="has-error">
                      El campo es requerido.
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" [disabled]="!forma.valid" class="btn btn-primary" (click)="ok()" >
              Si
            </button>
            <button type="button" class="btn btn-secondary" (click)="cancel()" >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class AcceptanceDateComponent implements OnInit {
  forma: UntypedFormGroup;
  @Output() public alertOutput = new EventEmitter<any>();

  constructor( private formBuilder: UntypedFormBuilder ) { }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      AcceptanceDate: ['', [Validators.required]]
    });
  }

  open() {
    $('#myModal').modal();
  }

  ok() {
    $('#myModal').modal('hide');

    this.alertOutput.emit( this.forma.controls['AcceptanceDate'].value );
  }

  cancel() {
    $('#myModal').modal('hide');
  }

}
