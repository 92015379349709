import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DrawService } from './services/draw.service';
import { DrawInterface } from './interfaces/draw.interface';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-draw-list',
  templateUrl: './draw-list.component.html',
  styles: []
})
export class DrawListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  applications: ApplicationInterface[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private drawsService: DrawService,
    public accountService: AccountService,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.accountService.getApplications('administration', 'draws').subscribe(
      applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  loadData(value: string) {
    this.loadingData = true;
    this.drawsService.get(value).subscribe(
      data => {
        this.jsonArray = data;
        this.loadingData = false;
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  search() {
    let search = this.searchValue;
    if (search === '') {
      search = 'null';
    }
    this.loadData(search);
  }

  deleteItem(id: string, draw: DrawInterface) {
    draw.active = false;
    this.drawsService.update(id, draw).subscribe(
      () => {
        this.loadData('null');
        this.notificationService.toastConfirm(`Se elimino el sorteo ${draw.name}`);
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  showConfirm(id: string, draw: DrawInterface) {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar el sorteo ${draw.name}?`)
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(id, draw);
      }
    });
  }

  agregar() {
    const text = this.searchValue;
    this.router.navigate(['administration/draws/add', '', text]);
  }
}
