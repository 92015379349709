import { Component, OnInit } from '@angular/core';
import { LoanReportService } from './services/loan-report.service';
import { ConfigurationsService } from '../../configuration/configurations/services/configurations.service';
import { AccountService } from '../../account/login/services/account.service';
import { CurrencyPipe } from '@angular/common';
import { ConfigurationsInterface } from '../../configuration/configurations/interfaces/configurations.interface';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { PaymentPolicyService } from '../../policies/payments/services/payment-policy.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../services/excel.service';
import { InteresRecoverInterface } from './interfaces/interes-recover.interface';
declare let jsPDF;

@Component({
  selector: 'app-interests-recover-report',
  templateUrl: './interests-recover-report.component.html',
  styles: []
})
export class InterestsRecoverReportComponent implements OnInit {
  dataReport: InteresRecoverInterface[] = [];
  forma: UntypedFormGroup;
  errorMessage = '';
  loadingData = false;
  amountLifeInsurance = 0;
  amountCapital = 0;
  amountInteres = 0;
  amountIVA = 0;
  processingData = false;
  years: number[] = [];
  applications: ApplicationInterface[];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  configurations: ConfigurationsInterface =  {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };

  constructor( private reportService: LoanReportService,
    private configurationsService: ConfigurationsService,
    private currencyPipe: CurrencyPipe,
    public accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private paymentService: PaymentPolicyService,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      type: [1],
      amount: [0]
    });

    this.activatedRoute.params.subscribe(params => {
      if ( params['year'] && params['fortnight'] ) {
        this.forma.controls['year'].patchValue(params['year']);
        this.forma.controls['fortnight'].patchValue(params['fortnight']);
      }

      this.accountService.getApplications('reports', 'interestsrecover')
      .subscribe(applications => {
        this.applications = applications;
        this.configurationsService.get()
        .subscribe(configurations => {
          this.configurations = configurations;
          this.paymentService.getYears()
          .subscribe(years => {
            this.years = years;
            this.loadingData = false;
            // this.loadData( );
          }, error => this.setErrorMessage(error));
        }, err => this.setErrorMessage(err));
      }, err => this.setErrorMessage(err));
    }, error => this.setErrorMessage(error));
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  loadData( ) {
    this.reportService.getInterestsRecover( this.forma.controls['year'].value, this.forma.controls['fortnight'].value )
      .subscribe( (data) => {
        this.dataReport = data;
        console.log( data );
        // tslint:disable-next-line: max-line-length
        this.amountCapital = data.reduce(( sum, value ) => sum += ( value.prestamos - ( value.abonos + value.liquidacion + value.cobros )), 0) + 128236806.88;
        this.amountInteres = data.reduce(( sum, value ) => sum += ( value.interesGenerado - value.interesPagado ), 0) + 30193255.76;
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  sumGrandTotal(payment: any): void {
    this.amountCapital += payment.capital;
    this.amountInteres += payment.interests;
    this.amountIVA += payment.iva;
    this.amountLifeInsurance += payment.lifeInsurance;
  }

  restGrandTotal(payment: any): void {
    this.amountCapital -= payment.paymentCapital;
    this.amountInteres -= payment.paymentInterest;
    this.amountIVA -= payment.paymentIVA;
    this.amountLifeInsurance -= payment.paymentLifeInsurance;
  }

  evaluatedivisor(divisor: string): number {
    const arrDivisor = divisor.split('.');
    let loop = parseInt(arrDivisor[0], 0);
    if ( loop > 1 && parseInt(arrDivisor[1], 0) > 1 ) {
      loop += 1;
    }

    return ( loop === 0 ) ? 1 : loop;
  }
  searchInterest() {
    this.loadingData = true;
    this.loadData();
  }

  getPDF() {
    const totalPagesExp = '{total_pages_count_string}';
    const head = this.getHeader();
    const body = this.setBodyData(this.dataReport);
    this.generatePDF(head,
      body,
      totalPagesExp,
      this.configurations.imageSNTE,
      this.configurations.imageFOCAPS,
      this.forma.controls.year.value,
      this.forma.controls.fortnight.value);
  }

  getHeader(): any[] {
    return [{ amount: 'RFC', numberFortnight: 'Nombre', capital: 'Capital',
    interest: 'Interes', iva: 'IVA', lifeInsurance: 'Prima de Protección', total: 'Total' } ];
  }

  setBodyData(data: any[]): any[] {
    const body = [];
    data.forEach(item => {
      body.push({
        amount: item.rfc, // this.currencyPipe.transform(item.amount),
        numberFortnight: item.name,
        capital: this.currencyPipe.transform( item.capital - item.paymentCapital ),
        interest: this.currencyPipe.transform( item.interests - item.paymentInterests ),
        iva: this.currencyPipe.transform( item.iva - item.paymentIva ),
        lifeInsurance: this.currencyPipe.transform( item.lifeInsurance - item.paymentLifeInsurance ),
        total: this.currencyPipe.transform( (( item.capital - item.paymentCapital ) +
          ( item.interests - item.paymentInterests ) +
          ( item.iva - item.paymentIva ) +
          ( item.lifeInsurance - item.paymentLifeInsurance ) ) )
      });
    });

    body.push({
      amount: 'Total General',
      capital: this.currencyPipe.transform(this.amountCapital),
      interest: this.currencyPipe.transform(this.amountInteres),
      iva: this.currencyPipe.transform(this.amountIVA),
      lifeInsurance: this.currencyPipe.transform(this.amountLifeInsurance),
      total: this.currencyPipe.transform(this.amountLifeInsurance + this.amountCapital + this.amountInteres + this.amountIVA)
    });

    body[body.length - 1].amount = { content: `Total General`,
      colSpan: 2, styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].lifeInsurance = { content: this.currencyPipe.transform(this.amountLifeInsurance),
      tyles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].capital = { content: this.currencyPipe.transform(this.amountCapital),
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].interest = { content: this.currencyPipe.transform(this.amountInteres),
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 1].iva = { content: this.currencyPipe.transform(this.amountIVA),
      styles: { halign: 'right', fontStyle: 'bold' } };

    // tslint:disable-next-line:max-line-length
    body[body.length - 1].total = { content: this.currencyPipe.transform(this.amountLifeInsurance + this.amountCapital + this.amountInteres + this.amountIVA),
      styles: { halign: 'right', fontStyle: 'bold' } };

    return body;
  }

  generatePDF(head: any[],
    body: any[],
    totalPagesExp: string,
    imageSNTE: string,
    imageFOCAPS: string,
    year: string,
    fortnight: string ): void {
    const doc = new jsPDF( 'landscape' );
    const pageContent = function (data) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if ( imageSNTE ) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
     }

     if ( imageFOCAPS ) {
       doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45 , 15, 30, 20);
     }
      doc.text(`INFORME INTERES POR RECUPERAR ${ year }-${ fortnight }`
      , data.settings.margin.left + 55, 20);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 40 },
      headStyles: {
        halign: 'center'
      },
      columnStyles: {
        amount: {
          halign: 'right'
        },
        numberFortnight: {
          halign: 'right'
        },
        lifeInsurance: {
          halign: 'right'
        },
        capital: {
          halign: 'right'
        },
        interest: {
          halign: 'right'
        },
        iva: {
          halign: 'right'
        },
        total: {
          halign: 'right'
        },
      },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`Prestamos_Detalle${ this.forma.controls.year }_${ this.forma.controls.fortnight }.pdf`);
  }

  handlerSearch(): void {
    this.loadingData = true;
    this.dataReport = [];
    this.amountCapital = 0;
    this.amountInteres = 0;
    this.amountIVA = 0;
    this.amountLifeInsurance = 0;
    this.loadData();
  }

  getExcel(): void {
    this.processingData = true;
    const data = this.dataReport.map( d => ({
      rfc: d.rfc,
      nombre: d.name,
      prestamos: d.prestamos,
      abonos: d.abonos,
      liquidacion: d.liquidacion,
      cobros: d.cobros,
      interesGenerado: d.interesGenerado,
      interesPagado: d.interesPagado,
      prima: d.prima
    }));
    // tslint:disable-next-line:max-line-length
    this.excelService.exportAsExcelFile(data, `Interes_por_recuperar_${ this.forma.controls['year'].value }_${ this.forma.controls['fortnight'].value }`);
    this.processingData = false;
  }

  clearData(data: any[]): any[] {
    const array = [];

    array.push( this.createItem( 'RFC', 'Nombre', 'Prima de Protección', 'Capital', 'Interes', 'IVA', 'Total' ) );

    data.forEach(element => {
      array.push(
        this.createItem( element.rfc,
          element.name,
          element.lifeInsurance,
          element.capital,
          element.interest,
          element.iva,
          element.recovery )
        );
    });
    return array;
  }

  // tslint:disable-next-line:max-line-length
  createItem(rfc: string, nombre: string, primaproteccion: string, capital: string, interes: string, iva: string, total: string ): any {
    return {
        rfc: rfc,
        nombre: nombre,
        primaproteccion: primaproteccion,
        capital: capital,
        interes: interes,
        iva: iva,
        recovery: total
      };
  }

}
