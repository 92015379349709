import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SavingReportService {

  private apiURL = 'api/Reports/Savings';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    get( rfc: string ) {
      return this.http.get<any>(this.apiURL + '/' + rfc);
    }

    getAccountingSavings( year: number, fortnight: number ) {
      return this.http.get<any>(this.apiURL + '/' + year + '/' + fortnight);
    }

    getDataSavingDetails( year: number, fortnight: number ) {
      return this.http.get<any>(this.apiURL + '/Details/' + year + '/' + fortnight);
    }
}
