import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelService } from '../../../services/excel.service';
import { AccountService } from '../../account/login/services/account.service';
import { LowStaffService } from '../../administration/low-staffing/services/low-staff.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';

@Component({
  selector: 'app-low-staffing-report',
  templateUrl: './low-staffing-report.component.html',
  styles: [
  ]
})
export class LowStaffingReportComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  errorMessage = '';
  typeSelected = 3;
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  jsonArray: any[] = [];
  applications: ApplicationInterface[];

  constructor( private activatedRoute: ActivatedRoute,
    private lowstaffService: LowStaffService, private router: Router,
    public accountService: AccountService,
    private excelService: ExcelService ) { }

  ngOnInit(): void {
    this.loadingData = true;
    this.accountService.getApplications('reports', 'lowstaffing')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['waytopay']) {
            this.typeSelected = params['waytopay'];
          }
          if (params['start']) {
            this.inicio = params['start'];
          }
          if (params['end']) {
            this.fin = params['end'];
          }

          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData( );
          } else {
            this.loadData( );
          }
        });
      }, error => console.error(error));
  }

  loadData( ) {
    this.lowstaffService.getReport( this.typeSelected,  this.inicio, this.fin, this.searchValue )
    .subscribe( response => {
      if ( response.isSuccess ) {
        this.jsonArray = response.result;
      } else {
        this.errorMessage = response.message;
      }
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.loadingData = false;
    }
  }

  search() {
    this.loadingData = true;
    this.loadData( );
  }

  exportData(): void {
    const newData = this.jsonArray.map(d => ({
      RFC: d.curp,
      Nombre: d.name,
      Quincena: d.fortnigh,
      Interes: d.amountInterests,
      Ahorro: d.amountSavings,
      Adeudo: d.amountCredit,
      InteresSinIVA: d.amountCreditInterest - (d.amountCreditInterest * 0.16),
      IVAInteres: d.amountCreditInterest * 0.16,
      InteresAdeudo: d.amountCreditInterest,
      TotalAdeudo: d.amountCredit + d.amountCreditInterest,
      Importe: d.amount,
      Cheque: d.number
    }));

    this.excelService.exportAsExcelFile(newData,
      `Cheques_Transferencias_`);
  }
}
