import { Component, OnInit } from '@angular/core';
import { LoanService } from './services/loan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoanInterface } from './interfaces/loan.interface';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { InputTypes } from '../../../../app/enums/sweetalert.enum';

@Component({
  selector: 'app-loan-list',
  templateUrl: './loan-list.component.html',
  styles: []
})
export class LoanListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray: any[] = [];
  statusArr: string[] = [];
  pending = 0;
  applications: ApplicationInterface[];

  constructor( private activatedRoute: ActivatedRoute, private loanService: LoanService,
    public accountService: AccountService,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'loans')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if ( params.searchValue ) {
            this.searchValue = params.searchValue;
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      }, error => this.helperService.setErrorMessage(error));
  }

  loadData(value: string) {
    this.loanService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
      this.loanService.havePending( )
      .subscribe(pending => {
        this.pending = pending;
        this.loadingData = false;
      },
      error => this.helperService.setErrorMessage(error));
    },
    error => this.helperService.setErrorMessage(error));
  }

  search() {
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search);
  }

  deleteItem(id: string, loan: LoanInterface) {
    loan.status = 'Cancelado';
    if (loan.teacher.colony === '') {
      loan.teacher.colony = 'N/A';
    }
    if (loan.teacher.street === '') {
      loan.teacher.street = 'N/A';
    }
    this.loanService.update(id, loan).subscribe(
      () => {
        this.loadData('null');
        this.notificationService.toastConfirm('Se cancelo correctamente el prestamo');
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  showChangeStatus( loan: LoanInterface ) {
    let status = 'Preliquidado'
    if( loan.status !== 'Activo' ) {
      status = 'Activo'
    }

    this.notificationService.showConfirm(`¿Seguro que desea cambiar el estatus al prestamo ${loan.loanNumber} a ${status}?`)
    .then(result => {
      if(result.isConfirmed) {
        loan.status = status;
        this.changeStatus(loan);
      }
    });
  }

  changeStatus( loan: LoanInterface ): void {
    loan.userIdLastUpdated = this.accountService.getUserId();
    if ( loan.teacher.colony === '' ) {
      loan.teacher.colony = 'N/A';
    }
    if ( loan.teacher.street === '' ) {
      loan.teacher.street = 'N/A';
    }

    this.loanService.update(loan.id, loan)
        .subscribe( () => {
          this.notificationService.toastConfirm('Se cambio el estatus correctamente.');
        }, error => this.helperService.setErrorMessage(error));
  }

  async showConfirm(id: string, numero: string, name: string, loan: LoanInterface) {
    const comment = await this.notificationService.showConfirmInput(`¿Seguro que desea cancelar el prestamo ${ numero } ( ${ name } )?`, 'Cancelación de prestamos', InputTypes.Textarea );
    if(comment !== null) {
      loan.userIdLastUpdated = this.accountService.getUserId();
      loan.comment = `${ loan.comment } CANCELADO: ${ comment }`;
      this.deleteItem(id, loan);
    }
  }
}
