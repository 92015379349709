import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { DeadlineService } from './services/deadline.service';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-deadline-list',
  templateUrl: './deadline-list.component.html',
  styles: []
})
export class DeadlineListComponent implements OnInit {
  applications: ApplicationInterface[];
  searchValue = '';
  loadingData = false;
  jsonArray = [];
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accountService: AccountService,
    private deadlinesServeice: DeadlineService,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.accountService.getApplications('administration', 'deadlines').subscribe(
      applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  loadData(value: string) {
    this.loadingData = true;
    this.deadlinesServeice.get(value).subscribe(
      data => {
        this.jsonArray = data;
        this.loadingData = false;
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  search() {
    let search = this.searchValue;
    if (search === '') {
      search = 'null';
    }
    this.loadData(search);
  }

  showConfirm( id: string, active: boolean ) {
    var message = ( active ) ? `¿Seguro que desea habilitar el tipo de préstamo?` : `¿Seguro que desea deshabilitar el tipo de préstamo?`;
    this.notificationService.showConfirm(message, 'Alerta')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(id, active);
      }
    });
  }

  deleteItem(id: string, active: boolean) {
    this.deadlinesServeice.delete(id, this.accountService.getUserId(), active).subscribe(
      () => {
        this.search();
        let message = (active) ? 'Se habilito el tipo de préstamo' : 'Se deshabilito el tipo de préstamo';
        this.notificationService.toastConfirm(message);
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

}
