<div class="content">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Consultas</li>
          <li class="breadcrumb-item"><a [routerLink]="['/reports/loans-check-transfers']" routerLinkActive="router-link-active">Consecutivo Cheques y/o Transferencias de Prestamos</a></li>
      </ol>
  </nav>

  <div class="title-search-block">
      <div class="title-block">
          <div class="row">
              <div class="col-md-6">
                  <h3 class="title">
                      <h3 class="title">Cheque</h3>
                  </h3>
              </div>
          </div>
      </div>
  </div>

  <div class="card card-block animated fadeIn fast">
      <form [formGroup]="forma" (ngSubmit)="sendForm()">
          <div class="row form-group">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['movementDate'].valid && forma.controls['movementDate'].touched, 'has-success': forma.controls['movementDate'].valid && forma.controls['movementDate'].touched }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Fecha del movimiento</label>
                      <input type="date" class="form-control underlined" formControlName="movementDate">
                      <span *ngIf="forma.controls['movementDate'].touched && forma.controls['movementDate'].errors?.required" class="has-error ">
                          El campo nombre es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['number'].valid && forma.controls['number'].touched, 'has-success': forma.controls['number'].valid && forma.controls['number'].touched }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Número</label>
                      <input type="number" class="form-control underlined" formControlName="number">
                      <span *ngIf="forma.controls['number'].touched && forma.controls['number'].errors?.required" class="has-error ">
                          El campo nombre es requerido.
                      </span>
                      <span *ngIf="forma.controls['number'].touched && forma.controls['number'].errors?.min" class="has-error ">
                          El valor mínimo es 1
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <div class="form-group" [ngClass]="{'has-error': !forma.controls['amount'].valid && forma.controls['amount'].touched, 'has-success': forma.controls['amount'].valid && forma.controls['amount'].touched }">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Importe</label>
                    <input type="number" class="form-control underlined" formControlName="amount">
                    <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.required" class="has-error ">
                        El campo nombre es requerido.
                    </span>
                    <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.min" class="has-error ">
                        El valor mínimo es 0
                    </span>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <div class="form-group ">
                    <label class="control-label "><i class="fas fa-asterisk required "></i> Comentario</label>
                    <input type="text" class="form-control underlined" formControlName="comment">
                    <span *ngIf="forma.controls['comment'].touched && forma.controls['comment'].errors?.minlength" class="has-error ">
                      El campo nombre debe tener almenos {{ forma.controls['comment'].errors.minlength.requiredLength }}
                      caracteres.
                  </span>
                </div>
              </div>
          </div>

          <div class="form-group text-center ">
              <button [disabled]="!forma.valid || sendingData " class="btn btn-outline-success" >
                  <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                  <span *ngIf="!sendingData ">Guardar</span>
                  <span *ngIf="sendingData ">Guardando...</span>
              </button>
          </div>

          <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
      </form>

      <div class="form-group">
          <a class="btn btn-outline-info " [routerLink]="['/reports/loans-check-transfers'] "><i class="fas fa-arrow-left "></i> Regresar</a>
      </div>
  </div>
</div>
