import { Routes } from '@angular/router';
import { SavingComponent } from './saving.component';
import { SavingListComponent } from './saving-list.component';

export const SAVINGS_ROUTES: Routes = [
    { path: 'add/:teacher/:pathReturn', component: SavingComponent },
    { path: 'add/:teacher', component: SavingComponent },
    { path: 'edit/:id', component: SavingComponent },
    { path: 'edit/:id/:searchValue', component: SavingComponent },
    { path: '', component: SavingListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
