<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Contabilidad</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Beneficiarios</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="isLoadingData" type="button" class="btn btn-outline-success" (click)="getExcel()">
                  <i [ngClass]="{ 'far fa-file-excel': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                  <span *ngIf="!isLoadingData">Generar</span>
                  <span *ngIf="isLoadingData" >
                      Procesando...
                  </span>
              </button>
            </div>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
    </div>
</article>