import { DataUpdateInterface } from './../interfaces/data-update.interface';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class DataUpdateService {
  private apiURL = 'api/Administration/DataUpdates';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    get( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    getValidateTeacher( number: number, rfc: string, curp: string ) {
      return this.http.get<any>( `${ this.apiURL }/ValidateTeacher/${ number }/${ rfc }/${ curp }` );
    }

    process( dataUpdates: DataUpdateInterface[] ) {
      return this.http.post<any>(this.apiURL + '/Process', dataUpdates);
    }

    processTeacher( dataUpdate: DataUpdateInterface ) {
      return this.http.post<any>(this.apiURL + '/ProcessTeacher', dataUpdate);
    }

    sendMails(  ) {
      return this.http.post<any>(this.apiURL + '/SednMail', null);
    }
}
