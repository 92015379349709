import { ExcelService } from './../../../services/excel.service';
import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../account/login/services/account.service';
import { LowStaffService } from '../../administration/low-staffing/services/low-staff.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { ChecksInterface } from './interfaces/checks.interface';
import { ChecksService } from './services/checks.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-low-staffing-global',
  templateUrl: './low-staffing-global.component.html',
  styles: []
})
export class LowStaffingGlobalComponent implements OnInit {
  applications: ApplicationInterface[];
  exporting = false;
  loadingData = false;
  type = 2;
  searchValue = '';
  errorMessage = '';
  jsonArray: ChecksInterface[] = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;

  constructor( private checksService: ChecksService,
    private lowStaffService: LowStaffService,
    public accountService: AccountService,
    private excelService: ExcelService,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService ) { }

  ngOnInit() {
    this.accountService.getApplications('reports', 'low-staffing-global')
      .subscribe(applications => {
        this.applications = applications;
        this.loadData();
      }, error => this.helperService.setErrorMessage(error));
  }

  loadData( ) {
    this.lowStaffService.getReport(this.type , this.inicio, this.fin, this.searchValue)
    .subscribe(response => {
      if ( response.isSuccess ) {
        this.jsonArray = response.result;
      } else {
        this.errorMessage = response.message;
      }
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  search(): void {
    this.loadingData = true;
    this.loadData();
  }

  getExcel(): void {
    this.exporting = true;
    const newData = this.jsonArray.map(d => ({
      nombre: d.name,
      fecha: `${ d.movementDate.toString().substring(0, 4) }-${ d.movementDate.toString().substring(5, 7) }-${ d.movementDate.toString().substring(8, 10) }`,
      cheque: d.number,
      importe: d.amount,
      comentario: d.comment,
      estatus: ( d.active ) ? 'Activo' : 'Cancelado'
    }));
    this.excelService.exportAsExcelFile(newData,
      `Cheques_`);
    this.exporting = false;
  }

  showConfirm( check: ChecksInterface ): void {
    this.notificationService.showConfirm(`¿Seguro que desea cancelar el movimiento ${ check.number }?`, 'Cancelación de movimiento')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem( check );
      }
    });
  }

  deleteItem( check: ChecksInterface ): void {
    this.checksService.delete(check.id).subscribe(
      () => {
        check.active = false;
        this.notificationService.toastConfirm(`Se cancelo correctamente el movimiento`);
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  agregar(): void {
    this.router.navigate( ['reports/low-staffing-global/add'] );
  }
}
