import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SupportCreateInterface } from '../interfaces/support-create.interface';

@Injectable()
export class SupportService {
  private apiURL = 'api/Administration/Supports';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    get( searchValue: string, startDate: string, endDate: string ) {
      let url = `${ this.apiURL }/Search/${ searchValue }`;
      if ( startDate !== '' && endDate !== '' ) {
        url = `${ url }/${ startDate }/${ endDate }`;
      }
      return this.http.get<any>( url );
    }

    create( saving: SupportCreateInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', saving);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    havePending( ) {
      return this.http.get<any>( this.apiURL + '/HavePending' );
    }

    getPending( ) {
      return this.http.get<any>( this.apiURL + '/SupportsPending' );
    }

    apply( userId: string, supports: any[] ) {
      return this.http.put<any>( this.apiURL + '/Apply/' + userId, supports );
    }
}
